import CommonComponent from "../../components/CommonComponent";

// Modules
import * as statisticsActions from "../../../redux/modules/Statistics";

class CompareFaceDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_COMPARE_FACE_ALL") {
      this.onGetCompareFaceAllResults(props);
    }
  }

  /* ------------------------------------------ request get compare face list ----------------------------------------- */
  requestGetCompareFaceAll = (hotelReservationOwnerId, hotelAccompanyId) => {
    this.setState({
      requestType: "GET_COMPARE_FACE_ALL",
    });
    this.props.onGetAll(hotelReservationOwnerId, hotelAccompanyId);
  };

  /* ------------------------------------------ get compare face list results ----------------------------------------- */
  onGetCompareFaceAllResults = (props) => {
    if (props.compareFaceList?.length === 0 && props.failData == null) {
      return;
    }
    if (props.compareFaceList != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: "",
      });
      if (props.compareFaceList?.success == true) {
      } else if (props.failData?.code != "INVALID_TOKEN") {
        this.props.showSimpleTopPopupDialog(
          this.props.MultiLanguage["language"][this.props.multiLanguageCode][
            "contents_failed_get_rule_information"
          ],
          () => {
            this.props.hideTopPopupDialog();
          }
        );
      } else {
        this.onFailed(props);
      }
    }
  };

  onFailed = (props) => {
    if (props.failData != null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      // else
      return;
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      compareFaceList: state.statistics.compareFaceList,
      failData: state.statistics.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: (hotelReservationOwnerId, hotelAccompanyId) => dispatch(statisticsActions.onGetAllCompareFace(hotelReservationOwnerId, hotelAccompanyId)),
    };
    return returnProps;
  };
}

export default CompareFaceDetailData;
