// Root.js
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import "../react/css/common/myConectButton.css";
import "../react/css/common/myConectColor.css";
import "../react/css/common/myConectInput.css";
import "../react/css/common/myConectTypography.css";
import "../react/css/root.css";
import {
  AgreementHistoryDetailPage,
  AgreementHistoryListPage,
  AmenityAddPage,
  AmenityDetailPage,
  AmenityEditPage,
  AmenityListPage,
  AppDetailPage,
  AppListPage,
  ApplicationEndPointAddPage,
  ApplicationEndPointDetailPage,
  ApplicationEndPointEditPage,
  ApplicationEndPointListPage,
  ApplicationTranslationAddPage,
  ApplicationTranslationDetailPage,
  ApplicationTranslationEditPage,
  ApplicationTranslationListPage,
  ApplicationVersionAddPage,
  ApplicationVersionListPage,
  AuthDataListPage,
  BookingEmailDetailPage,
  Buttons,
  ChangePasswordCharacterPage,
  ChangePasswordEditPage,
  CityAddPage,
  CityDetailPage,
  CityEditPage,
  CityListPage,
  // test page
  ColorDefinitions,
  CommonComponent,
  CountryAddPage,
  CountryDetailPage,
  CountryEditPage,
  CountryListPage,
  CreateEmailConfirmCharacterPage,
  CreateEmailConfirmEditPage,
  Dialog,
  EmailConfirmFailureCharacterPage,
  EmailConfirmFailureEditPage,
  EmailConfirmSuccessCharacterPage,
  EmailConfirmSuccessEditPage,
  FacilityImageAddPage,
  FacilityImageDetailPage,
  FacilityImageEditPage,
  FacilityImageListPage,
  ForgotIdCharacterPage,
  ForgotIdEditPage,
  ForgotPasswordCharacterPage,
  ForgotPasswordEditPage,
  GuestApplicationVersionAddPage,
  GuestApplicationVersionListPage,
  HotelAddPage,
  HotelAppDownloadListPage,
  HotelDetailPage,
  HotelEditPage,
  HotelListPage,
  HotelcopyPage,
  Login,
  Menu,
  MyInfoEditPage,
  OTAReservationPage,
  ParkingStaffApplicationVersionAddPage,
  ParkingStaffApplicationVersionListPage,
  PasswordEditPage,
  PaymentDetailPage,
  PaymentListPage,
  PaymentLogDetailPage,
  PaymentLogListPage,
  ProfileCharacterPage,
  ProfileEditPage,
  RecommendAddPage,
  RecommendDetailPage,
  RecommendEditPage,
  RecommendListPage,
  RegionAddPage,
  RegionDetailPage,
  RegionEditPage,
  RegionListPage,
  SettingListPage,
  SettingUpdatePage,
  StaffApplicationVersionAddPage,
  StaffApplicationVersionListPage,
  StaffNotificationLogDetailPage,
  StaffNotificationLogListPage,
  StaffTransferLogHistoryDetailInfoPage,
  StaffTransferLogHistoryDetailPage,
  StaffTransferLogHistoryListPage,
  TermsAddPage,
  TermsDetailPage,
  TermsEditPage,
  TermsGroupAddPage,
  TermsGroupDetailPage,
  TermsGroupEditPage,
  TermsGroupListPage,
  TermsListPage,
  TransactionLogDetailPage,
  TransactionLogListPage,
  TransferLogDetailPage,
  TransferLogListPage,
  Typography,
  UseKeyLogDetailPage,
  UseKeyLogListPage,
  UserInfoAddPage,
  UserInfoDetailPage,
  UserInfoEditPage,
  UserInfoListPage,
  UserNotificationLogDetailPage,
  UserNotificationLogListPage,
  UserTransferLogDetailPage,
  UserTransferLogHistoryDetailInfoPage,
  UserTransferLogHistoryDetailPage,
  UserTransferLogHistoryListPage,
  UserTransferLogListPage,
  VersionAddPage,
  VersionDetailPage,
  VersionListPage,
  WithdrawCharacterPage,
  WithdrawEditPage,
  IDRecognitionAddPage,
  IDRecognitionDetailPage,
  IDRecognitionEditPage,
  IDRecognitionListPage,
  StatisticsListPage,
  ReservationListPage,
  ReservationDetailPage,
  CompareFaceDetailPage,
} from "./components/Paths";

class Root extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasLoginData: false,
    };
  }

  componentDidMount() {
    // 로컬스토리지에 값이 있는지 확인
    const loginData = localStorage.getItem("accessToken");
    if (loginData) {
      this.setState({ hasLoginData: true });
    }
    // popup 창 닫기
    this.props.hideTopPopupDialog();
    this.props.loadMultiLanguage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorCode == "INVALID_TOKEN" || this.props.errorCode == "EMPTY_TOKEN") {
      //error code값 비우기
      this.props.checkedCommonError();
      //해당되는 popup창 노출
      this.props.showTopPopupDialog(
        "OK",
        this.props.errorDescription,
        () => {},
        () => {},
        () => {
          this.props.hideTopPopupDialog();
          localStorage.clear();
          this.props.history.push("/login");
        }
      );
    }
  }

  render() {
    return (
      <div className="wrap">
        <Menu />
        <Route
          path="/"
          exact
          render={() => {
            const hasLoginData = localStorage.getItem("accessToken");
            return hasLoginData ? <Redirect to="/hotelListPage" /> : <Redirect to="/login" />;
          }}
        />
        <Route path="/login" component={Login} />
        <Route path="/hotelListPage" component={HotelListPage} />
        <Route path="/hotelDetailPage" component={HotelDetailPage} />
        <Route path="/hotelAddPage" component={HotelAddPage} />
        <Route path="/hotelEditPage" component={HotelEditPage} />
        <Route path="/hotelcopyPage" component={HotelcopyPage} />

        <Route path="/settingListPage" component={SettingListPage} />
        <Route path="/settingUpdatePage" component={SettingUpdatePage} />

        <Route path="/regionListPage" component={RegionListPage} />
        <Route path="/regionAddPage" component={RegionAddPage} />
        <Route path="/regionDetailPage" component={RegionDetailPage} />
        <Route path="/regionEditPage" component={RegionEditPage} />

        <Route path="/countryListPage" component={CountryListPage} />
        <Route path="/countryAddPage" component={CountryAddPage} />
        <Route path="/countryDetailPage" component={CountryDetailPage} />
        <Route path="/countryEditPage" component={CountryEditPage} />

        <Route path="/cityListPage" component={CityListPage} />
        <Route path="/cityAddPage" component={CityAddPage} />
        <Route path="/cityDetailPage" component={CityDetailPage} />
        <Route path="/cityEditPage" component={CityEditPage} />

        <Route path="/appListPage" component={AppListPage} />
        <Route path="/appDetailPage" component={AppDetailPage} />

        <Route path="/paymentListPage" component={PaymentListPage} />
        <Route path="/paymentDetailPage" component={PaymentDetailPage} />
        <Route path="/paymentLogListPage" component={PaymentLogListPage} />
        <Route path="/paymentLogDetailPage" component={PaymentLogDetailPage} />

        <Route path="/versionListPage" component={VersionListPage} />
        <Route path="/versionDetailPage" component={VersionDetailPage} />
        <Route path="/versionAddPage" component={VersionAddPage} />
        <Route path="/applicationVersionListPage" component={ApplicationVersionListPage} />
        <Route path="/applicationVersionAddPage" component={ApplicationVersionAddPage} />
        <Route path="/guestApplicationVersionListPage" component={GuestApplicationVersionListPage} />
        <Route path="/guestApplicationVersionAddPage" component={GuestApplicationVersionAddPage} />
        <Route path="/staffApplicationVersionListPage" component={StaffApplicationVersionListPage} />
        <Route path="/staffApplicationVersionAddPage" component={StaffApplicationVersionAddPage} />
        <Route path="/parkingStaffApplicationVersionListPage" component={ParkingStaffApplicationVersionListPage} />
        <Route path="/parkingStaffApplicationVersionAddPage" component={ParkingStaffApplicationVersionAddPage} />

        <Route path="/applicationTranslationListPage" component={ApplicationTranslationListPage} />
        <Route path="/applicationTranslationAddPage" component={ApplicationTranslationAddPage} />
        <Route path="/applicationTranslationDetailPage" component={ApplicationTranslationDetailPage} />
        <Route path="/applicationTranslationEditPage" component={ApplicationTranslationEditPage} />

        <Route path="/bookingEmailDetailPage" component={BookingEmailDetailPage} />

        <Route path="/changePasswordEditPage" component={ChangePasswordEditPage} />
        <Route path="/changePasswordCharacterPage" component={ChangePasswordCharacterPage} />

        <Route path="/transferLogListPage" component={TransferLogListPage} />
        <Route path="/transferLogDetailPage" component={TransferLogDetailPage} />

        <Route path="/userTransferLogListPage" component={UserTransferLogListPage} />
        <Route path="/userTransferLogDetailPage" component={UserTransferLogDetailPage} />

        <Route path="/transactionLogListPage" component={TransactionLogListPage} />
        <Route path="/transactionLogDetailPage" component={TransactionLogDetailPage} />

        <Route path="/userTransferLogHistoryListPage" component={UserTransferLogHistoryListPage} />
        <Route path="/userTransferLogHistoryDetailPage" component={UserTransferLogHistoryDetailPage} />
        <Route path="/userTransferLogHistoryDetailInfoPage" component={UserTransferLogHistoryDetailInfoPage} />

        <Route path="/staffTransferLogHistoryListPage" component={StaffTransferLogHistoryListPage} />
        <Route path="/staffTransferLogHistoryDetailPage" component={StaffTransferLogHistoryDetailPage} />
        <Route path="/staffTransferLogHistoryDetailInfoPage" component={StaffTransferLogHistoryDetailInfoPage} />

        <Route path="/userNotificationLogListPage" component={UserNotificationLogListPage} />
        <Route path="/userNotificationLogDetailPage" component={UserNotificationLogDetailPage} />

        <Route path="/staffNotificationLogListPage" component={StaffNotificationLogListPage} />
        <Route path="/staffNotificationLogDetailPage" component={StaffNotificationLogDetailPage} />

        <Route path="/applicationEndPointListPage" component={ApplicationEndPointListPage} />
        <Route path="/applicationEndPointAddPage" component={ApplicationEndPointAddPage} />
        <Route path="/applicationEndPointDetailPage" component={ApplicationEndPointDetailPage} />
        <Route path="/applicationEndPointEditPage" component={ApplicationEndPointEditPage} />

        <Route path="/termsGroupListPage" component={TermsGroupListPage} />
        <Route path="/termsGroupAddPage" component={TermsGroupAddPage} />
        <Route path="/termsGroupDetailPage" component={TermsGroupDetailPage} />
        <Route path="/termsGroupEditPage" component={TermsGroupEditPage} />

        <Route path="/agreementHistoryListPage" component={AgreementHistoryListPage} />
        <Route path="/agreementHistoryDetailPage" component={AgreementHistoryDetailPage} />

        <Route path="/termsListPage" component={TermsListPage} />
        <Route path="/termsAddPage" component={TermsAddPage} />
        <Route path="/termsDetailPage" component={TermsDetailPage} />
        <Route path="/termsEditPage" component={TermsEditPage} />

        <Route path="/authDataListPage" component={AuthDataListPage} />

        <Route path="/useKeyLogListPage" component={UseKeyLogListPage} />
        <Route path="/useKeyLogDetailPage" component={UseKeyLogDetailPage} />

        <Route path="/amenityListPage" component={AmenityListPage} />
        <Route path="/amenityAddPage" component={AmenityAddPage} />
        <Route path="/amenityDetailPage" component={AmenityDetailPage} />
        <Route path="/amenityEditPage" component={AmenityEditPage} />

        <Route path="/facilityImageListPage" component={FacilityImageListPage} />
        <Route path="/facilityImageAddPage" component={FacilityImageAddPage} />
        <Route path="/facilityImageDetailPage" component={FacilityImageDetailPage} />
        <Route path="/facilityImageEditPage" component={FacilityImageEditPage} />

        <Route path="/recommendListPage" component={RecommendListPage} />
        <Route path="/recommendAddPage" component={RecommendAddPage} />
        <Route path="/recommendDetailPage" component={RecommendDetailPage} />
        <Route path="/recommendEditPage" component={RecommendEditPage} />

        <Route path="/userInfoListPage" component={UserInfoListPage} />
        <Route path="/userInfoAddPage" component={UserInfoAddPage} />
        <Route path="/userInfoDetailPage" component={UserInfoDetailPage} />
        <Route path="/userInfoEditPage" component={UserInfoEditPage} />
        <Route path="/myInfoEditPage" component={MyInfoEditPage} />

        <Route path="/passwordEditPage" component={PasswordEditPage} />
        <Route path="/forgotPasswordEditPage" component={ForgotPasswordEditPage} />
        <Route path="/forgotPasswordCharacterPage" component={ForgotPasswordCharacterPage} />
        <Route path="/forgotIdEditPage" component={ForgotIdEditPage} />
        <Route path="/forgotIdCharacterPage" component={ForgotIdCharacterPage} />
        <Route path="/createEmailConfirmEditPage" component={CreateEmailConfirmEditPage} />
        <Route path="/createEmailConfirmCharacterPage" component={CreateEmailConfirmCharacterPage} />
        <Route path="/emailConfirmFailureEditPage" component={EmailConfirmFailureEditPage} />
        <Route path="/emailConfirmFailureCharacterPage" component={EmailConfirmFailureCharacterPage} />
        <Route path="/emailConfirmSuccessEditPage" component={EmailConfirmSuccessEditPage} />
        <Route path="/emailConfirmSuccessCharacterPage" component={EmailConfirmSuccessCharacterPage} />
        <Route path="/profileEditPage" component={ProfileEditPage} />
        <Route path="/profileCharacterPage" component={ProfileCharacterPage} />
        <Route path="/withdrawEditPage" component={WithdrawEditPage} />
        <Route path="/withdrawCharacterPage" component={WithdrawCharacterPage} />

        <Route path="/idRecognitionListPage" component={IDRecognitionListPage} />
        <Route path="/idRecognitionAddPage" component={IDRecognitionAddPage} />
        <Route path="/idRecognitionDetailPage" component={IDRecognitionDetailPage} />
        <Route path="/idRecognitionEditPage" component={IDRecognitionEditPage} />

        <Route path="/download" component={HotelAppDownloadListPage} />

        <Route path="/OTAReservation" component={OTAReservationPage} />

        <Route path="/statisticsListPage" component={StatisticsListPage} />
        <Route path="/reservationListPage" component={ReservationListPage} />
        <Route path="/reservationDetailPage" component={ReservationDetailPage} />
        <Route path="/compareFaceDetailpage" component={CompareFaceDetailPage} />

        {/* view  */}
        <Route path="/colorDefinitions" component={ColorDefinitions} />
        <Route path="/typography" component={Typography} />
        <Route path="/buttons" component={Buttons} />

        <div>
          {/* dialog가 true면 팝업 실행 */}
          {this.props.isShowDialog && (
            <Dialog
              isShowDialog={this.props.isShowDialog}
              dialogType={this.props.dialogType}
              content={this.props.dialogContent}
              onClickOK={() => this.props.positiveFunction()}
              onClose={() => this.props.closeFunction()}
            />
          )}
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {
    //data에서 받은 값
    ...CommonComponent.mapStateToProps(state, props),
    isShowDialog: state.constant.dialog.isShowDialog,
    dialogType: state.constant.dialog.dialogType,
    dialogContent: state.constant.dialog.dialogContent,
    positiveFunction: state.constant.dialog.positiveFunction,
    negativeFunction: state.constant.dialog.negativeFunction,
    closeFunction: state.constant.dialog.closeFunction,
    // isLoggedIn: state.auth.isLoggedIn,
    errorCode: state.constant.commonError.code,
    errorDescription: state.constant.commonError.description,
  };
};

let mapDispatchToProps = (dispatch, props) => {
  return {
    ...CommonComponent.mapDispatchToProps(dispatch, props),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Root));
