// IDRecognitionAddData.js
import CommonComponent from "../../components/CommonComponent";

// module
import * as idRecognitionActions from "../../../redux/modules/IDRecognition";

class IDRecognitionEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_ID_RECOGNITION") {
      this.onEditIDRecognitionOneResults(props);
    }
  }

  // redux에 edit idRecognition 요청
  requestEditIDRecognition = (idRecognitionId, idRecognitionInfo) => {
      this.setState({
        requestType: "EDIT_ID_RECOGNITION",
      });
      this.props.onEdit(idRecognitionId, idRecognitionInfo);
  };

  // get edit idRecognition results
  onEditIDRecognitionOneResults = (props) => {
    if (props.idRecognitionEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.idRecognitionEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_id_recognition_information"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onEditSuccess = () => {
    const idRecognitionId = this.state.idRecognitionId;
    // this.props.history.push(`/idRecognitionDetailPage?idRecognitionId=${idRecognitionId}`);
    this.props.history.goBack();
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      idRecognitionDetail: state.idRecognition.idRecognitionDetail,
      idRecognitionEdit: state.idRecognition.idRecognitionEdit,
      failData: state.idRecognition.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onEdit: (idRecognitionId, idRecognitionInfo) => dispatch(idRecognitionActions.onEdit(idRecognitionId, idRecognitionInfo)),
    };
  };
}

export default IDRecognitionEditData;
