import CommonComponent from "../../components/CommonComponent";

// module
import * as applicationTranslationActions from "../../../redux/modules/ApplicationTranslation";



class ApplicaitonTranslationEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      country: "",
      city: "",
      region: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_APPLICAITON_TRANSLATION") {
      this.onEditApplicaitonTranslationOneResults(props);
    }
  }

  // redux에 edit applicationTranslation 요청
  requestEditApplicationTranslation = (applicationTranslationId, appType, osType, appVersion, version, translationFile) => {
    const formData = new FormData();
    formData.append("applicationType", appType);
    formData.append("osType", osType);
    formData.append("applicationVersion", appVersion);
    formData.append("version", version);
    formData.append("translationFile", translationFile);

    this.setState({
      requestType: "EDIT_APPLICAITON_TRANSLATION",
    });
    this.props.onEdit(applicationTranslationId, formData);
  };

  onEditApplicaitonTranslationOneResults(props) {
    if (props.applicationTranslationEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.applicationTranslationEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_upload_file"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  }

  onEditSuccess = () => {
    const applicationTranslationId = this.state.applicationTranslationId;
    // this.props.history.push(`/applicationTranslationDetailPage?applicationTranslationId=${applicationTranslationId}`);
    this.props.history.goBack();
  };


  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  //cancelBtn
  cancelBtn = () => {
    this.setState({ requestType: "" });
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_not_save_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.onBackBtn();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    // this.props.onClearApplicaitonTranslationEditState()
    this.props.showTopPopupDialog(
      "N",
      // "Are you sure you want to delete it?",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.editApplicaitonTranslation();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editApplicaitonTranslation = () => {
    this.setState({ isShowAllLoading: true });
    // 현재 선택된 값들을 default로 저장
    this.requestEditApplicationTranslation(this.state.applicationTranslationId, this.state.appType, this.state.osType, this.state.appVersion, this.state.version, this.state.translationFile);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/applicationTranslationListPage");
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      applicationTranslationDetail: state.applicationTranslation.applicationTranslationDetail,
      applicationTranslationEdit: state.applicationTranslation.applicationTranslationEdit,
      failData: state.applicationTranslation.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetOne: (applicationTranslationId) => dispatch(applicationTranslationActions.onGetApplicationTranslationOne(applicationTranslationId)),
      onEdit: (applicationTranslationId, formData) => dispatch(applicationTranslationActions.onEditApplicationTranslation(applicationTranslationId, formData)),
    };
  };
}

export default ApplicaitonTranslationEditData;
