import CommonComponent from "../../components/CommonComponent";

// module
import * as authActions from "../../../redux/modules/Auth";
import * as hotelActions from "../../../redux/modules/Hotel";


class HotelEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      country: "",
      city: "",
      region: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.requestType == "EDIT_HOTEL") {
      this.onEditHotelOneResults(nextProps);
    }
    if (this.state.requestType == "EDIT_HOTEL_IS_SHOW") {
      this.onEditHotelIsShowResults(nextProps);
    }
    if (nextProps.regionData != this.props.regionData) {
      if (this.state.requestType == "GET_REGION") {
        this.onGetRegionResults(nextProps);
      }
    }
    if (nextProps.countryData != this.props.countryData) {
      if (this.state.requestType == "GET_COUNTRY") {
        this.onGetCountryResults(nextProps);
      }
    }
    if (nextProps.cityData != this.props.cityData) {
      if (this.state.requestType == "GET_CITY") {
        this.onGetCityResults(nextProps);
      }
    }
    if (nextProps.countryNameData != this.props.countryNameData) {
      if (this.state.requestType == "GET_COUNTRY_NAME") {
        this.onGetCountryNameResults(nextProps);
      }
    }
    if (this.state.requestType == "EDIT_COUNTRY_NAME") {
      this.onEditCountryNameResults(nextProps);
    }
    if (this.state.requestType == "EDIT_PAYMENT_SETTING") {
      this.onEditPaymentSettingResults(nextProps);
    }
  }

  // redux에 edit hotel 요청
  requestEditHotel = (hotelId, region, country, city) => {
    this.setState({
      requestType: "EDIT_HOTEL",
    });
    this.props.onEdit(hotelId, region, country, city);
  };

  // redux에 eidt isShow 요청
  requestEditHotelIsShow = (hotelId, isShow) => {
    this.setState({
      requestType: "EDIT_HOTEL_IS_SHOW",
    });
    this.props.onEditIsShow(hotelId, isShow);
  };

  // redux에 get region  요청
  requestGetRegion = () => {
    this.setState({
      requestType: "GET_REGION",
    });
    this.props.onGetRegion();
  };

  // redux에 get country  요청
  requestGetCountry = (region) => {
    this.setState({
      requestType: "GET_COUNTRY",
    });
    this.props.onGetCountry(region);
  };
  // redux에 get city  요청
  requestGetCity = (regionId, countryId) => {
    this.setState({
      requestType: "GET_CITY",
    });
    this.props.onGetCity(regionId, countryId);
  };

  // redux에 get country name 요청
  requestGetCountryName = () => {
    this.setState({
      requestType: "GET_COUNTRY_NAME",
    });
    this.props.onGetCountryName();
  };

  // redux에 edit country name 요청
  requestEditCountryName = (hotelId, countryName) => {
    this.setState({
      requestType: "EDIT_COUNTRY_NAME",
    });
    this.props.onEditCountryName(hotelId, countryName);
  };

  // redux에 edit payment setting  요청
  requestEditPaymentSetting = () => {
    const { hotelId, paymentServerURL, isDirectPayable, isCardTokenizable } = this.state;
    this.setState({
      requestType: "EDIT_PAYMENT_SETTING",
    });
    this.props.onEditPaymentSetting(hotelId, paymentServerURL, isDirectPayable, isCardTokenizable);
  };

  // get Region results
  onGetRegionResults = (props) => {
    if (props.regionData?.length === 0 && props.failData == null) {
      // 응답이 오기 전에 실행하지 못하도록 return함
      return;
    }
    this.setState({ requestType: "" });
    this.requestGetCountryName();

    if (props.regionData?.success == true) {
      props.regionData.results.rows.map((region) => {
        if (region.name == props.hotelDetail?.results.region) {
          this.requestGetCountry(region.id);
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_region_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  // get Country results
  onGetCountryResults = (props) => {
    if (props.countryData?.length === 0 && props.failData == null) {
      // 응답이 오기 전에 실행하지 못하도록 return함
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.countryData?.success == true) {
      props.regionData.results.rows.map((region) => {
        if (region.name == props.hotelDetail?.results.region) {
          props.countryData.results.rows.map((country) => {
            if (country.name == props.hotelDetail?.results.country) {
              this.requestGetCity(region.id, country.id);
            }
          });
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_country_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  // get city results
  onGetCityResults = (props) => {
    if (props.cityData?.length === 0 && props.failData == null) {
      // 응답이 오기 전에 실행하지 못하도록 return함
      return;
    }
    this.setState({ requestType: "", isShowAllLoading: false });

    if (props.cityData?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_city_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  // get country name results
  onGetCountryNameResults = (props) => {
    if (props.countryNameData?.length === 0 && props.failData == null) {
      // 응답이 오기 전에 실행하지 못하도록 return함
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.countryNameData?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      const reason = props.countryNameData.reason;
      this.props.showSimpleTopPopupDialog(reason ?? this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  // edit hotel results
  onEditHotelOneResults = (props) => {
    if (props.hotelEdit == null && props.failData == null) {
      // 응답이 오기 전에 실행하지 못하도록 return함
      return;
    }
    if (props.hotelEdit != null || props.failData != null) {
      this.setState({ isShowAllLoading: false, requestType: "" });
      if (props.hotelEdit?.success == true) {
        // isShow 수정 요청
        const { hotelId, isShow } = this.state;
        this.setState({ isShowAllLoading: true });
        this.requestEditHotelIsShow(hotelId, isShow);
      } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_hotel"], () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.onFailed(props);
      }
    }
  };

  // edit country name results
  onEditCountryNameResults = (props) => {
    if (props.countryNameEdit == null && props.failData == null) {
      // 응답이 오기 전에 실행하지 못하도록 return함
      return;
    }

    if (props.countryNameEdit != null || props.failData != null) {
      this.setState({ isShowAllLoading: false, requestType: "" });
      if (props.countryNameEdit?.success == true) {
        // 수정이 완료된 후에 페이지 이동
        this.onEditSuccess();
      } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_hotel"], () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.onFailed(props);
      }
    }
  };

  // edit hotel isShow results
  onEditHotelIsShowResults = (props) => {
    if (props.hotelIsShowEdit == null && props.failData == null) {
      // 응답이 오기 전에 실행하지 못하도록 return함
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.hotelIsShowEdit?.success == true) {
      // pms setting eidt 요청
      this.setState({ isShowAllLoading: true });
      this.requestEditPaymentSetting(this.state.hotelId);
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_hotel"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  // edit payment setting results
  onEditPaymentSettingResults = (props) => {
    if (props.paymentSettingEdit == null && props.failData == null) {
      // 응답이 오기 전에 실행하지 못하도록 return함
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.paymentSettingEdit?.success == true) {
      const { hotelId, countryName } = this.state;
      this.setState({ isShowAllLoading: true });
      this.requestEditCountryName(hotelId, countryName);
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_hotel"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onEditSuccess = () => {
    const hotelId = this.state.hotelId;
    // this.props.history.push(`/hotelDetailPage?hotelId=${hotelId}`);
    this.props.history.goBack();
  };

  onFailed = (props) => {
    if (props.failData != null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      hotelDetail: state.hotel.hotelDetail,
      hotelEdit: state.hotel.hotelEdit,
      hotelIsShowEdit: state.hotel.hotelIsShowEdit,
      regionData: state.hotel.regionData,
      countryData: state.hotel.countryData,
      cityData: state.hotel.cityData,
      countryNameData: state.hotel.countryNameData,
      countryNameEdit: state.hotel.countryNameEdit,
      paymentSetting: state.hotel.paymentSetting,
      paymentSettingEdit: state.hotel.paymentSettingEdit,
      failData: state.hotel.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetOne: (hotelId) => dispatch(hotelActions.onGetOne(hotelId)),
      onEdit: (hotelId, region, country, city) => dispatch(hotelActions.onEdit(hotelId, region, country, city)),
      onEditIsShow: (hotelId, isShow) => dispatch(hotelActions.onEditIsShow(hotelId, isShow)),
      onGetRegion: () => dispatch(hotelActions.onGetRegion()),
      onGetCountry: (region) => dispatch(hotelActions.onGetCountry(region)),
      onGetCity: (regionId, countryId) => dispatch(hotelActions.onGetCity(regionId, countryId)),
      onGetCountryName: () => dispatch(hotelActions.onGetCountryName()),
      onEditCountryName: (hotelId, countryName) => dispatch(hotelActions.onEditCountryName(hotelId, countryName)),
      onEditPaymentSetting: (hotelId, paymentServerURL, isDirectPayable, isCardTokenizable) =>
        dispatch(hotelActions.onEditPaymentSetting(hotelId, paymentServerURL, isDirectPayable, isCardTokenizable)),
    };
  };
}

export default HotelEditData;
