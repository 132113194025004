import CommonComponent from "../../components/CommonComponent";

// module
import * as amenityActions from "../../../redux/modules/Amenity";



class AmenityEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_AMENITY") {
      this.onEditAmenityOneResults(props);
    }
  }

  // redux에 edit amenity 요청
  requestEditAmenity = (type, group, code, name, iconFile, iconText, amenityId) => {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("group", group);
    formData.append("code", code);
    formData.append("name", name);
    formData.append("amenityFile", iconFile);
    formData.append("iconText", iconText);

    this.setState({
      requestType: "EDIT_AMENITY",
    });
    this.props.onEdit(formData, amenityId);
  };

  onEditAmenityOneResults(props) {
    if (props.amenityEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.amenityEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_amenity"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  }

  onEditSuccess = () => {
    const amenityId = this.state.amenityId;
    // 페이지 이동
    // this.props.history.push(`/amenityDetailPage?amenityId=${amenityId}`);
    this.props.history.goBack();
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  //cancelBtn
  cancelBtn = () => {
    this.setState({ requestType: "" });
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_not_save_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.onBackBtn();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.props.showTopPopupDialog(
      "N",
      // "Are you sure you want to delete it?",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.editAmenity();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editAmenity = () => {
    this.requestEditAmenity(this.state.type, this.state.group, this.state.code, this.state.name, this.state.iconFile, this.state.iconText, this.state.amenityId);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/amenityListPage");
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      amenityDetail: state.amenity.amenityDetail,
      amenityEdit: state.amenity.amenityEdit,
      failData: state.amenity.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onEdit: (formData, amenityId) => dispatch(amenityActions.onEditAmenity(formData, amenityId)),
    };
  };
}

export default AmenityEditData;
