import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import ReservationDetailData from "./ReservationDetailData";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/statistics/reservationDetail.css";

// date utils
import { formatDateTimeSlash } from "../../common/Utils";

// icon
import syncIcon from "../../icon/icon_sync.svg";
import ServerUrl from "../../common/ServerUrl";

class RervationDetailView extends ReservationDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      reservationId: "",
      hotelId: "",
      isShowAgreementDetail: false,
      agreementDetailUrl: "",
    };
  }

  /* ------------------------------------------------------ 생명 주기 ----------------------------------------------------- */
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const reservationId = params.get("reservationId");
    const hotelId = params.get("hotelId");
    this.setState(
      {
        reservationId: reservationId,
        hotelId: hotelId,
      },
      () => {
        this.requestGetReservationOne();
      }
    );
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillUnmount() {}

  /* -------------------------------------------------- Event Handler ------------------------------------------------- */
  // reservation detail 취소 버튼을 눌렀을 때
  handleRervationDeleteButton = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.deleteReservation();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      } //cancel 버튼시
    );
  };

  // reservation 취소하기
  handleClickCancelReservation = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_booking_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.setState({
          isShowAllLoading: true,
        });
        this.requestDeleteReservation();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      } //cancel 버튼시
    );
  };

  handleClickGetAgreementDetail = (fileId, agreementRaw) => {
    const params = {
      type: "staff",
      agreements: agreementRaw,
    };

    const searchParms = new URLSearchParams();

    for (const key in params) {
      searchParms.append(key, params[key]);
    }

    const queryString = searchParms.toString();
    const hasFileId = fileId && fileId != "";
    this.setState((prev) => {
      return {
        ...prev,
        isShowAgreementDetail: true,
        agreementDetailUrl: hasFileId ? `${ServerUrl}/hotelTerms/${fileId}?${queryString}` : "",
      };
    });
  };

  // list page로 이동
  linkRervationList = () => {
    this.props.history.goBack();
  };

  // CompareFace 상세보기
  linkCompareFaceDetail(id, guestType) {
    const compareFaceDetailUrl = "../compareFaceDetailpage?compareFaceId=" + id + "&guestType=" + guestType;
    this.props.history.push(compareFaceDetailUrl);
  }

  /* ------------------------------------------------------ 기타 함수 ----------------------------------------------------- */

  // 예약상태에 따라 취소버튼에 클래스명 추가
  activateCancelButton = (status) => {
    if (status == "DUEIN" || status == "RESERVED") {
      return "";
    } else {
      return "disabled";
    }
  };

  // userAuthCompareFaces가 빈 배열일 떄 아이콘을 보여주지 않음
  showSyncIcon = (userAuthCompareFaces) => {
    if (userAuthCompareFaces == null || userAuthCompareFaces.length == 0) {
      return "displayNone";
    } else {
      return "displayBlock";
    }
  };

  // JSON 문자열을 배열로 변환
  agreementContent = (agreements) => {
    if (agreements == "") return "-";
    let agreement = JSON.parse(agreements); //배열
    let contentArr = [];
    if (agreement?.length > 0) {
      contentArr = agreement.map((agree) => {
        return ` ${agree.index} : ${agree.agreement}`;
      });
    }
    return contentArr;
  };

  wakeUpCallContent = (wakeUpCall) => {
    if (wakeUpCall == "") return "-";

    // 'T' 문자와 '.' 문자의 위치를 찾습니다.
    const tIndex = wakeUpCall.indexOf("T");
    const dotIndex = wakeUpCall.indexOf(".");

    // 'T'와 '.' 사이의 문자열을 추출합니다.
    return wakeUpCall.substring(tIndex + 1, dotIndex);
  };

  // 배열을 순회하며 각 객체내의 빈 값("")을 "-"로 변환
  replaceEmptyWithDash = (array) => {
    return array.map((item) => {
      const replacedItem = {};
      for (const [key, value] of Object.entries(item)) {
        replacedItem[key] = value == null || value == "" ? "-" : value;
      }
      return replacedItem;
    });
  };

  // iframe이 로딩완료되었을 때 실행
  onChangeAgreementIframe = () => {
    // const closeButton = document.getElementById("agreementIframe").contentDocument.getElementById("closeButton");
    const closeButton = $("#agreementIframe").contents().find("#closeButton");
    if (closeButton) {
      closeButton.on("click", () => {
        this.setState({
          isShowAgreementDetail: false,
        });
      });
    }
  };

  render() {
    let reservationDetail = null;
    let bookingUser = null;
    let reservationOwnerList = [];
    let reservationAccompanyList = [];
    let pmsPackageList = [];
    let pmsChargesByDateList = [];
    let payments = [];
    let reservationPMS = null;
    let pmsSettingDetail = null;
    let type = null;
    let earliestCheckInTime = null;
    let reservationInvoice = [];
    let ownerAgreement = [];

    if (this.props.reservationDetail != null && this.props.reservationDetail?.success == true) {
      reservationDetail = this.props.reservationDetail.reservation;
      bookingUser = this.props.reservationDetail.bookingUser;
      reservationOwnerList = this.props.reservationDetail.reservation.hotelReservationOwner;
      reservationAccompanyList = this.props.reservationDetail.reservation.hotelAccompany;

      payments = this.props.reservationDetail.payments;
      reservationPMS = this.props.reservationDetail.pms;
      pmsPackageList = this.props.reservationDetail.pms.packageArray;
      pmsChargesByDateList = this.props.reservationDetail.pms.chargesByDateArray;
    }

    if (this.props.pmsSettingDetail != null && this.props.pmsSettingDetail?.success == true) {
      pmsSettingDetail = this.props.pmsSettingDetail?.results?.rows[0];
      earliestCheckInTime = this.props.pmsSettingDetail?.results?.rows[0].earliestCheckInTime;
      type = this.props.pmsSettingDetail?.results?.rows[0].type;
    }

    if (this.props.reservationInvoice != null && this.props.reservationInvoice?.success == true) {
      reservationInvoice = this.props.reservationInvoice?.results?.invoicesArray;
    }

    if (this.props.ownerAgreement != null && this.props.ownerAgreement?.success == true) {
      ownerAgreement = this.props.ownerAgreement?.results?.rows;
    }

    return (
      <div className="reservationDetail">
        {this.state.isShowAllLoading && <Loading />}
        {reservationDetail != null && (
          <div className="reservation_detail_container">
            <div className="reservation_detail_innder">
              {/* 예약 번호 */}
              <div className="reservation_container">
                <div className="reservation_title_container">
                  <span data-detect="hotel_reservation_reservation_detail_label_confirmation_no">Confirmation No.</span>
                </div>
                <div className="reservation_info">
                  <div className="info_confirmationNo">
                    <span>{reservationDetail.confirmationNo}</span>
                  </div>
                  <div className="info_date">
                    <span>{formatDateTimeSlash(reservationDetail.created_at)}</span>
                  </div>
                </div>
              </div>
              {/* 예약자 본인 */}
              <div className="booking_user_container">
                <div className="booking_user_title_container">
                  <div className="title_line">
                    <div className="booking_user_title">
                      <span data-detect="hotel_reservation_future_booking_detail_label_owner">Owner</span>
                      <span id="imported"></span>
                    </div>
                  </div>
                </div>
                <div className="booking_user">
                  <div className="table booking_user_table">
                    <div className="thead booking_user_thead">
                      <div className="row">
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_booking_user_title">
                          Title
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_booking_user_first_name">
                          First Name
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_booking_user_last_name">
                          Last Name
                        </div>
                        <div className="cell" data-detect="hotel_reservation_future_booking_detail_label_owner_use_key">
                          Use Key
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_booking_user_confirmed_at">
                          Confirmed At
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_booking_user_date">
                          Date
                        </div>
                      </div>
                    </div>
                    <div className="tbody booking_user_tbody">
                      <div className="row tbodyRow">
                        <div className="cell">{bookingUser?.gender ?? "-"}</div>
                        <div className="cell">{bookingUser?.firstName ?? "-"}</div>
                        <div className="cell">{bookingUser?.lastName ?? "-"}</div>
                        <div className="cell">{bookingUser?.useKey ?? "-"}</div>
                        <div className="cell">{formatDateTimeSlash(bookingUser?.created_at)}</div>
                        <div className="cell">{formatDateTimeSlash(bookingUser?.mailConfimedAt)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 주 고객 */}
              <div className="owner_container">
                <div className="owner_title_container">
                  <span data-detect="hotel_reservation_reservation_detail_label_owner_main_guest">Main Guest</span>
                  {reservationPMS.reservationStatus == "DUEIN" ||
                    reservationPMS.reservationStatus == "RESERVED" ||
                    (reservationPMS.reservationStatus == "CHANGED" && reservationOwnerList.length != 0 && (
                      <div className="owner_delete_btn_container">
                        <button className="owner_delete_btn" data-detect="hotel_reservation_reservation_detail_label_owner_delete_btn">
                          Delete
                        </button>
                      </div>
                    ))}
                </div>
                <div className="owner_info">
                  <div className="table owner_info_table">
                    <div className="thead owner_info_thead">
                      <div className="row">
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_owner_profile_id">
                          Profile ID
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_owner_title">
                          Title
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_owner_first_name">
                          First Name
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_owner_last_name">
                          Last Name
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_owner_confirmed_at">
                          Confirmed At
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_owner_confirmed_type">
                          Confirmed Type
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_owner_use_key">
                          Use Key
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_owner_waiting_room">
                          Waiting Room
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_owner_date">
                          Date
                        </div>
                      </div>
                    </div>
                    <div className="tbody owner_info_tbody">
                      {reservationOwnerList?.length > 0 ? (
                        reservationOwnerList.map((owner, index) => {
                          return (
                            <div className="row tbodyRow" key={owner.id}>
                              <div className="cell">{owner.profileId}</div>
                              <div className="cell">{owner?.title}</div>
                              <div className="cell">{owner.firstName}</div>
                              <div className="cell">{owner.lastName}</div>
                              <div className="cell">
                                {formatDateTimeSlash(owner.confirmedAt)}
                                <span> </span>
                                <img
                                  src={syncIcon}
                                  alt="detail button"
                                  className={this.showSyncIcon(owner.userAuthCompareFaces)}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.linkCompareFaceDetail(owner.id, "owner")}
                                />
                              </div>
                              <div className="cell">{owner.confirmedType}</div>
                              <div className="cell">{owner.useKey}</div>
                              <div className="cell">{owner.waitingAt || "-"}</div>
                              <div className="cell">{formatDateTimeSlash(owner.created_at)}</div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="row tbodyRow">
                          <div className="emptyUserList">
                            <span className="empty" data-detect="hotel_reservation_reservation_detail_owner_list_guide_empty">
                              List is empty
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* 동반 고객 */}
              <div className="accompany_container">
                <div className="accompany_title_container">
                  <div className="title_line">
                    <div className="accompany_title">
                      <span data-detect="hotel_reservation_reservation_detail_label_accompany">Accompany</span>
                    </div>
                  </div>
                </div>
                <div className="accompany_info">
                  <div className="table accompany_info_table">
                    <div className="thead accompany_info_thead">
                      <div className="row">
                        <div id="accompanyTheadFistCell" className="cell" data-detect="hotel_reservation_reservation_detail_label_accompany_no">
                          No.
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_accompany_profile_id">
                          Profile ID
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_accompany_title">
                          Title
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_accompany_first_name">
                          First Name
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_accompany_last_name">
                          Last Name
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_accompany_use_key">
                          Use Key
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_accompany_confirmed_at">
                          Confirmed At
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_accompany_confirmed_type">
                          Confirmed Type
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_accompany_hotel_date">
                          Date
                        </div>
                      </div>
                    </div>
                    <div className="tbody accompany_info_tbody">
                      {reservationAccompanyList?.length > 0 ? (
                        reservationAccompanyList.map((accompany, index) => {
                          return (
                            <div className="row tbodyRow" key={accompany.profileId}>
                              <div className="cell">
                                <div className="eachIndexNo">
                                  <span>{index + 1}</span>
                                </div>
                              </div>
                              <div className="cell">{accompany.profileId}</div>
                              <div className="cell">{accompany?.title}</div>
                              <div className="cell">{accompany.firstName}</div>
                              <div className="cell">{accompany.lastName}</div>
                              <div className="cell">{accompany.useKey}</div>
                              <div className="cell">
                                {formatDateTimeSlash(accompany.confirmedAt)}
                                <span> </span>
                                <img
                                  src={syncIcon}
                                  alt="detail button"
                                  className={this.showSyncIcon(accompany.userAuthCompareFaces)}
                                  style={{ cursor: "pointer" }}
                                  // onClick={() => this.linkCompareFaceDetail(accompany.id, "accompany")}
                                />
                              </div>
                              <div className="cell">{accompany.confirmedType}</div>
                              <div className="cell">{formatDateTimeSlash(accompany.created_at)}</div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="row tbodyRow">
                          <div className="emptyUserList">
                            <span className="empty" data-detect="hotel_reservation_reservation_detail_accompany_list_guide_empty">
                              List is empty
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* 약관 동의 */}
              <div className="agreed_container">
                <div className="agreed_title_container">
                  <div className="title_line">
                    <div className="agreed_title">
                      <span data-detect="hotel_reservation_reservation_detail_label_agreed">Agreed</span>
                    </div>
                  </div>
                </div>
                <div className="agreed_info">
                  <div className="table agreed_info_table">
                    <div className="thead agreed_info_thead">
                      <div className="row">
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_agreed_title">
                          Title
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_agreed_agreement">
                          Agreement
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_agreed_agreed_at">
                          Agree At
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_agreed_view_details">
                          View Details
                        </div>
                      </div>
                    </div>
                    <div className="tbody agreed_info_tbody">
                      {ownerAgreement?.length > 0 ? (
                        ownerAgreement.map((agreement) => {
                          return (
                            <div className="row tbodyRow" key={agreement.id}>
                              <div className="cell">{agreement.hotelTerms?.title}</div>
                              <div className="cell">{agreement.agreements ? this.agreementContent(agreement.agreements) : "-"}</div>
                              <div className="cell">{formatDateTimeSlash(agreement.created_at)}</div>
                              <div className="cell">
                                {agreement.agreements !== null && (
                                  <button
                                    className="gotodetail_btn"
                                    name={agreement.hotelTerms.fileId}
                                    onClick={() => this.handleClickGetAgreementDetail(agreement.hotelTerms.fileId, agreement.agreements == "-" ? "" : agreement.agreements.replace(/\"/g, "'"))}
                                    data-detect="hotel_reservation_reservation_detail_label_agreed_go_to_detail"
                                  >
                                    Go to detail
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="emptyUserList">
                          <span className="empty" data-detect="hotel_reservation_reservation_detail_owner_list_guide_empty">
                            List is empty
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* 결제 */}
              <div className="payment_container">
                <div className="payment_title_container">
                  <div className="title_line">
                    <div className="payment_title">
                      <span data-detect="hotel_reservation_reservation_detail_label_payment">Payment</span>
                    </div>
                  </div>
                </div>
                <div className="payment_info">
                  <div className="table payment_info_table">
                    <div className="thead payment_info_thead">
                      <div className="row">
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_payment_long_info">
                          Long info
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_payment_amount">
                          Amount
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_payment_unit">
                          Unit
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_payment_pg_status_code">
                          PG status code
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_payment_pg_status_message">
                          PG status message
                        </div>
                        <div className="cell" data-detect="hotel_reservation_reservation_detail_label_payment_date">
                          Date
                        </div>
                      </div>
                    </div>
                    <div className="tbody payment_info_tbody">
                      {payments?.length > 0 ? (
                        payments.map((payment) => {
                          return (
                            <div className="row tbodyRow" key={payment.id}>
                              <div className="cell">{payment.longInfo}</div>
                              <div className="cell">{payment.amount}</div>
                              <div className="cell">{payment.unit}</div>
                              <div className="cell">{payment.pgStatusCode ?? "-"}</div>
                              <div className="cell">{payment.pgStatusMessage ?? "-"}</div>
                              <div className="cell">{formatDateTimeSlash(payment.created_at)}</div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="row tbodyRow">
                          <div className="emptyUserList">
                            <span className="empty" data-detect="hotel_reservation_reservation_detail_owner_list_guide_empty">
                              List is empty
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* PMS */}
              <div className="PMS_container">
                <div className="PMS_title_container">
                  <div className="title_line">
                    <div className="PMS_title">
                      <span data-detect="hotel_reservation_reservation_detail_label_pms">PMS</span>
                    </div>
                  </div>
                </div>
                <div className="PMS_info_container">
                  {/* 예약 pms 정보 */}
                  <div className="PMS_info_table">
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_confirmation_no">
                        Confirmation No.
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.confirmationNumber == "" ? "-" : reservationPMS.confirmationNumber}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_reservation_id">
                        Reservation ID
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.reservationID == "" ? "-" : reservationPMS.reservationID}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_profile_id">
                        Profile ID
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.profileId == "" ? "-" : reservationPMS.profileId}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_reservation_status">
                        Reservation Status
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.reservationStatus == "" ? "-" : reservationPMS.reservationStatus}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_computed_reservation_status">
                        Computed Reservation Status
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.computedReservationStatus == "" ? "-" : reservationPMS.computedReservationStatus}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_message_exists">
                        Message Exists
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.messageExists == "" ? "-" : reservationPMS.messageExists}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_arrival_date">
                        Arrival Date
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.arrivalDate == "" ? "-" : reservationPMS.arrivalDate.split("T")[0]}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_departure_date">
                        Departure Date
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.departureDate == "" ? "-" : reservationPMS.departureDate.split("T")[0]}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_room_type">
                        Room Type
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.roomType == "" ? "-" : reservationPMS.roomType}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_room_type_code">
                        Room Type Code
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.roomTypeCode == "" ? "-" : reservationPMS.roomTypeCode}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_room_rate_currency">
                        Room Rate Currency
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.roomRateCurrency == "" ? "-" : reservationPMS.roomRateCurrency}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_room_rate">
                        Room Rate
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.roomRate == "" ? "-" : reservationPMS.roomRate}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_total_rate">
                        Total Rate
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.totalRate == "" ? "-" : reservationPMS.totalRate}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_current_balance">
                        Current Balance
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.currentBalance == "" ? "-" : reservationPMS.currentBalance}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_room_number">
                        Room Number
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.roomNumber == "" ? "-" : reservationPMS.roomNumber}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_adults">
                        Adults
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.adults == "" ? "-" : reservationPMS.adults}</span>
                      </div>
                    </div>
                    <div className="PMS_info_row">
                      <div className="PMS_info_cell index" data-detect="hotel_reservation_reservation_detail_label_pms_children">
                        Children
                      </div>
                      <div className="PMS_info_cell content">
                        <span>{reservationPMS.children == "" ? "-" : reservationPMS.children}</span>
                      </div>
                    </div>
                  </div>
                  {/* 메인 고객 */}
                  <div className="PMS_main_guest_table">
                    <div className="PMS_main_guest_title">
                      <span data-detect="hotel_reservation_reservation_detail_label_pms_main_guest">Main Guest</span>
                    </div>
                    <div className="PMS_main_guest_info">
                      <div className="table">
                        <div className="thead">
                          <div className="row">
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_profile_id">
                              Profile ID
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_first_name">
                              First Name
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_last_name">
                              Last Name
                            </div>
                          </div>
                        </div>
                        <div className="tbody pms_main_guest_info_tbody">
                          {reservationPMS.profile != "" ? (
                            <div className="row tbodyRow">
                              <div className="cell">{reservationPMS.profile.profileId == "" ? "-" : reservationPMS.profile.profileId}</div>
                              <div className="cell">{reservationPMS.profile.firstName == "" ? "-" : reservationPMS.profile.firstName}</div>
                              <div className="cell">{reservationPMS.profile.lastName == "" ? "-" : reservationPMS.profile.lastName}</div>
                            </div>
                          ) : (
                            <div className="emptyUserList">
                              <span className="empty" data-detect="hotel_reservation_reservation_detail_owner_list_guide_empty">
                                List is empty
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {type == "PLANYO" && (
                      <>
                        {/* 여권 정보 */}
                        <div className="PMS_main_guest_info passport_info">
                          <div className="table">
                            <div className="thead">
                              <div className="row">
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_nationality">
                                  Nationality
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_passport_no">
                                  Passport No.
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_birth">
                                  Date Of Birth
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_gender">
                                  Gender
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_expiry">
                                  Date Of Expiry
                                </div>
                              </div>
                            </div>
                            <div className="tbody pms_main_guest_passport_info_tbody">
                              {reservationPMS.profile != null ? (
                                <div className="row tbodyRow">
                                  <div className="cell">{reservationPMS.profile.nationality == "" ? "-" : reservationPMS.profile.nationality}</div>
                                  <div className="cell">{reservationPMS.profile.passportNumber == "" ? "-" : reservationPMS.profile.passportNumber}</div>
                                  <div className="cell">{reservationPMS.profile.dateOfBirth == "" ? "-" : reservationPMS.profile.dateOfBirth}</div>
                                  <div className="cell">{reservationPMS.profile.gender == "" ? "-" : reservationPMS.profile.gender}</div>
                                  <div className="cell">{reservationPMS.profile.passportExpiryDate == "" ? "-" : reservationPMS.profile.passportExpiryDate}</div>
                                </div>
                              ) : (
                                <div className="emptyUserList">
                                  <span className="empty" data-detect="hotel_reservation_reservation_detail_owner_list_guide_empty">
                                    List is empty
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* 비행 정보 */}
                        <div className="PMS_main_guest_info flight_info">
                          <div className="table">
                            <div className="thead">
                              <div className="row">
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_date">
                                  Arrival Date
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_no">
                                  Arrival Flight No.
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_time">
                                  Arrival Flight Time
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_date">
                                  Departure Date
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_no">
                                  Departure Flight No.
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_time">
                                  Departure Time
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_wake_up_call">
                                  Wake Up Call
                                </div>
                              </div>
                            </div>
                            <div className="tbody pms_main_guest_flight_info_tbody">
                              {reservationPMS.profile != null ? (
                                <div className="row tbodyRow">
                                  <div className="cell">{reservationPMS.profile.arrivalDate == "" ? "-" : reservationPMS.profile.arrivalDate}</div>
                                  <div className="cell">{reservationPMS.profile.arrivalFlightNo == "" ? "-" : reservationPMS.profile.arrivalFlightNo}</div>
                                  <div className="cell">{reservationPMS.profile.arrivalFlightTime == "" ? "-" : reservationPMS.profile.arrivalFlightTime}</div>
                                  <div className="cell">{reservationPMS.profile.departureDate == "" ? "-" : reservationPMS.profile.departureDate}</div>
                                  <div className="cell">{reservationPMS.profile.departureFlightNo == "" ? "-" : reservationPMS.profile.departureFlightNo}</div>
                                  <div className="cell">{reservationPMS.profile.departureFlightTime == "" ? "-" : reservationPMS.profile.departureFlightTime}</div>
                                  <div className="cell">{this.wakeUpCallContent(reservationPMS.profile.wakeUpCall)}</div>
                                </div>
                              ) : (
                                <div className="emptyUserList">
                                  <span className="empty" data-detect="hotel_reservation_reservation_detail_owner_list_guide_empty">
                                    List is empty
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* 동반 고객 */}
                  <div className="PMS_accompany_table">
                    <div className="PMS_accompany_title">
                      <span data-detect="hotel_reservation_reservation_detail_label_pms_accompany">Accompany</span>
                    </div>
                    <div className="PMS_accompany_info">
                      <div className="table">
                        <div className="thead">
                          <div className="row">
                            <div id="PMSAccompanyTheadFistCell" className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_accompany_no">
                              No.
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_accompany_profile_id">
                              Profile ID
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_accompany_first_name">
                              First Name
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_accompany_last_name">
                              Last Name
                            </div>
                            {earliestCheckInTime == "day" && (
                              <>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_accompany_nationality">
                                  Nationality
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_accompany_passport_no">
                                  Passport No.
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_accompany_date_of_birth">
                                  Date Of Birth
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_accompany_gender">
                                  Gender
                                </div>
                                <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_accompany_date_of_expiry">
                                  Date Of Expiry
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="tbody pms_accompany_info_tbody">
                          {reservationAccompanyList?.length > 0 ? (
                            this.replaceEmptyWithDash(reservationAccompanyList).map((accompany, index) => {
                              return (
                                <div className="row tbodyRow" key={accompany.id}>
                                  <div className="cell">{index + 1}</div>
                                  <div className="cell">{accompany.profileId}</div>
                                  <div className="cell">{accompany.firstName}</div>
                                  <div className="cell">{accompany.lastName}</div>
                                  {earliestCheckInTime == "day" && (
                                    <>
                                      <div className="cell">{accompany.nationalityGuest}</div>
                                      <div className="cell">{accompany.passportNumberGuest}</div>
                                      <div className="cell">{accompany.dateBirthGuest}</div>
                                      <div className="cell">{accompany.gender}</div>
                                      <div className="cell">{accompany.passportExpiryDateGuest}</div>
                                    </>
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <div className="row tbodyRow">
                              <div className="emptyUserList">
                                <span className="empty" data-detect="hotel_reservation_reservation_detail_owner_list_guide_empty">
                                  List is empty
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 패키지 */}
                  <div className="PMS_package_table">
                    <div className="PMS_package_title">
                      <span data-detect="hotel_reservation_reservation_detail_label_pms_package">Package</span>
                    </div>
                    <div className="PMS_package_info">
                      <div className="table">
                        <div className="thead">
                          <div className="row">
                            <div id="PMSPackageTheadFistCell" className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_package_no">
                              No.
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_package_package_code">
                              Package Code
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_package_description">
                              Description
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_package_amount">
                              Amount
                            </div>
                          </div>
                        </div>
                        <div className="tbody pms_package_info_tbody">
                          {pmsPackageList?.length > 0 ? (
                            this.replaceEmptyWithDash(pmsPackageList).map((package_one, index) => {
                              return (
                                <div className="row tbodyRow" key={index}>
                                  <div className="cell">{index + 1}</div>
                                  <div className="cell">{package_one.packageCode}</div>
                                  <div className="cell">{package_one.currencyCode}</div>
                                  <div className="cell">{package_one.amount}</div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="row tbodyRow">
                              <div className="emptyUserList">
                                <span className="empty" data-detect="hotel_reservation_reservation_detail_owner_list_guide_empty">
                                  List is empty
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 날짜 별 요금 */}
                  <div className="PMS_charges_table">
                    <div className="PMS_charges_title">
                      <span data-detect="hotel_reservation_reservation_detail_label_pms_charges_by_date">Charges By Date</span>
                    </div>
                    <div className="PMS_charges_info">
                      <div className="table">
                        <div className="thead">
                          <div className="row">
                            <div id="PMSChargesTheadFistCell" className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_charges_by_date_no">
                              No.
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_charges_by_date_date">
                              Date
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_charges_by_date_description">
                              Description
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_charges_by_date_charges">
                              Charges
                            </div>
                          </div>
                        </div>
                        <div className="tbody pms_charges_info_tbody">
                          {pmsChargesByDateList?.length > 0 ? (
                            this.replaceEmptyWithDash(pmsChargesByDateList).map((chargeByDate, indexDate) => {
                              return this.replaceEmptyWithDash(chargeByDate.chargesArray).map((charge, indexEach) => {
                                return (
                                  <div className="row tbodyRow" key={indexDate + indexEach}>
                                    <div className="cell">{`${indexDate + 1}.${indexEach + 1}`}</div>
                                    <div className="cell">{chargeByDate.date}</div>
                                    <div className="cell">{chargeByDate.description}</div>
                                    <div className="cell">{`${charge.description}, ${charge.amount}, ${charge.code}`}</div>
                                  </div>
                                );
                              });
                            })
                          ) : (
                            <div className="row tbodyRow">
                              <div className="emptyUserList">
                                <span className="empty" data-detect="hotel_reservation_reservation_detail_owner_list_guide_empty">
                                  List is empty
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 청구서 */}
                  <div className="PMS_invoice_table">
                    <div className="PMS_invoice_title">
                      <span data-detect="hotel_reservation_reservation_detail_label_pms_invoice">Invoice</span>
                    </div>
                    <div className="PMS_invoice_info">
                      <div className="table">
                        <div className="thead">
                          <div className="row">
                            <div id="PMSInvoiceTheadFistCell" className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_invoice_no">
                              No.
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_invoice_date">
                              Date
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_invoice_description">
                              Description
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_invoice_currency_code">
                              Currency Code
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_invoice_amount">
                              Amount
                            </div>
                            <div className="cell" data-detect="hotel_reservation_reservation_detail_label_pms_invoice_supplement">
                              Supplement
                            </div>
                          </div>
                        </div>
                        <div className="tbody pms_invoice_info_tbody">
                          {reservationInvoice?.length > 0 ? (
                            reservationInvoice.map((invoice, index) => {
                              return this.replaceEmptyWithDash(invoice.billItemArray).map((bill, indexBill) => {
                                return (
                                  <div className="row tbodyRow" key={bill.transactionNo}>
                                    <div className="cell">{index + indexBill + 1}</div>
                                    <div className="cell">{bill.date}</div>
                                    <div className="cell">{bill.description}</div>
                                    <div className="cell">{bill.currencyCode}</div>
                                    <div className="cell">{bill.amount}</div>
                                    <div className="cell">{bill.supplement}</div>
                                  </div>
                                );
                              });
                            })
                          ) : (
                            <div className="row tbodyRow">
                              <div className="emptyUserList">
                                <span className="empty" data-detect="hotel_reservation_reservation_detail_owner_list_guide_empty">
                                  List is empty
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 버튼 */}
              <div className="button_container">
                <div className="gotolist_btn_container">
                  <button className="gotolist_btn" onClick={this.linkRervationList} data-detect="hotel_reservation_reservation_detail_button_go_to_list">
                    Go to list
                  </button>
                </div>
              </div>
            </div>
            {/* agreement detail */}
            {this.state.isShowAgreementDetail && (
              <div id="agreementIframeBox" onClick={() => this.setState({ isShowAgreementDetail: false })}>
                <iframe src={this.state.agreementDetailUrl} frameBorder="0" id="agreementIframe" onLoad={this.onChangeAgreementIframe}></iframe>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
let mapStateToProps = ReservationDetailData.mapStateToProps;
let mapDispatchToProps = ReservationDetailData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RervationDetailView));
