// CountryAddData.js
import CommonComponent from "../../../components/CommonComponent";

// module
import * as locationActions from "../../../../redux/modules/Location";



class CountryEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_COUNTRY") {
      this.onEditCountryOneResults(props);
    }
  }

  // redux에 edit co 요청
  requestEditCountry = (countryId, country) => {
    if (country == "") {
      this.setState({ requestType: "" });
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_country"],
        () => {},
        () => {},
        () => {}
      );
    } else {
      this.setState({
        requestType: "EDIT_COUNTRY",
      });
      this.props.onEdit(countryId, country);
    }
  };

  // get edit country results
  onEditCountryOneResults = (props) => {
    if (props.countryEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.countryEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_country"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onEditSuccess = () => {
    const countryId = this.state.countryId;
    // this.props.history.push(`/countryDetailPage?countryId=${countryId}`);
    this.props.history.goBack();
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  //edit 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.editCountry();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editCountry = () => {
    // 모든 필드가 값이 있으면 버튼을 비활성화
    document.querySelector(".edit_btn").setAttribute("disabled", "disabled");
    this.setState({
      isShowAllLoading: true,
    });
    this.requestEditCountry(this.state.countryId, this.state.country);
  };

  //cancelBtn
  cancelBtn = () => {
    this.setState({ requestType: "" });
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_not_save_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.onBackBtn();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/countryListPage");
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      countryDetail: state.location.countryDetail,
      countryEdit: state.location.countryEdit,
      failData: state.location.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onEdit: (countryId, country) => dispatch(locationActions.onEditCountry(countryId, country)),
    };
  };
}

export default CountryEditData;
