import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ServerUrl from "../../common/ServerUrl";
import { Loading } from "../../components/Paths";
import CompareFaceDetailData from "./CompareFaceDetailData";

// CSS
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/statistics/compareFaceDetail.css";

// Date Utils
import { formatDateTimeSlash, yyyymmddDot_hh_mm_ssByTimeZone } from "../../common/Utils";

class CompareFaceDetailView extends CompareFaceDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      compareFaceId: "",
      guestType: "",
      isShowFullImage: false,
      faceImageUrl: "",
    };
  }

  /* ---------------------------------------------------- Lifecycle --------------------------------------------------- */
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const compareFaceId = params.get("compareFaceId");
    const guestType = params.get("guestType");
    this.setState({
      compareFaceId: compareFaceId,
      guestType: guestType,
    });
    let hotelReservationOwnerId = "";
    let hotelAccompanyId = "";

    if (guestType == "owner") {
      hotelReservationOwnerId = compareFaceId;
    } else {
      hotelAccompanyId = compareFaceId;
    }
    this.requestGetCompareFaceAll(hotelReservationOwnerId, hotelAccompanyId);
  }

  componentDidUpdate(prevProps) {
    // 페이지 업데이트 시 번역이 되지 않는 문제 예방
    this.props.updateMultiLanguage(this.props.MultiLanguage);

    // full image를 화면에 표시할 때 사이드바를 제외한 영역의 중간에 위치하도록 함
    if (prevProps.isShowFullImage !== this.state.isShowFullImage) {
      if ($(window).width() >= 1400) {
        $(".show_full_image").css({ left: "300px" });
      } else {
        $(".show_full_image").css({ left: "0px" });
      }
    }
  }

  /* -------------------------------------------------- Event Handler ------------------------------------------------- */
  handleDeleteButton = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode][
        "button_delete_message"
      ],
      () => {
        this.props.hideTopPopupDialog();
        this.deleteCompareFace();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      } //cancel 버튼시
    );
  };

  // list page로 이동
  onClickGoBackButton = () => {
    this.props.history.goBack();
  };

  /* ------------------------------------------------------ 기타 함수 ----------------------------------------------------- */
  // status 상태에 따라 번역함.
  makeStatusText = (status) => {
    let statusText = "";
    if (status == "true") {
      statusText = this.props.MultiLanguage["language"][
        this.props.multiLanguageCode
      ]["common_success"];
    } else {
      statusText = this.props.MultiLanguage["language"][
        this.props.multiLanguageCode
      ]["common_failure"];
    }
    return statusText;
  };

  makeNameText = (compareFaceInfo, guestType) => {
    let title = "";
    let firstName = "";
    let lastName = "";

    if (guestType == "owner") {
      title = compareFaceInfo.hotelReservationOwner?.title;
      firstName = compareFaceInfo.hotelReservationOwner?.firstName;
      lastName = compareFaceInfo.hotelReservationOwner?.lastName;
    } else if (guestType == "accompany") {
      title = compareFaceInfo.hotelAccompany?.title;
      firstName = compareFaceInfo.hotelAccompany?.firstName;
      lastName = compareFaceInfo.hotelAccompany?.lastName;
    } else {
      return null;
    }
    return title + " " + firstName + " " + lastName;
  };

  // get Image Url
  getImageUrl = (imgPath) => {
    let hotelServerUrl = '';
    if (window.location.href.includes("cms.beta.myconect.net")) {
      hotelServerUrl = "https://hotel.api.beta.myconect.net";
    } else if (window.location.href.includes("cms.myconect.net")) {
      hotelServerUrl = "https://hotel.api.rel.myconect.net";
    } else {
      hotelServerUrl = "http://holich.iptime.org:8500";
    }
    if (imgPath != null) {
      return hotelServerUrl + "/image/compareFaces/" + imgPath;
    }
    return null;
  };

  // image를 열거나 닫음.
  handleToggleFullImage = (e) => {
    const imageUrl = e.target.src;
    this.setState((prev) => {
      return {
        ...prev,
        faceImageUrl: imageUrl,
        ["isShowFullImage"]: !prev.isShowFullImage,
      };
    });
  };

  //JSON 문자열을 보기좋게 포맷팅
  getBeautifyJson = (json) => {
    // json: JSON 문자열을 객체로 파싱된 상태, 예시: {name: 'John', age: 30, city: 'New York'}

    // JSON 문자열의 각 레벨이 6칸씩 들여쓰기됨
    // {
    //     "name": "John",
    //     "age": 30,
    //     "city": "New York"
    // }
    // '\n'로 줄 바꿈 처리를 수행하고 p1.length만큼 들여쓰기함
    return JSON.stringify(json, null, 6).replace(/\n( *)/g, function(
      match,
      p1
    ) {
      return "\n" + "\u00A0".repeat(p1.length);
    });
  };

  render() {
    let compareFaceList = null;

    if (
      this.props.compareFaceList != null &&
      this.props.compareFaceList?.success == true
    ) {
      compareFaceList = this.props.compareFaceList?.results?.rows;
    }

    return (
      <div className="compareFaceDetail">
        {/* 로딩 */}
        {/* {this.state.isShowAllLoading && <Loading />} */}

        {/* compare face 정보 */}
        <div className="compare_face_detail_container">
          {this.state.isShowFullImage && (
            <div className="compare_face_detail_full_img_container show_full_image" onClick={this.handleToggleFullImage}>
              <img src={this.state.faceImageUrl} alt="compareFace img" />
            </div>
          )}
          <div className="compare_face_detail_container_inner">
            <div className="title" data-detect="hotel_reservation_reservation_compare_face_detail_title">
              COMPARE FACE DETAIL
            </div>
            {/* compare face detail inner */}
            <div className="compare_face_detail_inner">
              {compareFaceList && compareFaceList.length > 0 ? (
                compareFaceList.map((compareFace, index) => (
                  <div className="compare_face_detail_table" key={compareFace.id}>
                    <div className="compare_face_detail_table_left">
                      <div className="compare_face_detail">
                        <div className="compare_face_detail_info user_name">
                          <div className="info_title" data-detect="hotel_reservation_reservation_detail_label_user_name">
                            User Name
                          </div>
                          <div className="info_text">{this.makeNameText(compareFace, this.state.guestType)}</div>
                        </div>
                        <div className="compare_face_detail_info identity_document_type">
                          <div className="info_title" data-detect="hotel_reservation_reservation_detail_label_identity_document_type">
                            Identity Document Type
                          </div>
                          <div className="info_text">{compareFace.identityDocumentType}</div>
                        </div>
                        <div className="compare_face_detail_info auth_status">
                          <div className="info_title" data-detect="hotel_reservation_reservation_detail_label_auth_status">
                            Auth Status
                          </div>
                          <div className="info_text">{this.makeStatusText(compareFace.authStatus)}</div>
                        </div>
                        <div className="compare_face_detail_info similarity">
                          <div className="info_title" data-detect="hotel_reservation_reservation_detail_label_similarity">
                            Similarity
                          </div>
                          <div className="info_text">{`${compareFace.similarity.toFixed(2)}%`}</div>
                        </div>
                        <div className="compare_face_detail_info created_at">
                          <div className="info_title" data-detect="hotel_reservation_reservation_detail_label_created_at">
                            Created At
                          </div>
                          <div className="info_text">{formatDateTimeSlash(compareFace.created_at)}</div>
                        </div>
                        {compareFace.govSgEVAStatu != null && (
                          <>
                            <div className="compare_face_detail_info government_singapore_eva_response">
                              <div className="info_title" data-detect="hotel_reservation_reservation_detail_label_government_singapore_eva_response">
                                Government Singapore EVA Response
                              </div>
                              <div className="info_text" style={{ whiteSpace: "pre-line" }}>
                                {this.getBeautifyJson(JSON.parse(compareFace.govSgEVAResponse))}
                              </div>
                            </div>
                            <div className="compare_face_detail_info government_singapore_eva_status">
                              <div className="info_title" data-detect="hotel_reservation_reservation_detail_label_government_singapore_eva_status">
                                Government Singapore EVA Status
                              </div>
                              <div className="info_text">{this.makeStatusText(compareFace.govSgEVAStatus)}</div>
                            </div>
                            <div className="compare_face_detail_info government_singapore_eva_request_at">
                              <div className="info_title" data-detect="hotel_reservation_reservation_detail_label_government_singapore_eva_request_at">
                                Government Singapore EVA Request At
                              </div>
                              <div className="info_text">{formatDateTimeSlash(compareFace.govSgEVARequestAt)}</div>
                            </div>
                            <div className="compare_face_detail_info government_singapore_eva_response_at">
                              <div className="info_title" data-detect="hotel_reservation_reservation_detail_label_government_singapore_eva_response_at">
                                Government Singapore EVA Response At
                              </div>
                              <div className="info_text">{formatDateTimeSlash(compareFace.govSgEVAResponseAt)}</div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="compare_face_detail_table_right">
                      <div className="compare_face_detail_table_img_box">
                        <img src={this.getImageUrl(compareFace.sourceImageId)} alt="compareFace img" onClick={this.handleToggleFullImage} />
                      </div>
                      <div className="compare_face_detail_table_img_box">
                        <img src={this.getImageUrl(compareFace.targetImageId)} alt="compareFace img" onClick={this.handleToggleFullImage} />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="compare_face_detail_table">
                  <div className="no-data-in-list" data-detect="admin_hotel_list_guide_empty">
                    List is empty
                  </div>
                </div>
              )}
            </div>
            {/* button */}
            <div className="gotolist_btn_container">
              <button className="gotolist_btn" onClick={this.onClickGoBackButton} data-detect="hotel_reservation_reservation_compare_face_detail_button_go_to_detail">
                Go to list
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = CompareFaceDetailData.mapStateToProps;
let mapDispatchToProps = CompareFaceDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompareFaceDetailView)
);
