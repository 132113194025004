import axiosInstance from "../api/axios";

/* ----------------------------------------------- get statistics list ---------------------------------------------- */
export function onGetAll(pageNo, startDate, endDate, hotelId) {
  // const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/v2/statistics/" + "?fromDate=" + startDate + "&endDate=" + endDate + "&hotelId=" + hotelId);
}

/* ----------------------------------------------- get all reservation ---------------------------------------------- */
export function onGetAllReservation(pageNo, hotelId, date, type) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/v2/statistics/type/" + type + "?hotelId=" + hotelId + "&date=" + date + "&offset=" + offset + "&limit=" + 10);
}
/* ----------------------------------------------- get reservation one ---------------------------------------------- */
export async function onGetReservationOne(hotelId, reservationId) {
  return axiosInstance.get("/admin/api/v2/hotel/reservation/hotel/" + hotelId + "/reservation/" + reservationId);
}

/* ----------------------------------------------- get owner agreement ---------------------------------------------- */
export async function onGetOwnerAgreement(hotelId, confirmationNo) {
  return axiosInstance.get("admin/api/v2/hotel/term/agreement?hotelId=" + hotelId + "&email=&content=&confirmationNo=" + confirmationNo + "&offset=0&limit=5");
}

/* ------------------------------------------------ get hotel invoice ----------------------------------------------- */
export async function onGetReservationInvoice(hotelId, reservationId) {
  return axiosInstance.get("/admin/api/v2/hotel/payment/invoice?hotelId=" + hotelId + "&reservationId=" + reservationId);
}

/* ------------------------------------------------- get pms setting ------------------------------------------------ */
export function onGetPMSOne(hotelId) {
  return axiosInstance.get("admin/api/v2/hotelPMS/hotel/" + hotelId);
}

/* ------------------------------------------------- get hotel list ------------------------------------------------- */
export function onGetAllHotel() {
  return axiosInstance.get("/admin/api/hotel/?content=&offset=0&limit=21000000");
}

//------------------------------- get List ------------------------------------------------------//
export function onGetAllCompareFace(hotelReservationOwnerId, hotelAccompanyId) {
  return axiosInstance.get(
    "/admin/api/v2/auth/compareFaces/authUser?hotelReservationOwnerId=" +
      hotelReservationOwnerId +
      "&hotelAccompanyId=" +
      hotelAccompanyId
  );
}