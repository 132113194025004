const MalayLanguage = {
  version: "2.0.19",
  language: {
      ms: {
          login_button_admin: "HALAMAN LOG MASUK ADMIN",
          login_button_hotel: "HALAMAN LOG MASUK HOTEL",
          login_guide_login: "Klik Halaman Log Masuk anda",
          admin_login_admin_label_admin: "ADMIN",
          admin_login_admin_placeholder_email: "E-mel",
          admin_login_admin_placeholder_password: "Kata laluan",
          admin_login_admin_button_login: "Log masuk",
          admin_login_admin_guide_login: "Log masuk sebagai pentadbir.",
          hotel_login_hotel_label_hotel: "HOTEL",
          hotel_login_hotel_placeholder_email: "E-mel",
          hotel_login_hotel_placeholder_password: "Kata laluan",
          hotel_login_hotel_button_login: "Log masuk",
          hotel_login_hotel_guide_login: "Log masuk sebagai hotel.",
          common_taxi_call: "Teksi",
          common_parcel_service: "Perkhidmatan Bungkusan",
          common_luggage_service: "Perkhidmatan Bagasi",
          common_taxi_call_ex: "Teksi Ekspres",
          common_parcel_service_ex: "Perkhidmatan Parcel Express",
          common_luggage_service_ex: "Perkhidmatan Luggage Express",
          common_green_campaign: "Kempen Hijau",
          common_make_up_room: "Bilik Solek",
          common_turn_down_service: "Perkhidmatan Turndown",
          common_emergency: "Servis kecemasan",
          common_dnd: "DND",
          common_in_room_dining: "Perkhidmatan Makan Dalam Bilik",
          common_ironing_service: "Perkhidmatan Menyeterika",
          common_laundry_service: "Perkhidmatan dobi",
          common_amenity_service: "Perkhidmatan Kemudahan",
          common_in_room_dining_ex: "Perkhidmatan Makan Dalam Bilik Ekspres",
          common_ironing_service_ex: "Perkhidmatan Menyeterika Ekspres",
          common_laundry_service_ex: "Perkhidmatan Dobi Ekspres",
          common_waiting_request: "Perkhidmatan Permintaan Siap Sedia",
          common_valet_return_additional_charge: "Bayaran Tambahan Pemulangan Valet",
          common_requested: "Diminta",
          common_confirmed: "Disahkan",
          common_change: "Berubah",
          common_cancled: "Dibatalkan",
          common_standby: "Bersedia",
          common_completed: "Selesai",
          common_stopped: "Berhenti",
          common_picked_up: "Dijemput",
          common_started: "Bermula",
          common_delivered: "Dihantar",
          common_not_available: "Tidak Tersedia",
          common_delay: "kelewatan",
          common_room_posting: "Pengeposan Bilik",
          common_room_posting_refund: "Bayaran Balik Pengeposan Bilik",
          common_self_settlement: "Penyelesaian Diri",
          common_self_settlement_refund: "Bayaran Balik Penyelesaian Sendiri",
          common_staff_room_posting: "Pengeposan Bilik Kakitangan",
          common_staff_settlement: "Penyelesaian Kakitangan",
          common_put_off: "Tangguh",
          common_vehicle_change: "No kenderaan ubah",
          common_valet: "Valet",
          common_location: "Lokasi parkir",
          common_waiting: "Permintaan siap sedia",
          common_waiting_confirm: "Disahkan",
          common_date_change: "Perubahan masa siap sedia",
          common_vehicle_standby: "Kenderaan sedia",
          common_preparing: "Bersedia",
          common_return: "Dipulangkan",
          common_handover: "Diserahkan",
          common_deleted: "Dipadamkan",
          commom_delay_15: "Kelewatan 15 Minit",
          commom_delay_30: "Kelewatan 30 Minit",
          commom_delay_45: "Kelewatan 45 Minit",
          commom_delay_60: "Kelewatan 60 Minit",
          commom_delay_asaa: "Sebaik sahaja tersedia",
          common_normal: "Biasalah",
          common_express: "Ekspres",
          common_boundary_line: "Garisan Sempadan",
          common_accepted: "Diterima",
          common_rejected: "Ditolak",
          common_key_expired: "Kunci Tamat Tempoh",
          common_disposable_key: "Kunci Pakai Pakai",
          common_today_only_key: "Hari Ini Sahaja Key",
          common_entire_stay_key: "Kunci Sepenuhnya",
          common_validation_name: "Sila masukkan nama.",
          common_validation_content: "Sila masukkan kandungan.",
          common_validation_select_img: "Sila pilih imej.",
          common_summernote_lang: "en-EN",
          common_clean: "Pembersihan selesai",
          common_inspected: "Diperiksa",
          common_dirty: "Kotor",
          common_out_of_order: "Telah habis",
          common_out_of_service: "Perkhidmatan tergendala",
          common_pick_up: "Angkat",
          common_cleaning: "Pembersihan",
          common_done: "Selesai",
          common_not_reserved: "Tidak dikhaskan",
          common_departed: "Berlepas",
          common_arrived: "dah sampai",
          common_reserved: "Terpelihara",
          common_due_in: "Due in",
          common_walk_in: "Walk in",
          common_check_in: "Check in",
          common_due_out: "Due out",
          common_check_out: "Checked out",
          common_cancel: "Batal",
          common_week_all: "All",
          common_week_mon: "isn",
          common_week_tue: "Tue",
          common_week_wed: "Rabu",
          common_week_thu: "Kha",
          common_week_fri: "Jum",
          common_week_sat: "Sab",
          common_week_sun: "matahari",
          common_percent: "Peratusan",
          common_nights: "Malam-malam",
          common_flat: "Kadar rata",
          common_all_nights: "Sepanjang Malam (seluruh penginapan)",
          common_deposit_only: "Deposit Sahaja",
          common_first_night: "Malam pertama",
          common_night: "Malam",
          common_inactive: "Tidak aktif",
          common_active: "Aktif",
          common_success: "kejayaan",
          common_failure: "kegagalan",
          admin_header_link_password: "Kata laluan",
          admin_header_link_logout: "Log keluar",
          admin_header_link_hotel: "Hotel",
          admin_header_link_setting: "Tetapan",
          admin_header_accordion_location: "Lokasi",
          admin_header_link_region_list: "Senarai Wilayah",
          admin_header_link_country_list: "Senarai Negara",
          admin_header_link_city_list: "Senarai Bandar",
          admin_header_link_application_user: "Pengguna Aplikasi",
          admin_header_link_payment: "Bayaran",
          admin_header_accordion_transfer: "Pemindahan",
          admin_header_link_transfer_log_list: "Senarai Log Pemindahan",
          admin_header_link_user_transfer_log_list: "Senarai Log Pemindahan Pengguna",
          admin_header_link_transaction_log_list: "Senarai Log Transaksi",
          admin_header_link_user_transfer_log_history: "Sejarah Log Pemindahan Pengguna",
          admin_header_link_staff_transfer_log_history: "Sejarah Log Perpindahan Kakitangan",
          admin_header_link_user_notification_log_list: "Senarai Log Pemberitahuan Pengguna",
          admin_header_link_staff_notification_log_list: "Senarai Log Pemberitahuan Kakitangan",
          admin_header_accordion_version: "Versi",
          admin_header_link_version: "Versi",
          admin_header_link_application_down_version_list: "Tetapan Skrin Muat Turun Apl",
          admin_header_link_guest_application_down_list: "Tetapan Versi Minimum Apl Tetamu",
          admin_header_link_staff_application_down_list: "Tetapan Versi Minimum Apl Kakitangan",
          admin_header_link_paking_staff_application_down_list: "Tetapan Versi Minimum Apl Kakitangan Tempat Letak Kereta",
          admin_header_link_application_translation: "Terjemahan Aplikasi",
          admin_header_link_booking_email: "Imej E-mel",
          admin_header_accordion_email_form: "Borang E-mel",
          admin_header_link_change_password: "Tukar kata laluan",
          admin_header_link_forgot_id: "Lupa ID",
          admin_header_link_forgot_password: "Lupa kata laluan",
          admin_header_link_create_confirm_email: "Sahkan E-mel",
          admin_header_link_create_confirm_email_fail: "Sahkan E-mel Gagal",
          admin_header_link_create_confirm_email_success: "Sahkan Kejayaan E-mel",
          admin_header_link_update_profile: "Kemas kini Profil",
          admin_header_link_withdraw: "Padam Akaun",
          admin_header_link_app_endpoint: "Titik Akhir Aplikasi",
          admin_header_accordion_terms_and_conditions: "Terma dan syarat",
          admin_header_link_terms: "Syarat",
          admin_header_link_terms_group: "Kumpulan Syarat",
          admin_header_link_agreement_history: "Sejarah Perjanjian",
          admin_header_current_version: "Versi terkini",
          admin_header_translation_version: "Versi Terjemahan",
          admin_header_link_admin_user: "Pengguna Admin",
          admin_header_accordion_auth_data: "Sejarah Sambungan Beacon/GPS",
          admin_header_link_auth_data_log_list: "Senarai Sambungan Beacon/GPS",
          admin_header_link_use_key_log: "Gunakan Log Kunci",
          admin_header_link_amenity: "Kemudahan",
          admin_header_link_facility_image: "Imej Kemudahan Hotel",
          admin_header_link_recommend_app: "Apl Disyorkan",
          admin_header_link_id_recognition_information: "Maklumat Pengecaman ID",
          admin_header_link_statistics: "Statistics",
          admin_password_title: "TUKAR KATA LALUAN",
          admin_password_guide_password: "Sila masukkan kata laluan baharu \ndengan sekurang-kurangnya 8 aksara.",
          admin_password_validation_current_password: "* Sila masukkan kata laluan semasa anda.",
          admin_password_validation_character_number_combining: "* Sila masukkan sekurang-kurangnya 8 aksara menggabungkan huruf dan nombor.",
          admin_password_validation_new_password: "* Sila masukkan kata laluan baharu.",
          admin_password_validation_new_password_again: "* Sila masukkan kata laluan baharu anda sekali lagi.",
          admin_password_validation_not_match: "* Kata laluan tidak sepadan.",
          admin_password_placeholder_password: "Kata laluan",
          admin_password_placeholder_new_password: "Kata laluan baharu",
          admin_password_placeholder_new_confirm_password: "Sahkan kata laluan baharu",
          admin_password_button_save: "Jimat",
          admin_hotel_list_title: "SENARAI HOTEL",
          admin_hotel_list_placeholder_search: "Cari...",
          admin_hotel_list_label_hotel_no: "Hotel No.",
          admin_hotel_list_label_name: "Nama",
          admin_hotel_list_label_address: "Alamat",
          admin_hotel_list_label_is_show: "Is Show",
          admin_hotel_list_label_date: "Tarikh",
          admin_hotel_list_guide_empty: "Senarai kosong",
          admin_hotel_list_button_delete: "Padam",
          admin_hotel_list_button_add: "Tambah",
          admin_hotel_list_button_copy: "Salinan",
          admin_hotel_add_title: "TAMBAH HOTEL",
          admin_hotel_add_guide_click_add: "Masukkan maklumat untuk menambah HOTEL \ndan klik butang <<'Tambah'>>.",
          admin_hotel_add_validation_name: "* Sila masukkan nama hotel.",
          admin_hotel_add_validation_address: "* Sila masukkan alamat hotel.",
          admin_hotel_add_validation_korea_english: "* Bahasa Korea dan Inggeris atau 'Sila masukkan menggunakan ._ -'.",
          admin_hotel_add_validation_email: "* Sila masukkan e-mel pengguna.",
          admin_hotel_add_validation_email_format: "* Sila masukkan e-mel dalam format someone@example.com",
          admin_hotel_add_validation_password: "* Sila masukkan kata laluan pengguna.",
          admin_hotel_add_validation_character_number_combining: "* Sila masukkan sekurang-kurangnya 8 aksara menggabungkan huruf dan nombor.",
          admin_hotel_add_validation_not_match: "* Kata laluan tidak sepadan.",
          admin_hotel_add_validation_image_file: "* Sila muat naik imej logo.",
          admin_hotel_add_label_name: "Nama",
          admin_hotel_add_placeholder_hotel_name: "Nama hotel",
          admin_hotel_add_label_address: "Alamat",
          admin_hotel_add_placeholder_hotel_address: "Alamat hotel",
          admin_hotel_add_label_email: "E-mel",
          admin_hotel_add_placeholder_staff_email: "E-mel kakitangan",
          admin_hotel_add_label_password: "Kata laluan",
          admin_hotel_add_placeholder_staff_password: "Kata laluan kakitangan",
          admin_hotel_add_label_confirm_password: "Sahkan Kata Laluan",
          admin_hotel_add_placeholder_staff_confirm_password: "Sahkan kata laluan kakitangan",
          admin_hotel_add_label_logo: "Logo",
          admin_hotel_add_button_upload: "Muat naik",
          admin_hotel_add_placeholder_upload: "cth) MyCONECT.png",
          admin_hotel_add_button_go_to_list: "Pergi ke senarai",
          admin_hotel_add_button_add: "Tambah",
          admin_hotel_detail_title: "MAKLUMAT HOTEL",
          admin_hotel_detail_label_hotel_no: "Hotel No.",
          admin_hotel_detail_label_name: "Nama",
          admin_hotel_detail_label_address: "Alamat",
          admin_hotel_detail_label_region: "Wilayah",
          admin_hotel_detail_label_country: "Negara",
          admin_hotel_detail_label_city: "Bandar",
          admin_hotel_detail_label_country_name: "Nama negara",
          admin_hotel_detail_label_payment_server_url: "URL Pelayan Pembayaran",
          admin_hotel_detail_label_is_direct_payable: "Dibayar Terus",
          admin_hotel_detail_label_is_card_tokenizable: "Adakah Kad Boleh Ditoken",
          admin_hotel_detail_label_is_show: "Is Show",
          admin_hotel_detail_button_yes: "Yse",
          admin_hotel_detail_button_no: "Tidak",
          admin_hotel_detail_label_logo: "Logo",
          admin_hotel_detail_label_hotel_photo: "Foto hotel",
          admin_hotel_detail_label_hotel_map: "Peta",
          admin_hotel_detail_button_go_to_list: "Pergi ke senarai",
          admin_hotel_detail_button_edit: "Sunting",
          admin_hotel_edit_title: "MAKLUMAT HOTEL",
          admin_hotel_edit_label_hotel_no: "Hotel No.",
          admin_hotel_edit_label_name: "Nama",
          admin_hotel_edit_label_address: "Alamat",
          admin_hotel_edit_label_region: "Wilayah",
          admin_hotel_edit_label_country: "Negara",
          admin_hotel_edit_label_choose: "Pilih",
          admin_hotel_edit_label_city: "Bandar",
          admin_hotel_edit_label_country_name: "Nama negara",
          admin_hotel_edit_label_logo: "Logo",
          admin_hotel_edit_label_payment_server_url: "URL Pelayan Pembayaran",
          admin_hotel_edit_label_is_direct_payable: "Dibayar Terus",
          admin_hotel_edit_label_is_card_tokenizable: "Adakah Kad Boleh Ditoken",
          admin_hotel_edit_label_is_show: "Is Show",
          admin_hotel_edit_button_yes: "Yse",
          admin_hotel_edit_button_no: "Tidak",
          admin_hotel_edit_button_upload: "Muat naik",
          admin_hotel_edit_placeholder_upload: "cth) MyCONECT.png",
          admin_hotel_edit_label_hotel_photo: "Foto hotel",
          admin_hotel_edit_label_hotel_map: "Peta",
          admin_hotel_edit_button_search: "Cari",
          admin_hotel_edit_button_cancel: "Batal",
          admin_hotel_edit_button_ok: "okey",
          admin_hotel_edit_validation_region: "* Sila pilih kawasan.",
          admin_hotel_edit_validation_country: "* Sila pilih negara.",
          admin_hotel_edit_validation_city: "* Sila pilih bandar.",
          admin_hotel_edit_validation_country_name: "* Sila pilih nama negara.",
          admin_hotel_edit_validation_is_show: "* Sila pilih Is Show.",
          admin_hotel_copy_title: "COPY HOTEL",
          admin_hotel_copy_guide_click_copy: "Masukkan butiran hotel dan klik 'Salin' untuk menyalin maklumat hotel.",
          admin_hotel_copy_button_copy: "Salinan",
          admin_setting_detail_title: "TETAPAN MyCONECT",
          admin_setting_detail_label_date: "Tarikh Dikemaskini",
          admin_setting_detail_label_setting: "tetapan",
          admin_setting_detail_label_coordinate_distance: "Jarak Koordinat",
          admin_setting_detail_button_edit: "Sunting",
          admin_setting_edit_title: "TETAPAN MyCONECT",
          admin_setting_edit_label_date: "Tarikh Dikemaskini",
          admin_setting_edit_label_setting: "tetapan",
          admin_setting_edit_label_coordinate_distance: "Jarak Koordinat",
          admin_setting_edit_button_ok: "okey",
          admin_setting_edit_button_cancel: "Batal",
          admin_setting_edit_validation_coordinate_distance: "* Sila masukkan jarak koordinat.",
          admin_region_list_title: "SENARAI WILAYAH",
          admin_region_list_placeholder_search: "Cari...",
          admin_region_list_label_region: "Wilayah",
          admin_region_list_label_date: "Tarikh",
          admin_region_list_guide_empty: "Senarai kosong",
          admin_region_list_button_delete: "Padam",
          admin_region_list_button_add: "Tambah",
          admin_region_add_title: "TAMBAH WILAYAH",
          admin_region_add_guide_click_add: "Masuk kawasan. \ndan klik butang <<'Tambah'>>.",
          admin_region_add_validation_name: "* Sila masukkan nama wilayah.",
          admin_region_add_label_region: "Wilayah",
          admin_region_add_placeholder_region_name: "Nama wilayah",
          admin_region_add_button_go_to_list: "Pergi ke senarai",
          admin_region_add_button_add: "Tambah",
          admin_region_detail_title: "WILAYAH",
          admin_region_detail_label_region: "Wilayah",
          admin_region_detail_label_created_at: "Dicipta Pada",
          admin_region_detail_label_updated_at: "Dikemaskini Pada",
          admin_region_detail_button_go_to_list: "Pergi ke senarai",
          admin_region_detail_button_edit: "Sunting",
          admin_region_edit_title: "WILAYAH",
          admin_region_edit_validation_name: "* Sila masukkan nama wilayah.",
          admin_region_edit_label_region: "Wilayah",
          admin_region_edit_placeholder_region_name: "Nama wilayah",
          admin_region_edit_label_created_at: "Cipta Di",
          admin_region_edit_label_updated_at: "Dikemaskini Pada",
          admin_region_edit_button_cancel: "Batal",
          admin_region_edit_button_ok: "okey",
          admin_country_list_title: "SENARAI NEGARA",
          admin_country_list_select_region_all: "Wilayah ( SEMUA )",
          admin_country_list_placeholder_search: "Cari...",
          admin_country_list_label_region: "Wilayah",
          admin_country_list_label_country: "Negara",
          admin_country_list_label_date: "Tarikh",
          admin_country_list_guide_empty: "Senarai kosong",
          admin_country_list_button_delete: "Padam",
          admin_country_list_button_add: "Tambah",
          admin_country_add_title: "TAMBAH NEGARA",
          admin_country_add_guide_click_add: "Masukkan negara dan klik butang <<'Tambah'>>.",
          admin_country_add_validation_region: "* Sila pilih wilayah.",
          admin_country_add_validation_country: "* Sila masukkan nama negara.",
          admin_country_add_label_region: "Wilayah",
          admin_country_add_label_choose: "Pilih",
          admin_country_add_label_country: "Negara",
          admin_country_add_placeholder_country_name: "Nama negara",
          admin_country_add_button_go_to_list: "Pergi ke senarai",
          admin_country_add_button_add: "Tambah",
          admin_country_detail_title: "NEGARA",
          admin_country_detail_label_region: "Wilayah",
          admin_country_detail_label_country: "Negara",
          admin_country_detail_placeholder_country_name: "Nama negara",
          admin_country_detail_label_created_at: "Dicipta Pada",
          admin_country_detail_label_updated_at: "Dikemaskini Pada",
          admin_country_detail_button_go_to_list: "Pergi ke senarai",
          admin_country_detail_button_edit: "Sunting",
          admin_country_edit_title: "NEGARA",
          admin_country_edit_validation_country: "* Sila masukkan nama negara.",
          admin_country_edit_label_region: "Wilayah",
          admin_country_edit_label_country: "Negara",
          admin_country_edit_placeholder_country_name: "Nama negara",
          admin_country_edit_label_created_at: "Dicipta Pada",
          admin_country_edit_label_updated_at: "Dikemaskini Pada",
          admin_country_edit_button_cancel: "Batal",
          admin_country_edit_button_ok: "okey",
          admin_city_list_title: "SENARAI BANDARAYA",
          admin_city_list_select_region_all: "Wilayah ( SEMUA )",
          admin_city_list_select_country_all: "Negara ( SEMUA )",
          admin_city_list_placeholder_search: "Cari...",
          admin_city_list_label_region: "Wilayah",
          admin_city_list_label_country: "Negara",
          admin_city_list_label_city: "Bandar",
          admin_city_list_label_date: "Tarikh",
          admin_city_list_guide_empty: "Senarai kosong",
          admin_city_list_button_delete: "Padam",
          admin_city_list_button_add: "Tambah",
          admin_city_add_title: "TAMBAH BANDARAYA",
          admin_city_add_guide_click_add: "Masuk ke bandar. \ndan klik butang <<'Tambah'>>.",
          admin_city_add_validation_region: "* Sila pilih wilayah.",
          admin_city_add_validation_country: "* Sila pilih negara.",
          admin_city_add_validation_city: "* Sila masukkan nama bandar.",
          admin_city_add_label_region: "Wilayah",
          admin_city_add_label_country: "Negara",
          admin_city_add_label_choose: "Pilih",
          admin_city_add_label_city: "Bandar",
          admin_city_add_placeholder_city_name: "Nama bandar",
          admin_city_add_button_go_to_list: "Pergi ke senarai",
          admin_city_add_button_add: "BANDARAYA",
          admin_city_detail_label_region: "Tambah",
          admin_city_detail_title: "Wilayah",
          admin_city_detail_label_country: "Negara",
          admin_city_detail_label_city: "Bandar",
          admin_city_detail_placeholder_city_name: "Nama bandar",
          admin_city_detail_label_created_at: "Dicipta Pada",
          admin_city_detail_label_updated_at: "Dikemaskini Pada",
          admin_city_detail_button_go_to_list: "Pergi ke senarai",
          admin_city_detail_button_edit: "Sunting",
          admin_city_edit_title: "BANDARAYA",
          admin_city_edit_validation_city: "* Sila masukkan nama bandar.",
          admin_city_edit_label_region: "Wilayah",
          admin_city_edit_label_country: "Negara",
          admin_city_edit_label_city: "Bandar",
          admin_city_edit_placeholder_city_name: "Nama bandar",
          admin_city_edit_label_created_at: "Dicipta Pada",
          admin_city_edit_label_updated_at: "Dikemaskini Pada",
          admin_city_edit_button_cancel: "Batal",
          admin_city_edit_button_ok: "okey",
          admin_application_user_list_title: "SENARAI PENGGUNA APLIKASI",
          admin_application_user_list_placeholder_select_box_user: "pengguna",
          admin_application_user_list_select_email: "E-mel",
          admin_application_user_list_select_name: "Nama",
          admin_application_user_list_placeholder_search: "Cari...",
          admin_application_user_list_label_email: "E-mel",
          admin_application_user_list_label_backup_email: "E-mel Sandaran",
          admin_application_user_list_label_name: "Nama",
          admin_application_user_list_label_alternative_name: "Nama Alternatif",
          admin_application_user_list_label_mail_confirmed_at: "E-mel Disahkan Di",
          admin_application_user_list_label_date: "Tarikh",
          admin_application_user_list_button_delete: "Padam",
          admin_application_user_list_guide_empty: "Senarai kosong",
          admin_application_user_detail_title: "E-mel Pengguna",
          admin_application_user_detail_label_user_information: "Maklumat Pengguna Aplikasi",
          admin_application_user_detail_user_info_email: "E-mel",
          admin_application_user_detail_user_info_gender: "Jantina",
          admin_application_user_detail_user_info_first_name: "Nama pertama",
          admin_application_user_detail_user_info_last_name: "Nama terakhir",
          admin_application_user_detail_user_info_alternative_first_name: "Nama Pertama Alternatif",
          admin_application_user_detail_user_info_alternative_last_name: "Nama Akhir Alternatif",
          admin_application_user_detail_user_info_member_type: "Jenis Ahli",
          admin_application_user_detail_user_info_backup_email: "E-mel Sandaran",
          admin_application_user_detail_user_info_mail_confirmed_at: "E-mel Disahkan Di",
          admin_application_user_detail_user_info_push_token: "Token Tolak",
          admin_application_user_detail_user_info_os_type: "Jenis OS",
          admin_application_user_detail_user_info_language_code: "Kod Bahasa",
          admin_application_user_detail_user_info_updated_at: "Dikemaskini Pada",
          admin_application_user_detail_user_info_date: "Tarikh",
          admin_application_user_detail_button_go_to_list: "Pergi ke senarai",
          admin_payment_list_title: "SENARAI BAYARAN",
          admin_payment_list_placeholder_hotel_id: "ID hotel",
          admin_payment_list_placeholder_user_name: "Nama Pengguna",
          admin_payment_list_placeholder_search: "Cari...",
          admin_payment_list_label_no: "Tidak.",
          admin_payment_list_label_reservation_id: "ID Tempahan",
          admin_payment_list_label_user_name: "Nama Pengguna",
          admin_payment_list_label_long_info: "Maklumat Panjang",
          admin_payment_list_label_amount: "Jumlah",
          admin_payment_list_label_unit: "Unit",
          admin_payment_list_label_pg_status_code: "Kod status PG",
          admin_payment_list_label_pg_status_message: "Mesej status PG",
          admin_payment_list_label_hotel_id: "ID hotel",
          admin_payment_list_label_date: "Tarikh",
          admin_payment_detail_label_order_no: "Pesanan No.",
          admin_payment_detail_label_user: "pengguna",
          admin_payment_detail_label_user_profile_id: "ID Profil",
          admin_payment_detail_label_user_title: "Tajuk",
          admin_payment_detail_label_user_first_name: "Nama pertama",
          admin_payment_detail_label_user_last_name: "Nama terakhir",
          admin_payment_detail_label_user_email: "E-mel",
          admin_payment_detail_label_user_date: "Tarikh",
          admin_payment_detail_label_reservation: "Tempahan",
          admin_payment_detail_label_reservation_confirmation_no: "No Pengesahan",
          admin_payment_detail_label_reservation_reservation_id: "ID Tempahan",
          admin_payment_detail_label_reservation_reservation_status: "Status Tempahan",
          admin_payment_detail_label_reservation_check_in_date: "Tarikh Daftar Masuk",
          admin_payment_detail_label_reservation_check_out_date: "Tarikh Daftar Keluar",
          admin_payment_detail_label_hotel: "Hotel",
          admin_payment_detail_label_hotel_hotel_id: "ID hotel",
          admin_payment_detail_label_hotel_hotel_name: "Nama Hotel",
          admin_payment_detail_label_hotel_region: "Wilayah",
          admin_payment_detail_label_hotel_country: "Negara",
          admin_payment_detail_label_hotel_city: "Bandar",
          admin_payment_detail_label_payment: "Bayaran",
          admin_payment_detail_button_go_to_list: "Pergi ke senarai",
          admin_payment_detail_button_go_to_log: "Pergi ke senarai log",
          admin_payment_detail_label_payment_order_no: "Pesanan No.",
          admin_payment_detail_label_payment_reservation_id: "ID Tempahan",
          admin_payment_detail_label_payment_long_info: "Maklumat Panjang",
          admin_payment_detail_label_payment_folio_view_no: "Pandangan Folio No.",
          admin_payment_detail_label_payment_amount: "Jumlah",
          admin_payment_detail_label_payment_unit: "Unit",
          admin_payment_detail_label_payment_pg_order_no: "Pesanan PG No.",
          admin_payment_detail_label_payment_pg_status: "Status PG",
          admin_payment_detail_label_payment_pg_status_code: "Kod Status PG",
          admin_payment_detail_label_payment_pg_status_message: "Mesej Status PG",
          admin_payment_detail_label_payment_pg_amount: "Jumlah PG",
          admin_payment_detail_label_payment_pg_unit: "Unit PG",
          admin_payment_detail_label_payment_pg_billing_no: "Bil PG No.",
          admin_payment_detail_label_payment_confirmed_at: "Disahkan Pada",
          admin_payment_detail_label_payment_created_at: "Dicipta Pada",
          admin_payment_detail_label_payment_updated_at: "Dikemaskini Pada",
          admin_payment_log_list_title: "SENARAI LOG PEMBAYARAN",
          admin_payment_log_list_label_no: "TIDAK.",
          admin_payment_log_list_label_type: "taip",
          admin_payment_log_list_label_method: "Kaedah",
          admin_payment_log_list_label_path: "Laluan",
          admin_payment_log_list_label_status: "Status",
          admin_payment_log_list_label_success: "Kejayaan",
          admin_payment_log_list_label_created_at: "Dicipta Pada",
          admin_payment_log_list_guide_empty: "Senarai kosong",
          admin_payment_log_list_button_go_to_detail: "Pergi ke Butiran",
          admin_payment_log_detail_title: "BUTIRAN LOG PEMBAYARAN",
          admin_payment_log_detail_label_id: "ID",
          admin_payment_log_detail_label_hotel_id: "ID hotel",
          admin_payment_log_detail_label_payment_id: "ID Pembayaran",
          admin_payment_log_detail_label_transfer_code: "Kod Pemindahan",
          admin_payment_log_detail_label_type: "taip",
          admin_payment_log_detail_label_method: "Kaedah",
          admin_payment_log_detail_label_path: "Laluan",
          admin_payment_log_detail_label_header: "Kepala",
          admin_payment_log_detail_label_param: "Param",
          admin_payment_log_detail_label_body: "Badan",
          admin_payment_log_detail_label_status: "Status",
          admin_payment_log_detail_label_session: "Sesi",
          admin_payment_log_detail_label_created_at: "Dicipta Pada",
          admin_payment_log_detail_button_go_to_list: "Pergi ke senarai",
          admin_transfer_log_list_title: "SENARAI PINDAH",
          admin_transfer_log_list_placeholder_user_id: "ID Pengguna",
          admin_transfer_log_list_placeholder_hotel_id: "ID hotel",
          admin_transfer_log_list_placeholder_search: "Cari...",
          admin_transfer_log_list_label_no: "Tidak.",
          admin_transfer_log_list_label_type: "taip",
          admin_transfer_log_list_label_user: "pengguna",
          admin_transfer_log_list_label_hotel_id: "ID hotel",
          admin_transfer_log_list_label_path: "Laluan",
          admin_transfer_log_list_label_status: "Status",
          admin_transfer_log_list_label_success: "Kejayaan",
          admin_transfer_log_list_label_created_at: "Dicipta Pada",
          admin_transfer_log_list_guide_empty: "Senarai kosong",
          admin_transfer_log_detail_title: "Butiran Pemindahan",
          admin_transfer_log_detail_label_transfer_id: "ID pemindahan",
          admin_transfer_log_detail_label_type: "taip",
          admin_transfer_log_detail_label_user: "pengguna",
          admin_transfer_log_detail_label_hotel_id: "ID hotel",
          admin_transfer_log_detail_label_user_email: "E-mel Pengguna",
          admin_transfer_log_detail_label_session: "Sesi",
          admin_transfer_log_detail_label_token: "Token",
          admin_transfer_log_detail_label_path: "Laluan",
          admin_transfer_log_detail_label_status: "Status",
          admin_transfer_log_detail_label_header: "Kepala",
          admin_transfer_log_detail_label_param: "Param",
          admin_transfer_log_detail_label_body: "Badan",
          admin_transfer_log_detail_label_created_at: "Dicipta pada",
          admin_transfer_log_detail_label_updated_at: "Dikemas kini pada",
          admin_transfer_log_detail_button_go_to_list: "Pergi ke senarai",
          admin_user_transfer_log_list_title: "Senarai Pemindahan Pengguna",
          admin_user_transfer_log_list_placeholder_email: "Sila masukkan e-mel",
          admin_user_transfer_log_list_placeholder_search: "Cari...",
          admin_user_transfer_log_list_label_no: "Tidak.",
          admin_user_transfer_log_list_label_type: "taip",
          admin_user_transfer_log_list_label_method: "Kaedah",
          admin_user_transfer_log_list_label_path: "Laluan",
          admin_user_transfer_log_list_label_status: "Status",
          admin_user_transfer_log_list_label_success: "Kejayaan",
          admin_user_transfer_log_list_label_user_email: "E-mel Pengguna",
          admin_user_transfer_log_list_label_created_at: "Dicipta Pada",
          admin_user_transfer_log_list_guide_empty: "Senarai kosong",
          admin_user_transfer_log_detail_title: "Butiran Pemindahan Pengguna",
          admin_user_transfer_log_detail_label_id: "ID",
          admin_user_transfer_log_detail_label_type: "taip",
          admin_user_transfer_log_detail_label_method: "Kaedah",
          admin_user_transfer_log_detail_label_path: "Laluan",
          admin_user_transfer_log_detail_label_header: "Kepala",
          admin_user_transfer_log_detail_label_param: "Param",
          admin_user_transfer_log_detail_label_body: "Badan",
          admin_user_transfer_log_detail_label_soap: "sabun",
          admin_user_transfer_log_detail_label_status: "Status",
          admin_user_transfer_log_detail_label_session: "Sesi",
          admin_user_transfer_log_detail_label_token: "Token",
          admin_user_transfer_log_detail_label_user_email: "E-mel Pengguna",
          admin_user_transfer_log_detail_label_created_at: "Dicipta Pada",
          admin_user_transfer_log_detail_button_go_to_list: "Pergi ke senarai",
          admin_transaction_log_list_title: "SENARAI LOG URUSAN",
          admin_transaction_log_list_select_complete_action: "Tindakan Lengkap ( SEMUA )",
          admin_transaction_log_list_select_complete_action_commit: "komited",
          admin_transaction_log_list_select_complete_action_rollback: "Kembalikan",
          admin_transaction_log_list_select_complete_action_none: "tiada",
          admin_transaction_log_list_placeholder_search: "Cari...",
          admin_transaction_log_list_label_no: "Tidak.",
          admin_transaction_log_list_label_act_on_function: "Bertindak mengikut fungsi",
          admin_transaction_log_list_label_complete_action: "Tindakan lengkap",
          admin_transaction_log_list_label_completed_at: "Selesai Pada",
          admin_transaction_log_list_label_date: "Tarikh",
          admin_transaction_log_detail_title: "BUTIRAN LOG TRANSAKSI",
          admin_transaction_log_detail_label_transaction_id: "ID Transaksi",
          admin_transaction_log_detail_label_act_on_function: "Bertindak mengikut fungsi",
          admin_transaction_log_detail_label_complete_action: "Tindakan lengkap",
          admin_transaction_log_detail_label_rollback_reason: "Sebab rollback",
          admin_transaction_log_detail_label_completed_at: "Selesai pada",
          admin_transaction_log_detail_label_created_at: "Dicipta Pada",
          admin_transaction_log_detail_label_updated_at: "Dikemaskini Pada",
          admin_transaction_log_detail_button_go_to_list: "Pergi ke senarai",
          admin_transaction_log_detail_rollback_reason_stack: "Timbunan",
          admin_transaction_log_detail_rollback_reason_error_message: "Mesej ralat",
          admin_user_transfer_log_history_title: "SEJARAH LOG PINDAH PENGGUNA",
          admin_user_transfer_log_history_placeholder_email: "Sila masukkan e-mel anda.",
          admin_user_transfer_log_history_placeholder_search: "Cari...",
          admin_user_transfer_log_history_label_no: "TIDAK.",
          admin_user_transfer_log_history_label_method: "Kaedah",
          admin_user_transfer_log_history_label_path: "Laluan",
          admin_user_transfer_log_history_label_status: "Status",
          admin_user_transfer_log_history_label_success: "Kejayaan",
          admin_user_transfer_log_history_label_user_email: "E-mel Pengguna",
          admin_user_transfer_log_history_label_created_at: "Dicipta Pada",
          admin_user_transfer_log_history_label_process_time: "Masa Kemajuan(ms)",
          admin_user_transfer_log_history_detail_title: "Sejarah Butiran Pemindahan Pengguna (↓)",
          admin_user_transfer_log_history_detail_label_no: "TIDAK.",
          admin_user_transfer_log_history_detail_label_type: "taip",
          admin_user_transfer_log_history_detail_label_method: "Kaedah",
          admin_user_transfer_log_history_detail_label_path: "Laluan",
          admin_user_transfer_log_history_detail_label_status: "Status",
          admin_user_transfer_log_history_detail_label_success: "Kejayaan",
          admin_user_transfer_log_history_detail_label_user_email: "E-mel Pengguna",
          admin_user_transfer_log_history_detail_label_created_at: "Dicipta Pada",
          admin_user_transfer_log_history_detail_button_go_to_list: "Pergi ke senarai",
          admin_staff_transfer_log_history_title: "SEJARAH LOG PEMINDAHAN STAF",
          admin_staff_transfer_log_history_list_placeholder_user_id: "ID Pengguna",
          admin_staff_transfer_log_history_list_placeholder_hotel_id: "ID hotel",
          admin_staff_transfer_log_history_list_placeholder_search: "Cari...",
          admin_staff_transfer_log_history_list_label_no: "Tidak.",
          admin_staff_transfer_log_history_list_label_method: "방법",
          admin_staff_transfer_log_history_list_label_user: "pengguna",
          admin_staff_transfer_log_history_list_label_hotel_id: "ID hotel",
          admin_staff_transfer_log_history_list_label_path: "Laluan",
          admin_staff_transfer_log_history_list_label_status: "Status",
          admin_staff_transfer_log_history_list_label_success: "Kejayaan",
          admin_staff_transfer_log_history_list_label_created_at: "Dicipta Pada",
          admin_staff_transfer_log_history_list_label_process_time: "Masa Kemajuan(ms)",
          admin_staff_transfer_log_history_list_guide_empty: "Senarai kosong",
          admin_staff_transfer_log_history_detail_title: "Sejarah Butiran Perpindahan Kakitangan (↓)",
          admin_staff_transfer_log_history_detail_label_no: "Tidak.",
          admin_staff_transfer_log_history_detail_label_type: "taip",
          admin_staff_transfer_log_history_detail_label_method: "Kaedah",
          admin_staff_transfer_log_history_detail_label_user: "pengguna",
          admin_staff_transfer_log_history_detail_label_hotel_id: "ID hotel",
          admin_staff_transfer_log_history_detail_label_path: "Laluan",
          admin_staff_transfer_log_history_detail_label_status: "Status",
          admin_staff_transfer_log_history_detail_label_success: "Kejayaan",
          admin_staff_transfer_log_history_detail_label_created_at: "Dicipta Pada",
          admin_staff_transfer_log_history_detail_button_go_to_list: "Pergi ke senarai",
          admin_user_notification_log_list_title: "SENARAI LOG PEMBERITAHUAN PENGGUNA",
          admin_user_notification_log_list_placeholder_email: "E-mel",
          admin_user_notification_log_list_placeholder_search: "Cari...",
          admin_user_notification_log_list_label_no: "TIDAK.",
          admin_user_notification_log_list_label_type: "taip",
          admin_user_notification_log_list_label_title: "Tajuk",
          admin_user_notification_log_list_label_email: "E-mel",
          admin_user_notification_log_list_label_user: "pengguna",
          admin_user_notification_log_list_label_success: "Kejayaan",
          admin_user_notification_log_list_label_os: "OS",
          admin_user_notification_log_list_label_language_code: "Kod Bahasa",
          admin_user_notification_log_list_label_created_at: "Dicipta Pada",
          admin_user_notification_log_detail_title: "BUTIRAN LOG PEMBERITAHUAN PENGGUNA",
          admin_user_notification_log_detail_label_notification_id: "ID Pemberitahuan",
          admin_user_notification_log_detail_label_user_id: "ID Pengguna",
          admin_user_notification_log_detail_label_success: "Kejayaan",
          admin_user_notification_log_detail_label_user: "pengguna",
          admin_user_notification_log_detail_label_user_email: "E-mel Pengguna",
          admin_user_notification_log_detail_label_language_code: "Kod Bahasa",
          admin_user_notification_log_detail_label_os_type: "Jenis OS",
          admin_user_notification_log_detail_label_type: "taip",
          admin_user_notification_log_detail_label_title: "Tajuk",
          admin_user_notification_log_detail_label_content: "Kandungan",
          admin_user_notification_log_detail_label_data: "Data",
          admin_user_notification_log_detail_label_token: "Token",
          admin_user_notification_log_detail_label_created_at: "Dicipta Pada",
          admin_user_notification_log_detail_label_updated_at: "Dikemaskini Pada",
          admin_user_notification_log_detail_button_go_to_list: "Pergi ke senarai",
          admin_staff_notification_log_list_title: "SENARAI LOG PEMBERITAHUAN STAF",
          admin_staff_notification_log_list_placeholder_email: "E-mel",
          admin_staff_notification_log_list_placeholder_search: "Cari...",
          admin_staff_notification_log_list_label_no: "TIDAK.",
          admin_staff_notification_log_list_label_type: "taip",
          admin_staff_notification_log_list_label_title: "Tajuk",
          admin_staff_notification_log_list_label_email: "E-mel",
          admin_staff_notification_log_list_label_hotel: "Hotel",
          admin_staff_notification_log_list_label_staff: "Kakitangan",
          admin_staff_notification_log_list_label_task: "Tugasan",
          admin_staff_notification_log_list_label_department: "Jabatan",
          admin_staff_notification_log_list_label_success: "Kejayaan",
          admin_staff_notification_log_list_label_os: "OS",
          admin_staff_notification_log_list_label_language_code: "Kod Bahasa",
          admin_staff_notification_log_list_label_created_at: "Dicipta Pada",
          admin_staff_notification_log_detail_title: "BUTIRAN LOG PEMBERITAHUAN STAF",
          admin_staff_notification_log_detail_label_notification_id: "ID Pemberitahuan",
          admin_staff_notification_log_detail_label_hotel_id: "ID hotel",
          admin_staff_notification_log_detail_label_staff_id: "ID kakitangan",
          admin_staff_notification_log_detail_label_success: "Kejayaan",
          admin_staff_notification_log_detail_label_staff_email: "E-mel",
          admin_staff_notification_log_detail_label_staff_no: "Kakitangan NO.",
          admin_staff_notification_log_detail_label_department: "Jabatan",
          admin_staff_notification_log_detail_label_team: "Pasukan",
          admin_staff_notification_log_detail_label_level: "Tahap",
          admin_staff_notification_log_detail_label_task: "Tugasan",
          admin_staff_notification_log_detail_label_location: "Lokasi",
          admin_staff_notification_log_detail_label_zone: "Kawasan",
          admin_staff_notification_log_detail_label_room: "Bilik",
          admin_staff_notification_log_detail_label_guest_inquiry_level: "Tahap Pertanyaan Tetamu",
          admin_staff_notification_log_detail_label_language_code: "Kod Bahasa",
          admin_staff_notification_log_detail_label_os_type: "Jenis OS",
          admin_staff_notification_log_detail_label_type: "taip",
          admin_staff_notification_log_detail_label_title: "Tajuk",
          admin_staff_notification_log_detail_label_content: "Kandungan",
          admin_staff_notification_log_detail_label_data: "Data",
          admin_staff_notification_log_detail_label_token: "Token",
          admin_staff_notification_log_detail_label_created_at: "Dicipta Pada",
          admin_staff_notification_log_detail_label_updated_at: "Dikemaskini Pada",
          admin_staff_notification_log_detail_button_go_to_list: "Pergi ke senarai",
          admin_version_list_title: "SENARAI VERSI",
          admin_version_list_placeholiser_search: "Cari...",
          admin_version_list_label_version_name: "Nama Versi",
          admin_version_list_label_ios: "iOS",
          admin_version_list_label_ios_debug: "Nyahpepijat iOS",
          admin_version_list_label_android: "Android",
          admin_version_list_label_android_debug: "Nyahpepijat Android",
          admin_version_list_guide_empty: "Senarai kosong",
          admin_version_list_button_delete: "Padam",
          admin_version_list_button_add: "Tambah",
          admin_version_add_title: "TAMBAH VERSI",
          admin_version_add_label_version_name: "Nama Versi",
          admin_version_add_label_ios: "iOS",
          admin_version_add_label_ios_debug: "Nyahpepijat iOS",
          admin_version_add_placeholder_ios_debug: "Nyahpepijat iOS",
          admin_version_add_select_ios_true: "BENAR",
          admin_version_add_select_ios_false: "SALAH",
          admin_version_add_label_android: "Android",
          admin_version_add_label_android_debug: "Nyahpepijat Android",
          admin_version_add_placeholder_android_debug: "Nyahpepijat Android",
          admin_version_add_validation_name: "* Sila masukkan nama versi.",
          admin_version_add_validation_ios: "* Sila masukkan iOS.",
          admin_version_add_validation_ios_debug: "* Sila pilih iOS Debug.",
          admin_version_add_validation_andriod: "* Sila masukkan Android.",
          admin_version_add_validation_andriod_debug: "* Sila pilih Android Debug.",
          admin_version_add_select_android_true: "BENAR",
          admin_version_add_select_android_false: "SALAH",
          admin_version_add_button_go_to_list: "Pergi ke senarai",
          admin_version_add_button_add: "Tambah",
          admin_version_detail_title: "MAKLUMAT VERSI",
          admin_version_detail_label_version_name: "Nama Versi",
          admin_version_detail_label_ios: "iOS",
          admin_version_detail_label_ios_debug: "Nyahpepijat iOS",
          admin_version_detail_label_android: "Android",
          admin_version_detail_label_android_debug: "Nyahpepijat Android",
          admin_version_detail_button_go_to_list: "Pergi ke senarai",
          admin_version_detail_button_delete: "Padam",
          admin_version_detail_button_edit: "Sunting",
          admin_version_edit_title: "MAKLUMAT VERSI",
          admin_version_edit_label_version_name: "Nama Versi",
          admin_version_edit_label_ios: "iOS",
          admin_version_edit_label_ios_debug: "Nyahpepijat iOS",
          admin_version_edit_select_ios_true: "BENAR",
          admin_version_edit_select_ios_false: "SALAH",
          admin_version_edit_label_android: "Android",
          admin_version_edit_label_android_debug: "Nyahpepijat Android",
          admin_version_edit_select_android_true: "BENAR",
          admin_version_edit_select_android_false: "SALAH",
          admin_version_edit_button_cancel: "Batal",
          admin_version_edit_button_delete: "Padam",
          admin_version_edit_button_ok: "okey",
          admin_version_app_down_version_list_title: "SENARAI VERSI APLIKASI",
          admin_version_app_down_version_list_label_os: "OS",
          admin_version_app_down_version_list_label_type: "taip",
          admin_version_app_down_version_list_label_version: "Versi",
          admin_version_app_down_version_list_label_link: "Pautan",
          admin_version_app_down_version_list_label_created_at: "Dicipta Pada",
          admin_version_app_down_version_list_button_delete: "Padam",
          admin_version_app_down_version_list_button_add: "Tambah",
          admin_version_app_down_version_list_placeholiser_search: "Cari...",
          admin_version_app_down_version_list_label_no: "Tidak.",
          admin_version_app_down_version_list_guide_empty: "Senarai kosong",
          admin_version_app_down_version_add_title: "TAMBAH VERSI MUAT TURUN APLIKASI",
          admin_version_app_down_version_add_validation_os: "* Sila pilih OS.",
          admin_version_app_down_version_add_validation_type: "* Sila pilih jenis.",
          admin_version_app_down_version_add_validation_version: "* Sila masukkan versi.",
          admin_version_app_down_version_add_validation_link: "* Sila masukkan pautan.",
          admin_version_app_down_version_add_label_os: "OS",
          admin_version_app_down_version_add_placeholder_os: "OS",
          admin_version_app_down_version_add_select_ios: "iOS",
          admin_version_app_down_version_add_select_aos: "AOS",
          admin_version_app_down_version_add_label_type: "taip",
          admin_version_app_down_version_add_placeholder_type: "taip",
          admin_version_app_down_version_add_select_guest: "TETAMU",
          admin_version_app_down_version_add_select_staff: "KAKITANGAN",
          admin_version_app_down_version_add_select_valet: "VALET",
          admin_version_app_down_version_add_select_parking_staff: "KAKITANGAN LETAK KERETA",
          admin_version_app_down_version_add_label_version: "Versi",
          admin_version_app_down_version_add_label_link: "Pautan",
          admin_version_app_down_version_add_button_go_to_list: "Pergi ke senarai",
          admin_version_app_down_version_add_button_add: "Tambah",
          admin_version_guest_app_version_list_title: "SENARAI VERSI APLIKASI TETAMU",
          admin_version_guest_app_version_list_placeholiser_search: "Cari...",
          admin_version_guest_app_version_list_label_no: "Tidak.",
          admin_version_guest_app_version_list_label_os: "OS",
          admin_version_guest_app_version_list_label_version: "Versi",
          admin_version_guest_app_version_list_label_link: "Pautan",
          admin_version_guest_app_version_list_label_created_at: "Dicipta Pada",
          admin_version_guest_app_version_list_button_delete: "Padam",
          admin_version_guest_app_version_list_button_add: "Tambah",
          admin_version_guest_app_version_list_guide_empty: "Senarai kosong",
          admin_version_guest_app_version_add_title: "TAMBAH VERSI TETAMU",
          admin_version_guest_app_version_add_validation_os: "* Sila pilih OS.",
          admin_version_guest_app_version_add_validation_version: "* Sila masukkan versi.",
          admin_version_guest_app_version_add_validation_link: "* Sila masukkan pautan.",
          admin_version_guest_app_version_add_label_os: "OS",
          admin_version_guest_app_version_add_placeholder_os: "OS",
          admin_version_guest_app_version_add_select_ios: "iOS",
          admin_version_guest_app_version_add_select_aos: "AOS",
          admin_version_guest_app_version_add_label_version: "Versi",
          admin_version_guest_app_version_add_label_link: "Pautan",
          admin_version_guest_app_version_add_button_go_to_list: "Pergi ke senarai",
          admin_version_guest_app_version_add_button_add: "Tambah",
          admin_version_staff_app_version_list_title: "SENARAI VERSI PERMOHONAN STAF",
          admin_version_staff_app_version_add_validation_os: "* Sila pilih OS.",
          admin_version_staff_app_version_add_validation_version: "* Sila masukkan versi.",
          admin_version_staff_app_version_add_validation_link: "* Sila masukkan pautan.",
          admin_version_staff_app_version_list_placeholiser_search: "Cari...",
          admin_version_staff_app_version_list_label_no: "Tidak.",
          admin_version_staff_app_version_list_label_os: "OS",
          admin_version_staff_app_version_list_label_version: "Versi",
          admin_version_staff_app_version_list_label_link: "Pautan",
          admin_version_staff_app_version_list_label_created_at: "Dicipta Pada",
          admin_version_staff_app_version_list_button_delete: "Padam",
          admin_version_staff_app_version_list_button_add: "Tambah",
          admin_version_staff_app_version_list_guide_empty: "Senarai kosong",
          admin_version_staff_app_version_add_title: "TAMBAH VERSI KAKITANGAN",
          admin_version_staff_app_version_add_label_os: "OS",
          admin_version_staff_app_version_add_placeholder_os: "OS",
          admin_version_staff_app_version_add_select_ios: "iOS",
          admin_version_staff_app_version_add_select_aos: "AOS",
          admin_version_staff_app_version_add_label_version: "Versi",
          admin_version_staff_app_version_add_label_link: "Pautan",
          admin_version_staff_app_version_add_button_go_to_list: "Pergi ke senarai",
          admin_version_staff_app_version_add_button_add: "Tambah",
          admin_version_parking_staff_app_version_list_title: "SENARAI MUAT TURUN PERMOHONAN STAF PARKING",
          admin_version_parking_staff_app_version_add_validation_os: "* Sila pilih OS.",
          admin_version_parking_staff_app_version_add_validation_version: "* Sila masukkan versi.",
          admin_version_parking_staff_app_version_add_validation_link: "* Sila masukkan pautan.",
          admin_version_parking_staff_app_version_list_placeholiser_search: "Cari...",
          admin_version_parking_staff_app_version_list_label_no: "Tidak.",
          admin_version_parking_staff_app_version_list_label_os: "OS",
          admin_version_parking_staff_app_version_list_label_version: "Versi",
          admin_version_parking_staff_app_version_list_label_link: "Pautan",
          admin_version_parking_staff_app_version_list_label_created_at: "Dicipta Pada",
          admin_version_parking_staff_app_version_list_button_delete: "Padam",
          admin_version_parking_staff_app_version_list_button_add: "Tambah",
          admin_version_parking_staff_app_version_add_title: "TAMBAH MUAT TURUN KAKITANGAN PARKING",
          admin_version_parking_staff_app_version_add_label_os: "OS",
          admin_version_parking_staff_app_version_add_placeholder_os: "OS",
          admin_version_parking_staff_app_version_add_select_ios: "iOS",
          admin_version_parking_staff_app_version_add_select_aos: "AOS",
          admin_version_parking_staff_app_version_add_label_version: "Versi",
          admin_version_parking_staff_app_version_add_label_link: "Pautan",
          admin_version_parking_staff_app_version_add_button_go_to_list: "Pergi ke senarai",
          admin_version_parking_staff_app_version_add_button_add: "Tambah",
          admin_application_translation_list_title: "SENARAI TERJEMAHAN PERMOHONAN",
          admin_application_translation_list_select_application_type: "Jenis Permohonan ( SEMUA )",
          admin_application_translation_list_select_application_type_guest: "tetamu",
          admin_application_translation_list_select_application_type_staff: "Kakitangan",
          admin_application_translation_list_select_application_type_parking_staff: "Kakitangan Tempat Letak Kereta",
          admin_application_translation_list_select_os_type: "Jenis OS ( SEMUA )",
          admin_application_translation_list_select_os_type_aos: "AOS",
          admin_application_translation_list_select_os_type_ios: "iOS",
          admin_application_translation_list_placehoder_application_version: "Versi Permohonan",
          admin_application_translation_list_placehoder_file_name: "Nama fail",
          admin_application_translation_list_label_application_type: "Jenis Permohonan",
          admin_application_translation_list_label_os: "OS",
          admin_application_translation_list_label_application_version: "Versi Permohonan",
          admin_application_translation_list_label_version: "Versi",
          admin_application_translation_list_label_file_name: "Nama fail",
          admin_application_translation_list_label_date: "Tarikh",
          admin_application_translation_list_button_delete: "Padam",
          admin_application_translation_list_button_add: "Tambah",
          admin_application_translation_list_guide_empty: "Senarai kosong",
          admin_application_translation_add_title: "TAMBAHKAN TERJEMAHAN PERMOHONAN",
          admin_application_translation_add_validation_application_type: "* Sila pilih jenis permohonan.",
          admin_application_translation_add_validation_os_type: "* Sila pilih jenis OS.",
          admin_application_translation_add_validation_application_version: "* Sila masukkan Versi Permohonan.",
          admin_application_translation_add_validation_version: "* Sila masukkan versi.",
          admin_application_translation_add_validation_translation_file: "* Sila masukkan fail terjemahan.",
          admin_application_translation_add_label_admin_application_type: "Jenis Permohonan",
          admin_application_translation_add_placeholder_admin_application_type: "Jenis Permohonan",
          admin_application_translation_add_select_admin_application_type_guest: "tetamu",
          admin_application_translation_add_select_admin_application_type_staff: "Kakitangan",
          admin_application_translation_add_select_admin_application_type_parking_staff: "Kakitangan Tempat Letak Kereta",
          admin_application_translation_add_label_os_type: "Jenis OS",
          admin_application_translation_add_placeholder_os_type: "Jenis OS",
          admin_application_translation_add_select_os_type_aos: "AOS",
          admin_application_translation_add_select_os_type_ios: "iOS",
          admin_application_translation_add_label_admin_application_version: "Versi Permohonan",
          admin_application_translation_add_label_version: "Versi",
          admin_application_translation_add_label_translation_file: "Fail Terjemahan",
          admin_application_translation_add_button_upload: "Muat naik",
          admin_application_translation_add_placeholder_upload: "cth) Terjemahan.json",
          admin_application_translation_add_button_go_to_list: "Pergi ke senarai",
          admin_application_translation_add_button_add: "Tambah",
          admin_application_translation_detail_title: "BUTIRAN TERJEMAHAN PERMOHONAN",
          admin_application_translation_detail_label_admin_application_type: "Jenis Permohonan",
          admin_application_translation_detail_placeholder_select_box_choose: "Pilih",
          admin_application_translation_detail_select_admin_application_type_guest: "tetamu",
          admin_application_translation_detail_select_admin_application_type_staff: "Kakitangan",
          admin_application_translation_detail_label_os_type: "Jenis OS",
          admin_application_translation_detail_select_os_type_aos: "AOS",
          admin_application_translation_detail_select_os_type_ios: "iOS",
          admin_application_translation_detail_label_admin_application_version: "Versi Permohonan",
          admin_application_translation_detail_label_version: "Versi",
          admin_application_translation_detail_label_file_name: "Nama fail",
          admin_application_translation_detail_label_date: "Tarikh",
          admin_application_translation_detail_button_edit: "Sunting",
          admin_application_translation_detail_button_go_to_list: "Pergi ke senarai",
          admin_application_translation_detail_button_download: "Muat turun",
          admin_application_translation_edit_title: "PERMOHONAN TERJEMAHAN BUTIRAN",
          admin_application_translation_edit_button_download: "Muat turun",
          admin_application_translation_edit_validation_application_type: "* Sila pilih jenis permohonan.",
          admin_application_translation_edit_validation_os_type: "* Sila pilih jenis OS.",
          admin_application_translation_edit_validation_application_version: "* Sila masukkan Versi Permohonan.",
          admin_application_translation_edit_validation_version: "* Sila masukkan versi.",
          admin_application_translation_edit_validation_translation_file: "* Sila masukkan fail terjemahan.",
          admin_application_translation_edit_label_admin_application_type: "Jenis Permohonan",
          admin_application_translation_edit_placeholder_select_box_choose: "Pilih",
          admin_application_translation_edit_select_admin_application_type_guest: "tetamu",
          admin_application_translation_edit_select_admin_application_type_staff: "Kakitangan",
          admin_application_translation_edit_label_os_type: "Jenis OS",
          admin_application_translation_edit_select_os_type_aos: "AOS",
          admin_application_translation_edit_select_os_type_ios: "iOS",
          admin_application_translation_edit_label_admin_application_version: "Versi Permohonan",
          admin_application_translation_edit_label_version: "Versi",
          admin_application_translation_edit_label_file_name: "Nama fail",
          admin_application_translation_edit_label_date: "Tarikh",
          admin_application_translation_edit_button_upload: "Muat naik",
          admin_application_translation_edit_button_ok: "okey",
          admin_application_translation_edit_button_cancel: "Batal",
          admin_booking_email_detail_label_format: "Format Imej E-mel",
          admin_booking_email_detail_label_image: "Imej E-mel",
          admin_booking_email_detail_button_upload: "Muat naik",
          admin_booking_email_detail_placeholder_upload: "cth) MyCONECT.png",
          admin_booking_email_detail_button_edit: "Sunting",
          admin_email_form_change_password_edit_title: "TUKAR KATA LALUAN",
          admin_email_form_change_password_edit_validation_content: "* Sila masukkan butiran anda.",
          admin_email_form_change_password_edit_select_kr: "KR",
          admin_email_form_change_password_edit_select_en: "EN",
          admin_email_form_change_password_edit_select_cn: "CN",
          admin_email_form_change_password_edit_select_jp: "JP",
          admin_email_form_change_password_edit_select_ms: "MS",
          admin_email_form_change_password_edit_button_remove: "Alih keluar",
          admin_email_form_change_password_edit_button_append: "Lampirkan",
          admin_email_form_change_password_edit_button_save: "Jimat",
          admin_email_form_change_password_edit_toolbar_bold: "berani",
          admin_email_form_change_password_edit_toolbar_italic: "Italic",
          admin_email_form_change_password_edit_toolbar_underline: "Garis bawah",
          admin_email_form_change_password_edit_toolbar_strikethrough: "Strikethrough",
          admin_email_form_change_password_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          admin_email_form_change_password_edit_toolbar_font_family: "Keluarga Font",
          admin_email_form_change_password_edit_toolbar_font_size: "Saiz huruf",
          admin_email_form_change_password_edit_toolbar_text_color: "Warna Teks",
          admin_email_form_change_password_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          admin_email_form_change_password_edit_toolbar_recent_color: "Warna Terkini",
          admin_email_form_change_password_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          admin_email_form_change_password_edit_toolbar_table: "Jadual",
          admin_email_form_change_password_edit_toolbar_unordered_list: "Senarai tidak teratur",
          admin_email_form_change_password_edit_toolbar_ordered_list: "Senarai pesanan",
          admin_email_form_change_password_edit_toolbar_paragraph: "Perenggan",
          admin_email_form_change_password_edit_toolbar_line_height: "Ketinggian Garisan",
          admin_email_form_change_password_edit_toolbar_picture: "Gambar",
          admin_email_form_change_password_edit_toolbar_code_view: "Pandangan Kod",
          admin_email_form_change_password_edit_toolbar_full_screen: "Skrin penuh",
          admin_email_form_change_password_edit_toolbar_help: "Tolong",
          admin_email_form_change_password_edit_toolbar_tip: "Petua",
          admin_email_form_change_password_edit_placeholder_content: "Sila masukkan kandungan.",
          admin_email_form_forgot_id_edit_title: "LUPA ID",
          admin_email_form_forgot_id_edit_validation_content: "* Sila masukkan butiran anda.",
          admin_email_form_forgot_id_edit_select_kr: "KR",
          admin_email_form_forgot_id_edit_select_en: "EN",
          admin_email_form_forgot_id_edit_select_cn: "CN",
          admin_email_form_forgot_id_edit_select_jp: "JP",
          admin_email_form_forgot_id_edit_select_ms: "MS",
          admin_email_form_forgot_id_edit_button_remove: "Alih keluar",
          admin_email_form_forgot_id_edit_button_append: "Lampirkan",
          admin_email_form_forgot_id_edit_button_save: "Jimat",
          admin_email_form_forgot_id_edit_toolbar_bold: "berani",
          admin_email_form_forgot_id_edit_toolbar_italic: "Italic",
          admin_email_form_forgot_id_edit_toolbar_underline: "Garis bawah",
          admin_email_form_forgot_id_edit_toolbar_strikethrough: "Strikethrough",
          admin_email_form_forgot_id_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          admin_email_form_forgot_id_edit_toolbar_font_family: "Keluarga Font",
          admin_email_form_forgot_id_edit_toolbar_font_size: "Saiz huruf",
          admin_email_form_forgot_id_edit_toolbar_text_color: "Warna Teks",
          admin_email_form_forgot_id_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          admin_email_form_forgot_id_edit_toolbar_recent_color: "Warna Terkini",
          admin_email_form_forgot_id_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          admin_email_form_forgot_id_edit_toolbar_table: "Jadual",
          admin_email_form_forgot_id_edit_toolbar_unordered_list: "Senarai tidak teratur",
          admin_email_form_forgot_id_edit_toolbar_ordered_list: "Senarai pesanan",
          admin_email_form_forgot_id_edit_toolbar_paragraph: "Perenggan",
          admin_email_form_forgot_id_edit_toolbar_line_height: "Ketinggian Garisan",
          admin_email_form_forgot_id_edit_toolbar_picture: "Gambar",
          admin_email_form_forgot_id_edit_toolbar_code_view: "Pandangan Kod",
          admin_email_form_forgot_id_edit_toolbar_full_screen: "Skrin penuh",
          admin_email_form_forgot_id_edit_toolbar_help: "Tolong",
          admin_email_form_forgot_id_edit_toolbar_tip: "Petua",
          admin_email_form_forgot_id_edit_placeholder_content: "Sila masukkan kandungan.",
          admin_email_form_forgot_id_detail_title: "TERLUPA PERWATAKAN ID",
          admin_email_form_forgot_id_detail_label_user_info: "Maklumat Pengguna",
          admin_email_form_forgot_id_detail_label_example_user_info: "{{Maklumat Pengguna}}",
          admin_email_form_forgot_password_edit_title: "LUPA KATA LALUAN",
          admin_email_form_forgot_password_edit_validation_content: "* Sila masukkan butiran anda.",
          admin_email_form_forgot_password_edit_select_kr: "KR",
          admin_email_form_forgot_password_edit_select_en: "EN",
          admin_email_form_forgot_password_edit_select_cn: "CN",
          admin_email_form_forgot_password_edit_select_jp: "JP",
          admin_email_form_forgot_password_edit_select_ms: "MS",
          admin_email_form_forgot_password_edit_button_remove: "Alih keluar",
          admin_email_form_forgot_password_edit_button_append: "Lampirkan",
          admin_email_form_forgot_password_edit_button_save: "Jimat",
          admin_email_form_forgot_password_edit_toolbar_bold: "berani",
          admin_email_form_forgot_password_edit_toolbar_italic: "Italic",
          admin_email_form_forgot_password_edit_toolbar_underline: "Garis bawah",
          admin_email_form_forgot_password_edit_toolbar_strikethrough: "Strikethrough",
          admin_email_form_forgot_password_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          admin_email_form_forgot_password_edit_toolbar_font_family: "Keluarga Font",
          admin_email_form_forgot_password_edit_toolbar_font_size: "Saiz huruf",
          admin_email_form_forgot_password_edit_toolbar_text_color: "Warna Teks",
          admin_email_form_forgot_password_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          admin_email_form_forgot_password_edit_toolbar_recent_color: "Warna Terkini",
          admin_email_form_forgot_password_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          admin_email_form_forgot_password_edit_toolbar_table: "Jadual",
          admin_email_form_forgot_password_edit_toolbar_unordered_list: "Senarai tidak teratur",
          admin_email_form_forgot_password_edit_toolbar_ordered_list: "Senarai pesanan",
          admin_email_form_forgot_password_edit_toolbar_paragraph: "Perenggan",
          admin_email_form_forgot_password_edit_toolbar_line_height: "Ketinggian Garisan",
          admin_email_form_forgot_password_edit_toolbar_picture: "Gambar",
          admin_email_form_forgot_password_edit_toolbar_code_view: "Pandangan Kod",
          admin_email_form_forgot_password_edit_toolbar_full_screen: "Skrin penuh",
          admin_email_form_forgot_password_edit_toolbar_help: "Tolong",
          admin_email_form_forgot_password_edit_toolbar_tip: "Petua",
          admin_email_form_forgot_password_edit_placeholder_content: "Sila masukkan kandungan.",
          admin_email_form_forgot_password_detail_title: "TERLUPA PERWATAKAN KATA LALUAN",
          admin_email_form_forgot_password_detail_label_email: "E-mel",
          admin_email_form_forgot_password_detail_label_example_email: "{{email}}",
          admin_email_form_forgot_password_detail_label_password: "Kata laluan",
          admin_email_form_forgot_password_detail_label_example_password: "{{kata laluan}}",
          admin_email_form_create_confirm_edit_title: "BUAT E-mel SAHKAN",
          admin_email_form_create_confirm_edit_validation_content: "* Sila masukkan butiran anda.",
          admin_email_form_create_confirm_edit_select_kr: "KR",
          admin_email_form_create_confirm_edit_select_en: "EN",
          admin_email_form_create_confirm_edit_select_cn: "CN",
          admin_email_form_create_confirm_edit_select_jp: "JP",
          admin_email_form_create_confirm_edit_select_ms: "MS",
          admin_email_form_create_confirm_edit_button_remove: "Alih keluar",
          admin_email_form_create_confirm_edit_button_append: "Lampirkan",
          admin_email_form_create_confirm_edit_button_save: "Jimat",
          admin_email_form_create_confirm_edit_toolbar_bold: "berani",
          admin_email_form_create_confirm_edit_toolbar_italic: "Italic",
          admin_email_form_create_confirm_edit_toolbar_underline: "Garis bawah",
          admin_email_form_create_confirm_edit_toolbar_strikethrough: "Strikethrough",
          admin_email_form_create_confirm_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          admin_email_form_create_confirm_edit_toolbar_font_family: "Keluarga Font",
          admin_email_form_create_confirm_edit_toolbar_font_size: "Saiz huruf",
          admin_email_form_create_confirm_edit_toolbar_text_color: "Warna Teks",
          admin_email_form_create_confirm_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          admin_email_form_create_confirm_edit_toolbar_recent_color: "Warna Terkini",
          admin_email_form_create_confirm_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          admin_email_form_create_confirm_edit_toolbar_table: "Jadual",
          admin_email_form_create_confirm_edit_toolbar_unordered_list: "Senarai tidak teratur",
          admin_email_form_create_confirm_edit_toolbar_ordered_list: "Senarai pesanan",
          admin_email_form_create_confirm_edit_toolbar_paragraph: "Perenggan",
          admin_email_form_create_confirm_edit_toolbar_line_height: "Ketinggian Garisan",
          admin_email_form_create_confirm_edit_toolbar_picture: "Gambar",
          admin_email_form_create_confirm_edit_toolbar_code_view: "Pandangan Kod",
          admin_email_form_create_confirm_edit_toolbar_full_screen: "Skrin penuh",
          admin_email_form_create_confirm_edit_toolbar_help: "Tolong",
          admin_email_form_create_confirm_edit_toolbar_tip: "Petua",
          admin_email_form_create_confirm_edit_placeholder_content: "Sila masukkan kandungan.",
          admin_email_form_create_confirm_detail_title: "CIPTA WATAK SAHKAN",
          admin_email_form_create_confirm_detail_label_url: "URL",
          admin_email_form_create_confirm_detail_example_url: "{{url}}",
          admin_email_form_create_confirm_failure_edit_title: "SAHKAN EMEL GAGAL",
          admin_email_form_create_confirm_failure_edit_validation_content: "* Sila masukkan butiran anda.",
          admin_email_form_create_confirm_failure_edit_select_kr: "KR",
          admin_email_form_create_confirm_failure_edit_select_en: "EN",
          admin_email_form_create_confirm_failure_edit_select_cn: "CN",
          admin_email_form_create_confirm_failure_edit_select_jp: "JP",
          admin_email_form_create_confirm_failure_edit_select_ms: "MS",
          admin_email_form_create_confirm_failure_edit_button_remove: "Alih keluar",
          admin_email_form_create_confirm_failure_edit_button_append: "Lampirkan",
          admin_email_form_create_confirm_failure_edit_button_save: "Jimat",
          admin_email_form_create_confirm_failure_edit_toolbar_bold: "berani",
          admin_email_form_create_confirm_failure_edit_toolbar_italic: "Italic",
          admin_email_form_create_confirm_failure_edit_toolbar_underline: "Garis bawah",
          admin_email_form_create_confirm_failure_edit_toolbar_strikethrough: "Strikethrough",
          admin_email_form_create_confirm_failure_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          admin_email_form_create_confirm_failure_edit_toolbar_font_family: "Keluarga Font",
          admin_email_form_create_confirm_failure_edit_toolbar_font_size: "Saiz huruf",
          admin_email_form_create_confirm_failure_edit_toolbar_text_color: "Warna Teks",
          admin_email_form_create_confirm_failure_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          admin_email_form_create_confirm_failure_edit_toolbar_recent_color: "Warna Terkini",
          admin_email_form_create_confirm_failure_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          admin_email_form_create_confirm_failure_edit_toolbar_table: "Jadual",
          admin_email_form_create_confirm_failure_edit_toolbar_unordered_list: "Senarai tidak teratur",
          admin_email_form_create_confirm_failure_edit_toolbar_ordered_list: "Senarai pesanan",
          admin_email_form_create_confirm_failure_edit_toolbar_paragraph: "Perenggan",
          admin_email_form_create_confirm_failure_edit_toolbar_line_height: "Ketinggian Garisan",
          admin_email_form_create_confirm_failure_edit_toolbar_picture: "Gambar",
          admin_email_form_create_confirm_failure_edit_toolbar_code_view: "Pandangan Kod",
          admin_email_form_create_confirm_failure_edit_toolbar_full_screen: "Skrin penuh",
          admin_email_form_create_confirm_failure_edit_toolbar_help: "Tolong",
          admin_email_form_create_confirm_failure_edit_toolbar_tip: "Petua",
          admin_email_form_create_confirm_failure_edit_placeholder_content: "Sila masukkan kandungan.",
          admin_email_form_create_confirm_failure_detail_title: "BUAT KANDUNGAN GAGAL E-mel",
          admin_email_form_create_confirm_failure_detail_label_reason: "Sebab",
          admin_email_form_create_confirm_failure_detail_label_example_reason: "{{sebab}}",
          admin_email_form_create_confirm_success_edit_title: "BUAT SAHKAN EMEL BERJAYA",
          admin_email_form_create_confirm_success_edit_validation_content: "* Sila masukkan butiran anda.",
          admin_email_form_create_confirm_success_edit_select_kr: "KR",
          admin_email_form_create_confirm_success_edit_select_en: "EN",
          admin_email_form_create_confirm_success_edit_select_cn: "CN",
          admin_email_form_create_confirm_success_edit_select_jp: "JP",
          admin_email_form_create_confirm_success_edit_select_ms: "MS",
          admin_email_form_create_confirm_success_edit_button_remove: "Alih keluar",
          admin_email_form_create_confirm_success_edit_button_append: "Lampirkan",
          admin_email_form_create_confirm_success_edit_button_save: "Jimat",
          admin_email_form_create_confirm_success_edit_toolbar_bold: "berani",
          admin_email_form_create_confirm_success_edit_toolbar_italic: "Italic",
          admin_email_form_create_confirm_success_edit_toolbar_underline: "Garis bawah",
          admin_email_form_create_confirm_success_edit_toolbar_strikethrough: "Strikethrough",
          admin_email_form_create_confirm_success_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          admin_email_form_create_confirm_success_edit_toolbar_font_family: "Keluarga Font",
          admin_email_form_create_confirm_success_edit_toolbar_font_size: "Saiz huruf",
          admin_email_form_create_confirm_success_edit_toolbar_text_color: "Warna Teks",
          admin_email_form_create_confirm_success_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          admin_email_form_create_confirm_success_edit_toolbar_recent_color: "Warna Terkini",
          admin_email_form_create_confirm_success_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          admin_email_form_create_confirm_success_edit_toolbar_table: "Jadual",
          admin_email_form_create_confirm_success_edit_toolbar_unordered_list: "Senarai tidak teratur",
          admin_email_form_create_confirm_success_edit_toolbar_ordered_list: "Senarai pesanan",
          admin_email_form_create_confirm_success_edit_toolbar_paragraph: "Perenggan",
          admin_email_form_create_confirm_success_edit_toolbar_line_height: "Ketinggian Garisan",
          admin_email_form_create_confirm_success_edit_toolbar_picture: "Gambar",
          admin_email_form_create_confirm_success_edit_toolbar_code_view: "Pandangan Kod",
          admin_email_form_create_confirm_success_edit_toolbar_full_screen: "Skrin penuh",
          admin_email_form_create_confirm_success_edit_toolbar_help: "Tolong",
          admin_email_form_create_confirm_success_edit_toolbar_tip: "Petua",
          admin_email_form_create_confirm_success_edit_placeholder_content: "Sila masukkan kandungan.",
          admin_email_form_update_profile_edit_title: "KEMASKINI PROFIL",
          admin_email_form_update_profile_edit_validation_content: "* Sila masukkan butiran anda.",
          admin_email_form_update_profile_edit_select_kr: "KR",
          admin_email_form_update_profile_edit_select_en: "EN",
          admin_email_form_update_profile_edit_select_cn: "CN",
          admin_email_form_update_profile_edit_select_jp: "JP",
          admin_email_form_update_profile_edit_select_ms: "MS",
          admin_email_form_update_profile_edit_button_remove: "Alih keluar",
          admin_email_form_update_profile_edit_button_append: "Lampirkan",
          admin_email_form_update_profile_edit_button_save: "Jimat",
          admin_email_form_update_profile_edit_toolbar_bold: "berani",
          admin_email_form_update_profile_edit_toolbar_italic: "Italic",
          admin_email_form_update_profile_edit_toolbar_underline: "Garis bawah",
          admin_email_form_update_profile_edit_toolbar_strikethrough: "Strikethrough",
          admin_email_form_update_profile_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          admin_email_form_update_profile_edit_toolbar_font_family: "Keluarga Font",
          admin_email_form_update_profile_edit_toolbar_font_size: "Saiz huruf",
          admin_email_form_update_profile_edit_toolbar_text_color: "Warna Teks",
          admin_email_form_update_profile_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          admin_email_form_update_profile_edit_toolbar_recent_color: "Warna Terkini",
          admin_email_form_update_profile_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          admin_email_form_update_profile_edit_toolbar_table: "Jadual",
          admin_email_form_update_profile_edit_toolbar_unordered_list: "Senarai tidak teratur",
          admin_email_form_update_profile_edit_toolbar_ordered_list: "Senarai pesanan",
          admin_email_form_update_profile_edit_toolbar_paragraph: "Perenggan",
          admin_email_form_update_profile_edit_toolbar_line_height: "Ketinggian Garisan",
          admin_email_form_update_profile_edit_toolbar_picture: "Gambar",
          admin_email_form_update_profile_edit_toolbar_code_view: "Pandangan Kod",
          admin_email_form_update_profile_edit_toolbar_full_screen: "Skrin penuh",
          admin_email_form_update_profile_edit_toolbar_help: "Tolong",
          admin_email_form_update_profile_edit_toolbar_tip: "Petua",
          admin_email_form_update_profile_edit_placeholder_content: "Sila masukkan kandungan.",
          admin_email_form_withdraw_edit_title: "MENARIK diri",
          admin_email_form_withdraw_edit_validation_content: "* Sila masukkan butiran anda.",
          admin_email_form_withdraw_edit_select_kr: "KR",
          admin_email_form_withdraw_edit_select_en: "EN",
          admin_email_form_withdraw_edit_select_cn: "CN",
          admin_email_form_withdraw_edit_select_jp: "JP",
          admin_email_form_withdraw_edit_select_ms: "MS",
          admin_email_form_withdraw_edit_button_remove: "Alih keluar",
          admin_email_form_withdraw_edit_button_append: "Lampirkan",
          admin_email_form_withdraw_edit_button_save: "Jimat",
          admin_email_form_withdraw_edit_toolbar_bold: "berani",
          admin_email_form_withdraw_edit_toolbar_italic: "Italic",
          admin_email_form_withdraw_edit_toolbar_underline: "Garis bawah",
          admin_email_form_withdraw_edit_toolbar_strikethrough: "Strikethrough",
          admin_email_form_withdraw_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          admin_email_form_withdraw_edit_toolbar_font_family: "Keluarga Font",
          admin_email_form_withdraw_edit_toolbar_font_size: "Saiz huruf",
          admin_email_form_withdraw_edit_toolbar_text_color: "Warna Teks",
          admin_email_form_withdraw_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          admin_email_form_withdraw_edit_toolbar_recent_color: "Warna Terkini",
          admin_email_form_withdraw_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          admin_email_form_withdraw_edit_toolbar_table: "Jadual",
          admin_email_form_withdraw_edit_toolbar_unordered_list: "Senarai tidak teratur",
          admin_email_form_withdraw_edit_toolbar_ordered_list: "Senarai pesanan",
          admin_email_form_withdraw_edit_toolbar_paragraph: "Perenggan",
          admin_email_form_withdraw_edit_toolbar_line_height: "Ketinggian Garisan",
          admin_email_form_withdraw_edit_toolbar_picture: "Gambar",
          admin_email_form_withdraw_edit_toolbar_code_view: "Pandangan Kod",
          admin_email_form_withdraw_edit_toolbar_full_screen: "Skrin penuh",
          admin_email_form_withdraw_edit_toolbar_help: "Tolong",
          admin_email_form_withdraw_edit_toolbar_tip: "Petua",
          admin_email_form_withdraw_edit_placeholder_content: "Sila masukkan kandungan.",
          admin_endpoint_list_title: "TITIK TAMAT PERMOHONAN",
          admin_endpoint_list_placeholiser_search: "Cari...",
          admin_endpoint_list_label_package_name: "Nama Pakej",
          admin_endpoint_list_label_os: "OS",
          admin_endpoint_list_label_version: "Versi",
          admin_endpoint_list_label_url: "URL",
          admin_endpoint_list_label_created_at: "Dicipta Pada",
          admin_endpoint_list_guide_empty: "Senarai kosong",
          admin_endpoint_list_button_delete: "Padam",
          admin_endpoint_list_button_add: "Tambah",
          admin_endpoint_add_title: "TAMBAH ENDPOINT PERMOHONAN",
          admin_endpoint_add_validation_package_name: "* Sila pilih nama pakej.",
          admin_endpoint_add_validation_os: "* Sila pilih OS.",
          admin_endpoint_add_validation_version: "* Sila masukkan versi.",
          admin_endpoint_add_validation_url: "* Sila pilih url.",
          admin_endpoint_add_label_package_name: "Nama Pakej",
          admin_endpoint_add_placeholder_choose: "pilih",
          admin_endpoint_add_select_os_staff: "Kakitangan",
          admin_endpoint_add_select_os_guest: "tetamu",
          admin_endpoint_add_select_os_staff_parking: "Tempat Letak Kereta Kakitangan",
          admin_endpoint_add_label_os: "OS",
          admin_endpoint_add_select_os_ios: "iOS",
          admin_endpoint_add_select_os_aos: "AOS",
          admin_endpoint_add_label_version: "Versi",
          admin_endpoint_add_label_url: "URL",
          admin_endpoint_add_button_go_to_list: "Pergi ke senarai",
          admin_endpoint_add_button_add: "Tambah",
          admin_endpoint_detail_title: "MAKLUMAT ENDPOINT PERMOHONAN",
          admin_endpoint_detail_label_package_name: "Nama Pakej",
          admin_endpoint_detail_placeholder_choose: "pilih",
          admin_endpoint_detail_select_os_staff: "Kakitangan",
          admin_endpoint_detail_select_os_guest: "tetamu",
          admin_endpoint_detail_select_os_staff_parking: "Tempat Letak Kereta Kakitangan",
          admin_endpoint_detail_label_os: "OS",
          admin_endpoint_detail_select_os_ios: "iOS",
          admin_endpoint_detail_select_os_aos: "AOS",
          admin_endpoint_detail_label_version: "Versi",
          admin_endpoint_detail_label_url: "URL",
          admin_endpoint_detail_label_create_at: "Dicipta Pada",
          admin_endpoint_detail_button_go_to_list: "Pergi ke senarai",
          admin_endpoint_detail_button_delete: "Padam",
          admin_endpoint_detail_button_edit: "Sunting",
          admin_endpoint_edit_title: "MAKLUMAT ENDPOINT PERMOHONAN",
          admin_endpoint_edit_validation_package_name: "* Sila pilih nama pakej.",
          admin_endpoint_edit_validation_os: "* Sila pilih OS.",
          admin_endpoint_edit_validation_version: "* Sila masukkan versi.",
          admin_endpoint_edit_validation_url: "* Sila pilih url.",
          admin_endpoint_edit_label_package_name: "Nama Pakej",
          admin_endpoint_edit_placeholder_choose: "pilih",
          admin_endpoint_edit_select_os_staff: "Kakitangan",
          admin_endpoint_edit_select_os_guest: "tetamu",
          admin_endpoint_edit_select_os_staff_parking: "Tempat Letak Kereta Kakitangan",
          admin_endpoint_edit_label_os: "OS",
          admin_endpoint_edit_select_os_ios: "iOS",
          admin_endpoint_edit_select_os_aos: "AOS",
          admin_endpoint_edit_label_version: "Versi",
          admin_endpoint_edit_label_url: "URL",
          admin_endpoint_edit_label_create_at: "Dicipta Pada",
          admin_endpoint_edit_button_cancel: "Batal",
          admin_endpoint_edit_button_delete: "Padam",
          admin_endpoint_edit_button_ok: "okey",
          admin_terms_list_title: "SENARAI TERMA",
          admin_terms_list_placeholder_search: "Cari...",
          admin_terms_list_label_language_code: "Kod Bahasa",
          admin_terms_list_label_file_name: "Nama fail",
          admin_terms_list_label_required: "Diperlukan",
          admin_terms_list_label_title: "Tajuk",
          admin_terms_list_label_terms_group_id: "ID Kumpulan Syarat",
          admin_terms_list_label_created_at: "Dicipta Pada",
          admin_terms_list_guide_empty: "Senarai kosong",
          admin_terms_list_button_delete: "Padam",
          admin_terms_list_button_add: "Tambah",
          admin_terms_add_title: "TAMBAH SYARAT",
          admin_terms_add_validation_title: "* Sila masukkan tajuk.",
          admin_terms_add_validation_terms_group_id: "* Sila pilih ID kumpulan istilah.",
          admin_terms_add_validation_language_code: "* Sila masukkan kod bahasa.",
          admin_terms_add_validation_required: "* Sila pilih sama ada perlu atau tidak.",
          admin_terms_add_validation_description: "* Sila masukkan penerangan.",
          admin_terms_add_validation_link_text: "* Sila masukkan teks pautan.",
          admin_terms_add_validation_file: "* Sila muat naik fail.",
          admin_terms_add_label_title: "Tajuk",
          admin_terms_add_label_terms_group_id: "ID Kumpulan Syarat",
          admin_terms_add_label_choose: "pilih",
          admin_terms_add_label_language_code: "Kod Bahasa",
          admin_terms_add_label_required: "Diperlukan",
          admin_terms_add_button_yes: "Yse",
          admin_terms_add_button_no: "Tidak",
          admin_terms_add_label_description: "Penerangan",
          admin_terms_add_label_link_text: "Teks Pautan",
          admin_terms_add_label_file_name: "Nama fail",
          admin_terms_add_label_file_url: "URL fail",
          admin_terms_add_button_upload: "Muat naik",
          admin_terms_add_button_go_to_list: "Pergi ke senarai",
          admin_terms_add_button_add: "Tambah",
          admin_terms_detail_title: "MAKLUMAT TERMA",
          admin_terms_detail_label_title: "Tajuk",
          admin_terms_detail_label_language_code: "Kod Bahasa",
          admin_terms_detail_label_description: "Penerangan",
          admin_terms_detail_label_link_text: "Teks Pautan",
          admin_terms_detail_label_required: "Diperlukan",
          admin_terms_detail_button_yes: "ya",
          admin_terms_detail_button_no: "Tidak",
          admin_terms_detail_label_terms_group_id: "ID Kumpulan Syarat",
          admin_terms_detail_label_choose: "pilih",
          admin_terms_detail_label_file_name: "Nama fail",
          admin_terms_detail_label_file_url: "URL fail",
          admin_terms_detail_button_upload: "Muat naik",
          admin_terms_detail_label_created_at: "Dicipta Pada",
          admin_terms_detail_label_updated_at: "Dikemaskini Pada",
          admin_terms_detail_button_go_to_list: "Pergi ke senarai",
          admin_terms_detail_button_edit: "Sunting",
          admin_terms_edit_title: "MAKLUMAT TERMA",
          admin_terms_edit_validation_title: "* Sila masukkan tajuk.",
          admin_terms_edit_validation_terms_group_id: "* Sila pilih ID kumpulan istilah.",
          admin_terms_edit_validation_language_code: "* Sila masukkan kod bahasa.",
          admin_terms_edit_validation_required: "* Sila pilih sama ada perlu atau tidak.",
          admin_terms_edit_validation_description: "* Sila masukkan penerangan.",
          admin_terms_edit_validation_link_text: "* Sila masukkan teks pautan.",
          admin_terms_edit_validation_file: "* Sila muat naik fail.",
          admin_terms_edit_label_title: "Tajuk",
          admin_terms_edit_label_language_code: "Kod Bahasa",
          admin_terms_edit_label_description: "Penerangan",
          admin_terms_edit_label_link_text: "Teks Pautan",
          admin_terms_edit_label_required: "Diperlukan",
          admin_terms_edit_button_yes: "ya",
          admin_terms_edit_button_no: "Tidak",
          admin_terms_edit_label_terms_group_id: "ID Kumpulan Syarat",
          admin_terms_edit_label_choose: "pilih",
          admin_terms_edit_label_file_name: "Nama fail",
          admin_terms_edit_label_file_url: "URL fail",
          admin_terms_edit_button_upload: "Muat naik",
          admin_terms_edit_label_created_at: "Dicipta Pada",
          admin_terms_edit_label_updated_at: "Dikemaskini Pada",
          admin_terms_edit_button_cancel: "Batal",
          admin_terms_edit_button_ok: "okey",
          admin_terms_group_list_title: "SENARAI KUMPULAN ISTILAH",
          admin_terms_group_list_placeholder_search: "Cari...",
          admin_terms_group_list_label_title: "Tajuk",
          admin_terms_group_list_label_country_code: "Kod negara",
          admin_terms_group_list_label_created_at: "Dicipta Pada",
          admin_terms_group_list_guide_empty: "Senarai kosong",
          admin_terms_group_list_button_delete: "Padam",
          admin_terms_group_list_button_add: "Tambah",
          admin_terms_group_add_title: "TAMBAH TERMA KUMPULAN",
          admin_terms_group_add_validation_title: "* Sila masukkan tajuk.",
          admin_terms_group_add_validation_country_code: "* Sila masukkan kod negara.",
          admin_terms_group_add_label_title: "Tajuk",
          admin_terms_group_add_label_country_code: "Kod negara",
          admin_terms_group_add_button_go_to_list: "Pergi ke senarai",
          admin_terms_group_add_button_add: "Tambah",
          admin_terms_group_detail_title: "TERMA MAKLUMAT KUMPULAN",
          admin_terms_group_detail_label_title: "Tajuk",
          admin_terms_group_detail_label_country_code: "Kod negara",
          admin_terms_group_detail_label_created_at: "Dicipta Pada",
          admin_terms_group_detail_label_updated_at: "Dikemaskini Pada",
          admin_terms_group_detail_button_go_to_list: "Pergi ke senarai",
          admin_terms_group_detail_button_edit: "Sunting",
          admin_terms_group_edit_title: "MAKLUMAT TERMA",
          admin_terms_group_edit_validation_title: "* Sila masukkan tajuk.",
          admin_terms_group_edit_validation_country_code: "* Sila masukkan kod negara.",
          admin_terms_group_edit_label_title: "Tajuk",
          admin_terms_group_edit_label_country_code: "Kod negara",
          admin_terms_group_edit_label_created_at: "Dicipta Pada",
          admin_terms_group_edit_label_updated_at: "Dikemaskini Pada",
          admin_terms_group_edit_button_cancel: "Batal",
          admin_terms_group_edit_button_ok: "okey",
          admin_agreement_list_title: "SENARAI SEJARAH PERJANJIAN",
          admin_agreement_list_placeholder_email: "Sila masukkan E-mel anda.",
          admin_agreement_list_placeholder_search: "Cari...",
          admin_agreement_list_label_no: "TIDAK.",
          admin_agreement_list_label_user_id: "ID Pengguna",
          admin_agreement_list_label_terms_id: "ID Syarat",
          admin_agreement_list_label_agreed: "Bersetuju",
          admin_agreement_list_label_email: "E-mel",
          admin_agreement_list_label_name: "Nama",
          admin_agreement_list_label_file_name: "Nama fail",
          admin_agreement_list_label_created_at: "Dicipta Pada",
          admin_agreement_detail_title: "MAKLUMAT SEJARAH PERJANJIAN",
          admin_agreement_detail_label_agreement: "Perjanjian",
          admin_agreement_detail_label_agreed: "Bersetuju",
          admin_agreement_detail_label_agreement_created_at: "Perjanjian Disahkan Pada",
          admin_agreement_detail_label_user: "pengguna",
          admin_agreement_detail_label_user_id: "ID Pengguna",
          admin_agreement_detail_label_email: "E-mel",
          admin_agreement_detail_label_name: "Nama",
          admin_agreement_detail_label_email_confimed_at: "E-mel Disahkan Di",
          admin_agreement_detail_label_user_language_code: "Kod Bahasa Pengguna",
          admin_agreement_detail_label_user_created_at: "Dicipta Pengguna Di",
          admin_agreement_detail_label_terms: "Syarat",
          admin_agreement_detail_label_terms_id: "ID Syarat",
          admin_agreement_detail_label_terms_language_code: "Syarat Kod Bahasa",
          admin_agreement_detail_label_file_name: "Nama fail",
          admin_agreement_detail_label_required: "Diperlukan",
          admin_agreement_detail_label_title: "Tajuk",
          admin_agreement_detail_label_description: "Penerangan",
          admin_agreement_detail_label_link_text: "Teks Pautan",
          admin_agreement_detail_label_terms_group_id: "ID Kumpulan Syarat",
          admin_agreement_detail_label_terms_created_at: "Terma Dicipta Di",
          admin_agreement_detail_button_go_to_list: "Pergi ke senarai",
          admin_auth_data_list_title: "Data yang mengesahkan pengumpulan,\npenggunaan dan penyediaan maklumat lokasi peribadi",
          admin_auth_data_list_placeholder_select_box_authentication_data: "Data pengesahan",
          admin_auth_data_list_select_hotel_id: "ID hotel",
          admin_auth_data_list_select_email: "E-mel",
          admin_auth_data_list_label_no: "Tidak.",
          admin_auth_data_list_placeholder_search: "Cari...",
          admin_auth_data_list_label_user_email: "E-mel",
          admin_auth_data_list_label_acquisition_path: "Laluan Pemerolehan",
          admin_auth_data_list_label_service: "Perkhidmatan",
          admin_auth_data_list_label_recipient: "Penerima",
          admin_auth_data_list_label_request_date: "Tarikh Permintaan",
          admin_auth_data_list_label_end_date: "Tarikh tamat",
          admin_auth_data_list_guide_empty: "Senarai kosong",
          admin_use_key_log_list_title: "GUNAKAN SENARAI LOG UTAMA",
          admin_use_key_log_list_placeholder_select_box_use_key_log: "Gunakan log kunci",
          admin_use_key_log_list_select_hotel_id: "ID hotel",
          admin_use_key_log_list_select_email: "E-mel",
          admin_use_key_log_list_placeholder_search: "Cari...",
          admin_use_key_log_list_label_no: "Tidak.",
          admin_use_key_log_list_label_user_email: "E-mel Pengguna",
          admin_use_key_log_list_label_device: "Peranti",
          admin_use_key_log_list_label_app_version: "Versi aplikasi",
          admin_use_key_log_list_label_hotel_id: "ID hotel",
          admin_use_key_log_list_label_key_id: "ID kunci",
          admin_use_key_log_list_label_date: "Tarikh",
          admin_use_key_log_list_guide_empty: "Senarai kosong",
          admin_use_key_log_detail_title: "GUNAKAN BUTIRAN LOG UTAMA",
          admin_use_key_log_detail_label_use_key_log_id: "Gunakan ID log kunci",
          admin_use_key_log_detail_label_user_id: "ID Pengguna",
          admin_use_key_log_detail_label_user_email: "E-mel Pengguna",
          admin_use_key_log_detail_label_hotel_key_id: "ID kunci hotel",
          admin_use_key_log_detail_label_hotel_id: "ID hotel",
          admin_use_key_log_detail_label_device: "Peranti",
          admin_use_key_log_detail_label_app_version: "Versi aplikasi",
          admin_use_key_log_detail_label_log: "Log",
          admin_use_key_log_detail_label_created_at: "Dicipta Pada",
          admin_use_key_log_detail_label_updated_at: "Dikemaskini Pada",
          admin_use_key_log_detail_button_go_to_list: "Pergi ke senarai",
          admin_amenity_list_title: "SENARAI KEMUDAHAN",
          admin_amenity_list_placeholder_search: "Cari...",
          admin_amenity_list_label_type: "taip",
          admin_amenity_list_label_group: "Kumpulan",
          admin_amenity_list_label_code: "Kod",
          admin_amenity_list_label_name: "Nama",
          admin_amenity_list_label_date: "Tarikh",
          admin_amenity_list_button_delete: "Padam",
          admin_amenity_list_button_add: "Tambah",
          admin_amenity_list_guide_empty: "Senarai kosong",
          admin_amenity_add_title: "TAMBAH KEMUDAHAN",
          admin_amenity_add_validation_type: "* Sila pilih jenis.",
          admin_amenity_add_validation_group: "* Sila masukkan kumpulan.",
          admin_amenity_add_validation_code: "* Sila masukkan kod.",
          admin_amenity_add_validation_name: "* Sila masukkan nama.",
          admin_amenity_add_validation_icon_image: "* Sila muat naik imej ikon.",
          admin_amenity_add_label_type: "taip",
          admin_amenity_add_placeholder_select_box_choose: "pilih",
          admin_amenity_add_select_hotel: "HOTEL",
          admin_amenity_add_select_room: "BILIK",
          admin_amenity_add_label_group: "Kumpulan",
          admin_amenity_add_label_code: "Kod",
          admin_amenity_add_label_name: "Nama",
          admin_amenity_add_label_icon: "ikon",
          admin_amenity_add_button_upload: "Muat naik",
          admin_amenity_add_placeholder_upload: "cth) MyCONECT.png",
          admin_amenity_add_button_go_to_list: "Pergi ke senarai",
          admin_amenity_add_button_add: "Tambah",
          admin_amenity_detail_title: "MAKLUMAT KEMUDAHAN",
          admin_amenity_detail_label_type: "taip",
          admin_amenity_detail_select_hotel: "HOTEL",
          admin_amenity_detail_select_room: "BILIK",
          admin_amenity_detail_label_group: "Kumpulan",
          admin_amenity_detail_label_code: "Kod",
          admin_amenity_detail_label_name: "Nama",
          admin_amenity_detail_label_icon: "ikon",
          admin_amenity_detail_button_upload: "Muat naik",
          admin_amenity_detail_placeholder_upload: "cth) MyCONECT.png",
          admin_amenity_detail_label_update_date: "Tarikh Dikemaskini",
          admin_amenity_detail_button_go_to_list: "Pergi ke senarai",
          admin_amenity_detail_button_cancel: "Batal",
          admin_amenity_detail_button_delete: "Padam",
          admin_amenity_detail_button_edit: "Sunting",
          admin_amenity_detail_button_ok: "okey",
          admin_amenity_edit_title: "MAKLUMAT KEMUDAHAN",
          admin_amenity_edit_validation_type: "* Sila pilih jenis.",
          admin_amenity_edit_validation_group: "* Sila masukkan kumpulan.",
          admin_amenity_edit_validation_code: "* Sila masukkan kod.",
          admin_amenity_edit_validation_name: "* Sila masukkan nama.",
          admin_amenity_edit_validation_icon_image: "* Sila muat naik imej ikon.",
          admin_amenity_edit_label_type: "taip",
          admin_amenity_edit_placeholder_select_box_choose: "pilih",
          admin_amenity_edit_select_hotel: "HOTEL",
          admin_amenity_edit_select_room: "BILIK",
          admin_amenity_edit_label_group: "Kumpulan",
          admin_amenity_edit_label_code: "Kod",
          admin_amenity_edit_label_name: "Nama",
          admin_amenity_edit_label_icon: "ikon",
          admin_amenity_edit_button_upload: "Muat naik",
          admin_amenity_edit_placeholder_upload: "cth) MyCONECT.png",
          admin_amenity_edit_label_update_date: "Tarikh Dikemaskini",
          admin_amenity_edit_button_cancel: "Batal",
          admin_amenity_edit_button_delete: "Padam",
          admin_amenity_edit_button_ok: "okey",
          admin_facility_img_list_title: "SENARAI IMEJ FASILITI HOTEL",
          admin_facility_img_list_label_facility_img: "Kemudahan hotel Imej",
          admin_facility_img_list_label_description: "Penerangan",
          admin_facility_img_list_label_date: "Tarikh",
          admin_facility_img_list_button_delete: "Padam",
          admin_facility_img_list_button_add: "Tambah",
          admin_facility_img_list_guide_empty: "Senarai kosong",
          admin_facility_img_add_title: "TAMBAHKAN IMEJ FASILITI HOTEL",
          admin_facility_img_add_validation_image: "* Sila muat naik imej.",
          admin_facility_img_add_validation_description: "* Sila masukkan penerangan.",
          admin_facility_img_add_label_img: "Gambar",
          admin_facility_img_add_button_upload: "Muat naik",
          admin_facility_img_add_label_description: "Penerangan",
          admin_facility_img_add_button_go_to_list: "Pergi ke senarai",
          admin_facility_img_add_button_add: "Tambah",
          admin_facility_img_detail_title: "MAKLUMAT IMEJ FASILITI HOTEL",
          admin_facility_img_detail_label_img: "Gambar",
          admin_facility_img_detail_button_upload: "Muat naik",
          admin_facility_img_detail_label_description: "Penerangan",
          admin_facility_img_detail_label_updated_at: "Dikemaskini Pada",
          admin_facility_img_detail_button_go_to_list: "Pergi ke senarai",
          admin_facility_img_detail_button_cancel: "Batal",
          admin_facility_img_detail_button_delete: "Padam",
          admin_facility_img_detail_button_edit: "Sunting",
          admin_facility_img_detail_button_ok: "okey",
          admin_facility_img_edit_title: "MAKLUMAT IMEJ FASILITI HOTEL",
          admin_facility_img_edit_validation_image: "* Sila muat naik imej.",
          admin_facility_img_edit_validation_description: "* Sila masukkan penerangan.",
          admin_facility_img_edit_label_img: "Gambar",
          admin_facility_img_edit_button_upload: "Muat naik",
          admin_facility_img_edit_label_description: "Penerangan",
          admin_facility_img_edit_label_updated_at: "Dikemaskini Pada",
          admin_facility_img_edit_button_cancel: "Batal",
          admin_facility_img_edit_button_delete: "Padam",
          admin_facility_img_edit_button_ok: "okey",
          admin_recommend_app_list_title: "SYORKAN SENARAI APP",
          admin_recommend_app_list_select_name: "Nama",
          admin_recommend_app_list_placeholder_search: "Cari...",
          admin_recommend_app_list_label_name: "Nama",
          admin_recommend_app_list_label_ios_scheme: "Skim iOS",
          admin_recommend_app_list_label_aos_package_id: "ID Pakej AOS",
          admin_recommend_app_list_label_date: "Tarikh",
          admin_recommend_app_list_button_delete: "Padam",
          admin_recommend_app_list_button_add: "Tambah",
          admin_recommend_app_list_guide_empty: "Senarai kosong",
          admin_recommend_app_add_title: "TAMBAHKAN APL SYOR",
          admin_recommend_app_add_validation_name: "* Sila masukkan nama.",
          admin_recommend_app_add_validation_ios_scheme: "* Sila masukkan Skim iOS.",
          admin_recommend_app_add_validation_ios_itunes_url: "* Sila masukkan URL iTunes iOS.",
          admin_recommend_app_add_validation_aos_package_id: "* Sila masukkan ID pakej AOS.",
          admin_recommend_app_add_validation_aos_play_store_url: "* Sila masukkan URL Gedung Play AOS.",
          admin_recommend_app_add_label_name: "Nama",
          admin_recommend_app_add_label_ios_scheme: "Skim iOS",
          admin_recommend_app_add_label_ios_itunes_url: "URL iTunes iOS",
          admin_recommend_app_add_label_aos_package_id: "ID Pakej AOS",
          admin_recommend_app_add_label_aos_play_store_url: "URL Gedung Play AOS",
          admin_recommend_app_add_button_go_to_list: "Pergi ke senarai",
          admin_recommend_app_add_button_add: "Tambah",
          admin_recommend_app_detail_title: "SYORKAN MAKLUMAT APP",
          admin_recommend_app_detail_label_name: "Nama",
          admin_recommend_app_detail_label_ios_scheme: "Skim iOS",
          admin_recommend_app_detail_label_ios_itunes_url: "URL iTunes iOS",
          admin_recommend_app_detail_label_aos_package_id: "ID Pakej AOS",
          admin_recommend_app_detail_label_aos_play_store_url: "URL Gedung Play AOS",
          admin_recommend_app_detail_label_updated_at: "Dikemaskini Pada",
          admin_recommend_app_detail_button_go_to_list: "Pergi ke senarai",
          admin_recommend_app_detail_button_delete: "Padam",
          admin_recommend_app_detail_button_edit: "Sunting",
          admin_recommend_app_edit_title: "SYORKAN MAKLUMAT APP",
          admin_recommend_app_edit_validation_name: "* Sila masukkan nama.",
          admin_recommend_app_edit_validation_ios_scheme: "* Sila masukkan Skim iOS.",
          admin_recommend_app_edit_validation_ios_itunes_url: "* Sila masukkan URL iTunes iOS.",
          admin_recommend_app_edit_validation_aos_package_id: "* Sila masukkan ID pakej AOS.",
          admin_recommend_app_edit_validation_aos_play_store_url: "* Sila masukkan URL Gedung Play AOS.",
          admin_recommend_app_edit_label_name: "Nama",
          admin_recommend_app_edit_label_ios_scheme: "Skim iOS",
          admin_recommend_app_edit_label_ios_itunes_url: "URL iTunes iOS",
          admin_recommend_app_edit_label_aos_package_id: "ID Pakej AOS",
          admin_recommend_app_edit_label_aos_play_store_url: "URL Gedung Play AOS",
          admin_recommend_app_editl_label_updated_at: "Dikemaskini Pada",
          admin_recommend_app_edit_button_cancel: "Batal",
          admin_recommend_app_edit_button_delete: "Padam",
          admin_recommend_app_edit_button_ok: "okey",
          admin_admin_user_list_title: "SENARAI PENGGUNA",
          admin_admin_user_list_placeholder_select_box_user: "pengguna",
          admin_admin_user_list_select_name: "Nama",
          admin_admin_user_list_select_email: "E-mel",
          admin_admin_user_list_select_department: "Jabatan",
          admin_admin_user_list_select_duty: "Kewajipan",
          admin_admin_user_list_placeholder_search: "Cari...",
          admin_admin_user_list_label_name: "Nama",
          admin_admin_user_list_label_email: "E-mel",
          admin_admin_user_list_label_department: "Jabatan",
          admin_admin_user_list_label_duty: "Kewajipan",
          admin_admin_user_list_label_date: "Tarikh",
          admin_admin_user_list_guide_empty: "Senarai kosong",
          admin_admin_user_list_button_delete: "Padam",
          admin_admin_user_list_button_add: "Tambah",
          admin_admin_user_add_title: "ADMIN BARU",
          admin_admin_user_add_guide_click_add: "Masukkan maklumat yang diperlukan dan klik butang <<\"Tambah\">> untuk menambah pengguna baharu.",
          admin_admin_user_add_validation_email: "* Sila masukkan e-mel pengguna.",
          admin_admin_user_add_validation_email_format: "* Sila masukkan e-mel dalam format someone@example.com",
          admin_admin_user_add_validation_password: "* Sila masukkan kata laluan pengguna.",
          admin_admin_user_add_validation_character_number_combining: "* Sila masukkan sekurang-kurangnya 8 aksara menggabungkan huruf dan nombor.",
          admin_admin_user_add_validation_not_match: "* Kata laluan tidak sepadan.",
          admin_admin_user_add_validation_name: "* Sila masukkan nama.",
          admin_admin_user_add_validation_korea_english: "* Bahasa Korea dan Inggeris atau 'Sila masukkan menggunakan ._ -'.",
          admin_admin_user_add_validation_department: "* Sila masukkan jabatan.",
          admin_admin_user_add_validation_select_duty: "* Sila pilih tugas pekerja.",
          admin_admin_user_add_label_email: "E-mel",
          admin_admin_user_add_placeholder_email: "E-mel",
          admin_admin_user_add_label_name: "Nama",
          admin_admin_user_add_placeholder_name: "Nama",
          admin_admin_user_add_label_passoword: "Kata laluan",
          admin_admin_user_add_placeholder_password: "Kata laluan",
          admin_admin_user_add_label_confirm_passoword: "Sahkan kata laluan",
          admin_admin_user_add_placeholder_confirm_password: "Sahkan kata laluan",
          admin_admin_user_add_label_department: "Jabatan",
          admin_admin_user_add_placeholder_department: "Jabatan",
          admin_admin_user_add_label_duty: "Kewajipan",
          admin_admin_user_add_select_choose: "Pilih",
          admin_admin_user_add_select_super_admin: "Super Admin",
          admin_admin_user_add_select_admin: "Admin",
          admin_admin_user_add_select_user: "pengguna",
          admin_admin_user_add_button_add: "Tambah",
          admin_admin_user_add_button_go_to_list: "Pergi ke senarai",
          admin_admin_user_detail_title: "MAKLUMAT PENGGUNA",
          admin_admin_user_detail_select_user: "pengguna",
          admin_admin_user_detail_select_admin: "Admin",
          admin_admin_user_detail_select_super_admin: "Super Admin",
          admin_admin_user_detail_label_admin: "Admin",
          admin_admin_user_detail_label_name: "Nama",
          admin_admin_user_detail_label_department: "Jabatan",
          admin_admin_user_detail_label_duty: "Kewajipan",
          admin_admin_user_detail_label_date: "Tarikh",
          admin_admin_user_detail_button_delete: "Padam",
          admin_admin_user_detail_button_go_to_list: "Pergi ke senarai",
          admin_admin_user_detail_button_ok: "okey",
          admin_admin_user_edit_title: "EDIT ADMIN",
          admin_admin_user_edit_guide_edit_or_leave: "Maklumat boleh diubah suai tidak termasuk alamat e-mel.",
          admin_admin_user_edit_validation_name: "* Sila masukkan nama.",
          admin_admin_user_edit_validation_department: "* Sila masukkan jabatan.",
          admin_admin_user_edit_validation_korea_english: "* Bahasa Korea dan Inggeris atau 'Sila masukkan menggunakan ._ -'.",
          admin_admin_user_edit_button_withdraw: "Menarik diri",
          admin_admin_user_edit_button_save: "Jimat",
          admin_id_recognition_information_list_title: "SENARAI MAKLUMAT PENGIKTIRAFAN ID",
          admin_id_recognition_information_list_select_id_type_all: "Jenis ID ( SEMUA )",
          admin_id_recognition_information_list_select_id_type_registration: "Pendaftaran",
          admin_id_recognition_information_list_select_id_type_passport: "pasport",
          admin_id_recognition_information_list_select_id_type_driver_license: "Lesen memandu",
          admin_id_recognition_information_list_placeholder_search: "Nama negara",
          admin_id_recognition_information_list_label_country_name: "Nama negara",
          admin_id_recognition_information_list_label_id_type: "Nama negara",
          admin_id_recognition_information_list_label_date: "Tarikh",
          admin_id_recognition_information_list_guide_empty: "Senarai kosong",
          admin_id_recognition_information_list_button_delete: "Padam",
          admin_id_recognition_information_list_button_add: "Tambah",
          admin_id_recognition_information_add_title: "TAMBAHKAN MAKLUMAT PENGIKTIRAFAN ID",
          admin_id_recognition_information_add_guide_click_add: "Masukkan Maklumat Pengecaman ID dan klik butang <<'Tambah'>>.",
          admin_id_recognition_information_add_label_country_name: "Nama negara",
          admin_id_recognition_information_add_placeholder_country_name: "Nama negara",
          admin_id_recognition_information_add_label_id_type: "Jenis ID",
          admin_id_recognition_information_add_placeholder_select_box_choose: "pilih",
          admin_id_recognition_information_add_select_id_type_registration: "Pendaftaran",
          admin_id_recognition_information_add_select_id_type_passport: "pasport",
          admin_id_recognition_information_add_select_id_type_driver_license: "Lesen memandu",
          admin_id_recognition_information_add_label_recognized_texts: "Teks Dikenali Semula",
          admin_id_recognition_information_add_placeholder_recognized_texts: "ex) REPUBLIK KOREA, No. Pasport, Nama Keluarga, Nama yang diberi, Tarikh lahir, ...",
          admin_id_recognition_information_add_validation_country_name: "* Sila masukkan nama negara.",
          admin_id_recognition_information_add_validation_id_type: "* Sila masukkan jenis ID.",
          admin_id_recognition_information_add_validation_recognized_texts: "* Masukkan teks pengecaman menggunakan ,.",
          admin_id_recognition_information_add_button_go_to_list: "Pergi ke senarai",
          admin_id_recognition_information_add_button_add: "Tambah",
          admin_id_recognition_information_detail_title: "MAKLUMAT PENGIKTIRAFAN ID",
          admin_id_recognition_information_detail_label_country_name: "Nama negara",
          admin_id_recognition_information_detail_label_id_type: "Jenis ID",
          admin_id_recognition_information_detail_label_recognized_texts: "Teks Dikenali Semula",
          admin_id_recognition_information_detail_label_created_at: "Dicipta Pada",
          admin_id_recognition_information_detail_label_updated_at: "Dikemaskini Pada",
          admin_id_recognition_information_detail_button_go_to_list: "Pergi ke Senarai",
          admin_id_recognition_information_detail_button_edit: "Sunting",
          admin_id_recognition_information_edit_title: "MAKLUMAT PENGIKTIRAFAN ID",
          admin_id_recognition_information_edit_label_country_name: "Nama negara",
          admin_id_recognition_information_edit_placeholder_country_name: "Nama negara",
          admin_id_recognition_information_edit_label_id_type: "Jenis ID",
          admin_id_recognition_information_edit_placeholder_select_box_choose: "pilih",
          admin_id_recognition_information_edit_select_id_type_registration: "Pendaftaran",
          admin_id_recognition_information_edit_select_id_type_passport: "pasport",
          admin_id_recognition_information_edit_select_id_type_driver_license: "Lesen memandu",
          admin_id_recognition_information_edit_label_recognized_texts: "Teks Dikenali Semula",
          admin_id_recognition_information_edit_placeholder_recognized_texts: "ex) REPUBLIK KOREA, No. Pasport, Nama Keluarga, Nama yang diberi, Tarikh lahir, ...",
          admin_id_recognition_information_edit_validation_country_name: "* Sila masukkan nama negara.",
          admin_id_recognition_information_edit_validation_id_type: "* Sila masukkan jenis ID.",
          admin_id_recognition_information_edit_validation_recognized_texts: "* Masukkan teks pengecaman menggunakan ,.",
          admin_id_recognition_information_edit_label_updated_at: "Dikemaskini Pada",
          admin_id_recognition_information_edit_button_cancel: "Batal",
          admin_id_recognition_information_edit_button_ok: "okey",
          admin_statistics_list_title: "STATISTICS LIST",
          admin_statistics_list_placeholder_select_box_choose: "pilih (Semua)",
          admin_statistics_list_placeholder_search: "Cari...",
          admin_statistics_list_label_date: "Tarikh",
          admin_statistics_list_label_reservation: "Tempahan",
          admin_statistics_list_label_walk_in: "Walk-In",
          admin_statistics_list_label_check_in: "Daftar masuk",
          admin_statistics_list_label_checked_out: "Daftar Keluar",
          admin_statistics_list_button_go_to_statistics: "Go to Statistics",
          admin_statistics_list_button_download: "Muat turun",
          hotel_header_link_password: "Kata laluan",
          hotel_header_link_logout: "Log keluar",
          hotel_header_accordion_hotel: "Hotel",
          hotel_header_link_hotel_information: "Maklumat",
          hotel_header_link_hotel_beacon: "lampu isyarat",
          hotel_header_link_hotel_currency: "mata wang",
          hotel_header_link_hotel_setting: "tetapan",
          hotel_header_link_hotel_detail: "Butiran Hotel",
          hotel_header_link_hotel_image: "Imej Hotel",
          hotel_header_accordion_room: "Bilik",
          hotel_header_link_room: "Bilik",
          hotel_header_link_room_detail: "Butiran Bilik",
          hotel_header_link_room_image: "Imej Bilik",
          hotel_header_accordion_room_management: "Pengurusan Bilik",
          hotel_header_link_room_list: "Senarai Bilik",
          hotel_header_link_room_management_setting: "tetapan",
          hotel_header_accordion_package: "Pakej",
          hotel_header_link_package_package: "Pakej",
          hotel_header_link_package_package_detail: "Butiran Pakej",
          hotel_header_accordion_member: "Ahli",
          hotel_header_link_member_member: "Ahli",
          hotel_header_link_member_non_member: "Non-Member",
          hotel_header_accordion_reservation: "Tempahan",
          hotel_header_link_reservation_reservation: "Tempahan",
          hotel_header_link_reservation_upsell: "Upsell",
          hotel_header_link_reservation_future_booking: "Tempahan Masa Depan",
          hotel_header_link_reservation_waiting_room: "Menunggu Tugasan Bilik",
          hotel_header_accordion_reservation_policy: "Polisi Tempahan",
          hotel_header_link_reservation_rule_setting_deposit_rule: "Peraturan Deposit",
          hotel_header_link_reservation_rule_setting_reservation_rule: "Peraturan Tempahan",
          hotel_header_link_reservation_rule_setting_cancellation_rule: "Peraturan Pembatalan",
          hotel_header_link_reservation_rule_setting_no_show_rule: "Peraturan Tidak Menunjukkan",
          hotel_header_link_reservation_rule_setting_rule_schedule: "Jadual Peraturan",
          hotel_header_accordion_payment: "Bayaran",
          hotel_header_link_payment_history: "Sejarah",
          hotel_header_link_payment_setting: "tetapan",
          hotel_header_accordion_service: "Perkhidmatan",
          hotel_header_link_service_hotel_info: "Maklumat Hotel",
          hotel_header_accordion_concierge: "Concierge",
          hotel_header_link_service_concierge_request: "Permintaan (Sebelum Pengesahan)",
          hotel_header_link_service_concierge_process: "Permintaan (Selepas Pengesahan)",
          hotel_header_link_service_concierge_schedule_process: "Jadual Kemajuan",
          hotel_header_link_service_concierge_schedule: "Penetapan Jadual",
          hotel_header_link_service_concierge_business_rule: "Tetapan Peraturan Perniagaan",
          hotel_header_link_service_concierge_boundary_line: "Penetapan Garisan Sempadan",
          hotel_header_accordion_housekeeping: "Pengemasan",
          hotel_header_link_service_housekeeping_request: "Permintaan (Sebelum Pengesahan)",
          hotel_header_link_service_housekeeping_process: "Permintaan (selepas Pengesahan)",
          hotel_header_link_service_housekeeping_schedule_process: "Jadual Kemajuan",
          hotel_header_link_service_housekeeping_schedule: "Penetapan Jadual",
          hotel_header_link_service_housekeeping_business_rule: "Tetapan Peraturan Perniagaan",
          hotel_header_link_service_housekeeping_boundary_line: "Penetapan Garisan Sempadan",
          hotel_header_link_service_setting_amenity: "Tetapan Kemudahan",
          hotel_header_link_service_housekeeping_setting: "tetapan",
          hotel_header_accordion_in_room_dining: "Makan Dalam Bilik",
          hotel_header_link_service_in_room_dining_request: "Permintaan (Sebelum Pengesahan)",
          hotel_header_link_service_in_room_dining_process: "Permintaan (Selepas Pengesahan)",
          hotel_header_link_service_in_room_dining_schedule_process: "Jadual Kemajuan",
          hotel_header_link_service_in_room_dining_schedule: "Penetapan Jadual",
          hotel_header_link_service_in_room_dining_business_rule: "Tetapan Peraturan Perniagaan",
          hotel_header_link_service_in_room_dining_boundary_line: "Penetapan Garisan Sempadan",
          hotel_header_link_service_in_room_dining_cart: "Troli",
          hotel_header_link_service_in_room_dining_category: "kategori",
          hotel_header_link_service_in_room_dining_menu_setting: "Tetapan Menu",
          hotel_header_link_service_in_room_dining_setting: "tetapan",
          hotel_header_accordion_valet: "Letak Kereta Berjoki",
          hotel_header_link_service_valet_process: "Permintaan",
          hotel_header_link_service_valet_schedule: "Penetapan Jadual",
          hotel_header_link_service_valet_business_rule: "Tetapan Peraturan Perniagaan",
          hotel_header_link_service_valet_parking_setting: "tetapan",
          hotel_header_accordion_self: "Letak Kereta Sendiri",
          hotel_header_link_service_self_parking_setting: "tetapan",
          hotel_header_link_service_hotel_inhouse_menu_setting: "Tetapan Menu Dalam Rumah",
          hotel_header_link_service_hotel_inhouse_menu_description: "Penerangan Perkhidmatan",
          hotel_header_accordion_key: "kunci",
          hotel_header_link_key_room_to_room: "Bilik ke Bilik",
          hotel_header_link_key_guest_key: "Kunci Tetamu",
          hotel_header_link_key_staff_key: "Kunci Kakitangan",
          hotel_header_link_key_share_key: "Kongsi Kunci",
          hotel_header_link_key_help_for_mobile_key: "Bantuan untuk Kunci Mudah Alih",
          hotel_header_link_key_staff_key_link: "Pautan",
          hotel_header_link_key_access: "Akses",
          hotel_header_link_key_access_detail: "Butiran Akses",
          hotel_header_link_key_key_setting: "tetapan",
          hotel_header_link_key_reservation_access: "Akses Pautan",
          hotel_header_accordion_pms: "PMS",
          hotel_header_link_pms_title: "Tajuk",
          hotel_header_link_pms_card_type: "Jenis kad",
          hotel_header_link_pms_membership_type: "Jenis Keahlian",
          hotel_header_link_pms_fias_manager: "Pengurus FIAS",
          hotel_header_link_pms_notification_callback: "Panggilan Balik Pemberitahuan",
          hotel_header_link_pms_setting: "tetapan",
          hotel_header_accordion_room_controller: "Pengawal Bilik",
          hotel_header_link_room_controller_info: "Maklumat",
          hotel_header_link_room_controller_setting: "tetapan",
          hotel_header_accordion_document: "Editor Alat Tulis",
          hotel_header_accordion_document_email: "E-mel",
          hotel_header_accordion_event: "Peristiwa",
          hotel_header_link_event_event: "Send Event",
          hotel_header_link_event_event_category: "Event Template",
          hotel_header_link_event_event_reservation: "Event Schedule",
          hotel_header_link_event_event_history: "Event History",
          hotel_header_link_email_reservation: "Tempahan",
          hotel_header_link_email_reservation_change: "Perubahan Tempahan",
          hotel_header_link_email_reservation_cancel: "Tempahan Batal",
          hotel_header_link_email_no_show: "Tidak Hadir",
          hotel_header_link_email_reservation_pack_add: "Tambah Pakej Tempahan",
          hotel_header_link_email_reservation_pack_delete: "Padam Pakej Tempahan",
          hotel_header_link_email_reservation_accom_add: "Tempahan Iringan Tambah",
          hotel_header_link_email_reservation_accom_delete: "Tempahan Mengiringi Padam",
          hotel_header_link_email_reservation_accom_email: "E-mel Tempahan Iringi",
          hotel_header_link_email_reservation_accom_change: "Iringi Perubahan Tempahan",
          hotel_header_link_email_reservation_accom_cancel: "Iringi Tempahan Batal",
          hotel_header_link_email_reservation_folio_email: "E-mel Folio",
          hotel_header_link_email_reservation_enroll_profile: "Daftar Profil",
          hotel_header_accordion_document_pdf: "PDF",
          hotel_header_link_pdf_reservation: "Tempahan PDF",
          hotel_header_link_pdf_reservation_accom_pdf: "Iringi Tempahan PDF",
          hotel_header_link_pdf_folio_pdf: "Folio PDF",
          hotel_header_accordion_terms_and_conditions: "Terma dan syarat",
          hotel_header_link_terms_and_conditions_terms: "Syarat",
          hotel_header_link_terms_and_conditions_agreement: "Perjanjian",
          hotel_header_current_version: "Versi terkini",
          hotel_header_translation_version: "Versi Terjemahan",
          hotel_header_link_staff_user: "Pengguna Kakitangan",
          hotel_header_link_app_download: "Muat Turun Apl",
          hotel_password_title: "TUKAR KATA LALUAN",
          hotel_password_guide_password: "Sila masukkan kata laluan baharu \ndengan sekurang-kurangnya 8 aksara.",
          hotel_password_validation_current_password: "* Sila masukkan kata laluan semasa anda.",
          hotel_password_validation_character_number_combining: "* Sila masukkan sekurang-kurangnya 8 aksara menggabungkan huruf dan nombor.",
          hotel_password_validation_new_password: "* Sila masukkan kata laluan baharu.",
          hotel_password_validation_new_password_again: "* Sila masukkan kata laluan baharu anda sekali lagi.",
          hotel_password_validation_not_match: "* Kata laluan tidak sepadan.",
          hotel_password_placeholder_password: "Kata laluan",
          hotel_password_placeholder_new_password: "Kata laluan baharu",
          hotel_password_placeholder_new_confirm_password: "Sahkan kata laluan baharu",
          hotel_password_button_save: "Jimat",
          hotel_hotel_info_detail_title: "Maklumat Hotel",
          hotel_hotel_info_detail_label_hotel_no: "Hotel No.",
          hotel_hotel_info_detail_label_name: "Nama",
          hotel_hotel_info_detail_label_address: "Alamat",
          hotel_hotel_info_detail_label_region: "Wilayah",
          hotel_hotel_info_detail_label_country: "Negara",
          hotel_hotel_info_detail_label_city: "Bandar",
          hotel_hotel_info_detail_label_logo: "Logo",
          hotel_hotel_info_detail_label_hotel_photo: "Foto Hotel",
          hotel_hotel_info_detail_label_hotel_map: "Peta",
          hotel_hotel_info_detail_button_edit: "Sunting",
          hotel_hotel_info_edit_title: "Maklumat Hotel",
          hotel_hotel_info_edit_label_hotel_no: "Hotel No.",
          hotel_hotel_info_edit_label_name: "Nama",
          hotel_hotel_info_edit_label_address: "Alamat",
          hotel_hotel_info_edit_label_region: "Wilayah",
          hotel_hotel_info_edit_label_country: "Negara",
          hotel_hotel_info_edit_label_city: "Bandar",
          hotel_hotel_info_edit_label_logo: "Logo",
          hotel_hotel_info_edit_button_upload: "Muat naik",
          hotel_hotel_info_edit_placeholder_upload: "cth) MyCONECT.png",
          hotel_hotel_info_edit_label_hotel_photo: "Foto hotel",
          hotel_hotel_info_edit_label_hotel_map: "Peta",
          hotel_hotel_info_edit_button_search: "Cari",
          hotel_hotel_info_edit_button_ok: "okey",
          hotel_hotel_info_edit_validation_hotel_name: "* Masukkan nama hotel.",
          hotel_hotel_info_edit_validation_hotel_main_tel: "* Masukkan nombor telefon utama hotel.",
          hotel_hotel_info_edit_validation_hotel_service_tel: "* Masukkan nombor telefon perkhidmatan hotel.",
          hotel_hotel_info_edit_validation_hotel_address: "* Sila masukkan alamat.",
          hotel_hotel_pms_detail_title: "TETAPAN",
          hotel_hotel_pms_detail_label_date: "Tarikh Dikemaskini",
          hotel_hotel_pms_detail_label_type: "taip",
          hotel_hotel_pms_detail_label_cloud_flag: "Bendera Awan",
          hotel_hotel_pms_detail_label_server_url: "Url Pelayan",
          hotel_hotel_pms_detail_label_origin_entity_id: "ID Entiti Asal",
          hotel_hotel_pms_detail_label_origin_system_type: "Jenis Sistem Asal",
          hotel_hotel_pms_detail_label_destination_entity_id: "ID Entiti Destinasi",
          hotel_hotel_pms_detail_label_destination_system_type: "Jenis Sistem Destinasi",
          hotel_hotel_pms_detail_label_user_name: "Nama Pengguna",
          hotel_hotel_pms_detail_label_user_password: "Kata Laluan Pengguna",
          hotel_hotel_pms_detail_label_domain: "Domain",
          hotel_hotel_pms_detail_label_hotel_code: "Kod Hotel",
          hotel_hotel_pms_detail_label_chain_code: "Kod Rantaian",
          hotel_hotel_pms_detail_label_reservation_transaction_code_is_credit: "Kod Transaksi Tempahan Adalah Kredit",
          hotel_hotel_pms_detail_label_cancellation_transaction_code_is_credit: "Kod Transaksi Pembatalan Adalah Kredit",
          hotel_hotel_pms_detail_label_deposit_transaction_code_is_credit: "Kod Urus Niaga Deposit Adalah Kredit",
          hotel_hotel_pms_detail_label_noshow_transaction_code_is_credit: "Kod Transaksi Tidak Hadir Adalah Kredit",
          hotel_hotel_pms_detail_label_service_fee_transaction_code_is_credit: "Kod Transaksi Yuran Perkhidmatan Adalah Kredit",
          hotel_hotel_pms_detail_label_default_reservation_transaction_code: "Kod Transaksi Tempahan Lalai",
          hotel_hotel_pms_detail_label_default_cancellation_transaction_code: "Kod Transaksi Pembatalan Lalai",
          hotel_hotel_pms_detail_label_default_deposit_transaction_code: "Kod Transaksi Deposit Lalai",
          hotel_hotel_pms_detail_label_default_noshow_transaction_code: "Kod Transaksi Tidak Tunjuk Lalai",
          hotel_hotel_pms_detail_label_default_service_fee_transaction_code: "Kod Transaksi Yuran Perkhidmatan Lalai",
          hotel_hotel_pms_detail_label_cancellation_debit_transaction_code: "Kod Transaksi Debit Pembatalan",
          hotel_hotel_pms_detail_label_noshow_debit_transaction_code: "Kod Transaksi Debit Tidak Hadir",
          hotel_hotel_pms_detail_label_market_code: "Kod Pasaran",
          hotel_hotel_pms_detail_label_future_booking: "Tempahan Masa Depan",
          hotel_hotel_pms_detail_label_walk_in: "Walk-In",
          hotel_hotel_pms_detail_label_web_booking: "Tempahan Web",
          hotel_hotel_pms_detail_label_ota_booking: "OTA Booking",
          hotel_hotel_pms_detail_label_origin_code: "Kod Asal",
          hotel_hotel_pms_detail_label_rate_code: "Kod Kadar",
          hotel_hotel_pms_detail_label_yes: "ya",
          hotel_hotel_pms_detail_label_no: "Tidak",
          hotel_hotel_pms_detail_label_earliest_checkin_time: "Waktu Daftar Masuk Terawal",
          hotel_hotel_pms_detail_label_dummy_pms_profile_id: "ID Profil PMS Dummy",
          hotel_hotel_pms_detail_button_go_to_info: "Pergi ke maklumat",
          hotel_hotel_pms_detail_button_edit: "Sunting",
          hotel_hotel_pms_edit_opera: "Opera",
          hotel_hotel_pms_edit_planyo: "Planyo",
          hotel_hotel_pms_edit_title: "TETAPAN",
          hotel_hotel_pms_edit_label_date: "Tarikh Dikemaskini",
          hotel_hotel_pms_edit_label_type: "taip",
          hotel_hotel_pms_edit_label_cloud_flag: "Bendera Awan",
          hotel_hotel_pms_edit_label_server_url: "Url Pelayan",
          hotel_hotel_pms_edit_label_origin_entity_id: "ID Entiti Asal",
          hotel_hotel_pms_edit_label_origin_system_type: "Jenis Sistem Asal",
          hotel_hotel_pms_edit_label_destination_entity_id: "ID Entiti Destinasi",
          hotel_hotel_pms_edit_label_destination_system_type: "Jenis Sistem Destinasi",
          hotel_hotel_pms_edit_label_user_name: "Nama Pengguna",
          hotel_hotel_pms_edit_label_user_password: "Kata Laluan Pengguna",
          hotel_hotel_pms_edit_label_domain: "Domain",
          hotel_hotel_pms_edit_label_hotel_code: "Kod Hotel",
          hotel_hotel_pms_edit_label_chain_code: "Kod Rantaian",
          hotel_hotel_pms_edit_label_reservation_transaction_code_is_credit: "Kod Transaksi Tempahan Adalah Kredit",
          hotel_hotel_pms_edit_label_cancellation_transaction_code_is_credit: "Kod Transaksi Pembatalan Adalah Kredit",
          hotel_hotel_pms_edit_label_deposit_transaction_code_is_credit: "Kod Urus Niaga Deposit Adalah Kredit",
          hotel_hotel_pms_edit_label_noshow_transaction_code_is_credit: "Kod Transaksi Tidak Hadir Adalah Kredit",
          hotel_hotel_pms_edit_label_service_fee_transaction_code_is_credit: "Kod Transaksi Yuran Perkhidmatan Adalah Kredit",
          hotel_hotel_pms_edit_label_default_reservation_transaction_code: "Kod Transaksi Tempahan Lalai",
          hotel_hotel_pms_edit_label_default_cancellation_transaction_code: "Kod Transaksi Pembatalan Lalai",
          hotel_hotel_pms_edit_label_default_deposit_transaction_code: "Kod Transaksi Deposit Lalai",
          hotel_hotel_pms_edit_label_default_noshow_transaction_code: "Kod Transaksi Tidak Tunjuk Lalai",
          hotel_hotel_pms_edit_label_default_service_fee_transaction_code: "Kod Transaksi Yuran Perkhidmatan Lalai",
          hotel_hotel_pms_edit_label_cancellation_debit_transaction_code: "Kod Transaksi Debit Pembatalan",
          hotel_hotel_pms_edit_label_noshow_debit_transaction_code: "Kod Transaksi Debit Tidak Hadir",
          hotel_hotel_pms_edit_label_market_code: "Kod Pasaran",
          hotel_hotel_pms_edit_label_future_booking: "Tempahan Masa Depan",
          hotel_hotel_pms_edit_label_walk_in: "Walk-In",
          hotel_hotel_pms_edit_label_web_booking: "Tempahan Web",
          hotel_hotel_pms_edit_label_ota_booking: "OTA Booking",
          hotel_hotel_pms_edit_label_origin_code: "Kod Asal",
          hotel_hotel_pms_edit_label_rate_code: "Kod Kadar",
          hotel_hotel_pms_edit_label_yes: "ya",
          hotel_hotel_pms_edit_label_no: "Tidak",
          hotel_hotel_pms_edit_button_cancel: "Batal",
          hotel_hotel_pms_edit_button_ok: "okey",
          hotel_hotel_pms_edit_validation_server_url: "* Sila masukkan URL pelayan.",
          hotel_hotel_pms_edit_validation_origin_entity_id: "* Sila masukkan ID Entiti Asal.",
          hotel_hotel_pms_edit_validation_origin_system_type: "* Sila masukkan Jenis Sistem Asal.",
          hotel_hotel_pms_edit_validation_destination_entity_id: "* Sila masukkan ID Entiti Destinasi.",
          hotel_hotel_pms_edit_validation_destination_system_type: "* Sila masukkan Jenis Sistem Destinasi.",
          hotel_hotel_pms_edit_validation_user_name: "* Sila masukkan nama pengguna anda.",
          hotel_hotel_pms_edit_validation_user_password: "* Sila masukkan kata laluan pengguna anda.",
          hotel_hotel_pms_edit_validation_domain: "* Sila masukkan domain.",
          hotel_hotel_setting_detail_title: "TETAPAN",
          hotel_hotel_setting_detail_label_date: "Tarikh Dikemaskini",
          hotel_hotel_setting_detail_label_room_control: "Kawalan Bilik",
          hotel_hotel_setting_detail_label_room_control_ip: "IP Kawalan Bilik",
          hotel_hotel_setting_detail_label_room_control_port: "Pelabuhan Kawalan Bilik",
          hotel_hotel_setting_detail_label_fias: "FIAS",
          hotel_hotel_setting_detail_label_notification_callback: "Panggilan Balik Pemberitahuan",
          hotel_hotel_setting_detail_label_fias_hotel_id: "ID hotel",
          hotel_hotel_setting_detail_label_fias_auth_code: "Kod Pengesahan",
          hotel_hotel_setting_detail_label_payment: "Bayaran",
          hotel_hotel_setting_detail_label_payment_auth_code: "Kod Pengesahan",
          hotel_hotel_setting_detail_label_store_id: "ID kedai",
          hotel_hotel_setting_detail_label_store_key: "Kunci Kedai",
          hotel_hotel_setting_detail_label_store_api_id: "Simpan ID API",
          hotel_hotel_setting_detail_label_payment_salt: "garam",
          hotel_hotel_setting_detail_label_payment_token_password: "Kata Laluan Token",
          hotel_hotel_setting_detail_label_secure_hash_secret: "Secure Hash Secret",
          hotel_hotel_setting_detail_label_payment_url: "URL pembayaran",
          hotel_hotel_setting_detail_label_refund_url: "URL bayaran balik",
          hotel_hotel_setting_detail_label_payment_type: "Jenis pembayaran",
          hotel_hotel_setting_detail_placeholder_select_box_choose: "pilih",
          hotel_hotel_setting_detail_select_nicepay: "Nicepay",
          hotel_hotel_setting_detail_select_asiapay: "Asiapay",
          hotel_hotel_setting_detail_select_mayapay: "Mayapay",
          hotel_hotel_setting_detail_label_booking_payment_using_myconect_flag: "Gunakan pembayaran MyCONECT semasa membuat tempahan",
          hotel_hotel_setting_detail_label_checkin_payment_using_myconect_flag: "Gunakan pembayaran MyCONECT semasa daftar masuk",
          hotel_hotel_setting_detail_label_walkin_payment_using_myconect_flag: "Gunakan pembayaran MyCONECT semasa walk-in",
          hotel_hotel_setting_detail_label_noshow_payment_using_myconect_flag: "Gunakan pembayaran MyCONECT semasa tidak hadir",
          hotel_hotel_setting_detail_label_checkout_payment_using_myconect_flag: "Gunakan pembayaran MyCONECT semasa daftar keluar",
          hotel_hotel_setting_detail_label_cancel_booking_payment_using_myconect_flag: "Gunakan pembayaran MyCONECT semasa pembatalan tempahan",
          hotel_hotel_setting_detail_label_need_card_information_for_booking: "Maklumat kad diperlukan semasa membuat tempahan",
          hotel_hotel_setting_detail_label_need_card_information_for_checkin: "Maklumat kad diperlukan semasa daftar masuk",
          hotel_hotel_setting_detail_label_need_card_information_for_walkin: "Maklumat kad diperlukan semasa berjalan masuk",
          hotel_hotel_setting_detail_label_is_direct_payable: "Dayakan bayaran langsung menggunakan maklumat token",
          hotel_hotel_setting_detail_label_is_card_tokenizable: "Dayakan tokenisasi kad",
          hotel_hotel_setting_detail_label_min_card_expiry_days_post_checkout: "Bilangan hari minimum sehingga kad tamat tempoh selepas pembayaran.",
          hotel_hotel_setting_detail_label_time: "Masa",
          hotel_hotel_setting_detail_label_early_check_in: "Daftar awal",
          hotel_hotel_setting_detail_label_assign_room_time: "Tetapkan Masa Bilik (Jam:Minit)",
          hotel_hotel_setting_detail_label_do_not_disturb_time: "Jangan Ganggu Masa (Jam:Minit)",
          hotel_hotel_setting_detail_label_time_zone: "Zon masa",
          hotel_hotel_setting_detail_label_tell: "Tel.",
          hotel_hotel_setting_detail_label_hotel_main_tel: "Tel Utama Hotel",
          hotel_hotel_setting_detail_label_hotel_service_tel: "Perkhidmatan Hotel Tel.",
          hotel_hotel_setting_detail_label_setting: "tetapan",
          hotel_hotel_setting_detail_label_dnd: "DND",
          hotel_hotel_setting_detail_label_dnd_status: "Tempoh Notis Peringatan Status DND (Jam:Minit)",
          hotel_hotel_setting_detail_label_dnd_relese: "Keluarkan DND Sebelum Pemberitahuan Perkhidmatan (minit)",
          hotel_hotel_setting_detail_button_yes: "ya",
          hotel_hotel_setting_detail_button_no: "Tidak",
          hotel_hotel_setting_detail_button_refresh: "Segarkan semula",
          hotel_hotel_setting_detail_label_member_number_check: "Sahkan Bilangan Tetamu Daftar Masuk",
          hotel_hotel_setting_detail_label_passport: "Modul Pengesahan Pasport",
          hotel_hotel_setting_detail_select_detion: "Detion",
          hotel_hotel_setting_detail_select_inzi_soft: "Inzi Lembut",
          hotel_hotel_setting_detail_label_accompany_local_check: "Imbasan ID Pengiring Domestik",
          hotel_hotel_setting_detail_label_accompany_foreigner_check: "Imbasan ID Pengiring Warga Asing",
          hotel_hotel_setting_detail_label_room_number: "Paparan Nombor Bilik",
          hotel_hotel_setting_detail_label_hotel_logo: "Paparkan Logo Hotel",
          hotel_hotel_setting_detail_label_member_lock_count: "Kiraan Kunci Ahli",
          hotel_hotel_setting_detail_label_payment_lock_count: "Kiraan Kunci Pembayaran",
          hotel_hotel_setting_detail_label_staff_login_lock_count: "Kiraan Kunci Log Masuk Kakitangan",
          hotel_hotel_setting_detail_label_default_currency: "Mata Wang Lalai",
          hotel_hotel_setting_detail_label_percent_for_hotel_charge: "Peratusan Caj Hotel (%)",
          hotel_hotel_setting_detail_label_language_code: "Kod Bahasa",
          hotel_hotel_setting_detail_label_alternative_name_code: "Kod Nama Alternatif",
          hotel_hotel_setting_detail_label_floor: "Lantai",
          hotel_hotel_setting_detail_label_out_of_order_check: "Semakan Habis Pesanan",
          hotel_hotel_setting_detail_label_password_change_day: "Hari Perubahan Kata Laluan (Hari)",
          hotel_hotel_setting_detail_label_password_change_notice: "Tempoh Notis Perubahan Kata Laluan (Hari)",
          hotel_hotel_setting_detail_label_occupy_minutes: "Menduduki Minit (Minit)",
          hotel_hotel_setting_detail_label_reservation_charge_waiting_minutes: "Caj Tempahan Minit Menunggu (Minit)",
          hotel_hotel_setting_detail_label_beacon_check_hours: "Waktu Semakan Beacon (Jam)",
          hotel_hotel_setting_detail_label_compare_faces_flag: "Bandingkan Bendera Wajah",
          hotel_hotel_setting_detail_label_compare_face_for_local_flag: "Compare Face for Local Flag",
          hotel_hotel_setting_detail_label_singapore_government_eva_flag: "Bendera EVA Kerajaan Singapura",
          hotel_hotel_setting_detail_label_singapore_government_eva_hotel_code: "Kod Hotel EVA Kerajaan Singapura",
          hotel_hotel_setting_detail_label_date_reservation_flag: "Bendera Tempahan Tarikh",
          hotel_hotel_setting_detail_label_time_reservation_flag: "Bendera Tempahan Masa",
          hotel_hotel_setting_detail_label_transit_hotel_flag: "Bendera Hotel Transit",
          hotel_hotel_setting_detail_label_request_make_up_room_at_checkout: "Minta Bilik Solek Semasa Checkout",
          hotel_hotel_setting_detail_label_show_rate_for_ota_flag: "Show Rate for OTA Flag",
          hotel_hotel_setting_detail_label_reservation_manager_emails: "Reservation Manager Emails",
          hotel_hotel_setting_detail_button_edit: "Sunting",
          hotel_hotel_setting_detail_button_cancel: "Batal",
          hotel_hotel_setting_edit_title: "tetapan",
          hotel_hotel_setting_edit_label_date: "Tarikh Dikemaskini",
          hotel_hotel_setting_edit_label_room_control: "Kawalan Bilik",
          hotel_hotel_setting_edit_label_room_control_ip: "IP Kawalan Bilik",
          hotel_hotel_setting_edit_placeholder_room_control_ip: "cth) 127.0.0.1",
          hotel_hotel_setting_edit_label_room_control_port: "Pelabuhan Kawalan Bilik",
          hotel_hotel_setting_edit_placeholder_room_control_port: "cth) 80",
          hotel_hotel_setting_edit_label_payment: "Bayaran",
          hotel_hotel_setting_edit_label_store_id: "ID kedai",
          hotel_hotel_setting_edit_placeholder_store_id: "ID kedai",
          hotel_hotel_setting_edit_label_payment_url: "URL pembayaran",
          hotel_hotel_setting_edit_placeholder_payment_url: "URL pembayaran",
          hotel_hotel_setting_edit_label_refund_url: "URL bayaran balik",
          hotel_hotel_setting_edit_placeholder_refund_url: "URL bayaran balik",
          hotel_hotel_setting_edit_label_time: "Masa",
          hotel_hotel_setting_edit_label_assign_room_time: "Tetapkan Masa Bilik",
          hotel_hotel_setting_edit_placeholder_assign_room_time: "Tetapkan Masa Bilik",
          hotel_hotel_setting_edit_label_do_not_disturb_time: "Jangan Ganggu Masa",
          hotel_hotel_setting_edit_placeholder_do_not_disturb_time: "Jangan Ganggu Masa",
          hotel_hotel_setting_edit_label_time_zone: "Zon masa",
          hotel_hotel_setting_edit_placeholder_time_zone: "Zon masa",
          hotel_hotel_setting_edit_label_tell: "Tel.",
          hotel_hotel_setting_edit_label_hotel_main_tel: "Tel Utama Hotel.",
          hotel_hotel_setting_edit_placeholder_hotel_main_tel: "Tel Utama Hotel.",
          hotel_hotel_setting_edit_label_hotel_service_tel: "Perkhidmatan Hotel Tel.",
          hotel_hotel_setting_edit_placeholder_hotel_service_tel: "Perkhidmatan Hotel Tel.",
          hotel_hotel_setting_edit_label_setting: "tetapan",
          hotel_hotel_setting_edit_button_yes: "ya",
          hotel_hotel_setting_edit_button_no: "Tidak",
          hotel_hotel_setting_edit_label_member_number_check: "Sahkan Bilangan Tetamu Daftar Masuk",
          hotel_hotel_setting_edit_label_accompany_local_check: "Imbasan ID Pengiring Domestik",
          hotel_hotel_setting_edit_label_accompany_foreigner_check: "Imbasan ID Pengiring Warga Asing",
          hotel_hotel_setting_edit_label_room_number: "Paparan Nombor Bilik",
          hotel_hotel_setting_edit_label_hotel_logo: "Paparkan Logo Hotel",
          hotel_hotel_setting_edit_label_member_lock_count: "Kiraan Kunci Ahli",
          hotel_hotel_setting_edit_placeholder_member_lock_count: "Kiraan Kunci Ahli",
          hotel_hotel_setting_edit_label_payment_lock_count: "Kiraan Kunci Pembayaran",
          hotel_hotel_setting_edit_placeholder_payment_lock_count: "Kiraan Kunci Pembayaran",
          hotel_hotel_setting_edit_label_default_currency: "Mata Wang Lalai",
          hotel_hotel_setting_edit_placeholder_default_currency: "Mata Wang Lalai",
          hotel_hotel_setting_edit_label_percent_for_hotel_charge: "Peratusan Caj Hotel",
          hotel_hotel_setting_edit_placeholder_percent_for_hotel_charge: "Peratusan Caj Hotel",
          hotel_hotel_setting_edit_label_language_code: "Kod Bahasa",
          hotel_hotel_setting_edit_placeholder_language_code: "Kod Bahasa",
          hotel_hotel_setting_edit_label_alternative_name_code: "Kod Nama Alternatif",
          hotel_hotel_setting_edit_placeholder_alternative_name_code: "Kod Nama Alternatif",
          hotel_hotel_setting_edit_label_floor: "Lantai",
          hotel_hotel_setting_edit_placeholder_floor: "Lantai",
          hotel_hotel_setting_edit_button_go_to_info: "Pergi ke maklumat",
          hotel_hotel_setting_edit_button_cancel: "Batal",
          hotel_hotel_setting_edit_button_ok: "okey",
          hotel_hotel_setting_edit_button_refresh: "Segarkan semula",
          hotel_hotel_setting_edit_validation_assign_room_time: "* Sila masukkan masa tugasan bilik.",
          hotel_hotel_setting_edit_validation_time_zone: "* Sila pilih zon waktu.",
          hotel_hotel_setting_edit_validation_member_lock_count: "* Sila masukkan bilangan ahli sekat keluar.",
          hotel_hotel_setting_edit_validation_payment_lock_count: "* Sila masukkan bilangan sekatan pembayaran.",
          hotel_hotel_setting_edit_validation_staff_lock_count: "* Sila masukkan bilangan sekat masuk kakitangan.",
          hotel_hotel_setting_edit_validation_default_currency: "* Sila masukkan mata wang lalai anda.",
          hotel_hotel_setting_edit_validation_language_code: "* Sila masukkan kod bahasa.",
          hotel_hotel_setting_edit_validation_alternative_name_code: "* Sila masukkan kod nama Korea anda.",
          hotel_hotel_setting_edit_validation_floors: "* Sila masuk ke lantai.",
          hotel_hotel_setting_edit_validation_password_change_day: "* Sila masukkan tarikh pertukaran kata laluan.",
          hotel_hotel_setting_edit_validation_password_change_notice_period: "* Sila masukkan tempoh pemberitahuan pertukaran kata laluan.",
          hotel_hotel_setting_edit_validation_occupy_minutes: "* Sila masukkan masa pekerjaan.",
          hotel_hotel_setting_edit_validation_reservation_charge_waiting_minutes: "* Sila masukkan masa menunggu untuk prabayaran.",
          hotel_hotel_setting_edit_validation_beacon_check_hours: "* Sila masukkan masa pengesahan suar.",
          hotel_hotel_setting_edit_validation_gov_sg_eva_hotel_code: "* Sila masukkan kod hotel EVA kerajaan singapura.",
          hotel_hotel_setting_edit_validation_reservation_manager_emails: "* The email address format you entered is incorrect. Email addresses must be in the format example@domain.com, and if there are multiple addresses, they should be separated by commas.",
          hotel_hotel_setting_edit_validation_store_id: "* Sila masukkan ID kedai anda.",
          hotel_hotel_setting_edit_validation_store_key: "* Sila masukkan kunci kedai anda.",
          hotel_hotel_setting_edit_validation_payment_url: "* Sila masukkan URL pembayaran.",
          hotel_hotel_setting_edit_validation_refund_url: "* Sila masukkan URL bayaran balik.",
          hotel_hotel_setting_edit_validation_payment_type: "* Sila pilih jenis pembayaran.",
          hotel_hotel_setting_edit_validation_payment_salt: "* Sila masukkan maklumat garam.",
          hotel_hotel_setting_edit_validation_payment_token_password: "* Sila masukkan kata laluan token.",
          hotel_hotel_setting_edit_validation_secure_hash_secret: "* Please enter the secure hash secret.",
          hotel_hotel_setting_edit_validation_booking_payment_using_myconect_flag: "* Gunakan pembayaran MyCONECT semasa membuat tempahan",
          hotel_hotel_setting_edit_validation_checkin_payment_using_myconect_flag: "* Gunakan pembayaran MyCONECT semasa daftar masuk",
          hotel_hotel_setting_edit_validation_walkin_payment_using_myconect_flag: "* Gunakan pembayaran MyCONECT semasa walk-in",
          hotel_hotel_setting_edit_validation_noshow_payment_using_myconect_flag: "* Gunakan pembayaran MyCONECT semasa tidak hadir",
          hotel_hotel_setting_edit_validation_checkout_payment_using_myconect_flag: "* Gunakan pembayaran MyCONECT semasa daftar keluar",
          hotel_hotel_setting_edit_validation_cancel_booking_payment_using_myconect_flag: "* Gunakan pembayaran MyCONECT semasa pembatalan tempahan",
          hotel_hotel_setting_edit_validation_need_card_information_for_booking: "* Pilih sama ada maklumat kad diperlukan semasa membuat tempahan.",
          hotel_hotel_setting_edit_validation_need_card_information_for_checkin: "* Pilih sama ada maklumat kad diperlukan semasa daftar masuk.",
          hotel_hotel_setting_edit_validation_need_card_information_for_walkin: "* Pilih sama ada maklumat kad diperlukan semasa berjalan masuk.",
          hotel_hotel_setting_edit_validation_is_direct_payable: "* Pilih sama ada bayaran langsung tersedia.",
          hotel_hotel_setting_edit_validation_is_card_tokenizable: "* Sila pilih kad Is boleh tokenizable.",
          hotel_hotel_setting_edit_validation_cancel_booking_post_charge_using_flag: "* Gunakan selepas pembayaran apabila tempahan dibatalkan.",
          hotel_hotel_setting_edit_validation_no_show_post_charge_using_flag: "* Pilih sama ada untuk mendayakan pasca pembayaran apabila tidak hadir.",
          hotel_hotel_setting_edit_validation_min_card_expiry_days_checkout: "* Masukkan bilangan hari minimum sehingga kad tamat tempoh selepas pembayaran.",
          hotel_hotel_setting_detail_label_cancel_booking_post_charge_using_flag: "Dayakan pasca pembayaran apabila tempahan dibatalkan",
          hotel_hotel_setting_detail_label_noshow_post_charge_using_flag: "Dayakan pengebilan selepas pembayaran apabila tidak hadir",
          hotel_hotel_setting_edit_validation_dnd_status: "* Sila masukkan tempoh pemberitahuan untuk mengesahkan mod DND tetamu.",
          hotel_hotel_setting_edit_validation_dnd_relese: "* Sila masukkan masa untuk menghantar pemberitahuan peringatan keluaran DND kepada tetamu sebelum waktu perkhidmatan.",
          hotel_hotel_setting_edit_validation_room_control_property_id: "* Sila masukkan ID hotel anda.",
          hotel_hotel_setting_edit_validation_room_control_ip: "* Sila masukkan IP kawalan bilik.",
          hotel_hotel_setting_edit_validation_room_control_port: "* Sila masukkan port kawalan bilik.",
          hotel_hotel_detail_list_title: "SENARAI BUTIRAN HOTEL",
          hotel_hotel_detail_list_label_name: "Nama",
          hotel_hotel_detail_list_label_language_code: "Kod Bahasa",
          hotel_hotel_detail_list_label_rating: "Penilaian",
          hotel_hotel_detail_list_label_address: "Alamat",
          hotel_hotel_detail_list_label_date: "Tarikh",
          hotel_hotel_detail_list_button_delete: "Padam",
          hotel_hotel_detail_list_button_add: "Tambah",
          hotel_hotel_detail_add_title: "TAMBAHKAN BUTIRAN HOTEL",
          hotel_hotel_detail_add_label_name: "Nama",
          hotel_hotel_detail_add_label_language_code: "Kod Bahasa",
          hotel_hotel_detail_add_placeholder_select_box_choose: "Pilih",
          hotel_hotel_detail_add_select_ko: "KR",
          hotel_hotel_detail_add_select_en: "EN",
          hotel_hotel_detail_add_select_cn: "CN",
          hotel_hotel_detail_add_select_jp: "JP",
          hotel_hotel_detail_add_label_rating: "Penilaian",
          hotel_hotel_detail_add_label_address: "Alamat",
          hotel_hotel_detail_add_label_phone: "telefon",
          hotel_hotel_detail_add_label_email: "E-mel",
          hotel_hotel_detail_add_button_go_to_list: "Pergi ke senarai",
          hotel_hotel_detail_add_button_add: "Tambah",
          hotel_hotel_detail_add_validation_name: "* Sila masukkan nama hotel.",
          hotel_hotel_detail_add_validation_languageCode: "* Sila pilih kod bahasa.",
          hotel_hotel_detail_add_validation_rating: "* Sila masukkan gred hotel.",
          hotel_hotel_detail_add_validation_address: "*Sila masukkan alamat.",
          hotel_hotel_detail_add_validation_phone: "* Sila masukkan nombor telefon.",
          hotel_hotel_detail_add_validation_email: "* Sila masukkan alamat e-mel hotel.",
          hotel_hotel_detail_add_validation_about: "* Sila masukkan keterangan bilik.",
          hotel_hotel_detail_detail_title: "BUTIRAN HOTEL",
          hotel_hotel_detail_detail_label_details: "Butiran",
          hotel_hotel_detail_detail_label_name: "Nama",
          hotel_hotel_detail_detail_label_language_code: "Kod Bahasa",
          hotel_hotel_detail_detail_placeholder_select_box_choose: "Pilih",
          hotel_hotel_detail_detail_select_ko: "KR",
          hotel_hotel_detail_detail_select_en: "EN",
          hotel_hotel_detail_detail_select_cn: "CN",
          hotel_hotel_detail_detail_select_jp: "JP",
          hotel_hotel_detail_detail_label_rating: "Penilaian",
          hotel_hotel_detail_detail_label_address: "Alamat",
          hotel_hotel_detail_detail_label_phone: "telefon",
          hotel_hotel_detail_detail_label_email: "E-mel",
          hotel_hotel_detail_detail_label_about: "Tentang",
          hotel_hotel_detail_detail_label_date: "Tarikh",
          hotel_hotel_detail_detail_label_facility: "Kemudahan",
          hotel_hotel_detail_detail_label_add_facility: "Tambah Kemudahan",
          hotel_hotel_detail_detail_label_edit_facility: "Edit Kemudahan",
          hotel_hotel_detail_detail_add_placeholder_name: "Sila masukkan nama.",
          hotel_hotel_detail_detail_label_amenity: "Kemudahan",
          hotel_hotel_detail_detail_label_add_amenity: "Tambah Kemudahan",
          hotel_hotel_detail_detail_type: "taip",
          hotel_hotel_detail_detail_group: "Kumpulan",
          hotel_hotel_detail_detail_code: "Kod",
          hotel_hotel_detail_detail_name: "Nama",
          hotel_hotel_detail_detail_icon: "ikon",
          hotel_hotel_detail_detail_date: "Tarikh",
          hotel_hotel_detail_detail_label_recommend_application: "Apl yang Dicadangkan",
          hotel_hotel_detail_detail_application: "Permohonan",
          hotel_hotel_detail_detail_label_add_recommend_app: "Tambah Apl yang Dicadangkan",
          hotel_hotel_detail_detail_empty_guide: "Sila Tambah Kandungan",
          hotel_hotel_detail_detail_button_go_to_list: "Pergi ke senarai",
          hotel_hotel_detail_detail_button_delete: "Padam",
          hotel_hotel_detail_detail_button_add: "Tambah",
          hotel_hotel_detail_detail_button_cancel: "Batal",
          hotel_hotel_detail_detail_button_edit: "Sunting",
          hotel_hotel_detail_detail_button_ok: "okey",
          hotel_hotel_detail_edit_validation_name: "* Sila masukkan nama hotel.",
          hotel_hotel_detail_edit_validation_languageCode: "* Sila pilih kod bahasa.",
          hotel_hotel_detail_edit_validation_rating: "* Sila masukkan gred hotel.",
          hotel_hotel_detail_edit_validation_address: "*Sila masukkan alamat.",
          hotel_hotel_detail_edit_validation_phone: "* Sila masukkan nombor telefon.",
          hotel_hotel_detail_edit_validation_email: "* Sila masukkan alamat e-mel hotel.",
          hotel_hotel_detail_edit_validation_about: "* Sila masukkan keterangan bilik.",
          hotel_hotel_img_list_title: "SENARAI IMEJ HOTEL",
          hotel_hotel_img_list_label_category: "kategori",
          hotel_hotel_img_list_label_type: "taip",
          hotel_hotel_img_list_label_hotel_img: "Imej Hotel",
          hotel_hotel_img_list_label_date: "Tarikh",
          hotel_hotel_img_list_button_delete: "Padam",
          hotel_hotel_img_list_button_add: "Tambah",
          hotel_hotel_img_add_title: "TAMBAH IMEJ HOTEL",
          hotel_hotel_img_add_label_category: "kategori",
          hotel_hotel_img_add_label_type: "taip",
          hotel_hotel_img_add_placeholder_select_box_choose: "pilih",
          hotel_hotel_img_add_select_gallery: "Galeri",
          hotel_hotel_img_add_select_hotel_side: "Sebelah Hotel",
          hotel_hotel_img_add_select_hotel_detail: "Butiran Hotel",
          hotel_hotel_img_add_select_hotel_center: "Pusat Hotel",
          hotel_hotel_img_add_select_hotel_front: "Depan Hotel",
          hotel_hotel_img_add_select_hotel_background: "Latar Belakang Hotel",
          hotel_hotel_img_add_select_logo_dark: "Logo Gelap",
          hotel_hotel_img_add_select_logo_light: "Lampu Logo",
          hotel_hotel_img_add_select_logo_chat: "Sembang Logo",
          hotel_hotel_img_add_select_card_key: "Kunci Kad",
          hotel_hotel_img_add_select_direct: "Input langsung",
          hotel_hotel_img_add_label_hotel_img: "Imej Hotel",
          hotel_hotel_img_add_button_upload: "Muat naik",
          hotel_hotel_img_add_button_go_to_list: "Pergi ke senarai",
          hotel_hotel_img_add_button_add: "Tambah",
          hotel_hotel_img_add_validation_category: "* Sila pilih kategori.",
          hotel_hotel_img_add_validation_hotel_img: "* Sila muat naik imej hotel.",
          hotel_hotel_img_detail_title: "MAKLUMAT IMEJ HOTEL",
          hotel_hotel_img_detail_label_category: "kategori",
          hotel_hotel_img_detail_label_type: "taip",
          hotel_hotel_img_detail_label_type_gallery: "Galeri",
          hotel_hotel_img_detail_placeholder_select_box_choose: "pilih",
          hotel_hotel_img_detail_select_direct: "Input langsung",
          hotel_hotel_img_detail_select_gallery: "Galeri",
          hotel_hotel_img_detail_select_hotel_side: "Sebelah Hotel",
          hotel_hotel_img_detail_select_hotel_detail: "Butiran Hotel",
          hotel_hotel_img_detail_select_hotel_center: "Pusat Hotel",
          hotel_hotel_img_detail_select_hotel_front: "Depan Hotel",
          hotel_hotel_img_detail_select_hotel_background: "Latar Belakang Hotel",
          hotel_hotel_img_detail_select_logo_dark: "Logo Gelap",
          hotel_hotel_img_detail_select_logo_light: "Lampu Logo",
          hotel_hotel_img_detail_select_logo_chat: "Sembang Logo",
          hotel_hotel_img_detail_select_card_key: "Kunci Kad",
          hotel_hotel_img_detail_label_hotel_img: "Imej Hotel",
          hotel_hotel_img_detail_button_upload: "Muat naik",
          hotel_hotel_img_detail_label_updated_at: "Dikemaskini Pada",
          hotel_hotel_img_detail_button_go_to_list: "Pergi ke senarai",
          hotel_hotel_img_detail_button_delete: "Padam",
          hotel_hotel_img_detail_button_edit: "Sunting",
          hotel_hotel_img_detail_button_ok: "okey",
          hotel_hotel_img_detail_button_cancel: "Batal",
          hotel_room_list_title: "SENARAI BILIK",
          hotel_room_list_placeholder_select_box_choose: "Pilih",
          hotel_room_list_select_content: "Kandungan",
          hotel_room_list_placeholder_search: "Cari...",
          hotel_room_list_label_rate_plan: "Pelan Kadar",
          hotel_room_list_label_room_type: "Jenis bilik",
          hotel_room_list_label_date: "Tarikh",
          hotel_room_list_button_delete: "Padam",
          hotel_room_list_button_add: "Tambah",
          hotel_room_list_guide_empty: "Senarai kosong",
          hotel_room_add_title: "TAMBAH BILIK",
          hotel_room_add_label_rate_plan: "Pelan Kadar",
          hotel_room_add_label_room_type: "Jenis bilik",
          hotel_room_add_button_go_to_list: "Pergi ke senarai",
          hotel_room_add_button_add: "Tambah",
          hotel_room_add_validation_rate_plan: "* Sila masukkan pelan kadar anda.",
          hotel_room_add_validation_room_type: "* Sila masukkan jenis bilik.",
          hotel_room_detail_title: "MAKLUMAT BILIK",
          hotel_room_detail_label_rate_plan: "Pelan Kadar",
          hotel_room_detail_label_room_type: "Jenis bilik",
          hotel_room_detail_label_room_date: "Tarikh",
          hotel_room_detail_label_resource_information: "Maklumat Sumber",
          hotel_room_detail_label_resource_information_resource_id: "ID sumber",
          hotel_room_detail_label_resource_information_name: "Nama",
          hotel_room_detail_label_resource_information_unit_price: "Harga seunit",
          hotel_room_detail_label_resource_information_currency: "mata wang",
          hotel_room_detail_label_resource_information_quantity: "Kuantiti",
          hotel_room_detail_label_resource_information_time_unit: "Unit Masa",
          hotel_room_detail_label_resource_information_min_rental_time: "Masa Sewa Min",
          hotel_room_detail_label_resource_information_max_rental_time: "Masa Sewa Maks",
          hotel_room_detail_label_resource_information_min_time_between_rentals: "Masa Minimum Antara Sewaan",
          hotel_room_detail_label_resource_information_min_hours_to_rental: "Min Jam Untuk Sewa",
          hotel_room_detail_label_resource_information_start_quarters: "Suku Mula",
          hotel_room_detail_label_resource_information_start_hour: "Jam Mula",
          hotel_room_detail_label_resource_information_end_hour: "Waktu Tamat",
          hotel_room_detail_label_resource_information_unit_names: "Nama Unit",
          hotel_room_detail_button_go_to_list: "Pergi ke senarai",
          hotel_room_detail_button_cancel: "Batal",
          hotel_room_detail_button_delete: "Padam",
          hotel_room_detail_button_edit: "Sunting",
          hotel_room_detail_button_ok: "okey",
          hotel_room_edit_validation_rate_plan: "* Sila masukkan pelan kadar anda.",
          hotel_room_edit_validation_room_type: "* Sila masukkan jenis bilik.",
          hotel_room_detail_list_title: "SENARAI BUTIRAN BILIK",
          hotel_room_detail_list_placeholder_select_box_choose: "Pilih",
          hotel_room_detail_list_select_room_type: "Jenis bilik",
          hotel_room_detail_list_select_rate_plan: "Pelan Kadar",
          hotel_room_detail_list_placeholder_search: "Cari...",
          hotel_room_detail_list_label_language_code: "Kod Bahasa",
          hotel_room_detail_list_label_name: "Nama",
          hotel_room_detail_list_label_view: "Lihat",
          hotel_room_detail_list_label_type: "taip",
          hotel_room_detail_list_label_room_type: "Jenis bilik",
          hotel_room_detail_list_label_rate_plan: "Pelan Kadar",
          hotel_room_detail_list_label_date: "Tarikh",
          hotel_room_detail_list_button_delete: "Padam",
          hotel_room_detail_list_button_add: "Tambah",
          hotel_room_detail_list_guide_empty: "Senarai kosong",
          hotel_room_detail_add_title: "TAMBAHKAN BUTIRAN BILIK",
          hotel_room_detail_add_label_language_code: "Kod Bahasa",
          hotel_room_detail_add_placeholder_select_box_choose: "Pilih",
          hotel_room_detail_add_select_ko: "KR",
          hotel_room_detail_add_select_en: "EN",
          hotel_room_detail_add_select_cn: "CN",
          hotel_room_detail_add_select_jp: "JP",
          hotel_room_detail_add_label_name: "Nama",
          hotel_room_detail_add_label_view: "Lihat",
          hotel_room_detail_add_label_type: "taip",
          hotel_room_detail_add_label_bed_type: "Jenis Katil",
          hotel_room_detail_add_label_max_occupancy: "Penghunian Maks",
          hotel_room_detail_add_label_standard_occupancy: "Penghunian Standard",
          hotel_room_detail_add_label_size: "Saiz (m²)",
          hotel_room_detail_add_label_checkin_time: "Masa Daftar Masuk (Jam:Minit)",
          hotel_room_detail_add_label_checkout_time: "Masa Daftar Keluar (Jam:Minit)",
          hotel_room_detail_add_label_about: "Tentang",
          hotel_room_detail_add_label_more: "Lagi",
          hotel_room_detail_add_label_room_type: "Jenis bilik",
          hotel_room_detail_add_button_go_to_list: "Pergi ke senarai",
          hotel_room_detail_add_button_add: "Tambah",
          hotel_room_detail_add_validation_room_type: "* Sila pilih jenis bilik.",
          hotel_room_detail_add_validation_language_code: "* Sila pilih kod bahasa.",
          hotel_room_detail_add_validation_name: "* Sila masukkan nama bilik.",
          hotel_room_detail_add_validation_view: "* Sila masukkan pemandangan bilik.",
          hotel_room_detail_add_validation_type: "* Sila masukkan jenis bilik.",
          hotel_room_detail_add_validation_bed_type: "* Sila masukkan jenis katil.",
          hotel_room_detail_add_validation_max_occupancy: "* Sila masukkan bilangan maksimum tetamu.",
          hotel_room_detail_add_validation_standard_occupancy: "* Sila masukkan bilangan minimum tetamu.",
          hotel_room_detail_add_validation_checkin_time: "* Sila masukkan masa daftar masuk.",
          hotel_room_detail_add_validation_checkout_time: "* Sila masukkan masa daftar keluar.",
          hotel_room_detail_add_validation_about: "* Sila masukkan keterangan bilik.",
          hotel_room_detail_add_validation_more: "* Sila masukkan sebarang keterangan bilik tambahan.",
          hotel_room_detail_detail_title: "MAKLUMAT BUTIRAN BILIK",
          hotel_room_detail_detail_label_details: "Butiran Bilik",
          hotel_room_detail_detail_label_name: "Nama",
          hotel_room_detail_detail_label_language_code: "Kod Bahasa",
          hotel_room_detail_detail_placeholder_select_box_choose: "Pilih",
          hotel_room_detail_detail_select_ko: "KR",
          hotel_room_detail_detail_select_en: "EN",
          hotel_room_detail_detail_select_cn: "CN",
          hotel_room_detail_detail_select_jp: "JP",
          hotel_room_detail_detail_label_view: "Lihat",
          hotel_room_detail_detail_label_type: "taip",
          hotel_room_detail_detail_label_bed_type: "Jenis Katil",
          hotel_room_detail_detail_label_max_occupancy: "Penghunian Maks",
          hotel_room_detail_detail_label_standard_occupancy: "Penghunian Standard",
          hotel_room_detail_detail_label_size: "Saiz (m²)",
          hotel_room_detail_detail_label_checkin_time: "Masa Daftar Masuk (Jam:Minit)",
          hotel_room_detail_detail_label_checkout_time: "Masa Daftar Keluar (Jam:Minit)",
          hotel_room_detail_detail_label_about: "Tentang",
          hotel_room_detail_detail_label_more: "Lagi",
          hotel_room_detail_detail_label_date: "Tarikh",
          hotel_room_detail_detail_title_amenity: "Kemudahan",
          hotel_room_detail_detail_type: "taip",
          hotel_room_detail_detail_group: "Kumpulan",
          hotel_room_detail_detail_code: "Kod",
          hotel_room_detail_detail_name: "Nama",
          hotel_room_detail_detail_icon: "ikon",
          hotel_room_detail_detail_date: "Tarikh",
          hotel_room_detail_detail_title_add_amenity: "Tambah Kemudahan",
          hotel_room_detail_detail_button_go_to_list: "Pergi ke senarai",
          hotel_room_detail_detail_button_add: "Tambah",
          hotel_room_detail_detail_button_delete: "Padam",
          hotel_room_detail_detail_button_cancel: "Batal",
          hotel_room_detail_detail_button_edit: "Sunting",
          hotel_room_detail_detail_button_ok: "okey",
          hotel_room_detail_edit_validation_room_type: "* Sila pilih jenis bilik.",
          hotel_room_detail_edit_validation_language_code: "* Sila pilih kod bahasa.",
          hotel_room_detail_edit_validation_name: "* Sila masukkan nama bilik.",
          hotel_room_detail_edit_validation_view: "* Sila masukkan pemandangan bilik.",
          hotel_room_detail_edit_validation_type: "* Sila masukkan jenis bilik.",
          hotel_room_detail_edit_validation_bed_type: "* Sila masukkan jenis katil.",
          hotel_room_detail_edit_validation_max_occupancy: "* Sila masukkan bilangan maksimum tetamu.",
          hotel_room_detail_edit_validation_standard_occupancy: "* Sila masukkan bilangan minimum tetamu.",
          hotel_room_detail_edit_validation_size: "* Sila masukkan saiz bilik.",
          hotel_room_detail_edit_validation_checkin_time: "* Sila masukkan masa daftar masuk.",
          hotel_room_detail_edit_validation_checkout_time: "* Sila masukkan masa daftar keluar.",
          hotel_room_detail_edit_validation_about: "* Sila masukkan keterangan bilik.",
          hotel_room_detail_edit_validation_more: "* Sila masukkan sebarang keterangan bilik tambahan.",
          hotel_room_img_list_title: "SENARAI IMEJ BILIK",
          hotel_room_img_list_label_rate_plan: "Pelan Kadar",
          hotel_room_img_list_label_room_type: "Jenis bilik",
          hotel_room_img_list_label_type: "taip",
          hotel_room_img_list_label_room_img: "Imej Bilik",
          hotel_room_img_list_label_date: "Tarikh",
          hotel_room_img_list_button_delete: "Padam",
          hotel_room_img_list_button_add: "Tambah",
          hotel_room_img_add_title: "TAMBAH IMEJ BILIK",
          hotel_room_img_add_label_room_type: "Jenis bilik",
          hotel_room_img_add_label_type: "taip",
          hotel_room_img_add_placeholder_select_box_choose: "Pilih",
          hotel_room_img_add_select_gallery: "Galeri",
          hotel_room_img_add_select_room_detail: "Butiran Bilik",
          hotel_room_img_add_select_room_center: "Pusat Bilik",
          hotel_room_img_add_label_hotel_img: "Imej Bilik",
          hotel_room_img_add_button_upload: "Muat naik",
          hotel_room_img_add_button_go_to_list: "Pergi ke senarai",
          hotel_room_img_add_button_add: "Tambah",
          hotel_room_img_add_validation_room_type: "* Sila pilih jenis bilik.",
          hotel_room_img_add_validation_image_file: "* Sila muat naik imej hotel.",
          hotel_room_img_detail_title: "MAKLUMAT IMEJ BILIK",
          hotel_room_img_detail_label_room_type: "Jenis bilik",
          hotel_room_img_detail_placeholder_select_box_choose: "Pilih",
          hotel_room_img_detail_label_type: "taip",
          hotel_room_img_detail_select_gallery: "Galeri",
          hotel_room_img_detail_select_room_detail: "Butiran Bilik",
          hotel_room_img_detail_select_room_center: "Pusat Bilik",
          hotel_room_img_detail_label_room_img: "Imej Bilik",
          hotel_room_img_detail_button_upload: "Muat naik",
          hotel_room_img_detail_label_updated_at: "Dikemaskini Pada",
          hotel_room_img_detail_button_go_to_list: "Pergi ke senarai",
          hotel_room_img_detail_button_delete: "Padam",
          hotel_room_img_detail_button_edit: "Sunting",
          hotel_room_img_detail_button_ok: "okey",
          hotel_room_img_detail_button_cancel: "Batal",
          hotel_room_management_room_list_list_title: "SENARAI BILIK",
          hotel_room_management_room_list_list_select_floor: "Lantai",
          hotel_room_management_room_list_list_select_cleaning_status: "Cleaning Status",
          hotel_room_management_room_list_list_select_room_type: "Jenis bilik",
          hotel_room_management_room_list_list_select_rooms_status: "Status Bilik",
          hotel_room_management_room_list_list_select_fo_status: "Status FO",
          hotel_room_management_room_list_list_select_reservation_status: "Status Tempahan",
          hotel_room_management_room_list_list_placeholder_room_no: "Nombor bilik.",
          hotel_room_management_room_list_list_button_all: "Semua",
          hotel_room_management_room_list_list_button_change: "Ubah",
          hotel_room_management_room_list_list_guide_empty: "Senarai kosong",
          hotel_room_management_room_list_detail_title: "BUTIRAN BILIK",
          hotel_room_management_room_list_detail_label_floor: "Lantai",
          hotel_room_management_room_list_detail_label_room_no: "Nombor bilik.",
          hotel_room_management_room_list_detail_label_room_type: "Jenis bilik",
          hotel_room_management_room_list_detail_label_room_status: "Status Bilik",
          hotel_room_management_room_list_detail_label_fo_status: "Status FO",
          hotel_room_management_room_list_detail_label_reservation_status: "Status Tempahan",
          hotel_room_management_room_list_detail_label_created_at: "Dicipta Pada",
          hotel_room_management_room_list_detail_button_status: "Status",
          hotel_room_management_room_list_detail_button_cleaning: "Pembersihan",
          hotel_room_management_room_list_detail_label_history: "Sejarah",
          hotel_room_management_room_list_detail_placeholder_select_box_choose: "Pilih",
          hotel_room_management_room_list_detail_select_housekeeping: "Pengemasan",
          hotel_room_management_room_list_detail_placeholder_search: "Cari...",
          hotel_room_management_room_list_detail_label_number: "Tidak.",
          hotel_room_management_room_list_detail_label_housekeeping: "Pengemasan",
          hotel_room_management_room_list_detail_label_staff: "Kakitangan",
          hotel_room_management_room_list_detail_label_date: "Tarikh",
          hotel_room_management_room_list_detail_guide_empty: "Senarai kosong",
          hotel_room_management_room_list_modal_title_status_message: "Status Bilik",
          hotel_room_management_room_list_modal_status_clean: "Bersih",
          hotel_room_management_room_list_modal_status_inspected: "Diperiksa",
          hotel_room_management_room_list_modal_status_dirty: "Kotor",
          hotel_room_management_room_list_modal_status_pick_up: "Angkat",
          hotel_room_management_setting_detail_title: "TETAPAN",
          hotel_room_management_setting_detail_label_total_floors: "Jumlah lantai",
          hotel_room_management_setting_detail_label_total_number_of_rooms: "Jumlah bilangan bilik",
          hotel_room_management_setting_detail_label_floor_detail: "Perincian Lantai",
          hotel_room_management_setting_detail_label_number: "Tidak.",
          hotel_room_management_setting_detail_label_number_of_rooms_on_the_floor: "Bilangan bilik di atas lantai",
          hotel_room_management_setting_detail_label_number_of_rooms: "Nombor bilik",
          hotel_room_management_setting_detail_guide_empty: "Senarai kosong",
          hotel_room_management_setting_detail_button_edit: "Sunting",
          hotel_room_management_setting_edit_validation_total_floors: "* Sila masukkan jumlah tingkat.",
          hotel_room_management_setting_edit_validation_number_of_rooms: "* Please enter the number of rooms.",
          hotel_room_management_setting_edit_label_total_floors: "Jumlah lantai",
          hotel_room_management_setting_edit_label_total_number_of_rooms: "Jumlah bilangan bilik",
          hotel_room_management_setting_edit_label_floor_detail: "Perincian Lantai",
          hotel_room_management_setting_edit_label_number: "Tidak.",
          hotel_room_management_setting_edit_label_number_of_rooms_on_the_floor: "Bilangan bilik di atas lantai",
          hotel_room_management_setting_edit_label_number_of_rooms: "Nombor bilik",
          hotel_room_management_setting_edit_guide_empty: "Senarai kosong",
          hotel_room_management_setting_edit_button_cancel: "Batal",
          hotel_room_management_setting_edit_button_ok: "okey",
          hotel_package_list_title: "SENARAI PAKEJ",
          hotel_package_list_label_type: "taip",
          hotel_package_list_label_code: "Kod",
          hotel_package_list_label_name: "Nama",
          hotel_package_list_label_description: "Penerangan",
          hotel_package_list_label_date: "Tarikh",
          hotel_package_list_button_delete: "Padam",
          hotel_package_list_button_add: "Tambah",
          hotel_package_list_guide_empty: "Senarai kosong",
          hotel_package_add_title: "TAMBAH PAKEJ",
          hotel_package_add_label_type: "taip",
          hotel_package_add_placeholder_select_box_choose: "Pilih",
          hotel_package_add_placeholder_select_box_package: "Pakej",
          hotel_package_add_placeholder_select_box_transaction: "Transaksi",
          hotel_package_add_label_code: "Kod",
          hotel_package_add_label_name: "Nama",
          hotel_package_add_label_description: "Penerangan",
          hotel_package_add_button_go_to_list: "Pergi ke senarai",
          hotel_package_add_button_add: "Tambah",
          hotel_package_add_validation_type: "* Sila pilih jenis pakej.",
          hotel_package_add_validation_code: "* Sila masukkan kod pakej.",
          hotel_package_add_validation_name: "* Sila masukkan nama pakej.",
          hotel_package_add_validation_description: "* Sila masukkan keterangan pakej.",
          hotel_package_detail_title: "MAKLUMAT PAKEJ",
          hotel_package_detail_label_type: "taip",
          hotel_package_detail_label_code: "Kod",
          hotel_package_detail_label_name: "Nama",
          hotel_package_detail_label_description: "penerangan",
          hotel_package_detail_label_updated_at: "Dikemaskini Pada",
          hotel_package_detail_button_go_to_list: "Pergi ke senarai",
          hotel_package_detail_button_cancel: "Batal",
          hotel_package_detail_button_delete: "Padam",
          hotel_package_detail_button_edit: "Sunting",
          hotel_package_detail_button_ok: "okey",
          hotel_package_edit_validation_type: "* Sila pilih jenis pakej.",
          hotel_package_edit_validation_code: "* Sila masukkan kod pakej.",
          hotel_package_edit_validation_name: "* Sila masukkan nama pakej.",
          hotel_package_edit_validation_description: "* Sila masukkan keterangan pakej.",
          hotel_package_detail_list_title: "SENARAI DETAIL PAKEJ",
          hotel_package_detail_list_label_code: "Kod",
          hotel_package_detail_list_label_name: "Nama",
          hotel_package_detail_list_label_language_code: "Kod Bahasa",
          hotel_package_detail_list_label_date: "Tarikh",
          hotel_package_detail_list_button_delete: "Padam",
          hotel_package_detail_list_button_add: "Tambah",
          hotel_package_detail_list_guide_empty: "Senarai kosong",
          hotel_package_detail_add_title: "TAMBAHKAN DETAIL PAKEJ",
          hotel_package_detail_add_label_name: "Nama",
          hotel_package_detail_add_label_package_code: "Kod Pakej",
          hotel_package_detail_add_placeholder_select_box_choose: "Pilih",
          hotel_package_detail_add_label_language_code: "Kod Bahasa",
          hotel_package_detail_add_select_ko: "KR",
          hotel_package_detail_add_select_en: "EN",
          hotel_package_detail_add_select_cn: "CN",
          hotel_package_detail_add_select_jp: "JP",
          hotel_package_detail_add_label_description: "Penerangan",
          hotel_package_detail_add_label_content: "Kandungan",
          hotel_package_detail_add_label_package_img: "Imej Pakej",
          hotel_package_detail_add_button_upload: "Muat naik",
          hotel_package_detail_add_button_go_to_list: "Pergi ke senarai",
          hotel_package_detail_add_button_add: "Tambah",
          hotel_package_detail_add_validation_package_code: "* Sila pilih kod pakej.",
          hotel_package_detail_add_validation_language_code: "* Sila pilih kod bahasa.",
          hotel_package_detail_add_validation_description: "* Sila masukkan penerangan pakej.",
          hotel_package_detail_add_validation_image_file: "* Sila muat naik imej pakej.",
          hotel_package_detail_add_validation_content: "* Sila masukkan butiran pakej.",
          hotel_package_detail_detail_title: "BUTIR-BUTIR PAKEJ",
          hotel_package_detail_detail_label_date: "Tarikh Dikemaskini",
          hotel_package_detail_detail_label_name: "Nama",
          hotel_package_detail_detail_label_package_code: "Kod",
          hotel_package_detail_detail_placeholder_select_box_choose: "Pilih",
          hotel_package_detail_detail_label_language_code: "Kod Bahasa",
          hotel_package_detail_detail_select_ko: "KR",
          hotel_package_detail_detail_select_en: "EN",
          hotel_package_detail_detail_select_cn: "CN",
          hotel_package_detail_detail_select_jp: "JP",
          hotel_package_detail_detail_label_description: "Penerangan",
          hotel_package_detail_detail_label_content: "Kandungan",
          hotel_package_detail_detail_label_package_img: "Imej Pakej",
          hotel_package_detail_detail_button_upload: "Muat naik",
          hotel_package_detail_detail_button_go_to_list: "Pergi ke senarai",
          hotel_package_detail_detail_button_cancel: "Batal",
          hotel_package_detail_detail_button_delete: "Padam",
          hotel_package_detail_detail_button_edit: "Sunting",
          hotel_package_detail_detail_button_ok: "okey",
          hotel_package_detail_edit_validation_package_code: "* Sila pilih kod pakej.",
          hotel_package_detail_edit_validation_language_code: "* Sila pilih kod bahasa.",
          hotel_package_detail_edit_validation_description: "* Sila masukkan penerangan pakej.",
          hotel_package_detail_edit_validation_image_file: "* Sila muat naik imej pakej.",
          hotel_package_detail_edit_validation_content: "* Sila masukkan butiran pakej.",
          hotel_hotel_valet_setting_detail_title: "TETAPAN PARKING VALET",
          hotel_hotel_valet_setting_detail_label_date: "Tarikh Dikemaskini",
          hotel_hotel_valet_setting_detail_label_parking: "Tempat letak kenderaan",
          hotel_hotel_valet_setting_detail_label_parking_availability: "Ketersediaan Tempat Letak Kereta Berjoki",
          hotel_hotel_valet_setting_detail_button_yes: "ya",
          hotel_hotel_valet_setting_detail_button_no: "Tidak",
          hotel_hotel_valet_setting_detail_label_time: "Masa",
          hotel_hotel_valet_setting_detail_label_delay_time: "Masa Kelewatan",
          hotel_hotel_valet_setting_detail_button_15: "15 minit",
          hotel_hotel_valet_setting_detail_button_30: "30 minit",
          hotel_hotel_valet_setting_detail_button_45: "45 Minit",
          hotel_hotel_valet_setting_detail_button_60: "60 Minit",
          hotel_hotel_valet_setting_detail_button_as_soon_as: "Sebaik sahaja tersedia",
          hotel_hotel_valet_setting_detail_label_calculation_time: "Masa Pengiraan Permintaan Menunggu (Jam:Minit)",
          hotel_hotel_valet_setting_detail_label_vehicle_time: "Masa Balik Kenderaan (Jam:Minit)",
          hotel_hotel_valet_setting_detail_label_request_caution_time: "Minta Awas Min (Minit)",
          hotel_hotel_valet_setting_detail_label_delay_available_time: "Kelewatan Masa Tersedia (Minit)",
          hotel_hotel_valet_setting_detail_label_requirement_time: "Masa Keperluan Min Boleh Diedit (Minit)",
          hotel_hotel_valet_setting_detail_label_log_active_hours: "Log Waktu Aktif (Jam)",
          hotel_hotel_valet_setting_detail_label_return_additional_charge: "Kembalikan Caj Tambahan",
          hotel_hotel_valet_setting_detail_label_return_push_message: "Kembalikan Bendera Mesej Tolak",
          hotel_hotel_valet_setting_detail_label_return_charge_count: "Kiraan Caj Pulangan",
          hotel_hotel_valet_setting_detail_label_return_repeat_message: "Bendera Ulangan Kembali",
          hotel_hotel_valet_setting_detail_button_edit: "Sunting",
          hotel_hotel_valet_setting_edit_title: "TETAPAN PARKING VALET",
          hotel_hotel_valet_setting_edit_label_date: "Tarikh Dikemaskini",
          hotel_hotel_valet_setting_edit_label_parking: "Tempat letak kenderaan",
          hotel_hotel_valet_setting_edit_label_parking_availability: "Ketersediaan Tempat Letak Kereta Berjoki",
          hotel_hotel_valet_setting_edit_button_yes: "ya",
          hotel_hotel_valet_setting_edit_button_no: "Tidak",
          hotel_hotel_valet_setting_edit_label_time: "Masa",
          hotel_hotel_valet_setting_edit_label_delay_time: "Masa Kelewatan",
          hotel_hotel_valet_setting_edit_placeholder_delay_time: "cth) 80",
          hotel_hotel_valet_setting_edit_label_calculation_time: "Masa Pengiraan Permintaan Menunggu",
          hotel_hotel_valet_setting_edit_placeholder_calculation_time: "cth) 24:00",
          hotel_hotel_valet_setting_edit_label_vehicle_time: "Masa Balik Kenderaan",
          hotel_hotel_valet_setting_edit_placeholder_vehicle_time: "cth) 80",
          hotel_hotel_valet_setting_edit_button_ok: "okey",
          hotel_hotel_valet_setting_edit_validation_parking_availability: "* Sila pilih status ketersediaan tempat letak kereta.",
          hotel_hotel_valet_setting_edit_validation_waiting_calculation_time: "* Sila masukkan masa permintaan menunggu.",
          hotel_hotel_valet_setting_edit_validation_vehicle_time: "* Sila masukkan masa 'kembali kenderaan ke tempat letak kereta'.",
          hotel_hotel_valet_setting_edit_validation_request_caution_time: "* Sila masukkan masa perhatian yang diminta.",
          hotel_hotel_valet_setting_edit_validation_requirement_time: "* Sila masukkan masa minimum yang boleh diedit.",
          hotel_hotel_valet_setting_edit_validation_charge_log_active_hours: "* Sila masukkan rekod masa aktiviti anda.",
          hotel_hotel_valet_setting_edit_validation_return_push_message: "* Sila pilih status pemberitahuan mesej balas.",
          hotel_hotel_self_setting_detail_title: "TETAPAN PARKING SENDIRI",
          hotel_hotel_self_setting_detail_label_date: "Tarikh Dikemaskini",
          hotel_hotel_self_setting_detail_label_parking: "Tempat letak kenderaan",
          hotel_hotel_self_setting_detail_label_parking_availability: "Ketersediaan Tempat Letak Kereta Sendiri",
          hotel_hotel_self_setting_detail_button_yes: "ya",
          hotel_hotel_self_setting_detail_button_no: "Tidak",
          hotel_hotel_self_setting_detail_button_edit: "Sunting",
          hotel_hotel_self_setting_edit_title: "TETAPAN PARKING SENDIRI",
          hotel_hotel_self_setting_edit_label_date: "Tarikh Dikemaskini",
          hotel_hotel_self_setting_edit_label_parking: "Tempat letak kenderaan",
          hotel_hotel_self_setting_edit_label_parking_availability: "Ketersediaan Tempat Letak Kereta Sendiri",
          hotel_hotel_self_setting_edit_button_yes: "ya",
          hotel_hotel_self_setting_edit_button_no: "Tidak",
          hotel_hotel_self_setting_edit_placeholder_vehicle_time: "cth) 80",
          hotel_hotel_self_setting_edit_button_ok: "okey",
          hotel_hotel_service_setting_detail_title: "TETAPAN MENU DALAM RUMAH",
          hotel_hotel_service_setting_detail_label_date: "Tarikh Dikemaskini",
          hotel_hotel_service_setting_detail_button_yes: "ya",
          hotel_hotel_service_setting_detail_button_no: "Tidak",
          hotel_hotel_service_setting_detail_label_housekeeping: "Pengemasan",
          hotel_hotel_service_setting_detail_label_housekeeping_dnd: "DND",
          hotel_hotel_service_setting_detail_label_housekeeping_green_campaign: "Kempen Hijau",
          hotel_hotel_service_setting_detail_label_housekeeping_emergency_service: "Servis kecemasan",
          hotel_hotel_service_setting_detail_label_housekeeping_make_up_room: "Bilik Solek",
          hotel_hotel_service_setting_detail_label_housekeeping_turndown_service: "Perkhidmatan Turndown",
          hotel_hotel_service_setting_detail_label_concierge: "Concierge",
          hotel_hotel_service_setting_detail_label_concierge_luggage_service: "Perkhidmatan Bagasi",
          hotel_hotel_service_setting_detail_label_concierge_parcel_service: "Perkhidmatan Bungkusan",
          hotel_hotel_service_setting_detail_label_concierge_taxi_call_service: "Perkhidmatan Teksi",
          hotel_hotel_service_setting_detail_label_guest_service: "Perkhidmatan Tetamu",
          hotel_hotel_service_setting_detail_label_guest_service_amenity_service: "Perkhidmatan Kemudahan",
          hotel_hotel_service_setting_detail_label_guest_service_laundry_service: "Perkhidmatan dobi",
          hotel_hotel_service_setting_detail_label_guest_service_in_room_dining: "Makan Dalam Bilik",
          hotel_hotel_service_setting_detail_label_guest_service_ironing_service: "Perkhidmatan Menyeterika",
          hotel_hotel_service_setting_detail_label_etc: "Dan lain-lain.",
          hotel_hotel_service_setting_detail_label_room_to_room: "Bilik ke Bilik",
          hotel_hotel_service_setting_detail_label_share_key: "Kongsi Kunci",
          hotel_hotel_service_setting_detail_label_in_room_control: "Dalam Kawalan Bilik",
          hotel_hotel_service_setting_detail_button_edit: "Sunting",
          hotel_hotel_service_setting_detail_button_cancel: "Batal",
          hotel_hotel_service_setting_detail_button_ok: "okey",
          hotel_service_description_list_title: "PENERANGAN PERKHIDMATAN",
          hotel_service_description_list_button_add: "Tambah",
          hotel_service_description_list_button_delete: "Padam",
          hotel_service_description_list_button_edit: "Sunting",
          hotel_service_description_list_guide_add: "Sila tambahkan perihalan perkhidmatan.",
          hotel_service_description_add_title: "TAMBAH HURAIAN PERKHIDMATAN",
          hotel_service_description_add_select_choose: "Sila pilih jenis",
          hotel_service_description_add_select_in_room_dining: "Makan Dalam Bilik",
          hotel_service_description_add_select_luggage_service: "Perkhidmatan Bagasi",
          hotel_service_description_add_select_parcel_service: "Perkhidmatan Bungkusan",
          hotel_service_description_add_select_taxi_call: "Teksi",
          hotel_service_description_add_select_amenity_service: "Perkhidmatan Kemudahan",
          hotel_service_description_add_select_ironing_service: "Perkhidmatan Menyeterika",
          hotel_service_description_add_select_laundry_service: "Perkhidmatan dobi",
          hotel_service_description_add_select_dnd: "DND",
          hotel_service_description_add_select_green_campaign: "Kempen Hijau",
          hotel_service_description_add_select_emergency_service: "Servis kecemasan",
          hotel_service_description_add_toolbar_bold: "berani",
          hotel_service_description_add_toolbar_italic: "Italic",
          hotel_service_description_add_toolbar_underline: "Garis bawah",
          hotel_service_description_add_toolbar_remove_font_style: "Alih keluar gaya fon",
          hotel_service_description_add_toolbar_font_family: "Keluarga fon",
          hotel_service_description_add_toolbar_font_size: "Saiz huruf",
          hotel_service_description_add_toolbar_recent_color: "Warna terkini",
          hotel_service_description_add_toolbar_recent_more_color: "Lebih banyak warna",
          hotel_service_description_add_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_service_description_add_toolbar_ordered_list: "Senarai pesanan",
          hotel_service_description_add_toolbar_paragraph: "Perenggan",
          hotel_service_description_add_toolbar_line_height: "Ketinggian Garisan",
          hotel_service_description_add_placeholder_content: "Sila masukkan kandungan.",
          hotel_service_description_add_button_cancel: "Batal",
          hotel_service_description_add_button_ok: "okey",
          hotel_service_description_add_validation_type: "* Sila pilih jenis.",
          hotel_service_description_add_validation_content: "* Sila masukkan butiran.",
          hotel_service_description_edit_title: "EDIT HURAIAN PERKHIDMATAN",
          hotel_service_description_edit_select_kr: "KR",
          hotel_service_description_edit_select_en: "EN",
          hotel_service_description_edit_select_cn: "CN",
          hotel_service_description_edit_select_jp: "JP",
          hotel_service_description_edit_toolbar_bold: "berani",
          hotel_service_description_edit_toolbar_italic: "Italic",
          hotel_service_description_edit_toolbar_underline: "Garis bawah",
          hotel_service_description_edit_toolbar_remove_font_style: "Alih keluar gaya fon",
          hotel_service_description_edit_toolbar_font_family: "Keluarga fon",
          hotel_service_description_edit_toolbar_font_size: "Saiz huruf",
          hotel_service_description_edit_toolbar_recent_color: "Warna terkini",
          hotel_service_description_edit_toolbar_recent_more_color: "Lebih banyak warna",
          hotel_service_description_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_service_description_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_service_description_edit_toolbar_paragraph: "Perenggan",
          hotel_service_description_edit_toolbar_code_view: "Pandangan kod",
          hotel_service_description_edit_toolbar_line_height: "Ketinggian garisan",
          hotel_service_description_edit_button_append: "Lampirkan",
          hotel_service_description_edit_button_remove: "Alih keluar",
          hotel_service_description_edit_button_cancel: "Batal",
          hotel_service_description_edit_button_ok: "okey",
          hotel_service_description_edit_validation_language_code: "* Sila pilih kod bahasa yang tidak bertindih.",
          hotel_service_description_edit_validation_content: "* Sila masukkan butiran.",
          hotel_hotel_beacon_list_title: "SENARAI SUARA",
          hotel_hotel_beacon_list_placeholder_search: "Cari...",
          hotel_hotel_beacon_list_label_uuid: "UUID",
          hotel_hotel_beacon_list_label_code: "Kod",
          hotel_hotel_beacon_list_label_type: "taip",
          hotel_hotel_beacon_list_label_content: "Kandungan",
          hotel_hotel_beacon_list_label_date: "Tarikh",
          hotel_hotel_beacon_list_guide_empty: "Senarai kosong",
          hotel_hotel_beacon_list_button_delete: "Padam",
          hotel_hotel_beacon_list_button_add: "Tambah",
          hotel_hotel_beacon_add_title: "TAMBAHKAN SUARA",
          hotel_hotel_beacon_add_label_uuid: "UUID",
          hotel_hotel_beacon_add_label_code: "Kod",
          hotel_hotel_beacon_add_label_type: "taip",
          hotel_hotel_beacon_add_label_content: "Kandungan",
          hotel_hotel_beacon_add_button_go_to_list: "Pergi ke senarai",
          hotel_hotel_beacon_add_button_add: "Tambah",
          hotel_hotel_beacon_add_validation_uuid: "* Sila masukkan UUID.",
          hotel_hotel_beacon_add_validation_code: "* Sila masukkan kod.",
          hotel_hotel_beacon_add_validation_type: "* Sila masukkan jenis.",
          hotel_hotel_beacon_add_validation_content: "* Sila masukkan kandungan.",
          hotel_hotel_beacon_detail_title: "MAKLUMAT SUARA",
          hotel_hotel_beacon_detail_label_uuid: "UUID",
          hotel_hotel_beacon_detail_label_code: "Kod",
          hotel_hotel_beacon_detail_label_type: "taip",
          hotel_hotel_beacon_detail_label_content: "Kandungan",
          hotel_hotel_beacon_detail_label_date: "Tarikh",
          hotel_hotel_beacon_detail_button_cancel: "Batal",
          hotel_hotel_beacon_detail_button_go_to_list: "Pergi ke senarai",
          hotel_hotel_beacon_detail_button_delete: "Padam",
          hotel_hotel_beacon_detail_button_edit: "Sunting",
          hotel_hotel_beacon_edit_title: "MAKLUMAT SUARA",
          hotel_hotel_beacon_edit_label_uuid: "UUID",
          hotel_hotel_beacon_edit_label_code: "Kod",
          hotel_hotel_beacon_edit_label_type: "taip",
          hotel_hotel_beacon_edit_label_content: "Kandungan",
          hotel_hotel_beacon_edit_label_date: "Tarikh",
          hotel_hotel_beacon_edit_button_cancel: "Batal",
          hotel_hotel_beacon_edit_button_delete: "Padam",
          hotel_hotel_beacon_edit_button_ok: "okey",
          hotel_hotel_beacon_edit_validation_uuid: "* Sila masukkan UUID.",
          hotel_hotel_beacon_edit_validation_code: "* Sila masukkan kod.",
          hotel_hotel_beacon_edit_validation_type: "* Sila masukkan jenis.",
          hotel_hotel_beacon_edit_validation_content: "* Sila masukkan kandungan.",
          hotel_hotel_currency_list_title: "SENARAI MATAWANG",
          hotel_hotel_currency_list_guide_exchange_rate: "Kadar pertukaran(KRW) :",
          hotel_hotel_currency_list_guide_won: "{{data}} menang / {{data}}",
          hotel_hotel_currency_list_placeholder_currency: "Mata wang ( SEMUA )",
          hotel_hotel_currency_list_select_display: "Paparan Mata Wang",
          hotel_hotel_currency_list_select_name: "Nama Mata Wang",
          hotel_hotel_currency_list_placeholder_search: "Cari...",
          hotel_hotel_currency_list_label_display: "Paparan Mata Wang",
          hotel_hotel_currency_list_label_name: "Nama Mata Wang",
          hotel_hotel_currency_list_label_exchange_rate: "Kadar pertukaran",
          hotel_hotel_currency_list_label_date: "Tarikh",
          hotel_hotel_currency_list_label_syne: "Segerakkan",
          hotel_hotel_currency_list_guide_empty: "Senarai kosong",
          hotel_hotel_currency_list_button_syne: "Segerakkan",
          hotel_hotel_currency_list_button_delete: "Padam",
          hotel_hotel_currency_list_button_add: "Tambah",
          hotel_hotel_currency_add_title: "TAMBAH MATAWANG",
          hotel_hotel_currency_add_guide_currency: "Kadar pertukaran(KRW)",
          hotel_hotel_currency_add_guide_won: "{{data}} menang / {{data}}",
          hotel_hotel_currency_add_select_display: "Paparan Mata Wang",
          hotel_hotel_currency_add_select_name: "Nama Mata Wang",
          hotel_hotel_currency_add_label_exchange_rate: "Kadar pertukaran",
          hotel_hotel_currency_add_button_go_to_list: "Pergi ke senarai",
          hotel_hotel_currency_add_button_add: "Tambah",
          hotel_hotel_currency_add_validation_currency_display: "* Sila masukkan simbol mata wang.",
          hotel_hotel_currency_add_validation_currency_name: "* Sila masukkan nama mata wang.",
          hotel_hotel_currency_add_validation_exchange_rate: "* Sila masukkan kadar pertukaran.",
          hotel_hotel_membership_list_title: "SENARAI JENIS KEAHLIAN",
          hotel_hotel_membership_list_placeholder_search: "Cari...",
          hotel_hotel_membership_list_label_membership_type: "Jenis Keahlian",
          hotel_hotel_membership_list_label_description: "Penerangan",
          hotel_hotel_membership_list_label_date: "Tarikh",
          hotel_hotel_membership_list_guide_empty: "Senarai kosong",
          hotel_hotel_membership_list_button_delete: "Padam",
          hotel_hotel_membership_list_button_sync: "Segerakkan",
          hotel_hotel_membership_list_button_add: "Tambah",
          hotel_hotel_membership_add_title: "TAMBAH JENIS KEAHLIAN",
          hotel_hotel_membership_add_label_membership_type: "Jenis Keahlian",
          hotel_hotel_membership_add_label_description: "Penerangan",
          hotel_hotel_membership_add_button_go_to_list: "Pergi ke senarai",
          hotel_hotel_membership_add_button_add: "Tambah",
          hotel_hotel_membership_add_validation_membership_type: "* Sila masukkan jenis keahlian anda.",
          hotel_hotel_membership_add_validation_description: "* Sila masukkan butiran anda.",
          hotel_hotel_membership_detail_title: "MAKLUMAT JENIS KEAHLIAN",
          hotel_hotel_membership_detail_label_membership_type: "Jenis Keahlian",
          hotel_hotel_membership_detail_label_description: "Penerangan",
          hotel_hotel_membership_detail_label_date: "Tarikh",
          hotel_hotel_membership_detail_button_go_to_list: "Pergi ke senarai",
          hotel_hotel_membership_detail_button_delete: "Padam",
          hotel_hotel_membership_detail_button_edit: "Sunting",
          hotel_hotel_membership_edit_title: "MAKLUMAT JENIS KEAHLIAN",
          hotel_hotel_membership_edit_label_membership_type: "Jenis Keahlian",
          hotel_hotel_membership_edit_label_description: "Penerangan",
          hotel_hotel_membership_edit_label_date: "Tarikh",
          hotel_hotel_membership_edit_button_cancel: "Batal",
          hotel_hotel_membership_edit_button_delete: "Padam",
          hotel_hotel_membership_edit_button_ok: "okey",
          hotel_hotel_membership_edit_validation_membership_type: "* Sila masukkan jenis keahlian anda.",
          hotel_hotel_membership_edit_validation_description: "* Sila masukkan butiran anda.",
          hotel_Title_list_title: "SENARAI TAJUK",
          hotel_Title_list_placeholder_search: "Cari...",
          hotel_Title_list_label_title: "Tajuk",
          hotel_Title_list_label_description: "Penerangan",
          hotel_Title_list_label_date: "Tarikh",
          hotel_Title_list_guide_empty: "Senarai kosong",
          hotel_Title_list_button_delete: "Padam",
          hotel_Title_list_button_sync: "Segerakkan",
          hotel_Title_list_button_add: "Tambah",
          hotel_Title_add_title: "TAMBAH",
          hotel_Title_add_label_title: "Tajuk",
          hotel_Title_add_label_description: "Penerangan",
          hotel_Title_add_button_go_to_list: "Pergi ke senarai",
          hotel_Title_add_button_add: "Tambah",
          hotel_Title_detail_title: "MAKLUMAT TAJUK",
          hotel_Title_detail_label_title: "Tajuk",
          hotel_Title_detail_label_description: "Penerangan",
          hotel_Title_detail_label_date: "Tarikh",
          hotel_Title_detail_button_cancel: "Batal",
          hotel_Title_detail_button_go_to_list: "Pergi ke senarai",
          hotel_Title_detail_button_delete: "Padam",
          hotel_hotel_card_type_list_title: "JENIS KAD",
          hotel_hotel_card_type_list_label_ok: "TIDAK.",
          hotel_hotel_card_type_list_label_value: "Nilai",
          hotel_hotel_card_type_list_label_description: "Penerangan",
          hotel_hotel_payment_list_title: "SENARAI BAYARAN",
          hotel_hotel_payment_list_placeholder_user_name: "Nama pengguna",
          hotel_hotel_payment_list_placeholder_search: "Cari...",
          hotel_hotel_payment_list_label_no: "TIDAK.",
          hotel_hotel_payment_list_label_reservation_id: "ID Tempahan",
          hotel_hotel_payment_list_label_user_name: "Nama pengguna",
          hotel_hotel_payment_list_label_long_info: "info panjang",
          hotel_hotel_payment_list_label_amount: "Jumlah",
          hotel_hotel_payment_list_label_unit: "Unit",
          hotel_hotel_payment_list_label_pg_status_code: "Kod status PG",
          hotel_hotel_payment_list_label_pg_status_message: "Mesej status PG",
          hotel_hotel_payment_list_label_date: "Tarikh",
          hotel_hotel_payment_list_guide_empty: "Senarai kosong",
          hotel_hotel_payment_detail_label_order_no: "Pesanan No.",
          hotel_hotel_payment_detail_label_user: "pengguna",
          hotel_hotel_payment_detail_label_user_profile_id: "ID Profil",
          hotel_hotel_payment_detail_label_user_title: "Tajuk",
          hotel_hotel_payment_detail_label_user_first_name: "Nama pertama",
          hotel_hotel_payment_detail_label_user_last_name: "Nama terakhir",
          hotel_hotel_payment_detail_label_user_email: "E-mel",
          hotel_hotel_payment_detail_label_user_date: "Tarikh",
          hotel_hotel_payment_detail_label_reservation: "Tempahan",
          hotel_hotel_payment_detail_label_reservation_confirmation_no: "No Pengesahan",
          hotel_hotel_payment_detail_label_reservation_reservation_id: "ID Tempahan",
          hotel_hotel_payment_detail_label_reservation_reservation_status: "Status Tempahan",
          hotel_hotel_payment_detail_label_reservation_check_in_date: "Tarikh Daftar Masuk",
          hotel_hotel_payment_detail_label_reservation_check_out_date: "Tarikh Daftar Keluar",
          hotel_hotel_payment_detail_label_payment: "Bayaran",
          hotel_hotel_payment_detail_button_go_to_list: "Pergi ke senarai",
          hotel_hotel_payment_detail_button_go_to_log: "Pergi ke senarai log",
          hotel_hotel_payment_detail_label_payment_order_no: "Pesanan No.",
          hotel_hotel_payment_detail_label_payment_reservation_id: "ID Tempahan",
          hotel_hotel_payment_detail_label_payment_long_info: "Maklumat Panjang",
          hotel_hotel_payment_detail_label_payment_folio_view_no: "Pandangan Folio No.",
          hotel_hotel_payment_detail_label_payment_amount: "Jumlah",
          hotel_hotel_payment_detail_label_payment_unit: "Unit",
          hotel_hotel_payment_detail_label_payment_pg_order_no: "Pesanan PG No.",
          hotel_hotel_payment_detail_label_payment_pg_status: "Status PG",
          hotel_hotel_payment_detail_label_payment_pg_status_code: "Kod Status PG",
          hotel_hotel_payment_detail_label_payment_pg_status_message: "Mesej Status PG",
          hotel_hotel_payment_detail_label_payment_pg_amount: "Jumlah PG",
          hotel_hotel_payment_detail_label_payment_pg_unit: "Unit PG",
          hotel_hotel_payment_detail_label_payment_pg_billing_no: "Bil PG No.",
          hotel_hotel_payment_detail_label_payment_confirmed_at: "Disahkan Pada",
          hotel_hotel_payment_detail_label_payment_created_at: "Dicipta Pada",
          hotel_hotel_payment_detail_label_payment_updated_at: "Dikemaskini Pada",
          hotel_hotel_payment_detail_label_schedule: "Jadual",
          hotel_hotel_payment_detail_label_schedule_rule_type: "Jenis Peraturan",
          hotel_hotel_payment_detail_label_schedule_name: "Nama",
          hotel_hotel_payment_detail_label_schedule_description: "Penerangan",
          hotel_hotel_payment_detail_label_schedule_rate_code: "Kod Kadar",
          hotel_hotel_payment_detail_label_schedule_begin_date: "Tarikh Mula",
          hotel_hotel_payment_detail_label_schedule_end_date: "Tarikh tamat",
          hotel_hotel_payment_detail_label_schedule_day_of_the_week: "Hari dalam seminggu",
          hotel_hotel_payment_detail_label_schedule_created_at: "CreatedAt",
          hotel_hotel_payment_detail_label_schedule_updated_at: "DikemaskiniPada",
          hotel_hotel_payment_detail_label_reservation_rule: "Peraturan Tempahan",
          hotel_hotel_payment_detail_label_rule_name: "Nama Peraturan",
          hotel_hotel_payment_detail_label_description: "Penerangan",
          hotel_hotel_payment_detail_label_reservation_amount: "Jumlah Tempahan",
          hotel_hotel_payment_detail_label_day_before_arrival: "Hari Sebelum Tiba",
          hotel_hotel_payment_detail_label_before_time: "Sebelum masa",
          hotel_hotel_payment_detail_label_deposit_rule: "Peraturan Deposit",
          hotel_hotel_payment_detail_label_type: "taip",
          hotel_hotel_payment_detail_label_deposit_amount: "Jumlah Deposit",
          hotel_hotel_payment_detail_label_cancellation_rule: "Peraturan Pembatalan",
          hotel_hotel_payment_detail_label_no_show_rule: "Peraturan Tidak Menunjukkan",
          hotel_hotel_payment_detail_label_source_code: "Kod sumber",
          hotel_hotel_payment_detail_label_penalty_amount: "Jumlah Penalti",
          hotel_hotel_payment_detail_label_guarantee_type: "Jenis Jaminan",
          hotel_hotel_payment_detail_label_posting_rule: "Peraturan Penyiaran",
          hotel_payment_history_log_list_title: "Senarai Log Pembayaran",
          hotel_payment_history_log_list_label_no: "TIDAK.",
          hotel_payment_history_log_list_label_type: "taip",
          hotel_payment_history_log_list_label_method: "Kaedah",
          hotel_payment_history_log_list_label_path: "Paty",
          hotel_payment_history_log_list_label_status: "patung",
          hotel_payment_history_log_list_label_success: "Kejayaan",
          hotel_payment_history_log_list_label_created_at: "Dicipta Pada",
          hotel_payment_history_log_list_guide_empty: "Senarai kosong",
          hotel_payment_history_log_list_button_go_to_detail: "Pergi ke Butiran",
          hotel_payment_history_log_detail_title: "Butiran Log Pembayaran",
          hotel_payment_history_log_detail_label_id: "ID",
          hotel_payment_history_log_detail_label_hotel_id: "ID hotel",
          hotel_payment_history_log_detail_label_payment_id: "ID Pembayaran",
          hotel_payment_history_log_detail_label_transfer_code: "Kod Pemindahan",
          hotel_payment_history_log_detail_label_type: "taip",
          hotel_payment_history_log_detail_label_method: "Kaedah",
          hotel_payment_history_log_detail_label_path: "Laluan",
          hotel_payment_history_log_detail_label_header: "Kepala",
          hotel_payment_history_log_detail_label_param: "Param",
          hotel_payment_history_log_detail_label_body: "Badan",
          hotel_payment_history_log_detail_label_status: "Status",
          hotel_payment_history_log_detail_label_session: "Sesi",
          hotel_payment_history_log_detail_label_created_at: "Dicipta Pada",
          hotel_payment_history_log_detail_button_go_to_list: "Pergi ke senarai",
          hotel_hotel_reservation_email_edit_title: "FORMAT EMEL TEMPAHAN",
          hotel_hotel_reservation_email_edit_select_kr: "KR",
          hotel_hotel_reservation_email_edit_select_en: "EN",
          hotel_hotel_reservation_email_edit_select_cn: "CN",
          hotel_hotel_reservation_email_edit_select_jp: "JP",
          hotel_hotel_reservation_email_edit_select_ms: "MS",
          hotel_hotel_reservation_email_edit_button_remove: "Alih keluar",
          hotel_hotel_reservation_email_edit_button_append: "Lampirkan",
          hotel_hotel_reservation_email_edit_button_save: "Jimat",
          hotel_hotel_reservation_email_edit_toolbar_bold: "berani",
          hotel_hotel_reservation_email_edit_toolbar_italic: "Italic",
          hotel_hotel_reservation_email_edit_toolbar_underline: "Garis bawah",
          hotel_hotel_reservation_email_edit_toolbar_strikethrough: "Strikethrough",
          hotel_hotel_reservation_email_edit_toolbar_remove_font_style: "Alih keluar gaya fon",
          hotel_hotel_reservation_email_edit_toolbar_font_family: "Keluarga fon",
          hotel_hotel_reservation_email_edit_toolbar_font_size: "Saiz huruf",
          hotel_hotel_reservation_email_edit_toolbar_text_color: "Warna teks",
          hotel_hotel_reservation_email_edit_toolbar_text_more_color: "Lebih banyak warna",
          hotel_hotel_reservation_email_edit_toolbar_recent_color: "Warna terkini",
          hotel_hotel_reservation_email_edit_toolbar_recent_more_color: "Lebih banyak warna",
          hotel_hotel_reservation_email_edit_toolbar_table: "Jadual",
          hotel_hotel_reservation_email_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_hotel_reservation_email_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_hotel_reservation_email_edit_toolbar_paragraph: "Perenggan",
          hotel_hotel_reservation_email_edit_toolbar_line_height: "Ketinggian garisan",
          hotel_hotel_reservation_email_edit_toolbar_picture: "Gambar",
          hotel_hotel_reservation_email_edit_toolbar_code_view: "Pandangan kod",
          hotel_hotel_reservation_email_edit_toolbar_full_screen: "Skrin penuh",
          hotel_hotel_reservation_email_edit_toolbar_help: "Tolong",
          hotel_hotel_reservation_email_edit_toolbar_tip: "Petua",
          hotel_hotel_reservation_email_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_hotel_reservation_email_edit_validation_content: "* Sila masukkan butiran anda.",
          hotel_hotel_reservation_email_edit_validation_language_code: "* Sila pilih kod bahasa yang tidak bertindih.",
          hotel_hotel_reservation_email_detail_title: "KANDUNGAN EMAIL TEMPAHAN",
          hotel_hotel_reservation_email_detail_label_confurmation_number: "Nombor pengesahan",
          hotel_hotel_reservation_email_detail_example_confurmation_number: "{{nombor pengesahan}}",
          hotel_hotel_reservation_email_detail_label_reservation_id: "ID Tempahan",
          hotel_hotel_reservation_email_detail_example_reservation_id: "{{reservationID}}",
          hotel_hotel_reservation_email_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_hotel_reservation_email_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_hotel_reservation_email_detail_label_departure_date: "Tarikh berlepas",
          hotel_hotel_reservation_email_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_hotel_reservation_email_detail_label_room_type: "Jenis bilik",
          hotel_hotel_reservation_email_detail_example_room_type: "{{jenis bilik}}",
          hotel_hotel_reservation_email_detail_label_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_hotel_reservation_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_hotel_reservation_email_detail_label_room_rate: "Kadar bilik",
          hotel_hotel_reservation_email_detail_example_room_rate: "{{kadar bilik}}",
          hotel_hotel_reservation_email_detail_label_total_rate: "Jumlah Kadar",
          hotel_hotel_reservation_email_detail_example_total_rate: "{{TotalRate}}",
          hotel_hotel_reservation_email_detail_label_current_balance: "Baki terkini",
          hotel_hotel_reservation_email_detail_example_current_balance: "{{baki terkini}}",
          hotel_hotel_reservation_email_detail_label_room_number: "Nombor bilik",
          hotel_hotel_reservation_email_detail_example_room_number: "{{nombor bilik}}",
          hotel_hotel_reservation_email_detail_label_adults: "Dewasa",
          hotel_hotel_reservation_email_detail_example_adults: "{{dewasa}}",
          hotel_hotel_reservation_email_detail_label_children: "Kanak-kanak",
          hotel_hotel_reservation_email_detail_example_children: "{{kanak-kanak}}",
          hotel_hotel_reservation_email_detail_label_check_out_time: "Masa daftar keluar",
          hotel_hotel_reservation_email_detail_example_check_out_time: "{{masa daftar keluar}}",
          hotel_hotel_reservation_email_detail_label_name_title: "Nama Tajuk",
          hotel_hotel_reservation_email_detail_example_name_title: "{{nameTitle}}",
          hotel_hotel_reservation_email_detail_label_first_name: "Nama pertama",
          hotel_hotel_reservation_email_detail_example_first_name: "{{nama pertama}}",
          hotel_hotel_reservation_email_detail_label_last_name: "Nama terakhir",
          hotel_hotel_reservation_email_detail_example_last_name: "{{nama terakhir}}",
          hotel_hotel_reservation_email_detail_label_country_code: "Kod negara",
          hotel_hotel_reservation_email_detail_example_country_code: "{{kod negara}}",
          hotel_hotel_reservation_email_detail_label_alternative_language_code: "Kod Bahasa Alternatif",
          hotel_hotel_reservation_email_detail_example_alternative_language_code: "{{AlternativeLanguageCode}}",
          hotel_hotel_reservation_email_detail_label_alternative_first_name: "Nama Pertama Alternatif",
          hotel_hotel_reservation_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
          hotel_hotel_reservation_email_detail_label_alternative_last_name: "Nama Akhir Alternatif",
          hotel_hotel_reservation_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
          hotel_hotel_reservation_email_detail_label_phone: "Telefon Bimbit No.",
          hotel_hotel_reservation_email_detail_example_phone: "{{telefon}}",
          hotel_hotel_reservation_email_detail_label_email: "E-mel",
          hotel_hotel_reservation_email_detail_example_email: "{{email}}",
          hotel_hotel_reservation_email_detail_label_accompany: "menemani",
          hotel_hotel_reservation_email_detail_example_accompany: "{{menemani}}",
          hotel_hotel_reservation_pdf_edit_title: "FORMAT PDF TEMPAHAN",
          hotel_hotel_reservation_pdf_edit_select_kr: "KR",
          hotel_hotel_reservation_pdf_edit_select_en: "EN",
          hotel_hotel_reservation_pdf_edit_select_cn: "CN",
          hotel_hotel_reservation_pdf_edit_select_jp: "JP",
          hotel_hotel_reservation_pdf_edit_select_ms: "MS",
          hotel_hotel_reservation_pdf_edit_button_remove: "Alih keluar",
          hotel_hotel_reservation_pdf_edit_button_append: "Lampirkan",
          hotel_hotel_reservation_pdf_edit_button_save: "Jimat",
          hotel_hotel_reservation_pdf_edit_toolbar_bold: "berani",
          hotel_hotel_reservation_pdf_edit_toolbar_italic: "Italic",
          hotel_hotel_reservation_pdf_edit_toolbar_underline: "Garis bawah",
          hotel_hotel_reservation_pdf_edit_toolbar_strikethrough: "Strikethrough",
          hotel_hotel_reservation_pdf_edit_toolbar_remove_font_style: "Alih keluar gaya fon",
          hotel_hotel_reservation_pdf_edit_toolbar_font_family: "Keluarga fon",
          hotel_hotel_reservation_pdf_edit_toolbar_font_size: "Saiz huruf",
          hotel_hotel_reservation_pdf_edit_toolbar_text_color: "Warna teks",
          hotel_hotel_reservation_pdf_edit_toolbar_text_more_color: "Lebih banyak warna",
          hotel_hotel_reservation_pdf_edit_toolbar_recent_color: "Warna terkini",
          hotel_hotel_reservation_pdf_edit_toolbar_recent_more_color: "Lebih banyak warna",
          hotel_hotel_reservation_pdf_edit_toolbar_table: "Jadual",
          hotel_hotel_reservation_pdf_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_hotel_reservation_pdf_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_hotel_reservation_pdf_edit_toolbar_paragraph: "Perenggan",
          hotel_hotel_reservation_pdf_edit_toolbar_line_height: "Ketinggian garisan",
          hotel_hotel_reservation_pdf_edit_toolbar_picture: "Gambar",
          hotel_hotel_reservation_pdf_edit_toolbar_code_view: "Pandangan kod",
          hotel_hotel_reservation_pdf_edit_toolbar_full_screen: "Skrin penuh",
          hotel_hotel_reservation_pdf_edit_toolbar_help: "Tolong",
          hotel_hotel_reservation_pdf_edit_toolbar_tip: "Petua",
          hotel_hotel_reservation_pdf_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_hotel_reservation_pdf_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_hotel_reservation_pdf_edit_validation_content: "* Sila masukkan butiran anda.",
          hotel_hotel_reservation_pdf_edit_validation_language_code: "* Sila pilih kod bahasa yang tidak bertindih.",
          hotel_hotel_reservation_pdf_detail_title: "TEMPAHAN PDF KANDUNGAN",
          hotel_hotel_reservation_pdf_detail_label_confurmation_number: "Nombor pengesahan",
          hotel_hotel_reservation_pdf_detail_example_confurmation_number: "{{nombor pengesahan}}",
          hotel_hotel_reservation_pdf_detail_label_reservation_id: "ID Tempahan",
          hotel_hotel_reservation_pdf_detail_example_reservation_id: "{{reservationID}}",
          hotel_hotel_reservation_pdf_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_hotel_reservation_pdf_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_hotel_reservation_pdf_detail_label_departure_date: "Tarikh berlepas",
          hotel_hotel_reservation_pdf_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_hotel_reservation_pdf_detail_label_room_type: "Jenis bilik",
          hotel_hotel_reservation_pdf_detail_example_room_type: "{{jenis bilik}}",
          hotel_hotel_reservation_pdf_detail_label_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_hotel_reservation_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_hotel_reservation_pdf_detail_label_room_rate: "Kadar bilik",
          hotel_hotel_reservation_pdf_detail_example_room_rate: "{{kadar bilik}}",
          hotel_hotel_reservation_pdf_detail_label_total_rate: "Jumlah Kadar",
          hotel_hotel_reservation_pdf_detail_example_total_rate: "{{TotalRate}}",
          hotel_hotel_reservation_pdf_detail_label_current_balance: "Baki terkini",
          hotel_hotel_reservation_pdf_detail_example_current_balance: "{{baki terkini}}",
          hotel_hotel_reservation_pdf_detail_label_room_number: "Nombor bilik",
          hotel_hotel_reservation_pdf_detail_example_room_number: "{{nombor bilik}}",
          hotel_hotel_reservation_pdf_detail_label_adults: "Dewasa",
          hotel_hotel_reservation_pdf_detail_example_adults: "{{dewasa}}",
          hotel_hotel_reservation_pdf_detail_label_children: "Kanak-kanak",
          hotel_hotel_reservation_pdf_detail_example_children: "{{kanak-kanak}}",
          hotel_hotel_reservation_pdf_detail_label_check_out_time: "Masa daftar keluar",
          hotel_hotel_reservation_pdf_detail_example_check_out_time: "{{masa daftar keluar}}",
          hotel_hotel_reservation_pdf_detail_label_name_title: "Nama Tajuk",
          hotel_hotel_reservation_pdf_detail_example_name_title: "{{nameTitle}}",
          hotel_hotel_reservation_pdf_detail_label_first_name: "Nama pertama",
          hotel_hotel_reservation_pdf_detail_example_first_name: "{{nama pertama}}",
          hotel_hotel_reservation_pdf_detail_label_last_name: "Nama terakhir",
          hotel_hotel_reservation_pdf_detail_example_last_name: "{{nama terakhir}}",
          hotel_hotel_reservation_pdf_detail_label_country_code: "Kod negara",
          hotel_hotel_reservation_pdf_detail_example_country_code: "{{kod negara}}",
          hotel_hotel_reservation_pdf_detail_label_alternative_language_code: "Kod Bahasa Alternatif",
          hotel_hotel_reservation_pdf_detail_example_alternative_language_code: "{{AlternativeLanguageCode}}",
          hotel_hotel_reservation_pdf_detail_label_alternative_first_name: "Nama Pertama Alternatif",
          hotel_hotel_reservation_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
          hotel_hotel_reservation_pdf_detail_label_alternative_last_name: "Nama Akhir Alternatif",
          hotel_hotel_reservation_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
          hotel_hotel_reservation_pdf_detail_label_phone: "Telefon Bimbit No.",
          hotel_hotel_reservation_pdf_detail_example_phone: "{{telefon}}",
          hotel_hotel_reservation_pdf_detail_label_email: "E-mel",
          hotel_hotel_reservation_pdf_detail_example_email: "{{email}}",
          hotel_hotel_reservation_accompany_email_edit_title: "MENGIRINGI EMAIL TEMPAHAN",
          hotel_hotel_reservation_accompany_email_edit_select_kr: "KR",
          hotel_hotel_reservation_accompany_email_edit_select_en: "EN",
          hotel_hotel_reservation_accompany_email_edit_select_cn: "CN",
          hotel_hotel_reservation_accompany_email_edit_select_jp: "JP",
          hotel_hotel_reservation_accompany_email_edit_select_ms: "MS",
          hotel_hotel_reservation_accompany_email_edit_button_remove: "Alih keluar",
          hotel_hotel_reservation_accompany_email_edit_button_append: "Lampirkan",
          hotel_hotel_reservation_accompany_email_edit_button_save: "Jimat",
          hotel_hotel_reservation_accompany_email_edit_toolbar_bold: "berani",
          hotel_hotel_reservation_accompany_email_edit_toolbar_italic: "Italic",
          hotel_hotel_reservation_accompany_email_edit_toolbar_underline: "Garis bawah",
          hotel_hotel_reservation_accompany_email_edit_toolbar_strikethrough: "Strikethrough",
          hotel_hotel_reservation_accompany_email_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_hotel_reservation_accompany_email_edit_toolbar_font_family: "Keluarga Font",
          hotel_hotel_reservation_accompany_email_edit_toolbar_font_size: "Saiz huruf",
          hotel_hotel_reservation_accompany_email_edit_toolbar_text_color: "Warna Teks",
          hotel_hotel_reservation_accompany_email_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_hotel_reservation_accompany_email_edit_toolbar_recent_color: "Warna Terkini",
          hotel_hotel_reservation_accompany_email_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_hotel_reservation_accompany_email_edit_toolbar_table: "Jadual",
          hotel_hotel_reservation_accompany_email_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_hotel_reservation_accompany_email_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_hotel_reservation_accompany_email_edit_toolbar_paragraph: "Perenggan",
          hotel_hotel_reservation_accompany_email_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_hotel_reservation_accompany_email_edit_toolbar_picture: "Gambar",
          hotel_hotel_reservation_accompany_email_edit_toolbar_code_view: "Pandangan Kod",
          hotel_hotel_reservation_accompany_email_edit_toolbar_full_screen: "Skrin penuh",
          hotel_hotel_reservation_accompany_email_edit_toolbar_help: "Tolong",
          hotel_hotel_reservation_accompany_email_edit_toolbar_tip: "Petua",
          hotel_hotel_reservation_accompany_email_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_hotel_reservation_accompany_email_detail_title: "MENGIRINGI KANDUNGAN EMEL TEMPAHAN",
          hotel_hotel_reservation_accompany_email_detail_label_confurmation_number: "Nombor pengesahan",
          hotel_hotel_reservation_accompany_email_detail_example_confurmation_number: "{{nombor pengesahan}}",
          hotel_hotel_reservation_accompany_email_detail_label_reservation_id: "ID Tempahan",
          hotel_hotel_reservation_accompany_email_detail_example_reservation_id: "{{reservationID}}",
          hotel_hotel_reservation_accompany_email_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_hotel_reservation_accompany_email_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_hotel_reservation_accompany_email_detail_label_departure_date: "Tarikh berlepas",
          hotel_hotel_reservation_accompany_email_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_hotel_reservation_accompany_email_detail_label_room_type: "Jenis bilik",
          hotel_hotel_reservation_accompany_email_detail_example_room_type: "{{jenis bilik}}",
          hotel_hotel_reservation_accompany_email_detail_label_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_hotel_reservation_accompany_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_hotel_reservation_accompany_email_detail_label_room_rate: "Kadar bilik",
          hotel_hotel_reservation_accompany_email_detail_example_room_rate: "{{kadar bilik}}",
          hotel_hotel_reservation_accompany_email_detail_label_total_rate: "Jumlah Kadar",
          hotel_hotel_reservation_accompany_email_detail_example_total_rate: "{{TotalRate}}",
          hotel_hotel_reservation_accompany_email_detail_label_current_balance: "Baki terkini",
          hotel_hotel_reservation_accompany_email_detail_example_current_balance: "{{baki terkini}}",
          hotel_hotel_reservation_accompany_email_detail_label_room_number: "Nombor bilik",
          hotel_hotel_reservation_accompany_email_detail_example_room_number: "{{nombor bilik}}",
          hotel_hotel_reservation_accompany_email_detail_label_adults: "Dewasa",
          hotel_hotel_reservation_accompany_email_detail_example_adults: "{{dewasa}}",
          hotel_hotel_reservation_accompany_email_detail_label_children: "Kanak-kanak",
          hotel_hotel_reservation_accompany_email_detail_example_children: "{{kanak-kanak}}",
          hotel_hotel_reservation_accompany_email_detail_label_check_out_time: "Masa daftar keluar",
          hotel_hotel_reservation_accompany_email_detail_example_check_out_time: "{{masa daftar keluar}}",
          hotel_hotel_reservation_accompany_email_detail_label_name_title: "Nama Tajuk",
          hotel_hotel_reservation_accompany_email_detail_example_name_title: "{{nameTitle}}",
          hotel_hotel_reservation_accompany_email_detail_label_first_name: "Nama pertama",
          hotel_hotel_reservation_accompany_email_detail_example_first_name: "{{nama pertama}}",
          hotel_hotel_reservation_accompany_email_detail_label_last_name: "Nama terakhir",
          hotel_hotel_reservation_accompany_email_detail_example_last_name: "{{nama terakhir}}",
          hotel_hotel_reservation_accompany_email_detail_label_country_code: "Kod negara",
          hotel_hotel_reservation_accompany_email_detail_example_country_code: "{{kod negara}}",
          hotel_hotel_reservation_accompany_email_detail_label_alternative_language_code: "Kod Bahasa Alternatif",
          hotel_hotel_reservation_accompany_email_detail_example_alternative_language_code: "{{AlternativeLanguageCode}}",
          hotel_hotel_reservation_accompany_email_detail_label_alternative_first_name: "Nama Pertama Alternatif",
          hotel_hotel_reservation_accompany_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
          hotel_hotel_reservation_accompany_email_detail_label_alternative_last_name: "Nama Akhir Alternatif",
          hotel_hotel_reservation_accompany_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
          hotel_hotel_reservation_accompany_email_detail_label_phone: "Telefon Bimbit No.",
          hotel_hotel_reservation_accompany_email_detail_example_phone: "{{telefon}}",
          hotel_hotel_reservation_accompany_email_detail_label_email: "E-mel",
          hotel_hotel_reservation_accompany_email_detail_example_email: "{{email}}",
          hotel_hotel_reservation_accompany_email_detail_label_accompany_title: "Tajuk Iringan",
          hotel_hotel_reservation_accompany_email_detail_example_accompany_title: "{{accompanyTitle}}",
          hotel_hotel_reservation_accompany_email_detail_label_accompany_first_name: "Iringi Nama Pertama",
          hotel_hotel_reservation_accompany_email_detail_example_accompany_first_name: "{{accompanyFirstName}}",
          hotel_hotel_reservation_accompany_email_detail_label_accompany_last_name: "Iringi Nama Akhir",
          hotel_hotel_reservation_accompany_email_detail_example_accompany_last_name: "{{accompanyLastName}}",
          hotel_document_accom_reservation_change_edit_title: "IRINGI UBAHSUAI EMEL TEMPAHAN",
          hotel_document_accom_reservation_change_edit_select_kr: "KR",
          hotel_document_accom_reservation_change_edit_select_en: "EN",
          hotel_document_accom_reservation_change_edit_select_cn: "CN",
          hotel_document_accom_reservation_change_edit_select_jp: "JP",
          hotel_document_accom_reservation_change_edit_select_ms: "MS",
          hotel_document_accom_reservation_change_edit_button_remove: "keluarkan",
          hotel_document_accom_reservation_change_edit_button_append: "Lampirkan",
          hotel_document_accom_reservation_change_edit_button_save: "Jimat",
          hotel_document_accom_reservation_change_edit_toolbar_bold: "berani",
          hotel_document_accom_reservation_change_edit_toolbar_italic: "Italic",
          hotel_document_accom_reservation_change_edit_toolbar_underline: "Garis bawah",
          hotel_document_accom_reservation_change_edit_toolbar_strikethrough: "Strikethrough",
          hotel_document_accom_reservation_change_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_document_accom_reservation_change_edit_toolbar_font_family: "Keluarga Font",
          hotel_document_accom_reservation_change_edit_toolbar_font_size: "Saiz huruf",
          hotel_document_accom_reservation_change_edit_toolbar_text_color: "Warna Teks",
          hotel_document_accom_reservation_change_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_document_accom_reservation_change_edit_toolbar_recent_color: "Warna Terkini",
          hotel_document_accom_reservation_change_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_document_accom_reservation_change_edit_toolbar_table: "Jadual",
          hotel_document_accom_reservation_change_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_document_accom_reservation_change_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_document_accom_reservation_change_edit_toolbar_paragraph: "Perenggan",
          hotel_document_accom_reservation_change_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_document_accom_reservation_change_edit_toolbar_picture: "Gambar",
          hotel_document_accom_reservation_change_edit_toolbar_code_view: "Pandangan Kod",
          hotel_document_accom_reservation_change_edit_toolbar_full_screen: "Skrin penuh",
          hotel_document_accom_reservation_change_edit_toolbar_help: "Tolong",
          hotel_document_accom_reservation_change_edit_toolbar_tip: "Petua",
          hotel_document_accom_reservation_change_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_document_accom_reservation_change_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_document_accom_reservation_change_detail_title: "MENGIRINGI TEMPAHAN PERUBAHAN KANDUNGAN",
          hotel_document_accom_reservation_change_detail_label_confirmation_number: "Nombor pengesahan",
          hotel_document_accom_reservation_change_detail_example_confirmation_number: "{{nombor pengesahan}}",
          hotel_document_accom_reservation_change_detail_label_accom_first_name: "Iringi Nama Pertama",
          hotel_document_accom_reservation_change_detail_example_accom_first_name: "{{accompanyFirstName}}",
          hotel_document_accom_reservation_change_detail_label_accom_last_name: "Iringi Nama Akhir",
          hotel_document_accom_reservation_change_detail_example_accom_last_name: "{{accompanyLastName}}",
          hotel_document_accom_reservation_change_detail_label_first_name: "Nama pertama",
          hotel_document_accom_reservation_change_detail_example_first_name: "{{nama pertama}}",
          hotel_document_accom_reservation_change_detail_label_last_name: "Nama terakhir",
          hotel_document_accom_reservation_change_detail_example_last_name: "{{nama terakhir}}",
          hotel_document_accom_reservation_change_detail_label_room_type: "Jenis bilik",
          hotel_document_accom_reservation_change_detail_example_room_type: "{{jenis bilik}}",
          hotel_document_accom_reservation_change_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_document_accom_reservation_change_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_document_accom_reservation_change_detail_label_departure_date: "Tarikh berlepas",
          hotel_document_accom_reservation_change_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_document_accom_reservation_change_detail_label_accom_title: "Tajuk Iringan",
          hotel_document_accom_reservation_change_detail_example_accom_title: "{{accompanyTitle}}",
          hotel_document_accom_reservation_change_detail_label_adults: "Dewasa",
          hotel_document_accom_reservation_change_detail_example_adults: "{{dewasa}}",
          hotel_document_accom_reservation_change_detail_label_night: "Malam",
          hotel_document_accom_reservation_change_detail_example_night: "{{malam}}",
          hotel_document_accom_reservation_change_detail_label_room_rate: "Kadar bilik",
          hotel_document_accom_reservation_change_detail_example_room_rate: "{{kadar bilik}}",
          hotel_document_accom_reservation_change_detail_label_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_document_accom_reservation_change_detail_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_document_accom_reservation_change_detail_label_total_rate: "Jumlah Kadar",
          hotel_document_accom_reservation_change_detail_example_total_rate: "{{TotalRate}}",
          hotel_document_accom_reservation_change_detail_label_cancellation: "Polisi Pembatalan",
          hotel_document_accom_reservation_change_detail_example_cancellationPolicy: "{{Dasar pembatalan}}",
          hotel_document_accom_reservation_cancel_edit_title: "TEMPAHAN MENGIRINGI BATALKAN EMEL",
          hotel_document_accom_reservation_cancel_edit_select_kr: "KR",
          hotel_document_accom_reservation_cancel_edit_select_en: "EN",
          hotel_document_accom_reservation_cancel_edit_select_cn: "CN",
          hotel_document_accom_reservation_cancel_edit_select_jp: "JP",
          hotel_document_accom_reservation_cancel_edit_select_ms: "MS",
          hotel_document_accom_reservation_cancel_edit_button_remove: "keluarkan",
          hotel_document_accom_reservation_cancel_edit_button_append: "Lampirkan",
          hotel_document_accom_reservation_cancel_edit_button_save: "Jimat",
          hotel_document_accom_reservation_cancel_edit_toolbar_bold: "berani",
          hotel_document_accom_reservation_cancel_edit_toolbar_italic: "Italic",
          hotel_document_accom_reservation_cancel_edit_toolbar_underline: "Garis bawah",
          hotel_document_accom_reservation_cancel_edit_toolbar_strikethrough: "Strikethrough",
          hotel_document_accom_reservation_cancel_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_document_accom_reservation_cancel_edit_toolbar_font_family: "Keluarga Font",
          hotel_document_accom_reservation_cancel_edit_toolbar_font_size: "Saiz huruf",
          hotel_document_accom_reservation_cancel_edit_toolbar_text_color: "Warna Teks",
          hotel_document_accom_reservation_cancel_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_document_accom_reservation_cancel_edit_toolbar_recent_color: "Warna Terkini",
          hotel_document_accom_reservation_cancel_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_document_accom_reservation_cancel_edit_toolbar_table: "Jadual",
          hotel_document_accom_reservation_cancel_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_document_accom_reservation_cancel_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_document_accom_reservation_cancel_edit_toolbar_paragraph: "Perenggan",
          hotel_document_accom_reservation_cancel_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_document_accom_reservation_cancel_edit_toolbar_picture: "Gambar",
          hotel_document_accom_reservation_cancel_edit_toolbar_code_view: "Pandangan Kod",
          hotel_document_accom_reservation_cancel_edit_toolbar_full_screen: "Skrin penuh",
          hotel_document_accom_reservation_cancel_edit_toolbar_help: "Tolong",
          hotel_document_accom_reservation_cancel_edit_toolbar_tip: "Petua",
          hotel_document_accom_reservation_cancel_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_document_accom_reservation_cancel_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_document_accom_reservation_cancel_detail_title: "MENGIRINGI TEMPAHAN BATALKAN KANDUNGAN",
          hotel_document_accom_reservation_cancel_detail_label_confirmation_number: "Nombor pengesahan",
          hotel_document_accom_reservation_cancel_detail_example_confirmation_number: "{{nombor pengesahan}}",
          hotel_document_accom_reservation_cancel_detail_label_accom_first_name: "Iringi Nama Pertama",
          hotel_document_accom_reservation_cancel_detail_example_accom_first_name: "{{accompanyFirstName}}",
          hotel_document_accom_reservation_cancel_detail_label_accom_last_name: "Iringi Nama Akhir",
          hotel_document_accom_reservation_cancel_detail_example_accom_last_name: "{{accompanyLastName}}",
          hotel_document_accom_reservation_cancel_detail_label_first_name: "Nama pertama",
          hotel_document_accom_reservation_cancel_detail_example_first_name: "{{nama pertama}}",
          hotel_document_accom_reservation_cancel_detail_label_last_name: "Nama terakhir",
          hotel_document_accom_reservation_cancel_detail_example_last_name: "{{nama terakhir}}",
          hotel_document_accom_reservation_cancel_detail_label_room_type: "Jenis bilik",
          hotel_document_accom_reservation_cancel_detail_example_room_type: "{{jenis bilik}}",
          hotel_document_accom_reservation_cancel_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_document_accom_reservation_cancel_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_document_accom_reservation_cancel_detail_label_departure_date: "Tarikh berlepas",
          hotel_document_accom_reservation_cancel_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_document_accom_reservation_cancel_detail_label_accom_title: "Tajuk Iringan",
          hotel_document_accom_reservation_cancel_detail_example_accom_title: "{{accompanyTitle}}",
          hotel_document_accom_reservation_cancel_detail_label_adults: "Dewasa",
          hotel_document_accom_reservation_cancel_detail_example_adults: "{{dewasa}}",
          hotel_document_accom_reservation_cancel_detail_label_night: "Malam",
          hotel_document_accom_reservation_cancel_detail_example_night: "{{malam}}",
          hotel_document_accom_reservation_cancel_detail_label_room_rate: "Kadar bilik",
          hotel_document_accom_reservation_cancel_detail_example_room_rate: "{{kadar bilik}}",
          hotel_document_accom_reservation_cancel_detail_label_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_document_accom_reservation_cancel_detail_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_document_accom_reservation_cancel_detail_label_total_rate: "Jumlah Kadar",
          hotel_document_accom_reservation_cancel_detail_example_total_rate: "{{TotalRate}}",
          hotel_document_accom_reservation_cancel_detail_label_cancellation: "Polisi Pembatalan",
          hotel_document_accom_reservation_cancel_detail_example_cancellationPolicy: "{{Dasar pembatalan}}",
          hotel_hotel_reservation_accompany_pdf_edit_title: "FORMAT PDF TEMPAHAN MENGIRINGI",
          hotel_hotel_reservation_accompany_pdf_edit_select_kr: "KR",
          hotel_hotel_reservation_accompany_pdf_edit_select_en: "EN",
          hotel_hotel_reservation_accompany_pdf_edit_select_cn: "CN",
          hotel_hotel_reservation_accompany_pdf_edit_select_jp: "JP",
          hotel_hotel_reservation_accompany_pdf_edit_select_ms: "MS",
          hotel_hotel_reservation_accompany_pdf_edit_button_remove: "Alih keluar",
          hotel_hotel_reservation_accompany_pdf_edit_button_append: "Lampirkan",
          hotel_hotel_reservation_accompany_pdf_edit_button_save: "Jimat",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_bold: "berani",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_italic: "Italic",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_underline: "Garis bawah",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_strikethrough: "Strikethrough",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_font_family: "Keluarga Font",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_font_size: "Saiz huruf",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_text_color: "Warna Teks",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_recent_color: "Warna Terkini",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_table: "Jadual",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_paragraph: "Perenggan",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_picture: "Gambar",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_code_view: "Pandangan Kod",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_full_screen: "Skrin penuh",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_help: "Tolong",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_tip: "Petua",
          hotel_hotel_reservation_accompany_pdf_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_hotel_reservation_accompany_pdf_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_hotel_reservation_accompany_pdf_detail_title: "TEMPAHAN MENGIRINGI PDF KANDUNGAN",
          hotel_hotel_reservation_accompany_pdf_detail_label_confurmation_number: "Nombor pengesahan",
          hotel_hotel_reservation_accompany_pdf_detail_example_confurmation_number: "{{nombor pengesahan}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_reservation_id: "ID Tempahan",
          hotel_hotel_reservation_accompany_pdf_detail_example_reservation_id: "{{reservationID}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_hotel_reservation_accompany_pdf_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_departure_date: "Tarikh berlepas",
          hotel_hotel_reservation_accompany_pdf_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_room_type: "Jenis bilik",
          hotel_hotel_reservation_accompany_pdf_detail_example_room_type: "{{jenis bilik}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_hotel_reservation_accompany_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_room_rate: "Kadar bilik",
          hotel_hotel_reservation_accompany_pdf_detail_example_room_rate: "{{kadar bilik}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_total_rate: "Jumlah Kadar",
          hotel_hotel_reservation_accompany_pdf_detail_example_total_rate: "{{TotalRate}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_current_balance: "Baki terkini",
          hotel_hotel_reservation_accompany_pdf_detail_example_current_balance: "{{baki terkini}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_room_number: "Nombor bilik",
          hotel_hotel_reservation_accompany_pdf_detail_example_room_number: "{{nombor bilik}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_adults: "Dewasa",
          hotel_hotel_reservation_accompany_pdf_detail_example_adults: "{{dewasa}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_children: "Kanak-kanak",
          hotel_hotel_reservation_accompany_pdf_detail_example_children: "{{kanak-kanak}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_check_out_time: "Masa daftar keluar",
          hotel_hotel_reservation_accompany_pdf_detail_example_check_out_time: "{{masa daftar keluar}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_name_title: "Nama Tajuk",
          hotel_hotel_reservation_accompany_pdf_detail_example_name_title: "{{nameTitle}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_first_name: "Nama pertama",
          hotel_hotel_reservation_accompany_pdf_detail_example_first_name: "{{nama pertama}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_last_name: "Nama terakhir",
          hotel_hotel_reservation_accompany_pdf_detail_example_last_name: "{{nama terakhir}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_country_code: "Kod negara",
          hotel_hotel_reservation_accompany_pdf_detail_example_country_code: "{{kod negara}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_alternative_language_code: "Kod Bahasa Alternatif",
          hotel_hotel_reservation_accompany_pdf_detail_example_alternative_language_code: "{{AlternativeLanguageCode}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_alternative_first_name: "Nama Pertama Alternatif",
          hotel_hotel_reservation_accompany_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_alternative_last_name: "Nama Akhir Alternatif",
          hotel_hotel_reservation_accompany_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_phone: "Telefon Bimbit No.",
          hotel_hotel_reservation_accompany_pdf_detail_example_phone: "{{telefon}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_email: "E-mel",
          hotel_hotel_reservation_accompany_pdf_detail_example_email: "{{email}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_accompany_title: "Tajuk Iringan",
          hotel_hotel_reservation_accompany_pdf_detail_example_accompany_title: "{{accompanyTitle}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_accompany_first_name: "Iringi Nama Pertama",
          hotel_hotel_reservation_accompany_pdf_detail_example_accompany_first_name: "{{accompanyFirstName}}",
          hotel_hotel_reservation_accompany_pdf_detail_label_accompany_last_name: "Iringi Nama Akhir",
          hotel_hotel_reservation_accompany_pdf_detail_example_accompany_last_name: "{{accompanyLastName}}",
          hotel_hotel_folio_email_edit_title: "FORMAT E-mel FOLIO",
          hotel_hotel_folio_email_edit_select_kr: "KR",
          hotel_hotel_folio_email_edit_select_en: "EN",
          hotel_hotel_folio_email_edit_select_cn: "CN",
          hotel_hotel_folio_email_edit_select_jp: "JP",
          hotel_hotel_folio_email_edit_select_ms: "MS",
          hotel_hotel_folio_email_edit_button_remove: "keluarkan",
          hotel_hotel_folio_email_edit_button_append: "Lampirkan",
          hotel_hotel_folio_email_edit_button_save: "Jimat",
          hotel_hotel_folio_email_edit_toolbar_bold: "berani",
          hotel_hotel_folio_email_edit_toolbar_italic: "Italic",
          hotel_hotel_folio_email_edit_toolbar_underline: "Garis bawah",
          hotel_hotel_folio_email_edit_toolbar_strikethrough: "Strikethrough",
          hotel_hotel_folio_email_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_hotel_folio_email_edit_toolbar_font_family: "Keluarga Font",
          hotel_hotel_folio_email_edit_toolbar_font_size: "Saiz huruf",
          hotel_hotel_folio_email_edit_toolbar_text_color: "Warna Teks",
          hotel_hotel_folio_email_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_hotel_folio_email_edit_toolbar_recent_color: "Warna Terkini",
          hotel_hotel_folio_email_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_hotel_folio_email_edit_toolbar_table: "Jadual",
          hotel_hotel_folio_email_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_hotel_folio_email_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_hotel_folio_email_edit_toolbar_paragraph: "Perenggan",
          hotel_hotel_folio_email_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_hotel_folio_email_edit_toolbar_picture: "Gambar",
          hotel_hotel_folio_email_edit_toolbar_code_view: "Pandangan Kod",
          hotel_hotel_folio_email_edit_toolbar_full_screen: "Skrin penuh",
          hotel_hotel_folio_email_edit_toolbar_help: "Tolong",
          hotel_hotel_folio_email_edit_toolbar_tip: "Petua",
          hotel_hotel_folio_email_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_hotel_folio_email_detail_title: "KANDUNGAN E-MEL FOLIO",
          hotel_hotel_folio_email_detail_label_confurmation_number: "Nombor pengesahan",
          hotel_hotel_folio_email_detail_example_confurmation_number: "{{nombor pengesahan}}",
          hotel_hotel_folio_email_detail_label_reservation_id: "ID Tempahan",
          hotel_hotel_folio_email_detail_example_reservation_id: "{{reservationID}}",
          hotel_hotel_folio_email_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_hotel_folio_email_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_hotel_folio_email_detail_label_departure_date: "Tarikh berlepas",
          hotel_hotel_folio_email_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_hotel_folio_email_detail_label_room_type: "Jenis bilik",
          hotel_hotel_folio_email_detail_example_room_type: "{{jenis bilik}}",
          hotel_hotel_folio_email_detail_label_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_hotel_folio_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_hotel_folio_email_detail_label_room_rate: "Kadar bilik",
          hotel_hotel_folio_email_detail_example_room_rate: "{{kadar bilik}}",
          hotel_hotel_folio_email_detail_label_total_rate: "Jumlah Kadar",
          hotel_hotel_folio_email_detail_example_total_rate: "{{TotalRate}}",
          hotel_hotel_folio_email_detail_label_current_balance: "Baki terkini",
          hotel_hotel_folio_email_detail_example_current_balance: "{{baki terkini}}",
          hotel_hotel_folio_email_detail_label_room_number: "Nombor bilik",
          hotel_hotel_folio_email_detail_example_room_number: "{{nombor bilik}}",
          hotel_hotel_folio_email_detail_label_adults: "Dewasa",
          hotel_hotel_folio_email_detail_example_adults: "{{dewasa}}",
          hotel_hotel_folio_email_detail_label_children: "Kanak-kanak",
          hotel_hotel_folio_email_detail_example_children: "{{kanak-kanak}}",
          hotel_hotel_folio_email_detail_label_check_out_time: "Masa daftar keluar",
          hotel_hotel_folio_email_detail_example_check_out_time: "{{masa daftar keluar}}",
          hotel_hotel_folio_email_detail_label_name_title: "Nama Tajuk",
          hotel_hotel_folio_email_detail_example_name_title: "{{nameTitle}}",
          hotel_hotel_folio_email_detail_label_first_name: "Nama pertama",
          hotel_hotel_folio_email_detail_example_first_name: "{{nama pertama}}",
          hotel_hotel_folio_email_detail_label_last_name: "Nama terakhir",
          hotel_hotel_folio_email_detail_example_last_name: "{{nama terakhir}}",
          hotel_hotel_folio_email_detail_label_country_code: "Kod negara",
          hotel_hotel_folio_email_detail_example_country_code: "{{kod negara}}",
          hotel_hotel_folio_email_detail_label_alternative_language_code: "Kod Bahasa Alternatif",
          hotel_hotel_folio_email_detail_example_alternative_language_code: "{{AlternativeLanguageCode}}",
          hotel_hotel_folio_email_detail_label_alternative_first_name: "Nama Pertama Alternatif",
          hotel_hotel_folio_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
          hotel_hotel_folio_email_detail_label_alternative_last_name: "Nama Akhir Alternatif",
          hotel_hotel_folio_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
          hotel_hotel_folio_email_detail_label_phone: "Telefon Bimbit No.",
          hotel_hotel_folio_email_detail_example_phone: "{{telefon}}",
          hotel_hotel_folio_email_detail_label_email: "E-mel",
          hotel_hotel_folio_email_detail_example_email: "{{email}}",
          hotel_hotel_folio_email_detail_label_text_area: "Kawasan Teks Folio",
          hotel_hotel_folio_email_detail_example_text_area: "{{folio_text_area}}",
          hotel_hotel_enroll_profile_edit_title: "DAFTAR FORMAT PROFIL",
          hotel_hotel_enroll_profile_edit_select_kr: "KR",
          hotel_hotel_enroll_profile_edit_select_en: "EN",
          hotel_hotel_enroll_profile_edit_select_cn: "CN",
          hotel_hotel_enroll_profile_edit_select_jp: "JP",
          hotel_hotel_enroll_profile_edit_select_ms: "MS",
          hotel_hotel_enroll_profile_edit_button_remove: "keluarkan",
          hotel_hotel_enroll_profile_edit_button_append: "Lampirkan",
          hotel_hotel_enroll_profile_edit_button_save: "Jimat",
          hotel_hotel_enroll_profile_edit_toolbar_bold: "berani",
          hotel_hotel_enroll_profile_edit_toolbar_italic: "Italic",
          hotel_hotel_enroll_profile_edit_toolbar_underline: "Garis bawah",
          hotel_hotel_enroll_profile_edit_toolbar_strikethrough: "Strikethrough",
          hotel_hotel_enroll_profile_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_hotel_enroll_profile_edit_toolbar_font_family: "Keluarga Font",
          hotel_hotel_enroll_profile_edit_toolbar_font_size: "Saiz huruf",
          hotel_hotel_enroll_profile_edit_toolbar_text_color: "Warna Teks",
          hotel_hotel_enroll_profile_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_hotel_enroll_profile_edit_toolbar_recent_color: "Warna Terkini",
          hotel_hotel_enroll_profile_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_hotel_enroll_profile_edit_toolbar_table: "Jadual",
          hotel_hotel_enroll_profile_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_hotel_enroll_profile_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_hotel_enroll_profile_edit_toolbar_paragraph: "Perenggan",
          hotel_hotel_enroll_profile_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_hotel_enroll_profile_edit_toolbar_picture: "Gambar",
          hotel_hotel_enroll_profile_edit_toolbar_code_view: "Pandangan Kod",
          hotel_hotel_enroll_profile_edit_toolbar_full_screen: "Skrin penuh",
          hotel_hotel_enroll_profile_edit_toolbar_help: "Tolong",
          hotel_hotel_enroll_profile_edit_toolbar_tip: "Petua",
          hotel_hotel_enroll_profile_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_hotel_enroll_profile_detail_title: "DAFTAR KANDUNGAN PROFIL",
          hotel_hotel_enroll_profile_detail_label_email: "E-mel",
          hotel_hotel_enroll_profile_detail_example_email: "{{email}}",
          hotel_hotel_enroll_profile_detail_label_hotel_name: "Nama Hotel",
          hotel_hotel_enroll_profile_detail_example_hotel_name: "{{hotelName}}",
          hotel_hotel_enroll_profile_detail_label_title: "Tajuk",
          hotel_hotel_enroll_profile_detail_example_title: "{{tajuk}}",
          hotel_hotel_enroll_profile_detail_label_first_name: "Nama pertama",
          hotel_hotel_enroll_profile_detail_example_first_name: "{{nama pertama}}",
          hotel_hotel_enroll_profile_detail_label_last_name: "Nama terakhir",
          hotel_hotel_enroll_profile_detail_example_last_name: "{{nama terakhir}}",
          hotel_hotel_enroll_profile_detail_label_alternative_first_name: "Nama Pertama Alternatif",
          hotel_hotel_enroll_profile_detail_example_alternative_first_name: "{{alternativeFirstName}}",
          hotel_hotel_enroll_profile_detail_label_alternative_last_name: "Nama Akhir Alternatif",
          hotel_hotel_enroll_profile_detail_example_alternative_last_name: "{{alternativeLastName}}",
          hotel_hotel_enroll_profile_detail_label_mobile_number: "Nombor telefon",
          hotel_hotel_enroll_profile_detail_example_mobile_number: "{{nombor telefon}}",
          hotel_hotel_folio_pdf_edit_title: "FORMAT PDF FOLIO",
          hotel_hotel_folio_pdf_edit_select_kr: "KR",
          hotel_hotel_folio_pdf_edit_select_en: "EN",
          hotel_hotel_folio_pdf_edit_select_cn: "CN",
          hotel_hotel_folio_pdf_edit_select_jp: "JP",
          hotel_hotel_folio_pdf_edit_select_ms: "MS",
          hotel_hotel_folio_pdf_edit_button_remove: "keluarkan",
          hotel_hotel_folio_pdf_edit_button_append: "Lampirkan",
          hotel_hotel_folio_pdf_edit_button_save: "Jimat",
          hotel_hotel_folio_pdf_edit_toolbar_bold: "berani",
          hotel_hotel_folio_pdf_edit_toolbar_italic: "Italic",
          hotel_hotel_folio_pdf_edit_toolbar_underline: "Garis bawah",
          hotel_hotel_folio_pdf_edit_toolbar_strikethrough: "Strikethrough",
          hotel_hotel_folio_pdf_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_hotel_folio_pdf_edit_toolbar_font_family: "Keluarga Font",
          hotel_hotel_folio_pdf_edit_toolbar_font_size: "Saiz huruf",
          hotel_hotel_folio_pdf_edit_toolbar_text_color: "Warna Teks",
          hotel_hotel_folio_pdf_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_hotel_folio_pdf_edit_toolbar_recent_color: "Warna Terkini",
          hotel_hotel_folio_pdf_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_hotel_folio_pdf_edit_toolbar_table: "Jadual",
          hotel_hotel_folio_pdf_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_hotel_folio_pdf_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_hotel_folio_pdf_edit_toolbar_paragraph: "Perenggan",
          hotel_hotel_folio_pdf_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_hotel_folio_pdf_edit_toolbar_picture: "Gambar",
          hotel_hotel_folio_pdf_edit_toolbar_code_view: "Pandangan Kod",
          hotel_hotel_folio_pdf_edit_toolbar_full_screen: "Skrin penuh",
          hotel_hotel_folio_pdf_edit_toolbar_help: "Tolong",
          hotel_hotel_folio_pdf_edit_toolbar_tip: "Petua",
          hotel_hotel_folio_pdf_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_hotel_folio_pdf_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_hotel_folio_pdf_detail_title: "KANDUNGAN FOLIO PDF",
          hotel_hotel_folio_pdf_detail_label_confurmation_number: "Nombor pengesahan",
          hotel_hotel_folio_pdf_detail_example_confurmation_number: "{{nombor pengesahan}}",
          hotel_hotel_folio_pdf_detail_label_reservation_id: "ID Tempahan",
          hotel_hotel_folio_pdf_detail_example_reservation_id: "{{reservationID}}",
          hotel_hotel_folio_pdf_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_hotel_folio_pdf_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_hotel_folio_pdf_detail_label_departure_date: "Tarikh berlepas",
          hotel_hotel_folio_pdf_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_hotel_folio_pdf_detail_label_room_type: "Jenis bilik",
          hotel_hotel_folio_pdf_detail_example_room_type: "{{jenis bilik}}",
          hotel_hotel_folio_pdf_detail_label_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_hotel_folio_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_hotel_folio_pdf_detail_label_room_rate: "Kadar bilik",
          hotel_hotel_folio_pdf_detail_example_room_rate: "{{kadar bilik}}",
          hotel_hotel_folio_pdf_detail_label_total_rate: "Jumlah Kadar",
          hotel_hotel_folio_pdf_detail_example_total_rate: "{{TotalRate}}",
          hotel_hotel_folio_pdf_detail_label_current_balance: "Baki terkini",
          hotel_hotel_folio_pdf_detail_example_current_balance: "{{baki terkini}}",
          hotel_hotel_folio_pdf_detail_label_room_number: "Nombor bilik",
          hotel_hotel_folio_pdf_detail_example_room_number: "{{nombor bilik}}",
          hotel_hotel_folio_pdf_detail_label_adults: "Dewasa",
          hotel_hotel_folio_pdf_detail_example_adults: "{{dewasa}}",
          hotel_hotel_folio_pdf_detail_label_children: "Kanak-kanak",
          hotel_hotel_folio_pdf_detail_example_children: "{{kanak-kanak}}",
          hotel_hotel_folio_pdf_detail_label_check_out_time: "Masa daftar keluar",
          hotel_hotel_folio_pdf_detail_example_check_out_time: "{{masa daftar keluar}}",
          hotel_hotel_folio_pdf_detail_label_name_title: "Nama Tajuk",
          hotel_hotel_folio_pdf_detail_example_name_title: "{{nameTitle}}",
          hotel_hotel_folio_pdf_detail_label_first_name: "Nama pertama",
          hotel_hotel_folio_pdf_detail_example_first_name: "{{nama pertama}}",
          hotel_hotel_folio_pdf_detail_label_last_name: "Nama terakhir",
          hotel_hotel_folio_pdf_detail_example_last_name: "{{nama terakhir}}",
          hotel_hotel_folio_pdf_detail_label_country_code: "Kod negara",
          hotel_hotel_folio_pdf_detail_example_country_code: "{{kod negara}}",
          hotel_hotel_folio_pdf_detail_label_alternative_language_code: "Kod Bahasa Alternatif",
          hotel_hotel_folio_pdf_detail_example_alternative_language_code: "{{AlternativeLanguageCode}}",
          hotel_hotel_folio_pdf_detail_label_alternative_first_name: "Nama Pertama Alternatif",
          hotel_hotel_folio_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
          hotel_hotel_folio_pdf_detail_label_alternative_last_name: "Nama Akhir Alternatif",
          hotel_hotel_folio_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
          hotel_hotel_folio_pdf_detail_label_phone: "Telefon Bimbit No.",
          hotel_hotel_folio_pdf_detail_example_phone: "{{telefon}}",
          hotel_hotel_folio_pdf_detail_label_email: "E-mel",
          hotel_hotel_folio_pdf_detail_example_email: "{{email}}",
          hotel_hotel_folio_pdf_detail_label_text_area: "Kawasan Teks Folio",
          hotel_hotel_folio_pdf_detail_example_text_area: "{{folio_text_area}}",
          hotel_future_booking_change_email_edit_title: "PERUBAHAN TEMPAHAN MASA DEPAN E-mel",
          hotel_future_booking_change_email_edit_select_kr: "KR",
          hotel_future_booking_change_email_edit_select_en: "EN",
          hotel_future_booking_change_email_edit_select_cn: "CN",
          hotel_future_booking_change_email_edit_select_jp: "JP",
          hotel_future_booking_change_email_edit_select_ms: "MS",
          hotel_future_booking_change_email_edit_button_remove: "keluarkan",
          hotel_future_booking_change_email_edit_button_append: "Lampirkan",
          hotel_future_booking_change_email_edit_button_save: "Jimat",
          hotel_future_booking_change_email_edit_toolbar_bold: "berani",
          hotel_future_booking_change_email_edit_toolbar_italic: "Italic",
          hotel_future_booking_change_email_edit_toolbar_underline: "Garis bawah",
          hotel_future_booking_change_email_edit_toolbar_strikethrough: "Strikethrough",
          hotel_future_booking_change_email_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_future_booking_change_email_edit_toolbar_font_family: "Keluarga Font",
          hotel_future_booking_change_email_edit_toolbar_font_size: "Saiz huruf",
          hotel_future_booking_change_email_edit_toolbar_text_color: "Warna Teks",
          hotel_future_booking_change_email_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_future_booking_change_email_edit_toolbar_recent_color: "Warna Terkini",
          hotel_future_booking_change_email_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_future_booking_change_email_edit_toolbar_table: "Jadual",
          hotel_future_booking_change_email_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_future_booking_change_email_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_future_booking_change_email_edit_toolbar_paragraph: "Perenggan",
          hotel_future_booking_change_email_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_future_booking_change_email_edit_toolbar_picture: "Gambar",
          hotel_future_booking_change_email_edit_toolbar_code_view: "Pandangan Kod",
          hotel_future_booking_change_email_edit_toolbar_full_screen: "Skrin penuh",
          hotel_future_booking_change_email_edit_toolbar_help: "Tolong",
          hotel_future_booking_change_email_edit_toolbar_tip: "Petua",
          hotel_future_booking_change_email_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_future_booking_change_email_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_future_booking_change_email_detail_title: "UBAH KANDUNGAN TEMPAHAN MASA DEPAN",
          hotel_future_booking_change_email_detail_label_confirmation_number: "Nombor pengesahan",
          hotel_future_booking_change_email_detail_example_confirmation_number: "{{nombor pengesahan}}",
          hotel_future_booking_change_email_detail_label_first_name: "Nama pertama",
          hotel_future_booking_change_email_detail_example_first_name: "{{nama pertama}}",
          hotel_future_booking_change_email_detail_label_last_name: "Nama terakhir",
          hotel_future_booking_change_email_detail_example_last_name: "{{nama terakhir}}",
          hotel_future_booking_change_email_detail_label_room_type: "Jenis bilik",
          hotel_future_booking_change_email_detail_example_room_type: "{{jenis bilik}}",
          hotel_future_booking_change_email_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_future_booking_change_email_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_future_booking_change_email_detail_label_departure_date: "Tarikh berlepas",
          hotel_future_booking_change_email_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_future_booking_change_email_detail_label_name_title: "Nama Tajuk",
          hotel_future_booking_change_email_detail_example_name_title: "{{nameTitle}}",
          hotel_future_booking_change_email_detail_label_adults: "Dewasa",
          hotel_future_booking_change_email_detail_example_adults: "{{dewasa}}",
          hotel_future_booking_change_email_detail_label_night: "Malam",
          hotel_future_booking_change_email_detail_example_night: "{{malam}}",
          hotel_future_booking_change_email_detail_label_room_rate: "Kadar bilik",
          hotel_future_booking_change_email_detail_example_room_rate: "{{kadar bilik}}",
          hotel_future_booking_change_email_detail_label_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_future_booking_change_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_future_booking_change_email_detail_label_total_rate: "Jumlah Kadar",
          hotel_future_booking_change_email_detail_example_total_rate: "{{TotalRate}}",
          hotel_future_booking_change_email_detail_label_cancellation: "Polisi Pembatalan",
          hotel_future_booking_change_email_detail_example_cancellationPolicy: "{{Dasar pembatalan}}",
          hotel_future_booking_cancel_email_edit_title: "BATALKAN TEMPAHAN MASA DEPAN E-mel",
          hotel_future_booking_cancel_email_edit_select_kr: "KR",
          hotel_future_booking_cancel_email_edit_select_en: "EN",
          hotel_future_booking_cancel_email_edit_select_cn: "CN",
          hotel_future_booking_cancel_email_edit_select_jp: "JP",
          hotel_future_booking_cancel_email_edit_select_ms: "MS",
          hotel_future_booking_cancel_email_edit_button_remove: "keluarkan",
          hotel_future_booking_cancel_email_edit_button_append: "Lampirkan",
          hotel_future_booking_cancel_email_edit_button_save: "Jimat",
          hotel_future_booking_cancel_email_edit_toolbar_bold: "berani",
          hotel_future_booking_cancel_email_edit_toolbar_italic: "Italic",
          hotel_future_booking_cancel_email_edit_toolbar_underline: "Garis bawah",
          hotel_future_booking_cancel_email_edit_toolbar_strikethrough: "Strikethrough",
          hotel_future_booking_cancel_email_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_future_booking_cancel_email_edit_toolbar_font_family: "Keluarga Font",
          hotel_future_booking_cancel_email_edit_toolbar_font_size: "Saiz huruf",
          hotel_future_booking_cancel_email_edit_toolbar_text_color: "Warna Teks",
          hotel_future_booking_cancel_email_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_future_booking_cancel_email_edit_toolbar_recent_color: "Warna Terkini",
          hotel_future_booking_cancel_email_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_future_booking_cancel_email_edit_toolbar_table: "Jadual",
          hotel_future_booking_cancel_email_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_future_booking_cancel_email_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_future_booking_cancel_email_edit_toolbar_paragraph: "Perenggan",
          hotel_future_booking_cancel_email_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_future_booking_cancel_email_edit_toolbar_picture: "Gambar",
          hotel_future_booking_cancel_email_edit_toolbar_code_view: "Pandangan Kod",
          hotel_future_booking_cancel_email_edit_toolbar_full_screen: "Skrin penuh",
          hotel_future_booking_cancel_email_edit_toolbar_help: "Tolong",
          hotel_future_booking_cancel_email_edit_toolbar_tip: "Petua",
          hotel_future_booking_cancel_email_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_future_booking_cancel_email_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_future_booking_cancel_email_detail_title: "BATALKAN KANDUNGAN TEMPAHAN MASA DEPAN",
          hotel_future_booking_cancel_email_detail_label_confirmation_number: "Nombor pengesahan",
          hotel_future_booking_cancel_email_detail_example_confirmation_number: "{{nombor pengesahan}}",
          hotel_future_booking_cancel_email_detail_label_first_name: "Nama pertama",
          hotel_future_booking_cancel_email_detail_example_first_name: "{{nama pertama}}",
          hotel_future_booking_cancel_email_detail_label_last_name: "Nama terakhir",
          hotel_future_booking_cancel_email_detail_example_last_name: "{{nama terakhir}}",
          hotel_future_booking_cancel_email_detail_label_room_type: "Jenis bilik",
          hotel_future_booking_cancel_email_detail_example_room_type: "{{jenis bilik}}",
          hotel_future_booking_cancel_email_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_future_booking_cancel_email_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_future_booking_cancel_email_detail_label_departure_date: "Tarikh berlepas",
          hotel_future_booking_cancel_email_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_future_booking_cancel_email_detail_label_name_title: "Nama Tajuk",
          hotel_future_booking_cancel_email_detail_example_name_title: "{{nameTitle}}",
          hotel_hotel_no_show_email_edit_title: "TEMPAHAN MASA DEPAN E-mel TIDAK TAMPIL",
          hotel_hotel_no_show_email_edit_select_kr: "KR",
          hotel_hotel_no_show_email_edit_select_en: "EN",
          hotel_hotel_no_show_email_edit_select_cn: "CN",
          hotel_hotel_no_show_email_edit_select_jp: "JP",
          hotel_hotel_no_show_email_edit_select_ms: "MS",
          hotel_hotel_no_show_email_edit_button_remove: "keluarkan",
          hotel_hotel_no_show_email_edit_button_append: "Lampirkan",
          hotel_hotel_no_show_email_edit_button_save: "Jimat",
          hotel_hotel_no_show_email_edit_toolbar_bold: "berani",
          hotel_hotel_no_show_email_edit_toolbar_italic: "Italic",
          hotel_hotel_no_show_email_edit_toolbar_underline: "Garis bawah",
          hotel_hotel_no_show_email_edit_toolbar_strikethrough: "Strikethrough",
          hotel_hotel_no_show_email_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_hotel_no_show_email_edit_toolbar_font_family: "Keluarga Font",
          hotel_hotel_no_show_email_edit_toolbar_font_size: "Saiz huruf",
          hotel_hotel_no_show_email_edit_toolbar_text_color: "Warna Teks",
          hotel_hotel_no_show_email_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_hotel_no_show_email_edit_toolbar_recent_color: "Warna Terkini",
          hotel_hotel_no_show_email_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_hotel_no_show_email_edit_toolbar_table: "Jadual",
          hotel_hotel_no_show_email_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_hotel_no_show_email_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_hotel_no_show_email_edit_toolbar_paragraph: "Perenggan",
          hotel_hotel_no_show_email_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_hotel_no_show_email_edit_toolbar_picture: "Gambar",
          hotel_hotel_no_show_email_edit_toolbar_code_view: "Pandangan Kod",
          hotel_hotel_no_show_email_edit_toolbar_full_screen: "Skrin penuh",
          hotel_hotel_no_show_email_edit_toolbar_help: "Tolong",
          hotel_hotel_no_show_email_edit_toolbar_tip: "Petua",
          hotel_hotel_no_show_email_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_hotel_no_show_email_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_hotel_no_show_email_detail_title: "BATALKAN KANDUNGAN TEMPAHAN MASA DEPAN",
          hotel_hotel_no_show_email_detail_label_confirmation_number: "Nombor pengesahan",
          hotel_hotel_no_show_email_detail_example_confirmation_number: "{{nombor pengesahan}}",
          hotel_hotel_no_show_email_detail_label_reservation_id: "ID Tempahan",
          hotel_hotel_no_show_email_detail_example_reservation_id: "{{reservationID}}",
          hotel_hotel_no_show_email_detail_label_first_name: "Nama pertama",
          hotel_hotel_no_show_email_detail_example_first_name: "{{nama pertama}}",
          hotel_hotel_no_show_email_detail_label_last_name: "Nama terakhir",
          hotel_hotel_no_show_email_detail_example_last_name: "{{nama terakhir}}",
          hotel_hotel_no_show_email_detail_label_room_type: "Jenis bilik",
          hotel_hotel_no_show_email_detail_example_room_type: "{{jenis bilik}}",
          hotel_hotel_no_show_email_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_hotel_no_show_email_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_hotel_no_show_email_detail_label_departure_date: "Tarikh berlepas",
          hotel_hotel_no_show_email_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_hotel_no_show_email_detail_label_name_title: "Nama Tajuk",
          hotel_hotel_no_show_email_detail_example_name_title: "{{nameTitle}}",
          hotel_hotel_no_show_email_detail_label_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_hotel_no_show_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_hotel_no_show_email_detail_label_room_rate: "Kadar bilik",
          hotel_hotel_no_show_email_detail_example_room_rate: "{{kadar bilik}}",
          hotel_hotel_no_show_email_detail_label_total_rate: "Jumlah Kadar",
          hotel_hotel_no_show_email_detail_example_total_rate: "{{TotalRate}}",
          hotel_hotel_no_show_email_detail_label_current_balance: "Baki terkini",
          hotel_hotel_no_show_email_detail_example_current_balance: "{{baki terkini}}",
          hotel_hotel_no_show_email_detail_label_room_number: "Nombor bilik",
          hotel_hotel_no_show_email_detail_example_room_number: "{{nombor bilik}}",
          hotel_hotel_no_show_email_detail_label_adults: "Dewasa",
          hotel_hotel_no_show_email_detail_example_adults: "{{dewasa}}",
          hotel_hotel_no_show_email_detail_label_children: "Kanak-kanak",
          hotel_hotel_no_show_email_detail_example_children: "{{kanak-kanak}}",
          hotel_hotel_no_show_email_detail_label_check_out_time: "Masa daftar keluar",
          hotel_hotel_no_show_email_detail_example_check_out_time: "{{masa daftar keluar}}",
          hotel_hotel_no_show_email_detail_label_country_code: "Kod negara",
          hotel_hotel_no_show_email_detail_example_country_code: "{{kod negara}}",
          hotel_hotel_no_show_email_detail_label_alternative_language_code: "Kod Bahasa Alternatif",
          hotel_hotel_no_show_email_detail_example_alternative_language_code: "{{AlternativeLanguageCode}}",
          hotel_hotel_no_show_email_detail_label_alternative_first_name: "Nama Pertama Alternatif",
          hotel_hotel_no_show_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
          hotel_hotel_no_show_email_detail_label_alternative_last_name: "Nama Akhir Alternatif",
          hotel_hotel_no_show_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
          hotel_hotel_no_show_email_detail_label_phone: "telefon",
          hotel_hotel_no_show_email_detail_example_phone: "{{telefon}}",
          hotel_hotel_no_show_email_detail_label_email: "E-mel",
          hotel_hotel_no_show_email_detail_example_email: "{{email}}",
          hotel_hotel_no_show_email_detail_label_accompany: "menemani",
          hotel_hotel_no_show_email_detail_example_accompany: "{{menemani}}",
          hotel_future_booking_pack_add_email_edit_title: "PAKEJ TEMPAHAN MASA DEPAN TAMBAH E-mel",
          hotel_future_booking_pack_add_email_edit_select_kr: "KR",
          hotel_future_booking_pack_add_email_edit_select_en: "EN",
          hotel_future_booking_pack_add_email_edit_select_cn: "CN",
          hotel_future_booking_pack_add_email_edit_select_jp: "JP",
          hotel_future_booking_pack_add_email_edit_select_ms: "MS",
          hotel_future_booking_pack_add_email_edit_button_remove: "keluarkan",
          hotel_future_booking_pack_add_email_edit_button_append: "Lampirkan",
          hotel_future_booking_pack_add_email_edit_button_save: "Jimat",
          hotel_future_booking_pack_add_email_edit_toolbar_bold: "berani",
          hotel_future_booking_pack_add_email_edit_toolbar_italic: "Italic",
          hotel_future_booking_pack_add_email_edit_toolbar_underline: "Garis bawah",
          hotel_future_booking_pack_add_email_edit_toolbar_strikethrough: "Strikethrough",
          hotel_future_booking_pack_add_email_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_future_booking_pack_add_email_edit_toolbar_font_family: "Keluarga Font",
          hotel_future_booking_pack_add_email_edit_toolbar_font_size: "Saiz huruf",
          hotel_future_booking_pack_add_email_edit_toolbar_text_color: "Warna Teks",
          hotel_future_booking_pack_add_email_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_future_booking_pack_add_email_edit_toolbar_recent_color: "Warna Terkini",
          hotel_future_booking_pack_add_email_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_future_booking_pack_add_email_edit_toolbar_table: "Jadual",
          hotel_future_booking_pack_add_email_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_future_booking_pack_add_email_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_future_booking_pack_add_email_edit_toolbar_paragraph: "Perenggan",
          hotel_future_booking_pack_add_email_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_future_booking_pack_add_email_edit_toolbar_picture: "Gambar",
          hotel_future_booking_pack_add_email_edit_toolbar_code_view: "Pandangan Kod",
          hotel_future_booking_pack_add_email_edit_toolbar_full_screen: "Skrin penuh",
          hotel_future_booking_pack_add_email_edit_toolbar_help: "Tolong",
          hotel_future_booking_pack_add_email_edit_toolbar_tip: "Petua",
          hotel_future_booking_pack_add_email_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_future_booking_pack_add_email_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_future_booking_pack_add_email_detail_title: "TAMBAHKAN KANDUNGAN PAKEJ TEMPAHAN MASA DEPAN",
          hotel_future_booking_pack_add_email_detail_label_confirmation_number: "Nombor pengesahan",
          hotel_future_booking_pack_add_email_detail_example_confirmation_number: "{{nombor pengesahan}}",
          hotel_future_booking_pack_add_email_detail_label_first_name: "Nama pertama",
          hotel_future_booking_pack_add_email_detail_example_first_name: "{{nama pertama}}",
          hotel_future_booking_pack_add_email_detail_label_last_name: "Nama terakhir",
          hotel_future_booking_pack_add_email_detail_example_last_name: "{{nama terakhir}}",
          hotel_future_booking_pack_add_email_detail_label_room_type: "Jenis bilik",
          hotel_future_booking_pack_add_email_detail_example_room_type: "{{jenis bilik}}",
          hotel_future_booking_pack_add_email_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_future_booking_pack_add_email_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_future_booking_pack_add_email_detail_label_departure_date: "Tarikh berlepas",
          hotel_future_booking_pack_add_email_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_future_booking_pack_add_email_detail_label_name_title: "Nama Tajuk",
          hotel_future_booking_pack_add_email_detail_example_name_title: "{{nameTitle}}",
          hotel_future_booking_pack_add_email_detail_label_package_code: "Kod Pakej",
          hotel_future_booking_pack_add_email_detail_example_package_code: "{{Kod pakej}}",
          hotel_future_booking_pack_add_email_detail_label_package_description: "Penerangan Pakej",
          hotel_future_booking_pack_add_email_detail_example_package_description: "{{packageDescription}}",
          hotel_future_booking_pack_add_email_detail_label_amount: "Jumlah",
          hotel_future_booking_pack_add_email_detail_example_amount: "{{jumlah}}",
          hotel_future_booking_pack_add_email_detail_label_package_text_area: "Kawasan Teks Pakej",
          hotel_future_booking_pack_add_email_detail_example_package_text_area: "{{pakej_teks_kawasan}}",
          hotel_future_booking_pack_delete_email_edit_title: "PAKEJ TEMPAHAN MASA DEPAN PADAM E-mel",
          hotel_future_booking_pack_delete_email_edit_select_kr: "KR",
          hotel_future_booking_pack_delete_email_edit_select_en: "EN",
          hotel_future_booking_pack_delete_email_edit_select_cn: "CN",
          hotel_future_booking_pack_delete_email_edit_select_jp: "JP",
          hotel_future_booking_pack_delete_email_edit_select_ms: "MS",
          hotel_future_booking_pack_delete_email_edit_button_remove: "keluarkan",
          hotel_future_booking_pack_delete_email_edit_button_append: "Lampirkan",
          hotel_future_booking_pack_delete_email_edit_button_save: "Jimat",
          hotel_future_booking_pack_delete_email_edit_toolbar_bold: "berani",
          hotel_future_booking_pack_delete_email_edit_toolbar_italic: "Italic",
          hotel_future_booking_pack_delete_email_edit_toolbar_underline: "Garis bawah",
          hotel_future_booking_pack_delete_email_edit_toolbar_strikethrough: "Strikethrough",
          hotel_future_booking_pack_delete_email_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_future_booking_pack_delete_email_edit_toolbar_font_family: "Keluarga Font",
          hotel_future_booking_pack_delete_email_edit_toolbar_font_size: "Saiz huruf",
          hotel_future_booking_pack_delete_email_edit_toolbar_text_color: "Warna Teks",
          hotel_future_booking_pack_delete_email_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_future_booking_pack_delete_email_edit_toolbar_recent_color: "Warna Terkini",
          hotel_future_booking_pack_delete_email_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_future_booking_pack_delete_email_edit_toolbar_table: "Jadual",
          hotel_future_booking_pack_delete_email_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_future_booking_pack_delete_email_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_future_booking_pack_delete_email_edit_toolbar_paragraph: "Perenggan",
          hotel_future_booking_pack_delete_email_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_future_booking_pack_delete_email_edit_toolbar_picture: "Gambar",
          hotel_future_booking_pack_delete_email_edit_toolbar_code_view: "Pandangan Kod",
          hotel_future_booking_pack_delete_email_edit_toolbar_full_screen: "Skrin penuh",
          hotel_future_booking_pack_delete_email_edit_toolbar_help: "Tolong",
          hotel_future_booking_pack_delete_email_edit_toolbar_tip: "Petua",
          hotel_future_booking_pack_delete_email_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_future_booking_pack_delete_email_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_future_booking_pack_delete_email_detail_title: "PADAMKAN KANDUNGAN PAKEJ TEMPAHAN MASA DEPAN",
          hotel_future_booking_pack_delete_email_detail_label_confirmation_number: "Nombor pengesahan",
          hotel_future_booking_pack_delete_email_detail_example_confirmation_number: "{{nombor pengesahan}}",
          hotel_future_booking_pack_delete_email_detail_label_first_name: "Nama pertama",
          hotel_future_booking_pack_delete_email_detail_example_first_name: "{{nama pertama}}",
          hotel_future_booking_pack_delete_email_detail_label_last_name: "Nama terakhir",
          hotel_future_booking_pack_delete_email_detail_example_last_name: "{{nama terakhir}}",
          hotel_future_booking_pack_delete_email_detail_label_room_type: "Jenis bilik",
          hotel_future_booking_pack_delete_email_detail_example_room_type: "{{jenis bilik}}",
          hotel_future_booking_pack_delete_email_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_future_booking_pack_delete_email_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_future_booking_pack_delete_email_detail_label_departure_date: "Tarikh berlepas",
          hotel_future_booking_pack_delete_email_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_future_booking_pack_delete_email_detail_label_name_title: "Nama Tajuk",
          hotel_future_booking_pack_delete_email_detail_example_name_title: "{{nameTitle}}",
          hotel_future_booking_pack_delete_email_detail_label_package_code: "Kod Pakej",
          hotel_future_booking_pack_delete_email_detail_example_package_code: "{{Kod pakej}}",
          hotel_future_booking_pack_delete_email_detail_label_package_description: "Penerangan Pakej",
          hotel_future_booking_pack_delete_email_detail_example_package_description: "{{packageDescription}}",
          hotel_future_booking_pack_delete_email_detail_label_amount: "Jumlah",
          hotel_future_booking_pack_delete_email_detail_example_amount: "{{jumlah}}",
          hotel_future_booking_pack_delete_email_detail_label_package_text_area: "Kawasan Teks Pakej",
          hotel_future_booking_pack_delete_email_detail_example_package_text_area: "{{pakej_teks_kawasan}}",
          hotel_future_booking_accom_add_email_edit_title: "PENGIRING TEMPAHAN MASA DEPAN TAMBAH E-mel",
          hotel_future_booking_accom_add_email_edit_select_kr: "KR",
          hotel_future_booking_accom_add_email_edit_select_en: "EN",
          hotel_future_booking_accom_add_email_edit_select_cn: "CN",
          hotel_future_booking_accom_add_email_edit_select_jp: "JP",
          hotel_future_booking_accom_add_email_edit_select_ms: "MS",
          hotel_future_booking_accom_add_email_edit_button_remove: "keluarkan",
          hotel_future_booking_accom_add_email_edit_button_append: "Lampirkan",
          hotel_future_booking_accom_add_email_edit_button_save: "Jimat",
          hotel_future_booking_accom_add_email_edit_toolbar_bold: "berani",
          hotel_future_booking_accom_add_email_edit_toolbar_italic: "Italic",
          hotel_future_booking_accom_add_email_edit_toolbar_underline: "Garis bawah",
          hotel_future_booking_accom_add_email_edit_toolbar_strikethrough: "Strikethrough",
          hotel_future_booking_accom_add_email_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_future_booking_accom_add_email_edit_toolbar_font_family: "Keluarga Font",
          hotel_future_booking_accom_add_email_edit_toolbar_font_size: "Saiz huruf",
          hotel_future_booking_accom_add_email_edit_toolbar_text_color: "Warna Teks",
          hotel_future_booking_accom_add_email_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_future_booking_accom_add_email_edit_toolbar_recent_color: "Warna Terkini",
          hotel_future_booking_accom_add_email_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_future_booking_accom_add_email_edit_toolbar_table: "Jadual",
          hotel_future_booking_accom_add_email_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_future_booking_accom_add_email_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_future_booking_accom_add_email_edit_toolbar_paragraph: "Perenggan",
          hotel_future_booking_accom_add_email_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_future_booking_accom_add_email_edit_toolbar_picture: "Gambar",
          hotel_future_booking_accom_add_email_edit_toolbar_code_view: "Pandangan Kod",
          hotel_future_booking_accom_add_email_edit_toolbar_full_screen: "Skrin penuh",
          hotel_future_booking_accom_add_email_edit_toolbar_help: "Tolong",
          hotel_future_booking_accom_add_email_edit_toolbar_tip: "Petua",
          hotel_future_booking_accom_add_email_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_future_booking_accom_add_email_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_future_booking_accom_add_email_detail_title: "TAMBAHKAN KANDUNGAN PENGIRING TEMPAHAN MASA DEPAN",
          hotel_future_booking_accom_add_email_detail_label_confirmation_number: "Nombor pengesahan",
          hotel_future_booking_accom_add_email_detail_example_confirmation_number: "{{nombor pengesahan}}",
          hotel_future_booking_accom_add_email_detail_label_accom_first_name: "Iringi Nama Pertama",
          hotel_future_booking_accom_add_email_detail_example_accom_first_name: "{{accompanyFirstName}}",
          hotel_future_booking_accom_add_email_detail_label_accom_last_name: "Iringi Nama Akhir",
          hotel_future_booking_accom_add_email_detail_example_accom_last_name: "{{accompanyLastName}}",
          hotel_future_booking_accom_add_email_detail_label_first_name: "Nama pertama",
          hotel_future_booking_accom_add_email_detail_example_first_name: "{{nama pertama}}",
          hotel_future_booking_accom_add_email_detail_label_last_name: "Nama terakhir",
          hotel_future_booking_accom_add_email_detail_example_last_name: "{{nama terakhir}}",
          hotel_future_booking_accom_add_email_detail_label_room_type: "Jenis bilik",
          hotel_future_booking_accom_add_email_detail_example_room_type: "{{jenis bilik}}",
          hotel_future_booking_accom_add_email_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_future_booking_accom_add_email_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_future_booking_accom_add_email_detail_label_departure_date: "Tarikh berlepas",
          hotel_future_booking_accom_add_email_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_future_booking_accom_add_email_detail_label_accom_title: "Tajuk Iringan",
          hotel_future_booking_accom_add_email_detail_example_accom_title: "{{accompanyTitle}}",
          hotel_future_booking_accom_add_email_detail_label_adults: "Dewasa",
          hotel_future_booking_accom_add_email_detail_example_adults: "{{dewasa}}",
          hotel_future_booking_accom_add_email_detail_label_night: "Malam",
          hotel_future_booking_accom_add_email_detail_example_night: "{{malam}}",
          hotel_future_booking_accom_add_email_detail_label_room_rate: "Kadar bilik",
          hotel_future_booking_accom_add_email_detail_example_room_rate: "{{kadar bilik}}",
          hotel_future_booking_accom_add_email_detail_label_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_future_booking_accom_add_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_future_booking_accom_add_email_detail_label_total_rate: "Jumlah Kadar",
          hotel_future_booking_accom_add_email_detail_example_total_rate: "{{TotalRate}}",
          hotel_future_booking_accom_add_email_detail_label_cancellation: "Polisi Pembatalan",
          hotel_future_booking_accom_add_email_detail_example_cancellationPolicy: "{{Dasar pembatalan}}",
          hotel_future_booking_accom_delete_email_edit_title: "TEMPAHAN MASA DEPAN MENGIRINGI EMEL PADAM",
          hotel_future_booking_accom_delete_email_edit_select_kr: "KR",
          hotel_future_booking_accom_delete_email_edit_select_en: "EN",
          hotel_future_booking_accom_delete_email_edit_select_cn: "CN",
          hotel_future_booking_accom_delete_email_edit_select_jp: "JP",
          hotel_future_booking_accom_delete_email_edit_select_ms: "MS",
          hotel_future_booking_accom_delete_email_edit_button_remove: "keluarkan",
          hotel_future_booking_accom_delete_email_edit_button_append: "Lampirkan",
          hotel_future_booking_accom_delete_email_edit_button_save: "Jimat",
          hotel_future_booking_accom_delete_email_edit_toolbar_bold: "berani",
          hotel_future_booking_accom_delete_email_edit_toolbar_italic: "Italic",
          hotel_future_booking_accom_delete_email_edit_toolbar_underline: "Garis bawah",
          hotel_future_booking_accom_delete_email_edit_toolbar_strikethrough: "Strikethrough",
          hotel_future_booking_accom_delete_email_edit_toolbar_remove_font_style: "Keluarkan Gaya Fon",
          hotel_future_booking_accom_delete_email_edit_toolbar_font_family: "Keluarga Font",
          hotel_future_booking_accom_delete_email_edit_toolbar_font_size: "Saiz huruf",
          hotel_future_booking_accom_delete_email_edit_toolbar_text_color: "Warna Teks",
          hotel_future_booking_accom_delete_email_edit_toolbar_text_more_color: "Lebih Banyak Warna",
          hotel_future_booking_accom_delete_email_edit_toolbar_recent_color: "Warna Terkini",
          hotel_future_booking_accom_delete_email_edit_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_future_booking_accom_delete_email_edit_toolbar_table: "Jadual",
          hotel_future_booking_accom_delete_email_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_future_booking_accom_delete_email_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_future_booking_accom_delete_email_edit_toolbar_paragraph: "Perenggan",
          hotel_future_booking_accom_delete_email_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_future_booking_accom_delete_email_edit_toolbar_picture: "Gambar",
          hotel_future_booking_accom_delete_email_edit_toolbar_code_view: "Pandangan Kod",
          hotel_future_booking_accom_delete_email_edit_toolbar_full_screen: "Skrin penuh",
          hotel_future_booking_accom_delete_email_edit_toolbar_help: "Tolong",
          hotel_future_booking_accom_delete_email_edit_toolbar_tip: "Petua",
          hotel_future_booking_accom_delete_email_edit_toolbar_download_pdf: "Muat turun PDF",
          hotel_future_booking_accom_delete_email_edit_placeholder_content: "Sila masukkan kandungan.",
          hotel_future_booking_accom_delete_email_detail_title: "PADAMKAN KANDUNGAN TEMPAHAN MASA DEPAN",
          hotel_future_booking_accom_delete_email_detail_label_confirmation_number: "Nombor pengesahan",
          hotel_future_booking_accom_delete_email_detail_example_confirmation_number: "{{nombor pengesahan}}",
          hotel_future_booking_accom_delete_email_detail_label_accom_first_name: "Iringi Nama Pertama",
          hotel_future_booking_accom_delete_email_detail_example_accom_first_name: "{{accompanyFirstName}}",
          hotel_future_booking_accom_delete_email_detail_label_accom_last_name: "Iringi Nama Akhir",
          hotel_future_booking_accom_delete_email_detail_example_accom_last_name: "{{accompanyLastName}}",
          hotel_future_booking_accom_delete_email_detail_label_first_name: "Nama pertama",
          hotel_future_booking_accom_delete_email_detail_example_first_name: "{{nama pertama}}",
          hotel_future_booking_accom_delete_email_detail_label_last_name: "Nama terakhir",
          hotel_future_booking_accom_delete_email_detail_example_last_name: "{{nama terakhir}}",
          hotel_future_booking_accom_delete_email_detail_label_room_type: "Jenis bilik",
          hotel_future_booking_accom_delete_email_detail_example_room_type: "{{jenis bilik}}",
          hotel_future_booking_accom_delete_email_detail_label_arrival_date: "Tarikh ketibaan",
          hotel_future_booking_accom_delete_email_detail_example_arrival_date: "{{Tarikh ketibaan}}",
          hotel_future_booking_accom_delete_email_detail_label_departure_date: "Tarikh berlepas",
          hotel_future_booking_accom_delete_email_detail_example_departure_date: "{{tarikh berlepas}}",
          hotel_future_booking_accom_delete_email_detail_label_accom_title: "Tajuk Iringan",
          hotel_future_booking_accom_delete_email_detail_example_accom_title: "{{accompanyTitle}}",
          hotel_future_booking_accom_delete_email_detail_label_main_guest_name: "Nama Tetamu Utama",
          hotel_future_booking_accom_delete_email_detail_example_main_guest_name: "{{mainGuestName}}",
          hotel_member_list_title: "SENARAI AHLI",
          hotel_member_list_placeholder_search: "Cari...",
          hotel_member_list_label_no: "Tidak.",
          hotel_member_list_label_first_name: "Nama pertama",
          hotel_member_list_label_last_name: "Nama terakhir",
          hotel_member_list_label_email: "E-mel",
          hotel_member_list_label_gender: "Jantina",
          hotel_member_list_label_member_type: "Jenis Ahli",
          hotel_member_list_label_profile_id: "ID Profil",
          hotel_member_list_label_fail_count: "Kiraan Gagal",
          hotel_member_list_label_date: "Tarikh",
          hotel_member_list_guide_empty: "Senarai kosong",
          hotel_member_detail_title: "ID Profil",
          hotel_member_detail_label_user: "pengguna",
          hotel_member_detail_button_user_transfer: "Segerakkan semula",
          hotel_member_detail_label_user_first_name: "Nama pertama",
          hotel_member_detail_label_user_last_name: "Nama terakhir",
          hotel_member_detail_label_user_email: "E-mel",
          hotel_member_detail_label_user_gender: "Jantina",
          hotel_member_detail_label_user_member_type: "Jenis Ahli",
          hotel_member_detail_label_user_date: "Tarikh",
          hotel_member_detail_label_fail_log_list: "Senarai Log Gagal",
          hotel_member_detail_label_fail_count: "Kiraan Gagal",
          hotel_member_detail_button_unlock: "Buka kunci",
          hotel_member_detail_label_fail_log_no: "Tidak.",
          hotel_member_detail_label_fail_log_lock: "Kunci",
          hotel_member_detail_label_fail_log_date: "Tarikh",
          hotel_member_detail_label_pms_member_profile: "Profil Ahli PMS",
          hotel_member_detail_label_pms_member_profile_name_title: "Nama Tajuk",
          hotel_member_detail_label_pms_member_profile_first_name: "Nama pertama",
          hotel_member_detail_label_pms_member_profile_last_name: "Nama terakhir",
          hotel_member_detail_label_pms_member_profile_alternative_language_code: "Kod Bahasa Alternatif",
          hotel_member_detail_label_pms_member_profile_alternative_first_name: "Nama Pertama Alternatif",
          hotel_member_detail_label_pms_member_profile_alternative_last_name: "Nama Akhir Alternatif",
          hotel_member_detail_label_pms_member_profile_email: "E-mel",
          hotel_member_detail_label_pms_member_profile_email_opera_id: "E-mel ID Opera",
          hotel_member_detail_label_pms_member_profile_phone: "Telefon Bimbit No.",
          hotel_member_detail_label_pms_member_profile_phone_opera_id: "ID Opera No Telefon Bimbit",
          hotel_member_detail_button_go_to_list: "Pergi ke senarai",
          hotel_non_member_list_title: "NON MEMBER LIST",
          hotel_non_member_list_placeholder_search: "Cari...",
          hotel_non_member_list_label_no: "Tidak.",
          hotel_non_member_list_label_first_name: "Nama pertama",
          hotel_non_member_list_label_last_name: "Nama terakhir",
          hotel_non_member_list_label_email: "E-mel",
          hotel_non_member_list_label_gender: "Jantina",
          hotel_non_member_list_label_fail_count: "Jenis Ahli",
          hotel_non_member_list_label_date: "Tarikh",
          hotel_non_member_list_guide_empty: "Senarai kosong",
          hotel_non_member_detail_label_user: "pengguna",
          hotel_non_member_detail_label_user_first_name: "Nama pertama",
          hotel_non_member_detail_label_user_last_name: "Nama terakhir",
          hotel_non_member_detail_label_user_email: "E-mel",
          hotel_non_member_detail_label_user_gender: "Jantina",
          hotel_non_member_detail_label_user_date: "Tarikh",
          hotel_non_member_detail_label_fail_log_list: "Senarai Log Gagal",
          hotel_non_member_detail_label_fail_count: "Kiraan Gagal",
          hotel_non_member_detail_button_unlock: "Buka kunci",
          hotel_non_member_detail_label_fail_log_no: "Tidak.",
          hotel_non_member_detail_label_fail_log_lock: "Kunci",
          hotel_non_member_detail_label_fail_log_date: "Tarikh",
          hotel_non_member_detail_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_reservation_list_title: "SENARAI TEMPAHAN",
          hotel_reservation_reservation_list_placeholder_select_box_reservation_status: "Status Tempahan",
          hotel_reservation_reservation_list_select_inhouse: "Dalam Rumah",
          hotel_reservation_reservation_list_select_reserved: "Terpelihara",
          hotel_reservation_reservation_list_select_changed: "Berubah",
          hotel_reservation_reservation_list_select_canceled: "Dibatalkan",
          hotel_reservation_reservation_list_select_no_show: "Tidak Menunjukkan",
          hotel_reservation_reservation_list_select_checked_out: "Daftar Keluar",
          hotel_reservation_reservation_list_placeholder_select_box_reservation: "Tempahan",
          hotel_reservation_reservation_list_select_confirmation_no: "No Pengesahan",
          hotel_reservation_reservation_list_select_room_no: "Nombor bilik.",
          hotel_reservation_reservation_list_select_name: "Nama",
          hotel_reservation_reservation_list_placeholder_search: "Cari...",
          hotel_reservation_reservation_list_label_no: "Tidak.",
          hotel_reservation_reservation_list_label_confirmation_no: "No Pengesahan",
          hotel_reservation_reservation_list_label_owner: "Pemilik",
          hotel_reservation_reservation_list_label_main_guest: "Tetamu Utama",
          hotel_reservation_reservation_list_label_status: "Status",
          hotel_reservation_reservation_list_label_checkin_date: "Tarikh Daftar Masuk",
          hotel_reservation_reservation_list_label_checkout_date: "Tarikh Checkout",
          hotel_reservation_reservation_list_label_date: "Tarikh",
          hotel_reservation_reservation_list_label_cancel: "Batal",
          hotel_reservation_reservation_list_guide_empty: "Senarai kosong",
          hotel_reservation_reservation_list_button_cancel: "Batal",
          hotel_reservation_reservation_list_button_sync: "Segerakkan",
          hotel_reservation_reservation_detail_label_confirmation_no: "No Pengesahan",
          hotel_reservation_reservation_detail_label_owner_main_guest: "Tetamu Utama",
          hotel_reservation_reservation_detail_label_owner_delete_btn: "Padam",
          hotel_reservation_reservation_detail_label_owner_profile_id: "ID Profil",
          hotel_reservation_reservation_detail_label_owner_title: "Tajuk",
          hotel_reservation_reservation_detail_label_owner_first_name: "Nama pertama",
          hotel_reservation_reservation_detail_label_owner_last_name: "Nama terakhir",
          hotel_reservation_reservation_detail_label_owner_confirmed_at: "Disahkan Pada",
          hotel_reservation_reservation_detail_label_owner_confirmed_type: "Jenis Disahkan",
          hotel_reservation_reservation_detail_label_owner_use_key: "Gunakan Kunci",
          hotel_reservation_reservation_detail_label_owner_waiting_room: "Masa Permintaan Menunggu Bilik",
          hotel_reservation_reservation_detail_label_owner_date: "Tarikh",
          hotel_reservation_reservation_detail_owner_list_guide_empty: "Senarai kosong",
          hotel_reservation_reservation_detail_label_booking_user_owner: "Pemilik",
          hotel_reservation_reservation_detail_label_booking_user_title: "Tajuk",
          hotel_reservation_reservation_detail_label_booking_user_first_name: "Nama pertama",
          hotel_reservation_reservation_detail_label_booking_user_last_name: "Nama terakhir",
          hotel_reservation_reservation_detail_label_booking_user_confirmed_at: "Disahkan Pada",
          hotel_reservation_reservation_detail_label_booking_user_date: "Tarikh",
          hotel_reservation_reservation_detail_label_accompany: "menemani",
          hotel_reservation_reservation_detail_label_accompany_no: "Tidak.",
          hotel_reservation_reservation_detail_label_accompany_profile_id: "ID Profil",
          hotel_reservation_reservation_detail_label_accompany_title: "Tajuk",
          hotel_reservation_reservation_detail_label_accompany_first_name: "Nama pertama",
          hotel_reservation_reservation_detail_label_accompany_last_name: "Nama terakhir",
          hotel_reservation_reservation_detail_label_accompany_use_key: "Gunakan Kunci",
          hotel_reservation_reservation_detail_label_accompany_confirmed_at: "Disahkan Pada",
          hotel_reservation_reservation_detail_label_accompany_confirmed_type: "Jenis Disahkan",
          hotel_reservation_reservation_detail_label_accompany_hotel_member_id: "ID Ahli Hotel",
          hotel_reservation_reservation_detail_label_accompany_hotel_date: "Tarikh",
          hotel_reservation_reservation_detail_accompany_list_guide_empty: "Senarai kosong",
          hotel_reservation_reservation_detail_label_agreed: "Perjanjian",
          hotel_reservation_reservation_detail_label_agreed_title: "Tajuk",
          hotel_reservation_reservation_detail_label_agreed_agreement: "Perjanjian",
          hotel_reservation_reservation_detail_label_agreed_agreed_at: "Bersetuju Pada",
          hotel_reservation_reservation_detail_label_agreed_view_details: "Lihat butiran",
          hotel_reservation_reservation_detail_label_agreed_go_to_detail: "Pergi ke butiran",
          hotel_reservation_reservation_detail_label_payment: "Bayaran",
          hotel_reservation_reservation_detail_label_payment_long_info: "info panjang",
          hotel_reservation_reservation_detail_label_payment_amount: "Jumlah",
          hotel_reservation_reservation_detail_label_payment_unit: "Unit",
          hotel_reservation_reservation_detail_label_payment_pg_status_code: "Kod status PG",
          hotel_reservation_reservation_detail_label_payment_pg_status_message: "Mesej status PG",
          hotel_reservation_reservation_detail_label_payment_date: "Tarikh",
          hotel_reservation_reservation_detail_label_upsell: "Upsell",
          hotel_reservation_reservation_detail_label_upsell_before_room_type: "Before Room Type",
          hotel_reservation_reservation_detail_label_upsell_after_room_type: "After Room Type",
          hotel_reservation_reservation_detail_label_upsell_before_rate_plan: "Before Rate Plan",
          hotel_reservation_reservation_detail_label_upsell_after_rate_plan: "After Rate Plan",
          hotel_reservation_reservation_detail_label_pms: "PMS",
          hotel_reservation_reservation_detail_label_pms_confirmation_no: "No Pengesahan",
          hotel_reservation_reservation_detail_label_pms_reservation_id: "ID Tempahan",
          hotel_reservation_reservation_detail_label_pms_profile_id: "ID Profil",
          hotel_reservation_reservation_detail_label_pms_reservation_status: "Status Tempahan",
          hotel_reservation_reservation_detail_label_pms_computed_reservation_status: "Status Tempahan Dikira",
          hotel_reservation_reservation_detail_label_pms_message_exists: "Mesej Wujud",
          hotel_reservation_reservation_detail_label_pms_arrival_date: "Tarikh ketibaan",
          hotel_reservation_reservation_detail_label_pms_departure_date: "Tarikh berlepas",
          hotel_reservation_reservation_detail_label_pms_room_type: "Jenis bilik",
          hotel_reservation_reservation_detail_label_pms_room_type_code: "Kod Jenis Bilik",
          hotel_reservation_reservation_detail_label_pms_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_reservation_reservation_detail_label_pms_room_rate: "Kadar bilik",
          hotel_reservation_reservation_detail_label_pms_total_rate: "Jumlah Kadar",
          hotel_reservation_reservation_detail_label_pms_current_balance: "Baki terkini",
          hotel_reservation_reservation_detail_label_pms_room_number: "Nombor bilik",
          hotel_reservation_reservation_detail_label_pms_adults: "Dewasa",
          hotel_reservation_reservation_detail_label_pms_children: "Kanak-kanak",
          hotel_reservation_reservation_detail_label_pms_accompany: "menemani",
          hotel_reservation_reservation_detail_label_pms_accompany_no: "Tidak.",
          hotel_reservation_reservation_detail_label_pms_accompany_profile_id: "ID Profil",
          hotel_reservation_reservation_detail_label_pms_accompany_first_name: "Nama pertama",
          hotel_reservation_reservation_detail_label_pms_accompany_last_name: "Nama terakhir",
          hotel_reservation_reservation_detail_label_pms_accompany_nationality: "kewarganegaraan",
          hotel_reservation_reservation_detail_label_pms_accompany_passport_no: "Pasport No.",
          hotel_reservation_reservation_detail_label_pms_accompany_date_of_birth: "Tarikh lahir",
          hotel_reservation_reservation_detail_label_pms_accompany_gender: "Jantina",
          hotel_reservation_reservation_detail_label_pms_accompany_date_of_expiry: "Tarikh luput",
          hotel_reservation_reservation_detail_label_pms_main_guest: "Tetamu Utama",
          hotel_reservation_reservation_detail_label_pms_main_guest_profile_id: "ID Profil",
          hotel_reservation_reservation_detail_label_pms_main_guest_first_name: "Nama pertama",
          hotel_reservation_reservation_detail_label_pms_main_guest_last_name: "Nama terakhir",
          hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_nationality: "kewarganegaraan",
          hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_passport_no: "Pasport No.",
          hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_birth: "Tarikh lahir",
          hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_gender: "Jantina",
          hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_expiry: "Tarikh luput",
          hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_date: "Tarikh ketibaan",
          hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_no: "Penerbangan Ketibaan No.",
          hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_time: "Masa Penerbangan Tiba",
          hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_date: "Tarikh berlepas",
          hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_no: "Penerbangan Berlepas No.",
          hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_time: "Masa berlepas",
          hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_wake_up_call: "Panggilan Bangun",
          hotel_reservation_reservation_detail_label_pms_package: "Pakej",
          hotel_reservation_reservation_detail_label_pms_package_no: "Tidak.",
          hotel_reservation_reservation_detail_label_pms_package_package_code: "Kod Pakej",
          hotel_reservation_reservation_detail_label_pms_package_description: "Penerangan",
          hotel_reservation_reservation_detail_label_pms_package_amount: "Jumlah",
          hotel_reservation_reservation_detail_label_pms_charges_by_date: "Caj Mengikut Tarikh",
          hotel_reservation_reservation_detail_label_pms_charges_by_date_no: "Tidak.",
          hotel_reservation_reservation_detail_label_pms_charges_by_date_date: "Tarikh",
          hotel_reservation_reservation_detail_label_pms_charges_by_date_description: "Penerangan",
          hotel_reservation_reservation_detail_label_pms_charges_by_date_charges: "Caj",
          hotel_reservation_reservation_detail_label_pms_invoice: "Invois",
          hotel_reservation_reservation_detail_label_pms_invoice_no: "Tidak.",
          hotel_reservation_reservation_detail_label_pms_invoice_date: "Tarikh",
          hotel_reservation_reservation_detail_label_pms_invoice_description: "Penerangan",
          hotel_reservation_reservation_detail_label_pms_invoice_currency_code: "Kod matawang",
          hotel_reservation_reservation_detail_label_pms_invoice_amount: "Jumlah",
          hotel_reservation_reservation_detail_label_pms_invoice_supplement: "maklumat tambahan",
          hotel_reservation_reservation_detail_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_reservation_detail_button_cancel: "Batal",
          hotel_reservation_reservation_compare_face_detail_title: "BANDINGKAN BUTIRAN MUKA",
          hotel_reservation_reservation_detail_label_user_name: "Nama Pengguna",
          hotel_reservation_reservation_detail_label_identity_document_type: "Jenis Dokumen Identiti",
          hotel_reservation_reservation_detail_label_auth_status: "Status Pengesahan",
          hotel_reservation_reservation_detail_label_similarity: "persamaan",
          hotel_reservation_reservation_detail_label_government_singapore_eva_response: "Maklum Balas EVA Kerajaan Singapura",
          hotel_reservation_reservation_detail_label_government_singapore_eva_status: "Status EVA Kerajaan Singapura",
          hotel_reservation_reservation_detail_label_government_singapore_eva_request_at: "EVA Kerajaan Singapura Diminta Di",
          hotel_reservation_reservation_detail_label_government_singapore_eva_response_at: "Maklum Balas EVA Kerajaan Singapura Di",
          hotel_reservation_reservation_detail_label_created_at: "Dicipta Pada",
          hotel_reservation_reservation_compare_face_detail_button_go_to_detail: "Pergi ke butiran",
          hotel_reservation_reservation_exist_label_room_no: "Nombor bilik.",
          hotel_reservation_reservation_exist_label_period_of_stay: "Tempoh Penginapan",
          hotel_reservation_reservation_exist_label_user: "pengguna",
          hotel_reservation_reservation_exist_label_user_confirmation_no: "No Pengesahan",
          hotel_reservation_reservation_exist_label_user_first_name: "Nama pertama",
          hotel_reservation_reservation_exist_label_user_last_name: "Nama terakhir",
          hotel_reservation_reservation_exist_label_user_email: "E-mel",
          hotel_reservation_reservation_exist_label_user_gender: "Jantina",
          hotel_reservation_reservation_exist_label_user_member_type: "Jenis Ahli",
          hotel_reservation_reservation_exist_label_user_date: "Tarikh",
          hotel_reservation_reservation_exist_label_exist_request_list: "Senarai Permintaan Sedia Ada",
          hotel_reservation_reservation_exist_label_exist_request_list_: "Tarikh Permintaan",
          hotel_reservation_reservation_exist_label_exist_request_list_request_type: "Jenis Permintaan",
          hotel_reservation_reservation_exist_label_exist_request_list_express: "Tugasan",
          hotel_reservation_reservation_exist_label_exist_request_list_number_of_item: "Bilangan Item",
          hotel_reservation_reservation_exist_label_exist_request_list_action_status: "Status Tindakan",
          hotel_reservation_reservation_exist_label_exist_request_list_date: "Tarikh",
          hotel_reservation_reservation_exist_label_exist_request_list_hover: "Permintaan tidak boleh dihentikan.",
          hotel_reservation_reservation_exist_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_reservation_exist_button_stop: "Berhenti",
          hotel_reservation_upsell_list_title: "UPSELL LIST",
          hotel_reservation_upsell_list_placeholder_select_box_choose: "Pilih",
          hotel_reservation_upsell_list_select_inhouse: "Dalam Rumah",
          hotel_reservation_upsell_list_select_reserved: "Terpelihara",
          hotel_reservation_upsell_list_select_changed: "Berubah",
          hotel_reservation_upsell_list_select_canceled: "Dibatalkan",
          hotel_reservation_upsell_list_select_no_show: "Tidak Menunjukkan",
          hotel_reservation_upsell_list_select_checked_out: "Daftar Keluar",
          hotel_reservation_upsell_list_select_confirmation_no: "No Pengesahan",
          hotel_reservation_upsell_list_select_room_no: "Nombor bilik.",
          hotel_reservation_upsell_list_select_name: "Nama",
          hotel_reservation_upsell_list_placeholder_search: "Cari...",
          hotel_reservation_upsell_list_label_no: "Tidak.",
          hotel_reservation_upsell_list_label_confirmation_no: "No Pengesahan",
          hotel_reservation_upsell_list_label_main_guest: "Tetamu Utama",
          hotel_reservation_upsell_list_label_status: "Status",
          hotel_reservation_upsell_list_label_before_room_type: "Before Room Type",
          hotel_reservation_upsell_list_label_after_room_type: "After Room Type",
          hotel_reservation_upsell_list_label_before_rate_plan: "Before Rate Plan",
          hotel_reservation_upsell_list_label_after_rate_plan: "After Rate Plan",
          hotel_reservation_upsell_list_label_date: "Tarikh",
          hotel_reservation_upsell_list_guide_empty: "Senarai kosong",
          hotel_reservation_waiting_list_title: "SENARAI MENUNGGU BILIK",
          hotel_reservation_waiting_list_placeholder_select_box_waiting_room: "Bilik Tunggu",
          hotel_reservation_waiting_list_select_profile_id: "ID Profil",
          hotel_reservation_waiting_list_select_owner_name: "Nama tetamu",
          hotel_reservation_waiting_list_placeholder_search: "Cari...",
          hotel_reservation_waiting_list_label_no: "Tidak.",
          hotel_reservation_waiting_list_label_profile_id: "ID Profil",
          hotel_reservation_waiting_list_label_title: "Tajuk",
          hotel_reservation_waiting_list_label_main_guest: "Tetamu Utama",
          hotel_reservation_waiting_list_label_main_guest_email: "E-mel Tetamu Utama",
          hotel_reservation_waiting_list_label_owner_name: "Nama tetamu",
          hotel_reservation_waiting_list_label_owner_email: "E-mel Tetamu",
          hotel_reservation_waiting_list_label_confirmation_no: "No Pengesahan",
          hotel_reservation_waiting_list_guide_empty: "Senarai kosong",
          hotel_reservation_waiting_detail_label_confirmation_no: "No Pengesahan",
          hotel_reservation_waiting_detail_label_owner: "Tetamu Terpelihara",
          hotel_reservation_waiting_detail_label_owner_profile_id: "ID Profil",
          hotel_reservation_waiting_detail_label_owner_title: "Tajuk",
          hotel_reservation_waiting_detail_label_owner_first_name: "Nama pertama",
          hotel_reservation_waiting_detail_label_owner_last_name: "Nama terakhir",
          hotel_reservation_waiting_detail_label_owner_confirmed_at: "Disahkan Pada",
          hotel_reservation_waiting_detail_label_owner_confirmed_type: "Jenis Disahkan",
          hotel_reservation_waiting_detail_label_owner_waiting_room: "Bilik menunggu",
          hotel_reservation_waiting_detail_label_owner_date: "Tarikh",
          hotel_reservation_waiting_detail_label_accompany: "menemani",
          hotel_reservation_waiting_detail_label_accompany_no: "Tidak.",
          hotel_reservation_waiting_detail_label_accompany_profile_id: "ID Profil",
          hotel_reservation_waiting_detail_label_accompany_tile: "Tajuk",
          hotel_reservation_waiting_detail_label_accompany_first_name: "Nama pertama",
          hotel_reservation_waiting_detail_label_accompany_last_name: "Nama terakhir",
          hotel_reservation_waiting_detail_label_accompany_use_key: "Gunakan Kunci",
          hotel_reservation_waiting_detail_label_accompany_confirmed_at: "Disahkan Pada",
          hotel_reservation_waiting_detail_label_accompany_confirmed_type: "Jenis Disahkan",
          hotel_reservation_waiting_detail_label_accompany_hotel_member_id: "ID Ahli Hotel",
          hotel_reservation_waiting_detail_label_accompany_date: "Tarikh",
          hotel_reservation_waiting_detail_label_pms: "PMS",
          hotel_reservation_waiting_detail_label_pms_confirmation_no: "No Pengesahan",
          hotel_reservation_waiting_detail_label_pms_reservation_id: "ID Tempahan",
          hotel_reservation_waiting_detail_label_pms_profile_id: "ID Profil",
          hotel_reservation_waiting_detail_label_pms_reservation_status: "Status Tempahan",
          hotel_reservation_waiting_detail_label_pms_message_exists: "Mesej Wujud",
          hotel_reservation_waiting_detail_label_pms_arrival_date: "Tarikh ketibaan",
          hotel_reservation_waiting_detail_label_pms_departure_date: "Tarikh berlepas",
          hotel_reservation_waiting_detail_label_pms_room_type: "Jenis bilik",
          hotel_reservation_waiting_detail_label_pms_room_type_code: "Kod Jenis Bilik",
          hotel_reservation_waiting_detail_label_pms_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_reservation_waiting_detail_label_pms_room_rate: "Kadar bilik",
          hotel_reservation_waiting_detail_label_pms_total_rate: "Jumlah Kadar",
          hotel_reservation_waiting_detail_label_pms_current_balance: "Baki terkini",
          hotel_reservation_waiting_detail_label_pms_room_number: "Nombor bilik",
          hotel_reservation_waiting_detail_label_pms_adults: "Dewasa",
          hotel_reservation_waiting_detail_label_pms_children: "Kanak-kanak",
          hotel_reservation_waiting_detail_label_pms_accompany: "menemani",
          hotel_reservation_waiting_detail_label_pms_accompany_no: "Tidak.",
          hotel_reservation_waiting_detail_label_pms_accompany_profile_id: "ID Profil",
          hotel_reservation_waiting_detail_label_pms_accompany_first_name: "Nama pertama",
          hotel_reservation_waiting_detail_label_pms_accompany_last_name: "Nama terakhir",
          hotel_reservation_waiting_detail_label_pms_package: "Pakej",
          hotel_reservation_waiting_detail_label_pms_package_no: "Tidak.",
          hotel_reservation_waiting_detail_label_pms_package_package_code: "Kod Pakej",
          hotel_reservation_waiting_detail_label_pms_package_description: "Penerangan",
          hotel_reservation_waiting_detail_label_pms_package_amount: "Jumlah",
          hotel_reservation_waiting_detail_label_pms_charges_by_date: "Caj Mengikut Tarikh",
          hotel_reservation_waiting_detail_label_pms_charges_by_date_no: "Tidak.",
          hotel_reservation_waiting_detail_label_pms_charges_by_date_date: "Tarikh",
          hotel_reservation_waiting_detail_label_pms_charges_by_date_description: "Penerangan",
          hotel_reservation_waiting_detail_label_pms_charges_by_date_charges: "Caj",
          hotel_reservation_waiting_detail_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_waiting_detail_button_cancel: "Batal",
          hotel_reservation_future_booking_list_title: "SENARAI TEMPAHAN MASA DEPAN",
          hotel_reservation_future_booking_list_placeholder_select_box_reservation_status: "Status Tempahan",
          hotel_reservation_future_booking_list_select_inhouse: "Dalam Rumah",
          hotel_reservation_future_booking_list_select_reserved: "Terpelihara",
          hotel_reservation_future_booking_list_select_changed: "Berubah",
          hotel_reservation_future_booking_list_select_canceled: "Dibatalkan",
          hotel_reservation_future_booking_list_select_no_show: "Tidak Menunjukkan",
          hotel_reservation_future_booking_list_select_checked_out: "Daftar Keluar",
          hotel_reservation_future_booking_list_placeholder_select_box_reservation: "Tempahan Masa Depan",
          hotel_reservation_future_booking_list_select_confirmation_no: "No Pengesahan",
          hotel_reservation_future_booking_list_select_name: "Nama",
          hotel_reservation_future_booking_list_placeholder_search: "Cari...",
          hotel_reservation_future_booking_list_label_no: "Tidak.",
          hotel_reservation_future_booking_list_label_confirmation_no: "No Pengesahan",
          hotel_reservation_future_booking_list_label_booking_owner: "Pemilik Tempahan",
          hotel_reservation_future_booking_list_label_status: "Status",
          hotel_reservation_future_booking_list_label_occupy: "Pra-dudukan",
          hotel_reservation_future_booking_list_label_date: "Tarikh",
          hotel_reservation_future_booking_list_label_cancel: "Batal",
          hotel_reservation_future_booking_list_guide_empty: "Senarai kosong",
          hotel_reservation_future_booking_list_button_cancel: "Batal",
          hotel_reservation_future_booking_list_button_sync: "Segerakkan",
          hotel_reservation_future_booking_detail_label_confirmation_no: "No Pengesahan",
          hotel_reservation_future_booking_detail_label_owner: "Pemilik Tempahan",
          hotel_reservation_future_booking_detail_label_owner_title: "Tajuk",
          hotel_reservation_future_booking_detail_label_owner_first_name: "Nama pertama",
          hotel_reservation_future_booking_detail_label_owner_last_name: "Nama terakhir",
          hotel_reservation_future_booking_detail_label_owner_use_key: "Gunakan Kunci",
          hotel_reservation_future_booking_detail_label_owner_confirmed_at: "Disahkan",
          hotel_reservation_future_booking_detail_label_owner_date: "Tarikh",
          hotel_reservation_future_booking_detail_label_pms: "PMS",
          hotel_reservation_future_booking_detail_label_pms_confirmation_no: "No Pengesahan",
          hotel_reservation_future_booking_detail_label_pms_reservation_id: "ID Tempahan",
          hotel_reservation_future_booking_detail_label_pms_profile_id: "ID Profil",
          hotel_reservation_future_booking_detail_label_pms_reservation_status: "Status Tempahan",
          hotel_reservation_future_booking_detail_label_pms_message_exists: "Mesej Wujud",
          hotel_reservation_future_booking_detail_label_pms_arrival_date: "Tarikh ketibaan",
          hotel_reservation_future_booking_detail_label_pms_departure_date: "Tarikh berlepas",
          hotel_reservation_future_booking_detail_label_pms_room_type: "Jenis bilik",
          hotel_reservation_future_booking_detail_label_pms_room_type_code: "Kod Jenis Bilik",
          hotel_reservation_future_booking_detail_label_pms_room_rate_currency: "Mata Wang Kadar Bilik",
          hotel_reservation_future_booking_detail_label_pms_room_rate: "Kadar bilik",
          hotel_reservation_future_booking_detail_label_pms_total_rate: "Jumlah Kadar",
          hotel_reservation_future_booking_detail_label_pms_current_balance: "Baki terkini",
          hotel_reservation_future_booking_detail_label_pms_room_number: "Nombor bilik",
          hotel_reservation_future_booking_detail_label_pms_adults: "Dewasa",
          hotel_reservation_future_booking_detail_label_pms_children: "Kanak-kanak",
          hotel_reservation_future_booking_detail_label_pms_main_guest: "Tetamu Utama",
          hotel_reservation_future_booking_detail_label_pms_main_guest_profile_id: "ID Profil",
          hotel_reservation_future_booking_detail_label_pms_main_guest_first_name: "Nama pertama",
          hotel_reservation_future_booking_detail_label_pms_main_guest_last_name: "Nama terakhir",
          hotel_reservation_future_booking_detail_label_pms_main_guest_list_guide_empty: "Senarai kosong",
          hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_nationality: "kewarganegaraan",
          hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_passport_no: "Pasport No.",
          hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_date_of_birth: "Tarikh lahir",
          hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_gender: "Jantina",
          hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_date_of_expiry: "Tarikh luput",
          hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_list_guide_empty: "Senarai kosong",
          hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_date: "Tarikh ketibaan",
          hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_flight_no: "Penerbangan Ketibaan No.",
          hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_flight_time: "Masa Penerbangan Tiba",
          hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_date: "Penerbangan Berlepas No.",
          hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_flight_no: "Penerbangan Berlepas No.",
          hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_flight_time: "Masa berlepas",
          hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_wake_up_call: "Panggilan Bangun",
          hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_list_guide_empty: "Senarai kosong",
          hotel_reservation_future_booking_detail_label_pms_accompany: "menemani",
          hotel_reservation_future_booking_detail_label_pms_accompany_no: "Tidak.",
          hotel_reservation_future_booking_detail_label_pms_accompany_profile_id: "ID Profil",
          hotel_reservation_future_booking_detail_label_pms_accompany_first_name: "Nama pertama",
          hotel_reservation_future_booking_detail_label_pms_accompany_last_name: "Nama terakhir",
          hotel_reservation_future_booking_detail_label_pms_accompany_info_list_guide_empty: "Senarai kosong",
          hotel_reservation_future_booking_detail_label_pms_package: "Pakej",
          hotel_reservation_future_booking_detail_label_pms_package_no: "Tidak.",
          hotel_reservation_future_booking_detail_label_pms_package_package_code: "Kod Pakej",
          hotel_reservation_future_booking_detail_label_pms_package_description: "Penerangan",
          hotel_reservation_future_booking_detail_label_pms_package_amount: "Jumlah",
          hotel_reservation_future_booking_detail_label_pms_package_list_guide_empty: "Senarai kosong",
          hotel_reservation_future_booking_detail_label_pms_charges_by_date: "Caj Mengikut Tarikh",
          hotel_reservation_future_booking_detail_label_pms_charges_by_date_no: "Tidak.",
          hotel_reservation_future_booking_detail_label_pms_charges_by_date_date: "Tarikh",
          hotel_reservation_future_booking_detail_label_pms_charges_by_date_description: "Penerangan",
          hotel_reservation_future_booking_detail_label_pms_charges_by_date_charges: "Caj",
          hotel_reservation_future_booking_detail_label_pms_charges_list_guide_empty: "Senarai kosong",
          hotel_reservation_future_booking_detail_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_future_booking_detail_button_cancel: "Batal",
          hotel_reservation_rule_setting_reservation_rule_list_title: "SENARAI PERATURAN TEMPAHAN",
          hotel_reservation_rule_setting_reservation_rule_list_select_active_type: "Jenis Aktif ( SEMUA )",
          hotel_reservation_rule_setting_reservation_rule_list_select_active: "Aktif",
          hotel_reservation_rule_setting_reservation_rule_list_select_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_reservation_rule_list_placeholder_search: "Cari...",
          hotel_reservation_rule_setting_reservation_rule_list_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_reservation_rule_list_label_reservation_amount: "Jumlah Tempahan",
          hotel_reservation_rule_setting_reservation_rule_list_label_type: "taip",
          hotel_reservation_rule_setting_reservation_rule_list_label_day_before_arrival: "Hari Sebelum Tiba",
          hotel_reservation_rule_setting_reservation_rule_list_label_before_time: "Sebelum masa",
          hotel_reservation_rule_setting_reservation_rule_list_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_reservation_rule_list_label_created_at: "Dicipta Pada",
          hotel_reservation_rule_setting_reservation_rule_list_button_delete: "Padam",
          hotel_reservation_rule_setting_reservation_rule_list_button_add: "Tambah",
          hotel_reservation_rule_setting_reservation_rule_list_guide_empty: "Senarai kosong",
          hotel_reservation_rule_setting_reservation_rule_add_title: "TAMBAH PERATURAN TEMPAHAN",
          hotel_reservation_rule_setting_reservation_rule_add_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_reservation_rule_add_label_description: "Penerangan",
          hotel_reservation_rule_setting_reservation_rule_add_label_type: "taip",
          hotel_reservation_rule_setting_reservation_rule_add_placeholder_select_box_select: "Pilih",
          hotel_reservation_rule_setting_reservation_rule_add_select_percent: "Peratusan",
          hotel_reservation_rule_setting_reservation_rule_add_select_nights: "Malam-malam",
          hotel_reservation_rule_setting_reservation_rule_add_select_flat: "Kadar rata",
          hotel_reservation_rule_setting_reservation_rule_add_label_reservation_amount: "Jumlah",
          hotel_reservation_rule_setting_reservation_rule_add_label_reservation_percent: "Peratusan",
          hotel_reservation_rule_setting_reservation_rule_add_label_reservation_nights: "Malam-malam",
          hotel_reservation_rule_setting_reservation_rule_add_label_transaction_code: "Kod Transaksi",
          hotel_reservation_rule_setting_reservation_rule_add_label_day_before_arrival: "Hari Sebelum Ketibaan (Hari)",
          hotel_reservation_rule_setting_reservation_rule_add_label_before_time: "Sebelum Masa (Jam:Minit)",
          hotel_reservation_rule_setting_reservation_rule_add_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_reservation_rule_add_label_yes: "ya",
          hotel_reservation_rule_setting_reservation_rule_add_label_no: "Tidak",
          hotel_reservation_rule_setting_reservation_rule_add_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_rule_setting_reservation_rule_add_button_add: "Tambah",
          hotel_reservation_rule_setting_reservation_rule_add_validation_rule_name: "* Sila masukkan nama peraturan.",
          hotel_reservation_rule_setting_reservation_rule_add_validation_description: "* Sila masukkan penerangan.",
          hotel_reservation_rule_setting_reservation_rule_add_validation_type: "* Sila pilih jenis.",
          hotel_reservation_rule_setting_reservation_rule_add_validation_reservation_amount: "* Sila masukkan peratusan.",
          hotel_reservation_rule_setting_reservation_rule_add_validation_dayBefore_arrival: "* Sila masukkan kod pembayaran.",
          hotel_reservation_rule_setting_reservation_rule_add_validation_transaction_code: "* Sila masukkan bilangan hari sebelum daftar masuk.",
          hotel_reservation_rule_setting_reservation_rule_add_validation_inactive: "* Sila pilih status penyahaktifan.",
          hotel_reservation_rule_setting_reservation_rule_add_validation_before_time: "* Sila masukkan masa sebelum daftar masuk.",
          hotel_reservation_rule_setting_reservation_rule_detail_title: "BUTIRAN PERATURAN TEMPAHAN",
          hotel_reservation_rule_setting_reservation_rule_detail_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_reservation_rule_detail_label_description: "Penerangan",
          hotel_reservation_rule_setting_reservation_rule_detail_label_type: "taip",
          hotel_reservation_rule_setting_reservation_rule_detail_select_percent: "Peratusan",
          hotel_reservation_rule_setting_reservation_rule_detail_select_nights: "Malam-malam",
          hotel_reservation_rule_setting_reservation_rule_detail_select_flat: "Kadar rata",
          hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_amount: "Jumlah",
          hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_percent: "Peratusan",
          hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_nights: "Malam-malam",
          hotel_reservation_rule_setting_reservation_rule_detail_label_transactio_code: "Kod Transaksi",
          hotel_reservation_rule_setting_reservation_rule_detail_label_day_before_arrival: "Hari Sebelum Ketibaan (Hari)",
          hotel_reservation_rule_setting_reservation_rule_detail_label_before_time: "Sebelum Masa (Jam:Minit)",
          hotel_reservation_rule_setting_reservation_rule_detail_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_reservation_rule_detail_label_yes: "ya",
          hotel_reservation_rule_setting_reservation_rule_detail_label_no: "Tidak",
          hotel_reservation_rule_setting_reservation_rule_detail_label_created_at: "Dicipta Pada",
          hotel_reservation_rule_setting_reservation_rule_detail_label_updated_at: "Dikemaskini Pada",
          hotel_reservation_rule_setting_reservation_rule_detail_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_rule_setting_reservation_rule_detail_button_cancel: "Batal",
          hotel_reservation_rule_setting_reservation_rule_detail_button_delete: "Padam",
          hotel_reservation_rule_setting_reservation_rule_detail_button_edit: "Sunting",
          hotel_reservation_rule_setting_reservation_rule_detail_button_ok: "okey",
          hotel_reservation_rule_setting_reservation_rule_edit_validation_rule_name: "* Sila masukkan nama peraturan.",
          hotel_reservation_rule_setting_reservation_rule_edit_validation_description: "* Sila masukkan penerangan.",
          hotel_reservation_rule_setting_reservation_rule_edit_validation_type: "* Sila pilih jenis.",
          hotel_reservation_rule_setting_reservation_rule_edit_validation_reservation_amount: "* Sila masukkan peratusan.",
          hotel_reservation_rule_setting_reservation_rule_edit_validation_dayBefore_arrival: "* Sila masukkan kod pembayaran.",
          hotel_reservation_rule_setting_reservation_rule_edit_validation_transaction_code: "* Sila masukkan bilangan hari sebelum daftar masuk.",
          hotel_reservation_rule_setting_reservation_rule_edit_validation_inactive: "* Sila pilih status penyahaktifan.",
          hotel_reservation_rule_setting_reservation_rule_edit_validation_before_time: "* Sila masukkan masa sebelum daftar masuk.",
          hotel_reservation_rule_setting_deposit_rule_list_title: "SENARAI PERATURAN DEPOSIT",
          hotel_reservation_rule_setting_deposit_rule_list_select_active_type: "Jenis Aktif ( SEMUA )",
          hotel_reservation_rule_setting_deposit_rule_list_select_active: "Aktif",
          hotel_reservation_rule_setting_deposit_rule_list_select_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_deposit_rule_list_placeholder_search: "Cari...",
          hotel_reservation_rule_setting_deposit_rule_list_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_deposit_rule_list_label_deposit_amount: "Jumlah Deposit",
          hotel_reservation_rule_setting_deposit_rule_list_label_type: "taip",
          hotel_reservation_rule_setting_deposit_rule_list_label_day_before_arrival: "Hari Sebelum Tiba",
          hotel_reservation_rule_setting_deposit_rule_list_label_before_time: "Sebelum masa",
          hotel_reservation_rule_setting_deposit_rule_list_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_deposit_rule_list_label_created_at: "Dicipta Pada",
          hotel_reservation_rule_setting_deposit_rule_list_button_delete: "Padam",
          hotel_reservation_rule_setting_deposit_rule_list_button_add: "Tambah",
          hotel_reservation_rule_setting_deposit_rule_add_title: "TAMBAH PERATURAN DEPOSIT",
          hotel_reservation_rule_setting_deposit_rule_add_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_deposit_rule_add_label_description: "Penerangan",
          hotel_reservation_rule_setting_deposit_rule_add_label_type: "taip",
          hotel_reservation_rule_setting_deposit_rule_add_placeholder_select_box_select: "Pilih",
          hotel_reservation_rule_setting_deposit_rule_add_select_percent: "Peratusan",
          hotel_reservation_rule_setting_deposit_rule_add_select_nights: "Malam-malam",
          hotel_reservation_rule_setting_deposit_rule_add_select_flat: "Kadar rata",
          hotel_reservation_rule_setting_deposit_rule_add_label_deposit_amount: "Jumlah",
          hotel_reservation_rule_setting_deposit_rule_add_label_deposit_percent: "Peratusan",
          hotel_reservation_rule_setting_deposit_rule_add_label_deposit_nights: "Malam-malam",
          hotel_reservation_rule_setting_deposit_rule_add_label_transaction_code: "Kod Transaksi",
          hotel_reservation_rule_setting_deposit_rule_add_label_day_before_arrival: "Hari Sebelum Ketibaan (Hari)",
          hotel_reservation_rule_setting_deposit_rule_add_label_before_time: "Sebelum Masa (Jam:Minit)",
          hotel_reservation_rule_setting_deposit_rule_add_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_deposit_rule_add_label_yes: "ya",
          hotel_reservation_rule_setting_deposit_rule_add_label_no: "Tidak",
          hotel_reservation_rule_setting_deposit_rule_add_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_rule_setting_deposit_rule_add_button_add: "Tambah",
          hotel_reservation_rule_setting_deposit_rule_add_validation_rule_name: "* Sila masukkan nama peraturan.",
          hotel_reservation_rule_setting_deposit_rule_add_validation_description: "* Sila masukkan penerangan.",
          hotel_reservation_rule_setting_deposit_rule_add_validation_type: "* Sila pilih jenis.",
          hotel_reservation_rule_setting_deposit_rule_add_validation_deposit_amount: "* Sila masukkan peratusan.",
          hotel_reservation_rule_setting_deposit_rule_add_validation_transaction_code: "* Sila masukkan kod pembayaran.",
          hotel_reservation_rule_setting_deposit_rule_add_validation_inactive: "* Sila pilih status penyahaktifan.",
          hotel_reservation_rule_setting_deposit_rule_detail_title: "MAKLUMAT PERATURAN DEPOSIT",
          hotel_reservation_rule_setting_deposit_rule_detail_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_deposit_rule_detail_label_description: "Penerangan",
          hotel_reservation_rule_setting_deposit_rule_detail_label_type: "taip",
          hotel_reservation_rule_setting_deposit_rule_detail_select_percent: "Peratusan",
          hotel_reservation_rule_setting_deposit_rule_detail_select_nights: "Malam-malam",
          hotel_reservation_rule_setting_deposit_rule_detail_select_flat: "Kadar rata",
          hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_amount: "Jumlah",
          hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_percent: "Peratusan",
          hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_nights: "Malam-malam",
          hotel_reservation_rule_setting_deposit_rule_detail_label_transactio_code: "Kod Transaksi",
          hotel_reservation_rule_setting_deposit_rule_detail_label_day_before_arrival: "Hari Sebelum Ketibaan (Hari)",
          hotel_reservation_rule_setting_deposit_rule_detail_label_before_time: "Sebelum Masa (Jam:Minit)",
          hotel_reservation_rule_setting_deposit_rule_detail_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_deposit_rule_detail_label_yes: "ya",
          hotel_reservation_rule_setting_deposit_rule_detail_label_no: "Tidak",
          hotel_reservation_rule_setting_deposit_rule_detail_label_created_at: "Dicipta Pada",
          hotel_reservation_rule_setting_deposit_rule_detail_label_updated_at: "Dikemaskini Pada",
          hotel_reservation_rule_setting_deposit_rule_detail_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_rule_setting_deposit_rule_detail_button_cancel: "Batal",
          hotel_reservation_rule_setting_deposit_rule_detail_button_delete: "Padam",
          hotel_reservation_rule_setting_deposit_rule_detail_button_edit: "Sunting",
          hotel_reservation_rule_setting_deposit_rule_detail_button_ok: "okey",
          hotel_reservation_rule_setting_deposit_rule_edit_validation_rule_name: "* Sila masukkan nama peraturan.",
          hotel_reservation_rule_setting_deposit_rule_edit_validation_description: "* Sila masukkan penerangan.",
          hotel_reservation_rule_setting_deposit_rule_edit_validation_type: "* Sila pilih jenis.",
          hotel_reservation_rule_setting_deposit_rule_edit_validation_deposit_amount: "* Sila masukkan peratusan.",
          hotel_reservation_rule_setting_deposit_rule_edit_validation_transaction_code: "* Sila masukkan kod pembayaran.",
          hotel_reservation_rule_setting_deposit_rule_edit_validation_inactive: "* Sila pilih status penyahaktifan.",
          hotel_reservation_rule_setting_cancellation_rule_list_title: "SENARAI PERATURAN PEMBATALAN",
          hotel_reservation_rule_setting_cancellation_rule_list_select_active_type: "Jenis Aktif ( SEMUA )",
          hotel_reservation_rule_setting_cancellation_rule_list_select_active: "Aktif",
          hotel_reservation_rule_setting_cancellation_rule_list_select_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_cancellation_rule_list_placeholder_search: "Cari...",
          hotel_reservation_rule_setting_cancellation_rule_list_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_cancellation_rule_list_label_penalty_amount: "Jumlah Penalti",
          hotel_reservation_rule_setting_cancellation_rule_list_label_type: "taip",
          hotel_reservation_rule_setting_cancellation_rule_list_label_day_before_arrival: "Hari Sebelum Tiba",
          hotel_reservation_rule_setting_cancellation_rule_list_label_before_time: "Sebelum masa",
          hotel_reservation_rule_setting_cancellation_rule_list_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_cancellation_rule_list_label_created_at: "Dicipta Pada",
          hotel_reservation_rule_setting_cancellation_rule_list_button_delete: "Padam",
          hotel_reservation_rule_setting_cancellation_rule_list_button_add: "Tambah",
          hotel_reservation_rule_setting_cancellation_rule_list_guide_empty: "Senarai kosong",
          hotel_reservation_rule_setting_cancellation_rule_add_title: "TAMBAH PERATURAN PEMBATALAN",
          hotel_reservation_rule_setting_cancellation_rule_add_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_cancellation_rule_add_label_description: "Penerangan",
          hotel_reservation_rule_setting_cancellation_rule_add_label_type: "taip",
          hotel_reservation_rule_setting_cancellation_rule_add_placeholder_select_box_select: "Pilih",
          hotel_reservation_rule_setting_cancellation_rule_add_select_percent: "Peratusan",
          hotel_reservation_rule_setting_cancellation_rule_add_select_nights: "Malam-malam",
          hotel_reservation_rule_setting_cancellation_rule_add_select_flat: "Kadar rata",
          hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_amount: "Jumlah",
          hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_percent: "Peratusan",
          hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_nights: "Malam-malam",
          hotel_reservation_rule_setting_cancellation_rule_add_label_transaction_code: "Kod Transaksi",
          hotel_reservation_rule_setting_cancellation_rule_add_label_day_before_arrival: "Hari Sebelum Ketibaan (Hari)",
          hotel_reservation_rule_setting_cancellation_rule_add_label_before_time: "Sebelum Masa (Jam:Minit)",
          hotel_reservation_rule_setting_cancellation_rule_add_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_cancellation_rule_add_label_yes: "ya",
          hotel_reservation_rule_setting_cancellation_rule_add_label_no: "Tidak",
          hotel_reservation_rule_setting_cancellation_rule_add_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_rule_setting_cancellation_rule_add_button_add: "Tambah",
          hotel_reservation_rule_setting_cancellation_rule_add_validation_rule_name: "* Sila masukkan nama peraturan.",
          hotel_reservation_rule_setting_cancellation_rule_add_validation_description: "* Sila masukkan penerangan.",
          hotel_reservation_rule_setting_cancellation_rule_add_validation_type: "* Sila pilih jenis.",
          hotel_reservation_rule_setting_cancellation_rule_add_validation_penalty_amount: "* Sila masukkan peratusan.",
          hotel_reservation_rule_setting_cancellation_rule_add_validation_dayBefore_arrival: "* Sila masukkan kod pembayaran.",
          hotel_reservation_rule_setting_cancellation_rule_add_validation_transaction_code: "* Sila masukkan bilangan hari sebelum daftar masuk.",
          hotel_reservation_rule_setting_cancellation_rule_add_validation_inactive: "* Sila pilih status penyahaktifan.",
          hotel_reservation_rule_setting_cancellation_rule_add_validation_before_time: "* Sila masukkan masa sebelum daftar masuk.",
          hotel_reservation_rule_setting_cancellation_rule_detail_title: "MAKLUMAT PERATURAN PEMBATALAN",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_description: "Penerangan",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_type: "taip",
          hotel_reservation_rule_setting_cancellation_rule_detail_select_percent: "Peratusan",
          hotel_reservation_rule_setting_cancellation_rule_detail_select_nights: "Malam-malam",
          hotel_reservation_rule_setting_cancellation_rule_detail_select_flat: "Kadar rata",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_amount: "Jumlah",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_percent: "Peratusan",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_nights: "Malam-malam",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_day_before_arrival: "Hari Sebelum Ketibaan (Hari)",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_before_time: "Sebelum Masa (Jam:Minit)",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_transactio_code: "Kod Transaksi",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_yes: "ya",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_no: "Tidak",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_created_at: "Dicipta Pada",
          hotel_reservation_rule_setting_cancellation_rule_detail_label_updated_at: "Dikemaskini Pada",
          hotel_reservation_rule_setting_cancellation_rule_detail_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_rule_setting_cancellation_rule_detail_button_cancel: "Batal",
          hotel_reservation_rule_setting_cancellation_rule_detail_button_delete: "Padam",
          hotel_reservation_rule_setting_cancellation_rule_detail_button_edit: "Sunting",
          hotel_reservation_rule_setting_cancellation_rule_detail_button_ok: "okey",
          hotel_reservation_rule_setting_cancellation_rule_edit_validation_rule_name: "* Sila masukkan nama peraturan.",
          hotel_reservation_rule_setting_cancellation_rule_edit_validation_description: "* Sila masukkan penerangan.",
          hotel_reservation_rule_setting_cancellation_rule_edit_validation_type: "* Sila pilih jenis.",
          hotel_reservation_rule_setting_cancellation_rule_edit_validation_penalty_amount: "* Sila masukkan peratusan.",
          hotel_reservation_rule_setting_cancellation_rule_edit_validation_dayBefore_arrival: "* Sila masukkan kod pembayaran.",
          hotel_reservation_rule_setting_cancellation_rule_edit_validation_transaction_code: "* Sila masukkan bilangan hari sebelum daftar masuk.",
          hotel_reservation_rule_setting_cancellation_rule_edit_validation_inactive: "* Sila pilih status penyahaktifan.",
          hotel_reservation_rule_setting_cancellation_rule_edit_validation_before_time: "* Sila masukkan masa sebelum daftar masuk.",
          hotel_reservation_rule_setting_no_show_rule_list_title: "SENARAI PERATURAN TIADA TAYANGAN",
          hotel_reservation_rule_setting_no_show_rule_list_select_posting_rule: "Peraturan Penyiaran ( SEMUA )",
          hotel_reservation_rule_setting_no_show_rule_list_select_all_nights: "Sepanjang Malam",
          hotel_reservation_rule_setting_no_show_rule_list_select_deposit_only: "Deposit Sahaja",
          hotel_reservation_rule_setting_no_show_rule_list_select_first_night: "Malam pertama",
          hotel_reservation_rule_setting_no_show_rule_list_select_percent: "Peratusan",
          hotel_reservation_rule_setting_no_show_rule_list_placeholder_search: "Cari...",
          hotel_reservation_rule_setting_no_show_rule_list_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_no_show_rule_list_label_penalty_amount: "Jumlah Penalti",
          hotel_reservation_rule_setting_no_show_rule_list_label_source_code: "Kod sumber",
          hotel_reservation_rule_setting_no_show_rule_list_label_guarantee_type: "Jenis Jaminan",
          hotel_reservation_rule_setting_no_show_rule_list_label_posting_rule: "Peraturan Penyiaran",
          hotel_reservation_rule_setting_no_show_rule_list_label_created_at: "Dicipta Pada",
          hotel_reservation_rule_setting_no_show_rule_list_button_delete: "Padam",
          hotel_reservation_rule_setting_no_show_rule_list_button_add: "Tambah",
          hotel_reservation_rule_setting_no_show_rule_list_guide_empty: "Senarai kosong",
          hotel_reservation_rule_setting_no_show_rule_add_title: "TAMBAH PERATURAN TIDAK TAMPIL",
          hotel_reservation_rule_setting_no_show_rule_add_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_no_show_rule_add_label_description: "Penerangan",
          hotel_reservation_rule_setting_no_show_rule_add_label_source_code: "Kod sumber",
          hotel_reservation_rule_setting_no_show_rule_add_label_guarantee_type: "Jenis Jaminan",
          hotel_reservation_rule_setting_no_show_rule_add_label_posting_rule: "Peraturan Penyiaran",
          hotel_reservation_rule_setting_no_show_rule_add_select_all_nights: "Sepanjang Malam",
          hotel_reservation_rule_setting_no_show_rule_add_select_deposit_only: "Deposit Sahaja",
          hotel_reservation_rule_setting_no_show_rule_add_select_first_night: "Malam pertama",
          hotel_reservation_rule_setting_no_show_rule_add_select_percent: "Peratusan",
          hotel_reservation_rule_setting_no_show_rule_add_select_flat: "Kadar rata",
          hotel_reservation_rule_setting_no_show_rule_add_label_penalty_amount: "Jumlah Penalti",
          hotel_reservation_rule_setting_no_show_rule_add_label_penalty_percent: "Peratusan Penalti",
          hotel_reservation_rule_setting_no_show_rule_add_label_transaction_code: "Kod Transaksi",
          hotel_reservation_rule_setting_no_show_rule_add_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_rule_setting_no_show_rule_add_button_add: "Tambah",
          hotel_reservation_rule_setting_no_show_rule_add_validation_rule_name: "* Sila masukkan nama peraturan.",
          hotel_reservation_rule_setting_no_show_rule_add_validation_description: "* Sila masukkan penerangan.",
          hotel_reservation_rule_setting_no_show_rule_add_validation_posting_rule: "* Sila pilih peraturan pengebilan.",
          hotel_reservation_rule_setting_no_show_rule_add_validation_penalty_amount: "* Sila masukkan kod pembayaran.",
          hotel_reservation_rule_setting_no_show_rule_detail_title: "MAKLUMAT PERATURAN TIADA TAYANGAN",
          hotel_reservation_rule_setting_no_show_rule_detail_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_no_show_rule_detail_label_description: "Penerangan",
          hotel_reservation_rule_setting_no_show_rule_detail_label_source_code: "Kod sumber",
          hotel_reservation_rule_setting_no_show_rule_detail_label_guarantee_type: "Jenis Jaminan",
          hotel_reservation_rule_setting_no_show_rule_detail_label_posting_rule: "Peraturan Penyiaran",
          hotel_reservation_rule_setting_no_show_rule_detail_select_all_nights: "Sepanjang Malam",
          hotel_reservation_rule_setting_no_show_rule_detail_select_deposit_only: "Deposit Sahaja",
          hotel_reservation_rule_setting_no_show_rule_detail_select_first_night: "Malam pertama",
          hotel_reservation_rule_setting_no_show_rule_detail_select_percent: "Peratusan",
          hotel_reservation_rule_setting_no_show_rule_detaill_select_flat: "Kadar rata",
          hotel_reservation_rule_setting_no_show_rule_detail_label_penalty_amount: "Jumlah Penalti",
          hotel_reservation_rule_setting_no_show_rule_detail_label_penalty_percent: "Peratusan Penalti",
          hotel_reservation_rule_setting_no_show_rule_detail_label_transactio_code: "Kod Transaksi",
          hotel_reservation_rule_setting_no_show_rule_detail_label_created_at: "Dicipta Pada",
          hotel_reservation_rule_setting_no_show_rule_detail_label_updated_at: "Dikemaskini Pada",
          hotel_reservation_rule_setting_no_show_rule_detail_button_go_to_list: "Pergi ke senarai",
          hotel_reservation_rule_setting_no_show_rule_detail_button_cancel: "Batal",
          hotel_reservation_rule_setting_no_show_rule_detail_button_delete: "Padam",
          hotel_reservation_rule_setting_no_show_rule_detail_button_edit: "Sunting",
          hotel_reservation_rule_setting_no_show_rule_detail_button_ok: "okey",
          hotel_reservation_rule_setting_no_show_rule_edit_validation_rule_name: "* Sila masukkan nama peraturan.",
          hotel_reservation_rule_setting_no_show_rule_edit_validation_description: "* Sila masukkan penerangan.",
          hotel_reservation_rule_setting_no_show_rule_edit_validation_posting_rule: "* Sila pilih peraturan pengebilan.",
          hotel_reservation_rule_setting_no_show_rule_edit_validation_penalty_amount: "* Sila masukkan kod pembayaran.",
          hotel_reservation_rule_setting_rule_schedule_list_title: "SENARAI JADUAL PERATURAN",
          hotel_reservation_rule_setting_rule_schedule_list_label_active: "Aktif",
          hotel_reservation_rule_setting_rule_schedule_list_label_reservation_rule: "Peraturan Tempahan",
          hotel_reservation_rule_setting_rule_schedule_list_label_no: "Tidak.",
          hotel_reservation_rule_setting_rule_schedule_list_label_schedule_name: "Nama Jadual",
          hotel_reservation_rule_setting_rule_schedule_list_label_rate_code: "Kod Kadar",
          hotel_reservation_rule_setting_rule_schedule_list_label_begin_date: "Tarikh Mula",
          hotel_reservation_rule_setting_rule_schedule_list_label_end_date: "Tarikh tamat",
          hotel_reservation_rule_setting_rule_schedule_list_label_week: "Hari dalam seminggu",
          hotel_reservation_rule_setting_rule_schedule_list_label_week_all: "Semua",
          hotel_reservation_rule_setting_rule_schedule_list_label_created_at: "Dicipta Pada",
          hotel_reservation_rule_setting_rule_schedule_list_label_sorting: "Menyusun",
          hotel_reservation_rule_setting_rule_schedule_list_label_deposit_rule: "Peraturan Deposit",
          hotel_reservation_rule_setting_rule_schedule_list_label_cancellation_rule: "Peraturan Pembatalan",
          hotel_reservation_rule_setting_rule_schedule_list_label_no_show_rule: "Peraturan Tidak Menunjukkan",
          hotel_reservation_rule_setting_rule_schedule_list_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_rule_schedule_list_select_rule_type: "Jenis Peraturan ( SEMUA )",
          hotel_reservation_rule_setting_rule_schedule_list_select_reservation_rule: "Peraturan Tempahan",
          hotel_reservation_rule_setting_rule_schedule_list_select_deposit_rule: "Peraturan Deposit",
          hotel_reservation_rule_setting_rule_schedule_list_select_cancellation_rule: "Peraturan Pembatalan",
          hotel_reservation_rule_setting_rule_schedule_list_select_no_show_rule: "Peraturan Tidak Menunjukkan",
          hotel_reservation_rule_setting_rule_schedule_list_placeholder_search: "Cari...",
          hotel_reservation_rule_setting_rule_schedule_list_label_rule_type: "Jenis Peraturan",
          hotel_reservation_rule_setting_rule_schedule_list_label_delete: "Padam",
          hotel_reservation_rule_setting_rule_schedule_list_label_add: "Tambah",
          hotel_reservation_rule_setting_rule_schedule_list_guide_empty: "Senarai kosong",
          hotel_reservation_rule_setting_rule_schedule_add_title: "TAMBAH JADUAL PERATURAN",
          hotel_reservation_rule_setting_rule_schedule_add_label_rule_type: "Jenis Peraturan",
          hotel_reservation_rule_setting_rule_schedule_add_placeholder_select_box_select: "Pilih",
          hotel_reservation_rule_setting_rule_schedule_add_select_reservation_rule: "Peraturan Tempahan",
          hotel_reservation_rule_setting_rule_schedule_add_select_deposit_rule: "Peraturan Deposit",
          hotel_reservation_rule_setting_rule_schedule_add_select_cancellation_rule: "Peraturan Pembatalan",
          hotel_reservation_rule_setting_rule_schedule_add_select_no_show_rule: "Peraturan Tidak Menunjukkan",
          hotel_reservation_rule_setting_rule_schedule_add_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_rule_schedule_add_label_schedule_name: "Nama Jadual",
          hotel_reservation_rule_setting_rule_schedule_add_label_description: "Penerangan",
          hotel_reservation_rule_setting_rule_schedule_add_label_rate_code: "Kod Kadar",
          hotel_reservation_rule_setting_rule_schedule_add_label_begin_date: "Tarikh Mula",
          hotel_reservation_rule_setting_rule_schedule_add_label_end_date: "Tarikh tamat",
          hotel_reservation_rule_setting_rule_schedule_add_label_week: "Hari dalam seminggu",
          hotel_reservation_rule_setting_rule_schedule_add_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_rule_schedule_add_label_yes: "ya",
          hotel_reservation_rule_setting_rule_schedule_add_label_no: "Tidak",
          hotel_reservation_rule_setting_rule_schedule_add_label_sequence: "Urutan",
          hotel_reservation_rule_setting_rule_schedule_add_label_type: "taip",
          hotel_reservation_rule_setting_rule_schedule_add_label_amount: "Jumlah",
          hotel_reservation_rule_setting_rule_schedule_add_label_percent: "Peratusan",
          hotel_reservation_rule_setting_rule_schedule_add_label_nights: "Malam-malam",
          hotel_reservation_rule_setting_rule_schedule_add_label_transaction_code: "Kod Transaksi",
          hotel_reservation_rule_setting_rule_schedule_add_label_day_before_arrival: "Hari Sebelum Ketibaan (Hari)",
          hotel_reservation_rule_setting_rule_schedule_add_label_before_time: "Sebelum Masa (Jam:Minit)",
          hotel_reservation_rule_setting_rule_schedule_add_label_source_code: "Kod sumber",
          hotel_reservation_rule_setting_rule_schedule_add_label_guarantee_type: "Jenis Jaminan",
          hotel_reservation_rule_setting_rule_schedule_add_label_posting_rule: "Peraturan Penyiaran",
          hotel_reservation_rule_setting_rule_schedule_add_label_go_to_list: "Pergi ke senarai",
          hotel_reservation_rule_setting_rule_schedule_add_label_add: "Tambah",
          hotel_reservation_rule_setting_rule_schedule_add_validation_rule_type: "* Sila pilih jenis peraturan.",
          hotel_reservation_rule_setting_rule_schedule_add_validation_rule_name: "* Sila pilih nama peraturan.",
          hotel_reservation_rule_setting_rule_schedule_add_validation_schedule_name: "* Sila masukkan nama jadual.",
          hotel_reservation_rule_setting_rule_schedule_add_validation_description: "* Sila masukkan penerangan.",
          hotel_reservation_rule_setting_rule_schedule_add_validation_rate_code: "* Sila masukkan kod kadar.",
          hotel_reservation_rule_setting_rule_schedule_add_validation_begin_date: "* Sila pilih tarikh mula.",
          hotel_reservation_rule_setting_rule_schedule_add_validation_end_date: "* Sila pilih tarikh akhir.",
          hotel_reservation_rule_setting_rule_schedule_add_validation_week: "* Sila pilih hari.",
          hotel_reservation_rule_setting_rule_schedule_add_validation_inactive: "* Sila pilih status penyahaktifan.",
          hotel_reservation_rule_setting_rule_schedule_detail_title: "MAKLUMAT JADUAL PERATURAN",
          hotel_reservation_rule_setting_rule_schedule_detail_label_rule_type: "Jenis Peraturan",
          hotel_reservation_rule_setting_rule_schedule_detail_label_rule_name: "Nama Peraturan",
          hotel_reservation_rule_setting_rule_schedule_detail_label_schedule_name: "Nama Jadual",
          hotel_reservation_rule_setting_rule_schedule_detail_label_description: "Penerangan",
          hotel_reservation_rule_setting_rule_schedule_detail_label_rate_code: "Kod Kadar",
          hotel_reservation_rule_setting_rule_schedule_detail_label_begin_date: "Tarikh Mula",
          hotel_reservation_rule_setting_rule_schedule_detail_label_end_date: "Tarikh tamat",
          hotel_reservation_rule_setting_rule_schedule_detail_label_week: "Hari dalam seminggu",
          hotel_reservation_rule_setting_rule_schedule_detail_label_inactive: "Tidak aktif",
          hotel_reservation_rule_setting_rule_schedule_detail_label_yes: "ya",
          hotel_reservation_rule_setting_rule_schedule_detail_label_no: "Tidak",
          hotel_reservation_rule_setting_rule_schedule_detail_label_sequence: "Urutan",
          hotel_reservation_rule_setting_rule_schedule_detail_label_created_at: "Dicipta Pada",
          hotel_reservation_rule_setting_rule_schedule_detail_label_updated_at: "Dikemaskini Pada",
          hotel_reservation_rule_setting_rule_schedule_detail_label_type: "taip",
          hotel_reservation_rule_setting_rule_schedule_detail_label_amount: "Jumlah",
          hotel_reservation_rule_setting_rule_schedule_detail_label_percent: "Peratusan",
          hotel_reservation_rule_setting_rule_schedule_detail_label_nights: "Malam-malam",
          hotel_reservation_rule_setting_rule_schedule_detail_label_transaction_code: "Kod Transaksi",
          hotel_reservation_rule_setting_rule_schedule_detail_label_day_before_arrival: "Hari Sebelum Ketibaan (Hari)",
          hotel_reservation_rule_setting_rule_schedule_detail_label_before_time: "Sebelum Masa (Jam:Minit)",
          hotel_reservation_rule_setting_rule_schedule_detail_label_source_code: "Kod sumber",
          hotel_reservation_rule_setting_rule_schedule_detail_label_guarantee_type: "Jenis Jaminan",
          hotel_reservation_rule_setting_rule_schedule_detail_label_posting_rule: "Peraturan Penyiaran",
          hotel_reservation_rule_setting_rule_schedule_detail_label_go_to_list: "Pergi ke senarai",
          hotel_reservation_rule_setting_rule_schedule_detail_label_cancel: "Batal",
          hotel_reservation_rule_setting_rule_schedule_detail_label_delete: "Padam",
          hotel_reservation_rule_setting_rule_schedule_detail_label_edit: "Sunting",
          hotel_reservation_rule_setting_rule_schedule_detail_label_ok: "okey",
          hotel_reservation_rule_setting_rule_schedule_edit_validation_rule_type: "* Sila pilih jenis peraturan.",
          hotel_reservation_rule_setting_rule_schedule_edit_validation_rule_name: "* Sila pilih nama peraturan.",
          hotel_reservation_rule_setting_rule_schedule_edit_validation_schedule_name: "* Sila masukkan nama jadual.",
          hotel_reservation_rule_setting_rule_schedule_edit_validation_description: "* Sila masukkan penerangan.",
          hotel_reservation_rule_setting_rule_schedule_edit_validation_rate_code: "* Sila masukkan kod kadar.",
          hotel_reservation_rule_setting_rule_schedule_edit_validation_begin_date: "* Sila pilih tarikh mula.",
          hotel_reservation_rule_setting_rule_schedule_edit_validation_end_date: "* Sila pilih tarikh akhir.",
          hotel_reservation_rule_setting_rule_schedule_edit_validation_week: "* Sila pilih hari.",
          hotel_reservation_rule_setting_rule_schedule_edit_validation_inactive: "* Sila pilih status penyahaktifan.",
          hotel_inhouse_hotel_info_list_title: "MENU MAKLUMAT HOTEL",
          hotel_inhouse_hotel_info_list_button_add: "Tambah",
          hotel_inhouse_hotel_info_list_button_delete: "Padam",
          hotel_inhouse_hotel_info_list_button_edit: "Sunting",
          hotel_inhouse_hotel_info_list_guide_add: "Sila tambah maklumat hotel.",
          hotel_inhouse_hotel_info_add_title: "TAMBAH MAKLUMAT HOTEL",
          hotel_inhouse_hotel_info_add_placeholder_title: "Sila masukkan tajuk.",
          hotel_inhouse_hotel_info_add_placeholder_index: "Sila masukkan indeks.",
          hotel_inhouse_hotel_info_add_select_choose: "Sila pilih jenis.",
          hotel_inhouse_hotel_info_add_select_address: "Alamat Hotel",
          hotel_inhouse_hotel_info_add_select_wifi: "WiFi",
          hotel_inhouse_hotel_info_add_select_smoking: "Tidak merokok",
          hotel_inhouse_hotel_info_add_select_transportation: "Pengangkutan",
          hotel_inhouse_hotel_info_add_select_parking: "Tempat letak kenderaan",
          hotel_inhouse_hotel_info_add_select_fitness_center: "Pusat kecergasan",
          hotel_inhouse_hotel_info_add_select_business_center: "Pusat perniagaan",
          hotel_inhouse_hotel_info_add_select_guest_service: "Perkhidmatan Tetamu",
          hotel_inhouse_hotel_info_add_select_room_service: "Servis bilik",
          hotel_inhouse_hotel_info_add_select_breakfast: "Sarapan pagi",
          hotel_inhouse_hotel_info_add_select_dining: "Makan",
          hotel_inhouse_hotel_info_add_select_executive_lounge: "Lounge Eksekutif",
          hotel_inhouse_hotel_info_add_toolbar_bold: "berani",
          hotel_inhouse_hotel_info_add_toolbar_italic: "Italic",
          hotel_inhouse_hotel_info_add_toolbar_underline: "Garis bawah",
          hotel_inhouse_hotel_info_add_toolbar_remove_font_style: "Alih keluar gaya fon",
          hotel_inhouse_hotel_info_add_toolbar_font_family: "Keluarga fon",
          hotel_inhouse_hotel_info_add_toolbar_font_size: "Saiz huruf",
          hotel_inhouse_hotel_info_add_toolbar_recent_color: "Warna terkini",
          hotel_inhouse_hotel_info_add_toolbar_recent_more_color: "Lebih Banyak Warna",
          hotel_inhouse_hotel_info_add_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_inhouse_hotel_info_add_toolbar_ordered_list: "Senarai pesanan",
          hotel_inhouse_hotel_info_add_toolbar_paragraph: "Perenggan",
          hotel_inhouse_hotel_info_add_toolbar_line_height: "Ketinggian garisan",
          hotel_inhouse_hotel_info_add_placeholder_content: "Sila masukkan kandungan.",
          hotel_inhouse_hotel_info_add_button_cancel: "Batal",
          hotel_inhouse_hotel_info_add_button_ok: "okey",
          hotel_inhouse_hotel_info_edit_title: "EDIT MAKLUMAT HOTEL",
          hotel_inhouse_hotel_info_edit_placeholder_title: "Tajuk",
          hotel_inhouse_hotel_info_edit_select_kr: "KR",
          hotel_inhouse_hotel_info_edit_select_en: "EN",
          hotel_inhouse_hotel_info_edit_select_cn: "CN",
          hotel_inhouse_hotel_info_edit_select_jp: "JP",
          hotel_inhouse_hotel_info_edit_placeholder_index: "Indeks",
          hotel_inhouse_hotel_info_edit_toolbar_bold: "berani",
          hotel_inhouse_hotel_info_edit_toolbar_italic: "Italic",
          hotel_inhouse_hotel_info_edit_toolbar_underline: "Garis bawah",
          hotel_inhouse_hotel_info_edit_toolbar_remove_font_style: "Alih keluar gaya fon",
          hotel_inhouse_hotel_info_edit_toolbar_font_family: "Keluarga fon",
          hotel_inhouse_hotel_info_edit_toolbar_font_size: "Saiz huruf",
          hotel_inhouse_hotel_info_edit_toolbar_recent_color: "Warna terkini",
          hotel_inhouse_hotel_info_edit_toolbar_recent_more_color: "Lebih banyak warna",
          hotel_inhouse_hotel_info_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_inhouse_hotel_info_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_inhouse_hotel_info_edit_toolbar_paragraph: "Perenggan",
          hotel_inhouse_hotel_info_edit_toolbar_code_view: "Pandangan Kod",
          hotel_inhouse_hotel_info_edit_toolbar_line_height: "Ketinggian Garisan",
          hotel_inhouse_hotel_info_edit_button_append: "Lampirkan",
          hotel_inhouse_hotel_info_edit_button_remove: "Alih keluar",
          hotel_inhouse_hotel_info_edit_button_cancel: "Batal",
          hotel_inhouse_hotel_info_edit_button_ok: "okey",
          hotel_inhouse_room_to_room_list_title: "SENARAI BILIK KE BILIK",
          hotel_inhouse_room_to_room_list_button_alive: "Aktif",
          hotel_inhouse_room_to_room_list_placeholder_select_box_room_to_room: "Bilik ke Bilik",
          hotel_inhouse_room_to_room_list_select_room_no: "Nombor bilik.",
          hotel_inhouse_room_to_room_list_select_email: "E-mel",
          hotel_inhouse_room_to_room_list_select_user_name: "Nama Pengguna",
          hotel_inhouse_room_to_room_list_placeholder_search: "Cari...",
          hotel_inhouse_room_to_room_list_label_no: "Tidak.",
          hotel_inhouse_room_to_room_list_label_sender_room_no: "Bilik Pengirim No.",
          hotel_inhouse_room_to_room_list_label_sender_name: "Nama pengirim",
          hotel_inhouse_room_to_room_list_label_sender_email: "E-mel Pengirim",
          hotel_inhouse_room_to_room_list_label_key_type: "Jenis Kekunci",
          hotel_inhouse_room_to_room_list_label_expired_at: "Tamat tempoh Pada",
          hotel_inhouse_room_to_room_list_label_receiver_room_no: "Bilik Penerima No.",
          hotel_inhouse_room_to_room_list_label_receiver_email: "E-mel Penerima",
          hotel_inhouse_room_to_room_list_label_receiver_status: "Status Penerima",
          hotel_inhouse_room_to_room_list_label_date: "Tarikh",
          hotel_inhouse_room_to_room_list_guide_empty: "Senarai kosong",
          hotel_inhouse_room_to_room_detail_title: "BUTIRAN BILIK KE BILIK",
          hotel_inhouse_room_to_room_detail_label_sender_room_no: "Bilik Pengirim No.",
          hotel_inhouse_room_to_room_detail_label_sender_name: "Nama pengirim",
          hotel_inhouse_room_to_room_detail_label_sender_email: "E-mel Pengirim",
          hotel_inhouse_room_to_room_detail_label_key_type: "Jenis Kunci Bilik ke Bilik",
          hotel_inhouse_room_to_room_detail_label_expired_at: "Bilik ke Bilik Tamat",
          hotel_inhouse_room_to_room_detail_label_receiver_room_no: "Bilik Penerima No.",
          hotel_inhouse_room_to_room_detail_label_receiver_email: "E-mel Penerima",
          hotel_inhouse_room_to_room_detail_label_receiver_status: "Status Penerima",
          hotel_inhouse_room_to_room_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_room_to_room_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_concierge_request_list_title: "SENARAI PERMINTAAN CONCIERGE (Sebelum Pengesahan)",
          hotel_inhouse_concierge_request_list_label_that_date_request: "Bilangan Permintaan Tetamu",
          hotel_inhouse_concierge_request_list_label_that_date_not_confirmed: "Permintaan Tetamu Tidak Disahkan",
          hotel_inhouse_concierge_request_list_label_taxi_ex: "Teksi Ekspres",
          hotel_inhouse_concierge_request_list_label_taxi: "Teksi",
          hotel_inhouse_concierge_request_list_label_parcel_ex: "Perkhidmatan Parcel Express",
          hotel_inhouse_concierge_request_list_label_parcel: "Perkhidmatan Bungkusan",
          hotel_inhouse_concierge_request_list_label_luggage_ex: "Perkhidmatan Luggage Express",
          hotel_inhouse_concierge_request_list_label_luggage: "Perkhidmatan Bagasi",
          hotel_inhouse_concierge_request_list_label_total: "Jumlah",
          hotel_inhouse_concierge_request_list_select_request_type: "Jenis Permintaan ( SEMUA )",
          hotel_inhouse_concierge_request_list_select_request_type_all: "Semua",
          hotel_inhouse_concierge_request_list_select_taxi: "Teksi",
          hotel_inhouse_concierge_request_list_select_parcel: "Perkhidmatan Bungkusan",
          hotel_inhouse_concierge_request_list_select_luggage: "Perkhidmatan Bagasi",
          hotel_inhouse_concierge_request_list_select_express: "Tugasan ( SEMUA )",
          hotel_inhouse_concierge_request_list_select_express_all: "Semua",
          hotel_inhouse_concierge_request_list_select_yes: "Ekspres",
          hotel_inhouse_concierge_request_list_select_no: "Biasalah",
          hotel_inhouse_concierge_request_list_select_request: "Permintaan ( SEMUA )",
          hotel_inhouse_concierge_request_list_select_request_all: "Semua",
          hotel_inhouse_concierge_request_list_select_room_no: "Nombor bilik.",
          hotel_inhouse_concierge_request_list_select_user_name: "Nama Pengguna",
          hotel_inhouse_concierge_request_list_placeholder_search: "Cari...",
          hotel_inhouse_concierge_request_list_label_room_no: "Nombor bilik.",
          hotel_inhouse_concierge_request_list_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_concierge_request_list_label_user_name: "Nama Pengguna",
          hotel_inhouse_concierge_request_list_label_request_date: "Tarikh Permintaan",
          hotel_inhouse_concierge_request_list_label_request_type: "Jenis Permintaan",
          hotel_inhouse_concierge_request_list_label_express: "Tugasan",
          hotel_inhouse_concierge_request_list_label_number_of_item: "Bilangan Item",
          hotel_inhouse_concierge_request_list_label_action_status: "Status Tindakan",
          hotel_inhouse_concierge_request_list_label_date: "Tarikh",
          hotel_inhouse_concierge_request_list_guide_empty: "Senarai kosong",
          hotel_inhouse_concierge_request_detail_title: "PERMINTAAN BUTIRAN",
          hotel_inhouse_concierge_request_detail_label_room_no: "Nombor bilik.",
          hotel_inhouse_concierge_request_detail_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_concierge_request_detail_label_user_name: "Nama Pengguna",
          hotel_inhouse_concierge_request_detail_label_email: "E-mel",
          hotel_inhouse_concierge_request_detail_label_stay: "Kekal",
          hotel_inhouse_concierge_request_detail_label_request_type: "Jenis Permintaan",
          hotel_inhouse_concierge_request_detail_label_status: "Status",
          hotel_inhouse_concierge_request_detail_label_number_of_item: "Bilangan Item",
          hotel_inhouse_concierge_request_detail_label_request_at: "Diminta Pada",
          hotel_inhouse_concierge_request_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_concierge_request_detail_label_total: "Jumlah",
          hotel_inhouse_concierge_request_detail_button_not_available: "Tidak Tersedia",
          hotel_inhouse_concierge_request_detail_button_myconect: "MyCONECT",
          hotel_inhouse_concierge_request_detail_button_confirm: "sahkan",
          hotel_inhouse_concierge_request_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_concierge_request_modal_title: "MyCONECT",
          hotel_inhouse_concierge_request_modal_button_one_time: "Satu kali",
          hotel_inhouse_concierge_request_modal_button_one_hour: "Selepas 1 jam",
          hotel_inhouse_concierge_request_modal_button_two_hour: "Selepas 2 jam",
          hotel_inhouse_concierge_request_modal_button_three_hour: "Selepas 3 jam",
          hotel_inhouse_concierge_request_modal_button_unlimited: "Tidak diketahui",
          hotel_inhouse_concierge_request_modal_button_cancel: "Batal",
          hotel_inhouse_concierge_process_list_title: "SENARAI KEMAJUAN CONCIERGE\n(Selepas Pengesahan)",
          hotel_inhouse_concierge_process_list_label_request: "Bilangan Permintaan Tetamu",
          hotel_inhouse_concierge_process_list_label_unassigned: "Tarikh Tidak Ditugaskan",
          hotel_inhouse_concierge_process_list_label_taxi_ex: "Teksi Ekspres",
          hotel_inhouse_concierge_process_list_label_taxi: "Teksi",
          hotel_inhouse_concierge_process_list_label_parcel_ex: "Perkhidmatan Parcel Express",
          hotel_inhouse_concierge_process_list_label_parcel: "Perkhidmatan Bungkusan",
          hotel_inhouse_concierge_process_list_label_luggage_ex: "Perkhidmatan Luggage Express",
          hotel_inhouse_concierge_process_list_label_luggage: "Perkhidmatan Bagasi",
          hotel_inhouse_concierge_process_list_label_total: "Jumlah",
          hotel_inhouse_concierge_process_list_select_request_type: "Jenis Permintaan ( SEMUA )",
          hotel_inhouse_concierge_process_list_select_taxi: "Teksi",
          hotel_inhouse_concierge_process_list_select_parcel: "Perkhidmatan Bungkusan",
          hotel_inhouse_concierge_process_list_select_luggage: "Perkhidmatan Bagasi",
          hotel_inhouse_concierge_process_list_select_express: "Tugasan ( SEMUA )",
          hotel_inhouse_concierge_process_list_select_yes: "Ekspres",
          hotel_inhouse_concierge_process_list_select_no: "Biasalah",
          hotel_inhouse_concierge_process_list_select_request: "Permintaan ( SEMUA )",
          hotel_inhouse_concierge_process_list_select_room_no: "Nombor bilik.",
          hotel_inhouse_concierge_process_list_select_user_name: "Nama Pengguna",
          hotel_inhouse_concierge_process_list_placeholder_search: "Cari...",
          hotel_inhouse_concierge_process_list_label_room_no: "Nombor bilik.",
          hotel_inhouse_concierge_process_list_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_concierge_process_list_label_user_name: "Nama Pengguna",
          hotel_inhouse_concierge_process_list_label_request_date: "Tarikh Permintaan",
          hotel_inhouse_concierge_process_list_label_request_type: "Jenis Permintaan",
          hotel_inhouse_concierge_process_list_label_express: "Tugasan",
          hotel_inhouse_concierge_process_list_label_number_of_item: "Bilangan Item",
          hotel_inhouse_concierge_process_list_label_action_status: "Status Tindakan",
          hotel_inhouse_concierge_process_list_label_date: "Tarikh",
          hotel_inhouse_concierge_process_list_guide_empty: "Senarai kosong",
          hotel_inhouse_concierge_process_detail_title: "PERMINTAAN (Selepas Pengesahan) DETAIL",
          hotel_inhouse_concierge_process_detail_label_room_no: "Nombor bilik.",
          hotel_inhouse_concierge_process_detail_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_concierge_process_detail_label_user_name: "Nama Pengguna",
          hotel_inhouse_concierge_process_detail_label_email: "E-mel",
          hotel_inhouse_concierge_process_detail_label_stay: "Kekal",
          hotel_inhouse_concierge_process_detail_label_request_type: "Jenis Permintaan",
          hotel_inhouse_concierge_process_detail_label_status: "Status",
          hotel_inhouse_concierge_process_detail_label_number_of_item: "Bilangan Item",
          hotel_inhouse_concierge_process_detail_label_request_at: "Permintaan Di",
          hotel_inhouse_concierge_process_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_concierge_process_detail_button_stand_by: "Berdiri",
          hotel_inhouse_concierge_process_detail_button_vehicle_no: "Kenderaan No.",
          hotel_inhouse_concierge_process_detail_button_complete: "lengkap",
          hotel_inhouse_concierge_process_detail_button_stop: "Berhenti",
          hotel_inhouse_concierge_process_detail_button_pickup: "Pickup",
          hotel_inhouse_concierge_process_detail_button_start: "Mulakan",
          hotel_inhouse_concierge_process_detail_button_deliver: "Hantarkan",
          hotel_inhouse_concierge_process_detail_button_change: "Ubah",
          hotel_inhouse_concierge_process_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_concierge_process_modal_title_vehicle_no: "Kenderaan No.",
          hotel_inhouse_concierge_process_modal_placeholder_vehicle_no: "Masukkan no kenderaan.",
          hotel_inhouse_concierge_process_modal_title_luggage: "Bilangan Bagasi",
          hotel_inhouse_concierge_process_modal_placeholder_luggage: "Masukkan nombor bagasi.",
          hotel_inhouse_concierge_process_modal_title_parcel: "Maklumat Bungkusan",
          hotel_inhouse_concierge_process_modal_button_cancel: "Batal",
          hotel_inhouse_concierge_process_modal_button_ok: "okey",
          hotel_inhouse_concierge_process_modal_title_stop_message: "Hentikan Mesej",
          hotel_inhouse_concierge_process_modal_button_unknown_situation: "Status Tidak Diketahui",
          hotel_inhouse_concierge_process_modal_button_no_taxi: "Teksi tidak hadir",
          hotel_inhouse_concierge_process_modal_button_no_guest: "Tetamu tidak hadir",
          hotel_inhouse_concierge_process_modal_button_waiting_30: "Masa menunggu melebihi 30 min",
          hotel_inhouse_concierge_process_modal_button_waiting_60: "Masa menunggu melebihi 60 min",
          hotel_inhouse_concierge_process_modal_button_no_motorcycle: "Penghantaran motosikal tidak hadir",
          hotel_inhouse_concierge_process_modal_button_not_luggage: "Bagasi belum siap",
          hotel_inhouse_concierge_myconect_list_title: "SENARAI KEMAJUAN JADUAL KONCIERGE",
          hotel_inhouse_concierge_myconect_list_label_user_count: "Dibatalkan Mengikut Kiraan Pengguna",
          hotel_inhouse_concierge_myconect_list_label_hotel_count: "Dibatalkan Oleh Kiraan Hotel",
          hotel_inhouse_concierge_myconect_list_label_taxi_ex: "Teksi Ekspres",
          hotel_inhouse_concierge_myconect_list_label_taxi: "Teksi",
          hotel_inhouse_concierge_myconect_list_label_parcel_ex: "Perkhidmatan Parcel Express",
          hotel_inhouse_concierge_myconect_list_label_parcel: "Perkhidmatan Bungkusan",
          hotel_inhouse_concierge_myconect_list_label_luggage_ex: "Perkhidmatan Luggage Express",
          hotel_inhouse_concierge_myconect_list_label_luggage: "Perkhidmatan Bagasi",
          hotel_inhouse_concierge_myconect_list_label_total: "Jumlah",
          hotel_inhouse_concierge_myconect_list_label_room_no: "Nombor bilik.",
          hotel_inhouse_concierge_myconect_list_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_concierge_myconect_list_label_user_name: "Nama Pengguna",
          hotel_inhouse_concierge_myconect_list_label_request_date: "Tarikh Permintaan",
          hotel_inhouse_concierge_myconect_list_label_request_type: "Jenis Permintaan",
          hotel_inhouse_concierge_myconect_list_label_express: "Tugasan",
          hotel_inhouse_concierge_myconect_list_label_action_status: "Status Tindakan",
          hotel_inhouse_concierge_myconect_list_label_myconect_at: "MyCONECT Di",
          hotel_inhouse_concierge_myconect_list_label_completed_at: "Selesai Pada",
          hotel_inhouse_concierge_myconect_list_label_date: "Tarikh",
          hotel_inhouse_concierge_myconect_list_guide_empty: "Senarai kosong",
          hotel_inhouse_concierge_myconect_detail_title: "BUTIR-BUTIR KEMAJUAN JADUAL",
          hotel_inhouse_concierge_myconect_detail_label_room_no: "Nombor bilik.",
          hotel_inhouse_concierge_myconect_detail_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_concierge_myconect_detail_label_user_name: "Nama Pengguna",
          hotel_inhouse_concierge_myconect_detail_label_email: "E-mel",
          hotel_inhouse_concierge_myconect_detail_label_stay: "Kekal",
          hotel_inhouse_concierge_myconect_detail_label_request_type: "Jenis Permintaan",
          hotel_inhouse_concierge_myconect_detail_label_status: "Status",
          hotel_inhouse_concierge_myconect_detail_label_number_of_item: "Bilangan Item",
          hotel_inhouse_concierge_myconect_detail_label_request_at: "Diminta Pada",
          hotel_inhouse_concierge_myconect_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_concierge_myconect_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_concierge_multi_myconect_list_title: "SENARAI PENETAPAN JADUAL KONCIERGE",
          hotel_inhouse_concierge_multi_myconect_list_button_alive: "Aktif",
          hotel_inhouse_concierge_multi_myconect_list_label_staff_number: "Nombor Kakitangan",
          hotel_inhouse_concierge_multi_myconect_list_label_request: "Permintaan",
          hotel_inhouse_concierge_multi_myconect_list_label_department: "Jabatan",
          hotel_inhouse_concierge_multi_myconect_list_label_email: "E-mel",
          hotel_inhouse_concierge_multi_myconect_list_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_concierge_multi_myconect_list_label_from: "daripada",
          hotel_inhouse_concierge_multi_myconect_list_label_to: "Kepada",
          hotel_inhouse_concierge_multi_myconect_list_label_date: "Tarikh",
          hotel_inhouse_concierge_multi_myconect_list_button_add: "Tambah",
          hotel_inhouse_concierge_multi_myconect_add_title: "TAMBAHKAN TETAPAN JADUAL CONCIERGE",
          hotel_inhouse_concierge_multi_myconect_add_label_request_type: "Jenis Permintaan",
          hotel_inhouse_concierge_multi_myconect_add_placeholder_select_box_choose: "Pilih",
          hotel_inhouse_concierge_multi_myconect_add_select_box_luggage: "Perkhidmatan Bagasi",
          hotel_inhouse_concierge_multi_myconect_add_select_box_parcel: "Perkhidmatan Bungkusan",
          hotel_inhouse_concierge_multi_myconect_add_select_box_taxi: "Teksi",
          hotel_inhouse_concierge_multi_myconect_add_label_date: "Tarikh",
          hotel_inhouse_concierge_multi_myconect_add_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_concierge_multi_myconect_add_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_concierge_multi_myconect_add_button_add: "Tambah",
          hotel_inhouse_concierge_multi_myconect_add_validation_type_request: "* Sila Pilih Jenis Permintaan.",
          hotel_inhouse_concierge_multi_myconect_add_validation_period_start: "* Sila Pilih Tarikh.",
          hotel_inhouse_concierge_multi_myconect_add_validation_period_end: "* Sila Pilih Tarikh.",
          hotel_inhouse_concierge_multi_myconect_add_validation_respond: "* Sila Pilih Respons MyCONECT.",
          hotel_inhouse_concierge_multi_myconect_detail_title: "MAKLUMAT PENETAPAN JADUAL CONCIERGE",
          hotel_inhouse_concierge_multi_myconect_detail_label_update: "Tarikh Dikemaskini",
          hotel_inhouse_concierge_multi_myconect_detail_label_date: "Tarikh",
          hotel_inhouse_concierge_multi_myconect_detail_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_concierge_multi_myconect_detail_label_staff_number: "Nombor Kakitangan",
          hotel_inhouse_concierge_multi_myconect_detail_label_request: "Permintaan",
          hotel_inhouse_concierge_multi_myconect_detail_label_department: "Jabatan",
          hotel_inhouse_concierge_multi_myconect_detail_label_email: "E-mel",
          hotel_inhouse_concierge_multi_myconect_detail_label_released_at: "Dikeluarkan Pada",
          hotel_inhouse_concierge_multi_myconect_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_concierge_multi_myconect_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_concierge_multi_myconect_detail_button_cancel: "Batal",
          hotel_inhouse_concierge_multi_myconect_detail_button_release: "Lepaskan",
          hotel_inhouse_concierge_multi_myconect_detail_button_edit: "Sunting",
          hotel_inhouse_concierge_multi_myconect_detail_button_ok: "okey",
          hotel_inhouse_housekeeping_request_list_title: "SENARAI PERMINTAAN HOUSEKEEPING\n(Sebelum Pengesahan)",
          hotel_inhouse_housekeeping_request_list_label_request: "Bilangan Permintaan Tetamu",
          hotel_inhouse_housekeeping_request_list_label_not_confirmed: "Permintaan Tetamu Tidak Disahkan",
          hotel_inhouse_housekeeping_request_list_label_mur: "Bilik Solek",
          hotel_inhouse_housekeeping_request_list_label_tds: "Perkhidmatan Turndown",
          hotel_inhouse_housekeeping_request_list_label_green: "Kempen Hijau",
          hotel_inhouse_housekeeping_request_list_label_ironing_ex: "Perkhidmatan Menyeterika Ekspres",
          hotel_inhouse_housekeeping_request_list_label_ironing: "Perkhidmatan Menyeterika",
          hotel_inhouse_housekeeping_request_list_label_laundry_ex: "Perkhidmatan Dobi Ekspres",
          hotel_inhouse_housekeeping_request_list_label_laundry: "Perkhidmatan dobi",
          hotel_inhouse_housekeeping_request_list_label_amenity: "Perkhidmatan Kemudahan",
          hotel_inhouse_housekeeping_request_list_label_total: "Jumlah",
          hotel_inhouse_housekeeping_request_list_select_request_type: "Jenis Permintaan ( SEMUA )",
          hotel_inhouse_housekeeping_request_list_select_mur: "Bilik Solek",
          hotel_inhouse_housekeeping_request_list_select_tds: "Perkhidmatan Turndown",
          hotel_inhouse_housekeeping_request_list_select_green: "Kempen Hijau",
          hotel_inhouse_housekeeping_request_list_select_ironing: "Perkhidmatan Menyeterika",
          hotel_inhouse_housekeeping_request_list_select_laundry: "Perkhidmatan dobi",
          hotel_inhouse_housekeeping_request_list_select_amenity: "Perkhidmatan Kemudahan",
          hotel_inhouse_housekeeping_request_list_select_request_express: "Tugasan ( SEMUA )",
          hotel_inhouse_housekeeping_request_list_select_yes: "Ekspres",
          hotel_inhouse_housekeeping_request_list_select_no: "Biasalah",
          hotel_inhouse_housekeeping_request_list_select_request: "Permintaan ( SEMUA )",
          hotel_inhouse_housekeeping_request_list_select_room_no: "Nombor bilik.",
          hotel_inhouse_housekeeping_request_list_select_user_name: "Nama Pengguna",
          hotel_inhouse_housekeeping_request_list_placeholder_search: "Cari...",
          hotel_inhouse_housekeeping_request_list_label_roon_no: "Nombor bilik.",
          hotel_inhouse_housekeeping_request_list_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_housekeeping_request_list_label_user_name: "Nama Pengguna",
          hotel_inhouse_housekeeping_request_list_label_request_date: "Tarikh Permintaan",
          hotel_inhouse_housekeeping_request_list_label_request_type: "Jenis Permintaan",
          hotel_inhouse_housekeeping_request_list_label_express: "Tugasan",
          hotel_inhouse_housekeeping_request_list_label_action_status: "Status Tindakan",
          hotel_inhouse_housekeeping_request_list_label_date: "Tarikh",
          hotel_inhouse_housekeeping_request_list_guide_empty: "Senarai kosong",
          hotel_inhouse_housekeeping_request_detail_title: "PERMINTAAN BUTIRAN",
          hotel_inhouse_housekeeping_request_detail_label_roon_no: "Nombor bilik.",
          hotel_inhouse_housekeeping_request_detail_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_housekeeping_request_detail_label_user_name: "Nama Pengguna",
          hotel_inhouse_housekeeping_request_detail_label_email: "E-mel",
          hotel_inhouse_housekeeping_request_detail_label_stay: "Kekal",
          hotel_inhouse_housekeeping_request_detail_label_request_type: "Jenis Permintaan",
          hotel_inhouse_housekeeping_request_detail_label_status: "Status",
          hotel_inhouse_housekeeping_request_detail_label_request_at: "Diminta Pada",
          hotel_inhouse_housekeeping_request_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_housekeeping_request_detail_label_total: "Jumlah",
          hotel_inhouse_housekeeping_request_detail_button_not_availble: "Tidak Tersedia",
          hotel_inhouse_housekeeping_request_detail_button_myconect: "MyCONECT",
          hotel_inhouse_housekeeping_request_detail_button_auto: "Auto",
          hotel_inhouse_housekeeping_request_detail_button_delay: "kelewatan",
          hotel_inhouse_housekeeping_request_detail_button_confirm: "sahkan",
          hotel_inhouse_housekeeping_request_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_housekeeping_request_modal_title_delay: "Tutup Mesej",
          hotel_inhouse_housekeeping_request_modal_button_delay_30: "Kelewatan 30 min",
          hotel_inhouse_housekeeping_request_modal_button_delay_60: "Kelewatan 60 min",
          hotel_inhouse_housekeeping_request_modal_button_as_soon_as: "Sebaik sahaja tersedia",
          hotel_inhouse_housekeeping_request_modal_title_myconect: "MyCONECT",
          hotel_inhouse_housekeeping_request_modal_button_one_time: "Satu kali",
          hotel_inhouse_housekeeping_request_modal_button_one_hour: "Selepas 1 jam",
          hotel_inhouse_housekeeping_request_modal_button_two_hour: "Selepas 2 jam",
          hotel_inhouse_housekeeping_request_modal_button_three_hour: "Selepas 3 jam",
          hotel_inhouse_housekeeping_request_modal_button_unlimited: "Tidak diketahui",
          hotel_inhouse_housekeeping_request_modal_button_cancel: "Batal",
          hotel_inhouse_housekeeping_process_list_title: "SENARAI KEMAJUAN HOUSEKEEPING (Disahkan)",
          hotel_inhouse_housekeeping_process_list_label_request: "Bilangan Permintaan Tetamu",
          hotel_inhouse_housekeeping_process_list_label_unassigned: "Tarikh Tidak Ditugaskan",
          hotel_inhouse_housekeeping_process_list_label_mur: "Bilik Solek",
          hotel_inhouse_housekeeping_process_list_label_tds: "Perkhidmatan Turndown",
          hotel_inhouse_housekeeping_process_list_label_housekeeping_total: "Jumlah",
          hotel_inhouse_housekeeping_process_list_label_ironing_ex: "Perkhidmatan Menyeterika Ekspres",
          hotel_inhouse_housekeeping_process_list_label_ironing: "Perkhidmatan Menyeterika",
          hotel_inhouse_housekeeping_process_list_label_laundry_ex: "Perkhidmatan Dobi Ekspres",
          hotel_inhouse_housekeeping_process_list_label_laundry: "Perkhidmatan dobi",
          hotel_inhouse_housekeeping_process_list_label_guest_service_total: "Jumlah",
          hotel_inhouse_housekeeping_process_list_label_green: "Kempen Hijau",
          hotel_inhouse_housekeeping_process_list_label_dnd: "DND",
          hotel_inhouse_housekeeping_process_list_label_amenity_number: "Ameniti Bilangan Item",
          hotel_inhouse_housekeeping_process_list_label_bath_towel: "Tuala mandi",
          hotel_inhouse_housekeeping_process_list_label_face_towel: "Tuala Muka",
          hotel_inhouse_housekeeping_process_list_label_hand_towel: "Tuala tangan",
          hotel_inhouse_housekeeping_process_list_label_bath_gown: "Jubah mandi",
          hotel_inhouse_housekeeping_process_list_label_razor: "Pisau cukur",
          hotel_inhouse_housekeeping_process_list_label_shampoo: "Syampu",
          hotel_inhouse_housekeeping_process_list_label_conditioner: "Perapi",
          hotel_inhouse_housekeeping_process_list_label_soap: "sabun",
          hotel_inhouse_housekeeping_process_list_label_amenity_total: "Jumlah",
          hotel_inhouse_housekeeping_process_list_select_request_type: "Jenis Permintaan ( SEMUA )",
          hotel_inhouse_housekeeping_process_list_select_mur: "Bilik Solek",
          hotel_inhouse_housekeeping_process_list_select_tds: "Perkhidmatan Turndown",
          hotel_inhouse_housekeeping_process_list_select_green: "Kempen Hijau",
          hotel_inhouse_housekeeping_process_list_select_dnd: "DND",
          hotel_inhouse_housekeeping_process_list_select_ironing: "Perkhidmatan Menyeterika",
          hotel_inhouse_housekeeping_process_list_select_laundry: "Perkhidmatan dobi",
          hotel_inhouse_housekeeping_process_list_select_amenity: "Perkhidmatan Kemudahan",
          hotel_inhouse_housekeeping_process_list_select_express: "Tugasan ( SEMUA )",
          hotel_inhouse_housekeeping_process_list_select_express_all: "Semua",
          hotel_inhouse_housekeeping_process_list_select_yes: "Ekspres",
          hotel_inhouse_housekeeping_process_list_select_no: "Biasalah",
          hotel_inhouse_housekeeping_process_list_select_floor: "Lantai ( SEMUA )",
          hotel_inhouse_housekeeping_process_list_select_floor_1: "1 tingkat",
          hotel_inhouse_housekeeping_process_list_select_floor_2: "2 tingkat",
          hotel_inhouse_housekeeping_process_list_select_floor_3: "3 tingkat",
          hotel_inhouse_housekeeping_process_list_select_floor_4: "4 tingkat",
          hotel_inhouse_housekeeping_process_list_select_floor_5: "5 tingkat",
          hotel_inhouse_housekeeping_process_list_select_floor_6: "6 tingkat",
          hotel_inhouse_housekeeping_process_list_select_floor_7: "7 tingkat",
          hotel_inhouse_housekeeping_process_list_select_floor_8: "8 tingkat",
          hotel_inhouse_housekeeping_process_list_select_request: "Permintaan ( SEMUA )",
          hotel_inhouse_housekeeping_process_list_select_room_no: "Nombor bilik.",
          hotel_inhouse_housekeeping_process_list_placeholder_search: "Cari...",
          hotel_inhouse_housekeeping_process_list_label_roon_no: "Nombor bilik.",
          hotel_inhouse_housekeeping_process_list_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_housekeeping_process_list_label_user_name: "Nama Pengguna",
          hotel_inhouse_housekeeping_process_list_label_request_date: "Tarikh Permintaan",
          hotel_inhouse_housekeeping_process_list_label_request_type: "Jenis Permintaan",
          hotel_inhouse_housekeeping_process_list_label_express: "Tugasan",
          hotel_inhouse_housekeeping_process_list_label_action_status: "Status Tindakan",
          hotel_inhouse_housekeeping_process_list_label_date: "Tarikh",
          hotel_inhouse_housekeeping_process_list_guide_empty: "Senarai kosong",
          hotel_inhouse_housekeeping_process_detail_title: "PERMINTAAN BUTIRAN",
          hotel_inhouse_housekeeping_process_detail_label_roon_no: "Nombor bilik.",
          hotel_inhouse_housekeeping_process_detail_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_housekeeping_process_detail_label_user_name: "Nama Pengguna",
          hotel_inhouse_housekeeping_process_detail_label_email: "E-mel",
          hotel_inhouse_housekeeping_process_detail_label_stay: "Kekal",
          hotel_inhouse_housekeeping_process_detail_label_request_type: "Jenis Permintaan",
          hotel_inhouse_housekeeping_process_detail_label_status: "Status",
          hotel_inhouse_housekeeping_process_detail_label_request_at: "Diminta Pada",
          hotel_inhouse_housekeeping_process_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_housekeeping_process_detail_button_put_off: "Tangguh",
          hotel_inhouse_housekeeping_process_detail_button_complete: "lengkap",
          hotel_inhouse_housekeeping_process_detail_button_start: "Mulakan",
          hotel_inhouse_housekeeping_process_detail_button_stop: "Berhenti",
          hotel_inhouse_housekeeping_process_detail_button_deliver: "Hantarkan",
          hotel_inhouse_housekeeping_process_detail_button_pickup: "Angkat",
          hotel_inhouse_housekeeping_process_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_housekeeping_process_modal_title_put_off: "Tutup Mesej",
          hotel_inhouse_housekeeping_process_modal_button_cancel: "Batal",
          hotel_inhouse_housekeeping_process_modal_button_delay_30: "Kelewatan 30 min",
          hotel_inhouse_housekeeping_process_modal_button_delay_60: "Kelewatan 60 min",
          hotel_inhouse_housekeeping_process_modal_button_as_soon_as: "Sebaik sahaja tersedia",
          hotel_inhouse_housekeeping_process_modal_button_out_of_order: "Telah habis",
          hotel_inhouse_housekeeping_process_modal_title_stop_message: "Hentikan Mesej",
          hotel_inhouse_housekeeping_process_modal_button_unknown_situation: "Status Tidak Diketahui",
          hotel_inhouse_housekeeping_process_modal_button_cancel_service: "Batalkan Perkhidmatan",
          hotel_inhouse_housekeeping_process_modal_button_not_found_it: "Tidak dapat ditemui",
          hotel_inhouse_housekeeping_process_modal_button_no_guest_room: "Tiada tetamu di dalam bilik",
          hotel_inhouse_housekeeping_process_modal_button_change_items: "Tukar Item",
          hotel_inhouse_housekeeping_myconect_list_title: "SENARAI KEMAJUAN JADUAL RUMAH",
          hotel_inhouse_housekeeping_myconect_list_label_user_count: "Dibatalkan oleh Kiraan Pengguna",
          hotel_inhouse_housekeeping_myconect_list_label_hotel_count: "Dibatalkan oleh Hotel Count",
          hotel_inhouse_housekeeping_myconect_list_label_mur: "Bilik Solek",
          hotel_inhouse_housekeeping_myconect_list_label_tds: "Perkhidmatan Turndown",
          hotel_inhouse_housekeeping_myconect_list_label_ironing_ex: "Perkhidmatan Menyeterika Ekspres",
          hotel_inhouse_housekeeping_myconect_list_label_ironing: "Perkhidmatan Menyeterika",
          hotel_inhouse_housekeeping_myconect_list_label_laundry_ex: "Perkhidmatan Dobi Ekspres",
          hotel_inhouse_housekeeping_myconect_list_label_laundry: "Perkhidmatan dobi",
          hotel_inhouse_housekeeping_myconect_list_label_total: "Jumlah",
          hotel_inhouse_housekeeping_myconect_list_label_roon_no: "Nombor bilik.",
          hotel_inhouse_housekeeping_myconect_list_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_housekeeping_myconect_list_label_user_name: "Nama Pengguna",
          hotel_inhouse_housekeeping_myconect_list_label_request_date: "Tarikh Permintaan",
          hotel_inhouse_housekeeping_myconect_list_label_request_type: "Jenis Permintaan",
          hotel_inhouse_housekeeping_myconect_list_label_express: "Tugasan",
          hotel_inhouse_housekeeping_myconect_list_label_action_status: "Status Tindakan",
          hotel_inhouse_housekeeping_myconect_list_label_myconect_at: "MyCONECT Di",
          hotel_inhouse_housekeeping_myconect_list_label_completed_at: "Selesai Pada",
          hotel_inhouse_housekeeping_myconect_list_label_date: "Tarikh",
          hotel_inhouse_housekeeping_myconect_list_guide_empty: "Senarai kosong",
          hotel_inhouse_housekeeping_myconect_detail_title: "BUTIRAN PROSES JADUAL",
          hotel_inhouse_housekeeping_myconect_detail_label_roon_no: "Nombor bilik.",
          hotel_inhouse_housekeeping_myconect_detail_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_housekeeping_myconect_detail_label_user_name: "Nama Pengguna",
          hotel_inhouse_housekeeping_myconect_detail_label_email: "E-mel",
          hotel_inhouse_housekeeping_myconect_detail_label_stay: "Kekal",
          hotel_inhouse_housekeeping_myconect_detail_label_request_type: "Jenis Permintaan",
          hotel_inhouse_housekeeping_myconect_detail_label_status: "Status",
          hotel_inhouse_housekeeping_myconect_detail_label_request_at: "Diminta Pada",
          hotel_inhouse_housekeeping_myconect_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_housekeeping_myconect_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_housekeeping_multi_myconect_list_title: "SENARAI JADUAL RUMAH",
          hotel_inhouse_housekeeping_multi_myconect_list_button_alive: "Aktif",
          hotel_inhouse_housekeeping_multi_myconect_list_label_staff_number: "Nombor Kakitangan",
          hotel_inhouse_housekeeping_multi_myconect_list_label_request: "Permintaan",
          hotel_inhouse_housekeeping_multi_myconect_list_label_department: "Jabatan",
          hotel_inhouse_housekeeping_multi_myconect_list_label_email: "E-mel",
          hotel_inhouse_housekeeping_multi_myconect_list_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_housekeeping_multi_myconect_list_label_from: "daripada",
          hotel_inhouse_housekeeping_multi_myconect_list_label_to: "Kepada",
          hotel_inhouse_housekeeping_multi_myconect_list_label_date: "Tarikh",
          hotel_inhouse_housekeeping_multi_myconect_list_button_add: "Tambah",
          hotel_inhouse_housekeeping_multi_myconect_add_title: "TAMBAH JADUAL RUMAH",
          hotel_inhouse_housekeeping_multi_myconect_add_label_request_type: "Jenis Permintaan",
          hotel_inhouse_housekeeping_multi_myconect_add_placeholder_select_box_choose: "Pilih",
          hotel_inhouse_housekeeping_multi_myconect_add_select_box_ironing: "Perkhidmatan Menyeterika",
          hotel_inhouse_housekeeping_multi_myconect_add_select_box_laundry: "Perkhidmatan dobi",
          hotel_inhouse_housekeeping_multi_myconect_add_select_box_amenity: "Perkhidmatan Kemudahan",
          hotel_inhouse_housekeeping_multi_myconect_add_select_box_make_up_room: "Bilik Solek",
          hotel_inhouse_housekeeping_multi_myconect_add_select_box_turn_down_service: "Perkhidmatan Turndown",
          hotel_inhouse_housekeeping_multi_myconect_add_select_box_green_campaign: "Kempen Hijau",
          hotel_inhouse_housekeeping_multi_myconect_add_label_date: "Tarikh",
          hotel_inhouse_housekeeping_multi_myconect_add_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_housekeeping_multi_myconect_add_select_not_availble: "Tidak Tersedia",
          hotel_inhouse_housekeeping_multi_myconect_add_select_confirm: "sahkan",
          hotel_inhouse_housekeeping_multi_myconect_add_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_housekeeping_multi_myconect_add_button_add: "Tambah",
          hotel_inhouse_housekeeping_multi_myconect_add_validation_type_request: "* Sila Pilih Jenis Permintaan.",
          hotel_inhouse_housekeeping_multi_myconect_add_validation_period_start: "* Sila Pilih Tarikh.",
          hotel_inhouse_housekeeping_multi_myconect_add_validation_period_end: "* Sila Pilih Tarikh.",
          hotel_inhouse_housekeeping_multi_myconect_add_validation_respond: "* Sila Pilih Respons MyCONECT.",
          hotel_inhouse_housekeeping_multi_myconect_add_button_set: "Tetapkan",
          hotel_inhouse_housekeeping_multi_myconect_detail_title: "MAKLUMAT JADUAL RUMAH",
          hotel_inhouse_housekeeping_multi_myconect_detail_label_update: "Tarikh Dikemaskini",
          hotel_inhouse_housekeeping_multi_myconect_detail_label_date: "Tarikh",
          hotel_inhouse_housekeeping_multi_myconect_detail_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_housekeeping_multi_myconect_detail_label_staff_number: "Nombor Kakitangan",
          hotel_inhouse_housekeeping_multi_myconect_detail_label_request: "Permintaan",
          hotel_inhouse_housekeeping_multi_myconect_detail_label_department: "Jabatan",
          hotel_inhouse_housekeeping_multi_myconect_detail_label_email: "E-mel",
          hotel_inhouse_housekeeping_multi_myconect_detail_label_released_at: "Dikeluarkan Pada",
          hotel_inhouse_housekeeping_multi_myconect_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_housekeeping_multi_myconect_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_housekeeping_multi_myconect_detail_button_cancel: "Batal",
          hotel_inhouse_housekeeping_multi_myconect_detail_button_release: "Lepaskan",
          hotel_inhouse_housekeeping_multi_myconect_detail_button_edit: "Sunting",
          hotel_inhouse_housekeeping_multi_myconect_detail_button_ok: "okey",
          hotel_inhouse_valet_list_title: "SENARAI PERMINTAAN PARKING VALET",
          hotel_inhouse_valet_list_label_valet_requst: "Permintaan Valet",
          hotel_inhouse_valet_list_label_requesting: "Meminta",
          hotel_inhouse_valet_list_label_parking: "Tempat letak kenderaan",
          hotel_inhouse_valet_list_label_waiting: "Menunggu",
          hotel_inhouse_valet_list_label_preparing: "Bersedia",
          hotel_inhouse_valet_list_label_standby: "Bersedia",
          hotel_inhouse_valet_list_label_return: "Kembali",
          hotel_inhouse_valet_list_label_total: "Jumlah",
          hotel_inhouse_valet_list_placeholder_select_box_request: "Permintaan ( SEMUA )",
          hotel_inhouse_valet_list_select_request_all: "Semua",
          hotel_inhouse_valet_list_select_vehicle_no: "Kenderaan No.",
          hotel_inhouse_valet_list_select_room_no: "Nombor bilik.",
          hotel_inhouse_valet_list_select_user_name: "Nama Pengguna",
          hotel_inhouse_valet_list_placeholder_search: "Cari...",
          hotel_inhouse_valet_list_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_valet_list_label_room_no: "Nombor bilik.",
          hotel_inhouse_valet_list_label_user_name: "Nama Pengguna",
          hotel_inhouse_valet_list_label_request_date: "Tarikh Permintaan",
          hotel_inhouse_valet_list_label_location_no: "Lokasi No.",
          hotel_inhouse_valet_list_label_vehicle_no: "Kenderaan No.",
          hotel_inhouse_valet_list_label_action_status: "Status Tindakan",
          hotel_inhouse_valet_list_label_date: "Tarikh",
          hotel_inhouse_valet_detail_title: "BUTIRAN PERMINTAAN PARKING VALET",
          hotel_inhouse_valet_detail_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_valet_detail_label_room_no: "Nombor bilik.",
          hotel_inhouse_valet_detail_label_user_name: "Nama Pengguna",
          hotel_inhouse_valet_detail_label_email: "E-mel",
          hotel_inhouse_valet_detail_label_request_type: "Jenis Permintaan",
          hotel_inhouse_valet_detail_label_location_no: "Lokasi No.",
          hotel_inhouse_valet_detail_label_vehicle_no: "Kenderaan No.",
          hotel_inhouse_valet_detail_label_status: "Status",
          hotel_inhouse_valet_detail_label_request_at: "Permintaan Di",
          hotel_inhouse_valet_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_valet_detail_button_valet_no: "Valet No.",
          hotel_inhouse_valet_detail_button_change: "Ubah",
          hotel_inhouse_valet_detail_button_myconect: "MyCONECT",
          hotel_inhouse_valet_detail_button_stop: "Berhenti",
          hotel_inhouse_valet_detail_button_location: "Lokasi",
          hotel_inhouse_valet_detail_button_standby: "Bersedia",
          hotel_inhouse_valet_detail_button_handover: "Serahkan",
          hotel_inhouse_valet_detail_button_return: "Kembali",
          hotel_inhouse_valet_detail_button_settlement: "Penyelesaian",
          hotel_inhouse_valet_detail_button_room_posting: "Pengeposan bilik",
          hotel_inhouse_valet_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_valet_detail_button_go_to_detail: "Pergi ke butiran",
          hotel_inhouse_valet_detail_button_payment: "Bayaran",
          hotel_inhouse_valet_detail_business_list_label_no: "TIDAK.",
          hotel_inhouse_valet_detail_business_list_label_type: "taip",
          hotel_inhouse_valet_detail_business_list_label_task: "Tugasan",
          hotel_inhouse_valet_detail_business_list_label_time_from: "Masa Tersedia Dari",
          hotel_inhouse_valet_detail_business_list_label_time_to: "Masa Tersedia Untuk",
          hotel_inhouse_valet_detail_business_list_label_charge_amount: "Jumlah Caj",
          hotel_inhouse_valet_detail_business_list_label_currency: "mata wang",
          hotel_inhouse_valet_detail_placeholder_select_box_choose: "Pilih",
          hotel_inhouse_valet_detail_modal_valet_title: "Penerimaan Valet No.",
          hotel_inhouse_valet_detail_modal_valet_request_received: "Permintaan anda telah diterima.",
          hotel_inhouse_valet_detail_modal_change_title: "Tukar Kenderaan No.",
          hotel_inhouse_valet_detail_modal_change_placeholder_vehicle_no: "No. Kenderaan",
          hotel_inhouse_valet_detail_modal_myconect_title: "Lokasi No.",
          hotel_inhouse_valet_detail_modal_myconect_placeholder_parking_area_no: "Kawasan Letak Kereta No.",
          hotel_inhouse_valet_detail_modal_stop_title: "Hentikan Mesej",
          hotel_inhouse_valet_detail_modal_stop_message_unparked: "Dibuka dan batalkan",
          hotel_inhouse_valet_detail_modal_stop_message_parked: "Diparkir dan batalkan",
          hotel_inhouse_valet_detail_modal_stop_message_unknown: "Keadaan yang tidak diketahui",
          hotel_inhouse_valet_detail_modal_valet_location_title: "Lokasi Tempat Letak Kereta No.",
          hotel_inhouse_valet_detail_modal_valet_location_message: "Mula mengambil kenderaan.",
          hotel_inhouse_valet_detail_modal_valet_standby_title: "Bersedia",
          hotel_inhouse_valet_detail_modal_valet_standby_message: "Teruskan dengan bersedia kenderaan.",
          hotel_inhouse_valet_detail_modal_valet_handover_title: "Serahkan",
          hotel_inhouse_valet_detail_modal_valet_handover_message: "Teruskan dengan penghantaran kenderaan kepada pemandu.",
          hotel_inhouse_valet_detail_modal_valet_return_title: "Kembali",
          hotel_inhouse_valet_detail_modal_valet_return_message: "Mula memulangkan kenderaan.",
          hotel_inhouse_valet_detail_modal_return_placeholder_parking_area_no: "Kawasan Letak Kereta No.",
          hotel_inhouse_valet_detail_modal_room_number: "Nombor bilik",
          hotel_inhouse_valet_detail_modal_button_cancel: "Batal",
          hotel_inhouse_valet_detail_modal_button_ok: "okey",
          hotel_inhouse_valet_multi_myconect_list_title: "SENARAI TETAPAN JADUAL VALET",
          hotel_inhouse_valet_multi_myconect_list_button_alive: "Aktif",
          hotel_inhouse_valet_multi_myconect_list_label_staff_number: "Nombor Kakitangan",
          hotel_inhouse_valet_multi_myconect_list_label_request: "Permintaan",
          hotel_inhouse_valet_multi_myconect_list_label_department: "Jabatan",
          hotel_inhouse_valet_multi_myconect_list_label_email: "E-mel",
          hotel_inhouse_valet_multi_myconect_list_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_valet_multi_myconect_list_label_from: "daripada",
          hotel_inhouse_valet_multi_myconect_list_label_to: "Kepada",
          hotel_inhouse_valet_multi_myconect_list_label_date: "Tarikh",
          hotel_inhouse_valet_multi_myconect_list_button_add: "Tambah",
          hotel_inhouse_valet_myconect_list_guide_empty: "Senarai kosong",
          hotel_inhouse_valet_multi_myconect_add_title: "TAMBAH TETAPAN JADUAL VALET",
          hotel_inhouse_valet_multi_myconect_add_label_request_type: "Jenis Permintaan",
          hotel_inhouse_valet_multi_myconect_add_label_repond: "Membalas",
          hotel_inhouse_valet_multi_myconect_add_label_delay_time: "Masa Kelewatan",
          hotel_inhouse_valet_multi_myconect_add_placeholder_select_box_choose: "Pilih",
          hotel_inhouse_valet_multi_myconect_add_select_box_not_available: "Tidak Tersedia",
          hotel_inhouse_valet_multi_myconect_add_select_box_delay15: "Kelewatan 15 Minit",
          hotel_inhouse_valet_multi_myconect_add_select_box_delay30: "Kelewatan 30 Minit",
          hotel_inhouse_valet_multi_myconect_add_select_box_delay45: "Kelewatan 45 Minit",
          hotel_inhouse_valet_multi_myconect_add_select_box_delay60: "Kelewatan 60 Minit",
          hotel_inhouse_valet_multi_myconect_add_select_box_asaa: "Sebaik sahaja tersedia",
          hotel_inhouse_valet_multi_myconect_add_select_box_valet: "Valet",
          hotel_inhouse_valet_multi_myconect_add_select_box_waiting_request: "Permintaan Siap Sedia",
          hotel_inhouse_valet_multi_myconect_add_label_date: "Tarikh",
          hotel_inhouse_valet_multi_myconect_add_validation_type_request: "* Please Select the Request Type.",
          hotel_inhouse_valet_multi_myconect_add_validation_repond: "* Please Select the Respond.",
          hotel_inhouse_valet_multi_myconect_add_validation_delay: "* Please Select the Delay Time.",
          hotel_inhouse_valet_multi_myconect_add_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_valet_multi_myconect_add_button_add: "Tambah",
          hotel_inhouse_valet_multi_myconect_detail_title: "MAKLUMAT PENETAPAN JADUAL VALET",
          hotel_inhouse_valet_multi_myconect_detail_label_update: "Tarikh Dikemaskini",
          hotel_inhouse_valet_multi_myconect_detail_label_date: "Tarikh",
          hotel_inhouse_valet_multi_myconect_detail_label_delay_time: "Masa Kelewatan",
          hotel_inhouse_valet_multi_myconect_detail_placeholder_select_box_choose: "Pilih",
          hotel_inhouse_valet_multi_myconect_detail_select_box_delay15: "Kelewatan 15 Minit",
          hotel_inhouse_valet_multi_myconect_detail_select_box_delay30: "Kelewatan 30 Minit",
          hotel_inhouse_valet_multi_myconect_detail_select_box_delay45: "Kelewatan 45 Minit",
          hotel_inhouse_valet_multi_myconect_detail_select_box_delay60: "Kelewatan 60 Minit",
          hotel_inhouse_valet_multi_myconect_detail_select_box_asaa: "Sebaik sahaja tersedia",
          hotel_inhouse_valet_multi_myconect_detail_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_valet_multi_myconect_detail_label_staff_number: "Nombor Kakitangan",
          hotel_inhouse_valet_multi_myconect_detail_label_request: "Permintaan",
          hotel_inhouse_valet_multi_myconect_detail_label_department: "Jabatan",
          hotel_inhouse_valet_multi_myconect_detail_label_email: "E-mel",
          hotel_inhouse_valet_multi_myconect_detail_label_released_at: "Dikeluarkan Pada",
          hotel_inhouse_valet_multi_myconect_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_valet_multi_myconect_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_valet_multi_myconect_detail_button_cancel: "Batal",
          hotel_inhouse_valet_multi_myconect_detail_button_release: "Lepaskan",
          hotel_inhouse_valet_multi_myconect_detail_button_edit: "Sunting",
          hotel_inhouse_valet_multi_myconect_detail_button_ok: "okey",
          hotel_inhouse_in_room_dining_cart_list_title: "SENARAI KERETA MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_cart_list_placeholder_select_box_select: "Pilih",
          hotel_inhouse_in_room_dining_cart_list_select_confirmation_no: "No Pengesahan",
          hotel_inhouse_in_room_dining_cart_list_placeholder_search: "Cari...",
          hotel_inhouse_in_room_dining_cart_list_label_no: "Tidak.",
          hotel_inhouse_in_room_dining_cart_list_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_in_room_dining_cart_list_label_room_no: "Nombor bilik.",
          hotel_inhouse_in_room_dining_cart_list_label_user_name: "Nama Pengguna",
          hotel_inhouse_in_room_dining_cart_list_label_update_date: "Tarikh Dikemaskini",
          hotel_inhouse_in_room_dining_cart_list_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_cart_detail_title: "BUTIRAN KERETA MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_cart_detail_label_info: "info",
          hotel_inhouse_in_room_dining_cart_detail_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_in_room_dining_cart_detail_label_room_no: "Nombor bilik.",
          hotel_inhouse_in_room_dining_cart_detail_label_user_name: "Nama Pengguna",
          hotel_inhouse_in_room_dining_cart_detail_label_updated_at: "Tarikh Dikemaskini",
          hotel_inhouse_in_room_dining_cart_detail_label_payment: "Bayaran",
          hotel_inhouse_in_room_dining_cart_detail_label_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_cart_detail_label_discount_rate: "Kadar diskaun",
          hotel_inhouse_in_room_dining_cart_detail_label_price: "harga",
          hotel_inhouse_in_room_dining_cart_detail_label_order: "Pesanan",
          hotel_inhouse_in_room_dining_cart_detail_label_no: "Tidak.",
          hotel_inhouse_in_room_dining_cart_detail_label_category: "kategori",
          hotel_inhouse_in_room_dining_cart_detail_label_menu: "Menu",
          hotel_inhouse_in_room_dining_cart_detail_label_option: "Pilihan",
          hotel_inhouse_in_room_dining_cart_detail_label_items: "barang",
          hotel_inhouse_in_room_dining_cart_detail_label_quantity: "Kuantiti",
          hotel_inhouse_in_room_dining_cart_detail_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_cart_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_in_room_dining_category_list_title: "SENARAI KATEGORI MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_category_list_label_all: "Tunjukkan menu (SEMUA)",
          hotel_inhouse_in_room_dining_category_list_label_exhibition_yes: "Tunjukkan menu (Ya)",
          hotel_inhouse_in_room_dining_category_list_label_exhibition_no: "Tunjukkan menu (Tidak)",
          hotel_inhouse_in_room_dining_category_list_placeholder_search: "Cari...",
          hotel_inhouse_in_room_dining_category_list_label_no: "Tidak.",
          hotel_inhouse_in_room_dining_category_list_label_name: "Nama",
          hotel_inhouse_in_room_dining_category_list_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_category_list_label_sorting: "Menyusun",
          hotel_inhouse_in_room_dining_category_list_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_category_list_button_delete: "Padam",
          hotel_inhouse_in_room_dining_category_list_button_add: "Tambah",
          hotel_inhouse_in_room_dining_category_add_title: "KATEGORI MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_category_add_label_name: "Nama",
          hotel_inhouse_in_room_dining_category_add_label_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_category_add_select_yes: "ya",
          hotel_inhouse_in_room_dining_category_add_select_no: "Tidak",
          hotel_inhouse_in_room_dining_category_add_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_in_room_dining_category_add_button_add: "Tambah",
          hotel_inhouse_in_room_dining_category_add_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_category_detail_title: "BUTIRAN KATEGORI MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_category_detail_label_name: "Nama",
          hotel_inhouse_in_room_dining_category_detail_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_category_detail_label_category_details: "Butiran Kategori",
          hotel_inhouse_in_room_dining_category_detail_label_language: "Bahasa",
          hotel_inhouse_in_room_dining_category_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_in_room_dining_category_detail_button_add: "Tambah",
          hotel_inhouse_in_room_dining_category_detail_button_delete: "Padam",
          hotel_inhouse_in_room_dining_category_detail_button_edit: "Sunting",
          hotel_inhouse_in_room_dining_category_detail_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_category_edit_title: "KATEGORI MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_category_edit_label_category_details: "Butiran Kategori",
          hotel_inhouse_in_room_dining_category_edit_label_language: "Bahasa",
          hotel_inhouse_in_room_dining_category_edit_label_name: "Nama",
          hotel_inhouse_in_room_dining_category_edit_label_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_category_edit_select_yes: "ya",
          hotel_inhouse_in_room_dining_category_edit_select_no: "Tidak",
          hotel_inhouse_in_room_dining_category_edit_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_category_edit_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_in_room_dining_category_edit_button_add: "Tambah",
          hotel_inhouse_in_room_dining_category_edit_button_delete: "Padam",
          hotel_inhouse_in_room_dining_category_edit_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_category_edit_button_ok: "okey",
          hotel_inhouse_in_room_dining_category_edit_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_category_edit_guide_empty: "Senarai kosong",
          hotel_hotel_inhouse_in_room_dining_category_translation_add_title: "TERJEMAHAN KATEGORI MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_category_translation_add_label_language: "Bahasa",
          hotel_inhouse_in_room_dining_category_translation_add_select_ko: "Korea",
          hotel_inhouse_in_room_dining_category_translation_add_select_en: "Inggeris",
          hotel_inhouse_in_room_dining_category_translation_add_select_ja: "Jepun",
          hotel_inhouse_in_room_dining_category_translation_add_select_zh: "cina",
          hotel_inhouse_in_room_dining_category_translation_add_select_ms: "Melayu",
          hotel_inhouse_in_room_dining_category_translation_add_label_name: "Nama",
          hotel_inhouse_in_room_dining_category_translation_add_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_category_translation_add_button_add: "Tambah",
          hotel_inhouse_in_room_dining_category_translation_add_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_category_translation_edit_title: "TERJEMAHAN KATEGORI MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_category_translation_edit_label_language: "Bahasa",
          hotel_inhouse_in_room_dining_category_translation_edit_select_ko: "Korea",
          hotel_inhouse_in_room_dining_category_translation_edit_select_en: "Inggeris",
          hotel_inhouse_in_room_dining_category_translation_edit_select_ja: "Jepun",
          hotel_inhouse_in_room_dining_category_translation_edit_select_zh: "cina",
          hotel_inhouse_in_room_dining_category_translation_edit_select_ms: "Melayu",
          hotel_inhouse_in_room_dining_category_translation_edit_label_name: "Nama",
          hotel_inhouse_in_room_dining_category_translation_edit_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_category_translation_edit_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_category_translation_edit_button_delete: "Padam",
          hotel_inhouse_in_room_dining_category_translation_edit_button_ok: "okey",
          hotel_inhouse_in_room_dining_category_translation_edit_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_setting_list_title: "SENARAI TETAPAN MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_setting_list_label_no: "Tidak.",
          hotel_inhouse_in_room_dining_menu_setting_list_label_type: "taip",
          hotel_inhouse_in_room_dining_menu_setting_list_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_setting_list_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_menu_setting_list_discount_rate: "Kadar diskaun",
          hotel_inhouse_in_room_dining_menu_setting_list_price: "harga",
          hotel_inhouse_in_room_dining_menu_setting_list_status: "Status",
          hotel_inhouse_in_room_dining_menu_setting_list_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_menu_setting_list_created_at: "Tarikh",
          hotel_inhouse_in_room_dining_menu_setting_list_select_sold_out: "Habis dijual",
          hotel_inhouse_in_room_dining_menu_setting_list_select_sale: "Jualan",
          hotel_inhouse_in_room_dining_menu_setting_list_select_preparing: "Bersedia",
          hotel_inhouse_in_room_dining_menu_setting_list_select_not_sale: "Bukan Jualan",
          hotel_inhouse_in_room_dining_menu_setting_list_select_yes: "ya",
          hotel_inhouse_in_room_dining_menu_setting_list_select_no: "Tidak",
          hotel_inhouse_in_room_dining_menu_setting_list_sorting: "Menyusun",
          hotel_inhouse_in_room_dining_menu_setting_list_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_menu_setting_list_button_delete: "Padam",
          hotel_inhouse_in_room_dining_menu_setting_list_button_add: "Tambah",
          hotel_inhouse_in_room_dining_menu_setting_add_title: "TAMBAHKAN TETAPAN MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_setting_add_label_category: "kategori",
          hotel_inhouse_in_room_dining_menu_setting_add_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_setting_add_label_about: "Tentang",
          hotel_inhouse_in_room_dining_menu_setting_add_label_main_image: "Imej Utama",
          hotel_inhouse_in_room_dining_menu_setting_add_label_detail_images: "Imej Perincian",
          hotel_inhouse_in_room_dining_menu_setting_add_label_upload: "Muat naik",
          hotel_inhouse_in_room_dining_menu_setting_add_label_more: "Lagi",
          hotel_inhouse_in_room_dining_menu_setting_add_label_appoint_start_time: "Masa mula pesanan (Jam:Minit)",
          hotel_inhouse_in_room_dining_menu_setting_add_label_appoint_cutoff_time: "Masa tamat pesanan (Jam:Minit)",
          hotel_inhouse_in_room_dining_menu_setting_add_label_day_of_week: "Hari dalam seminggu",
          hotel_inhouse_in_room_dining_menu_setting_add_label_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_menu_setting_add_label_discount_type: "Jenis Diskaun",
          hotel_inhouse_in_room_dining_menu_setting_add_select_flat: "Kadar rata",
          hotel_inhouse_in_room_dining_menu_setting_add_select_percent: "Peratusan",
          hotel_inhouse_in_room_dining_menu_setting_add_label_discount_amount: "Jumlah diskaun",
          hotel_inhouse_in_room_dining_menu_setting_add_label_status: "Status",
          hotel_inhouse_in_room_dining_menu_setting_add_select_sold_out: "Habis dijual",
          hotel_inhouse_in_room_dining_menu_setting_add_select_sale: "Jualan",
          hotel_inhouse_in_room_dining_menu_setting_add_select_preparing: "Bersedia",
          hotel_inhouse_in_room_dining_menu_setting_add_select_not_sale: "Bukan untuk dijual",
          hotel_inhouse_in_room_dining_menu_setting_add_label_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_menu_setting_add_button_yes: "ya",
          hotel_inhouse_in_room_dining_menu_setting_add_button_no: "Tidak",
          hotel_inhouse_in_room_dining_menu_setting_add_label_recommended: "Cadangan",
          hotel_inhouse_in_room_dining_menu_setting_add_label_packaging_availability: "Ketersediaan Pembungkusan",
          hotel_inhouse_in_room_dining_menu_setting_add_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_menu_setting_add_button_ok: "okey",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_menu_category_ids: "*Sila pilih kategori.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_about: "* Sila masukkan keterangan.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_main_image: "* Sila muat naik imej utama.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_detail_images: "* Sila muat naik imej terperinci.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_more: "* Sila masukkan sebarang huraian tambahan.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_from_at: "* Sila masukkan masa mula pesanan.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_to_at: "* Sila masukkan masa tamat pesanan.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_day_of_week: "* Sila pilih hari dalam seminggu.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_supply_price: "* Sila masukkan harga bekalan.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_show: "* Sila pilih sama ada untuk menunjukkan menu.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_recommended: "* Sila pilih sama ada untuk menunjukkan pengesyoran.",
          hotel_inhouse_in_room_dining_menu_setting_add_validation_packaging_availability: "* Sila pilih ketersediaan 'pek untuk pergi'.",
          hotel_inhouse_in_room_dining_menu_setting_detail_title: "BUTIRAN TETAPAN MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_setting_detail_button_edit: "Sunting",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_category: "kategori",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_about: "Tentang",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_main_image: "Imej Utama",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_detail_images: "Imej Perincian",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_more: "Lagi",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_appoint_start_time: "Masa mula pesanan (Jam:Minit)",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_appoint_cutoff_time: "Masa tamat pesanan (Jam:Minit)",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_day_of_week: "Hari dalam seminggu",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_discount_type: "Jenis Diskaun",
          hotel_inhouse_in_room_dining_menu_setting_detail_select_flat: "Kadar rata",
          hotel_inhouse_in_room_dining_menu_setting_detail_select_percent: "Peratusan",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_price: "harga",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_sorting: "Menyusun",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_status: "Status",
          hotel_inhouse_in_room_dining_menu_setting_detail_select_sold_out: "Habis dijual",
          hotel_inhouse_in_room_dining_menu_setting_detail_select_sale: "Jualan",
          hotel_inhouse_in_room_dining_menu_setting_detail_select_preparing: "Bersedia",
          hotel_inhouse_in_room_dining_menu_setting_detail_select_not_sale: "Bukan untuk dijual",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_menu_setting_detail_button_yes: "ya",
          hotel_inhouse_in_room_dining_menu_setting_detail_button_no: "Tidak",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_recommended: "Disyorkan",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_packaging_availability: "Ketersediaan Pembungkusan",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_language: "Bahasa",
          hotel_inhouse_in_room_dining_menu_setting_detail_button_delete: "Padam",
          hotel_inhouse_in_room_dining_menu_setting_detail_button_add: "Tambah",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_option: "Pilihan",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_no: "Tidak.",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_items: "barang",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_required: "Diperlukan",
          hotel_inhouse_in_room_dining_menu_setting_detail_label_discount_amount: "Jumlah diskaun",
          hotel_inhouse_in_room_dining_menu_setting_detail_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_menu_setting_detail_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_menu_setting_edit_title: "TETAPAN MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_setting_edit_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_menu_setting_edit_button_ok: "okey",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_category: "kategori",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_about: "Tentang",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_main_image: "Imej Utama",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_detail_images: "Imej Perincian",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_upload: "Muat naik",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_more: "Lagi",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_from_at: "Masa mula pesanan (Jam:Minit)",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_to_at: "Masa tamat pesanan (Jam:Minit)",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_week: "Hari dalam seminggu",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_discount_type: "Jenis Diskaun",
          hotel_inhouse_in_room_dining_menu_setting_edit_select_flat: "Kadar rata",
          hotel_inhouse_in_room_dining_menu_setting_edit_select_percent: "Peratusan",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_discount_amount: "Jumlah diskaun",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_status: "Status",
          hotel_inhouse_in_room_dining_menu_setting_edit_select_sold_out: "Habis dijual",
          hotel_inhouse_in_room_dining_menu_setting_edit_select_sale: "Jualan",
          hotel_inhouse_in_room_dining_menu_setting_edit_select_preparing: "Bersedia",
          hotel_inhouse_in_room_dining_menu_setting_edit_select_not_sale: "Bukan untuk dijual",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_menu_setting_edit_button_yes: "ya",
          hotel_inhouse_in_room_dining_menu_setting_edit_button_no: "Tidak",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_recommended: "Disyorkan",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_packaging_availability: "Ketersediaan Pembungkusan",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_language: "Bahasa",
          hotel_inhouse_in_room_dining_menu_setting_edit_button_delete: "Padam",
          hotel_inhouse_in_room_dining_menu_setting_edit_button_add: "Tambah",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_option: "Pilihan",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_no: "Tidak.",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_items: "barang",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_required: "Diperlukan",
          hotel_inhouse_in_room_dining_menu_setting_edit_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_menu_setting_edit_label_price: "harga",
          hotel_inhouse_in_room_dining_menu_setting_edit_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_menu_setting_edit_validation_menu_category_ids: "*Sila pilih kategori.",
          hotel_inhouse_in_room_dining_menu_setting_edit_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_setting_edit_validation_about: "* Sila masukkan tentang.",
          hotel_inhouse_in_room_dining_menu_setting_edit_validation_more: "* Sila masukkan lebih banyak.",
          hotel_inhouse_in_room_dining_menu_setting_edit_validation_from_at: "* Sila masukkan masa mula pesanan.",
          hotel_inhouse_in_room_dining_menu_setting_edit_validation_to_at: "* Sila masukkan masa tamat pesanan.",
          hotel_inhouse_in_room_dining_menu_setting_edit_validation_day_of_week: "* Sila pilih hari dalam seminggu.",
          hotel_inhouse_in_room_dining_menu_setting_edit_validation_supply_price: "* Sila masukkan harga bekalan.",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_title: "MENU MAKAN DALAM BILIK TAMBAH BAHASA",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_label_language_code: "Kod Bahasa",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ko: "Korea",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_select_en: "Inggeris",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ja: "Jepun",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_select_zh: "cina",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ms: "Melayu",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_label_about: "Tentang",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_label_more: "Lagi",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_button_add: "Tambah",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_language_code: "* Sila pilih kod bahasa.",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_about: "* Sila masukkan tentang.",
          hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_more: "* Sila masukkan lebih banyak.",
          hotel_inhouse_in_room_dining_menu_setting_translation_detail_title: "BUTIRAN PILIHAN MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_edit: "Sunting",
          hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_language_code: "Kod Bahasa",
          hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_about: "Tentang",
          hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_more: "Lagi",
          hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_delete: "Padam",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_title: "EDIT BAHASA MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_language_code: "Kod Bahasa",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ko: "Korea",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_en: "Inggeris",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ja: "Jepun",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_zh: "cina",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ms: "Melayu",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_about: "Tentang",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_more: "Lagi",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_delete: "Padam",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_ok: "okey",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_about: "* Sila masukkan tentang.",
          hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_more: "* Sila masukkan lebih banyak.",
          hotel_inhouse_in_room_dining_menu_option_group_add_title: "DI DALAM BILIK MENU PILIHAN KUMPULAN TAMBAH",
          hotel_inhouse_in_room_dining_menu_option_group_add_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_option_group_add_label_max_select: "Bilangan maksimum pilihan",
          hotel_inhouse_in_room_dining_menu_option_group_add_label_required: "Diperlukan",
          hotel_inhouse_in_room_dining_menu_option_group_add_button_yes: "ya",
          hotel_inhouse_in_room_dining_menu_option_group_add_button_no: "Tidak",
          hotel_inhouse_in_room_dining_menu_option_group_add_label_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_menu_option_group_add_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_menu_option_group_add_button_add: "Tambah",
          hotel_inhouse_in_room_dining_menu_option_group_add_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_option_group_add_validation_required: "* Sila pilih yang diperlukan.",
          hotel_inhouse_in_room_dining_menu_option_group_add_validation_max_select: "* Sila masukkan maks. bilangan pilihan.",
          hotel_inhouse_in_room_dining_menu_option_group_add_validation_show: "* Sila pilih sama ada untuk menunjukkan menu.",
          hotel_inhouse_in_room_dining_menu_option_group_detail_title: "BUTIRAN PILIHAN MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_option_group_detail_button_edit: "Sunting",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_max_select: "Bilangan maksimum pilihan",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_required: "Diperlukan",
          hotel_inhouse_in_room_dining_menu_option_group_detail_button_yes: "ya",
          hotel_inhouse_in_room_dining_menu_option_group_detail_button_no: "Tidak",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_language: "Bahasa",
          hotel_inhouse_in_room_dining_menu_option_group_detail_button_delete: "Padam",
          hotel_inhouse_in_room_dining_menu_option_group_detail_button_add: "Tambah",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_sorting: "Menyusun",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_option: "Pilihan",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_no: "Tidak.",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_items: "barang",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_discount_amount: "Jumlah diskaun",
          hotel_inhouse_in_room_dining_menu_option_group_detail_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_menu_option_group_detail_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_menu_option_group_detail_label_price: "harga",
          hotel_inhouse_in_room_dining_menu_option_group_edit_title: "EDIT PILIHAN MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_option_group_edit_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_menu_option_group_edit_button_ok: "okey",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_max_select: "Bilangan maksimum pilihan",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_required: "Diperlukan",
          hotel_inhouse_in_room_dining_menu_option_group_edit_button_yes: "ya",
          hotel_inhouse_in_room_dining_menu_option_group_edit_button_no: "Tidak",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_language: "Bahasa",
          hotel_inhouse_in_room_dining_menu_option_group_edit_button_delete: "Padam",
          hotel_inhouse_in_room_dining_menu_option_group_edit_button_add: "Tambah",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_option: "Pilihan",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_no: "Tidak.",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_items: "barang",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_discount_amount: "Jumlah diskaun",
          hotel_inhouse_in_room_dining_menu_option_group_edit_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_menu_option_group_edit_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_menu_option_group_edit_label_price: "harga",
          hotel_inhouse_in_room_dining_menu_option_group_edit_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_option_group_edit_validation_required: "* Sila pilih yang diperlukan.",
          hotel_inhouse_in_room_dining_menu_option_group_edit_validation_max_select: "* Sila masukkan maks. bilangan pilihan.",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_title: "TAMBAHKAN BAHASA PILIHAN MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_label_language_code: "Kod Bahasa",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ko: "Korea",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_en: "Inggeris",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ja: "Jepun",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_zh: "cina",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ms: "Melayu",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_button_add: "Tambah",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_validation_language_code: "* Sila pilih kod bahasa.",
          hotel_inhouse_in_room_dining_menu_option_group_translation_add_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_title: "EDIT BAHASA PILIHAN MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_language_code: "Kod Bahasa",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ko: "Korea",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_en: "Inggeris",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ja: "Jepun",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_zh: "cina",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ms: "Melayu",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_delete: "Padam",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_ok: "okey",
          hotel_inhouse_in_room_dining_menu_option_group_translation_edit_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_option_value_add_title: "TAMBAHKAN ITEM MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_option_value_add_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_option_value_add_label_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_menu_option_value_add_button_yes: "ya",
          hotel_inhouse_in_room_dining_menu_option_value_add_button_no: "Tidak",
          hotel_inhouse_in_room_dining_menu_option_value_add_label_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_menu_option_value_add_label_discount_type: "Jenis Diskaun",
          hotel_inhouse_in_room_dining_menu_option_value_add_select_flat: "Kadar rata",
          hotel_inhouse_in_room_dining_menu_option_value_add_select_percent: "Peratusan",
          hotel_inhouse_in_room_dining_menu_option_value_add_label_discount_amount: "Jumlah diskaun",
          hotel_inhouse_in_room_dining_menu_option_value_add_label_price: "harga",
          hotel_inhouse_in_room_dining_menu_option_value_add_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_menu_option_value_add_button_ok: "okey",
          hotel_inhouse_in_room_dining_menu_option_value_add_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_option_value_add_validation_supply_price: "* Sila masukkan harga bekalan.",
          hotel_inhouse_in_room_dining_menu_option_value_add_validation_show: "* Sila pilih sama ada untuk menunjukkan menu.",
          hotel_inhouse_in_room_dining_menu_option_value_detail_title: "BUTIRAN ITEM MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_option_value_detail_button_edit: "Sunting",
          hotel_inhouse_in_room_dining_menu_option_value_detail_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_option_value_detail_label_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_menu_option_value_detail_button_yes: "ya",
          hotel_inhouse_in_room_dining_menu_option_value_detail_button_no: "Tidak",
          hotel_inhouse_in_room_dining_menu_option_value_detail_label_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_menu_option_value_detail_label_discount_type: "Jenis Diskaun",
          hotel_inhouse_in_room_dining_menu_option_value_detail_label_discount_amount: "Jumlah diskaun",
          hotel_inhouse_in_room_dining_menu_option_value_detail_label_price: "harga",
          hotel_inhouse_in_room_dining_menu_option_value_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_menu_option_value_detail_label_language: "Bahasa",
          hotel_inhouse_in_room_dining_menu_option_value_detail_button_delete: "Padam",
          hotel_inhouse_in_room_dining_menu_option_value_detail_button_add: "Tambah",
          hotel_inhouse_in_room_dining_menu_option_value_detail_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_menu_option_value_detail_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_menu_option_value_detail_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_menu_option_value_edit_title: "EDIT ITEM MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_option_value_edit_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_menu_option_value_edit_button_ok: "okey",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_option_value_edit_button_yes: "ya",
          hotel_inhouse_in_room_dining_menu_option_value_edit_button_no: "Tidak",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_show: "Tunjukkan menu",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_language: "Bahasa",
          hotel_inhouse_in_room_dining_menu_option_value_edit_button_delete: "Padam",
          hotel_inhouse_in_room_dining_menu_option_value_edit_button_add: "Tambah",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_option: "Pilihan",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_no: "Tidak.",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_items: "barang",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_required: "Diperlukan",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_discount_amount: "Jumlah diskaun",
          hotel_inhouse_in_room_dining_menu_option_value_edit_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_menu_option_value_edit_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_discount_type: "Jenis Diskaun",
          hotel_inhouse_in_room_dining_menu_option_value_edit_select_flat: "Kadar rata",
          hotel_inhouse_in_room_dining_menu_option_value_edit_select_percent: "Peratusan",
          hotel_inhouse_in_room_dining_menu_option_value_edit_label_price: "harga",
          hotel_inhouse_in_room_dining_menu_option_value_edit_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_option_value_edit_validation_supply_price: "* Sila masukkan harga bekalan.",
          hotel_inhouse_in_room_dining_menu_option_value_translation_add_title: "TAMBAH BAHASA MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_option_value_translation_add_label_language_code: "Kod Bahasa",
          hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ko: "Korea",
          hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_en: "Inggeris",
          hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ja: "Jepun",
          hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_zh: "cina",
          hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ms: "Melayu",
          hotel_inhouse_in_room_dining_menu_option_value_translation_add_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_option_value_translation_add_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_menu_option_value_translation_add_button_add: "Tambah",
          hotel_inhouse_in_room_dining_menu_option_value_translation_add_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_title: "EDIT BAHASA PILIHAN MENU MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_language_code: "Kod Bahasa",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ko: "Korea",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_en: "Inggeris",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ja: "Jepun",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_zh: "cina",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ms: "Melayu",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_name: "Nama",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_delete: "Padam",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_ok: "okey",
          hotel_inhouse_in_room_dining_menu_option_value_translation_edit_validation_name: "* Sila masukkan nama.",
          hotel_inhouse_in_room_dining_process_list_title: "SENARAI KEMAJUAN MAKAN DALAM BILIK (Selepas Pengesahan)",
          hotel_inhouse_in_room_dining_process_list_label_number_of_guest_requests: "Bilangan Permintaan Tetamu",
          hotel_inhouse_in_room_dining_process_list_label_unconfirmed_guest_requests: "Permintaan Tetamu Tidak Disahkan",
          hotel_inhouse_in_room_dining_process_list_label_in_room_dining: "Makan Dalam Bilik",
          hotel_inhouse_in_room_dining_process_list_label_all: "Permintaan ( SEMUA )",
          hotel_inhouse_in_room_dining_process_list_label_room_no: "Nombor bilik.",
          hotel_inhouse_in_room_dining_process_list_label_user_name: "Nama Pengguna",
          hotel_inhouse_in_room_dining_process_list_placeholder_search: "Cari...",
          hotel_inhouse_in_room_dining_process_list_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_in_room_dining_process_list_label_request_date: "Tarikh Permintaan",
          hotel_inhouse_in_room_dining_process_list_label_request_type: "Jenis Permintaan",
          hotel_inhouse_in_room_dining_process_list_label_task: "Tugasan",
          hotel_inhouse_in_room_dining_process_list_label_action_status: "Status Tindakan",
          hotel_inhouse_in_room_dining_process_list_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_process_list_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_process_detail_title: "BUTIRAN PERMINTAAN MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_process_detail_label_order_no: "Pesanan No.",
          hotel_inhouse_in_room_dining_process_detail_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_in_room_dining_process_detail_label_room_no: "Nombor bilik.",
          hotel_inhouse_in_room_dining_process_detail_label_user_name: "Nama Pengguna",
          hotel_inhouse_in_room_dining_process_detail_label_status: "Status",
          hotel_inhouse_in_room_dining_process_detail_label_requests: "Permintaan",
          hotel_inhouse_in_room_dining_process_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_process_detail_label_request_at: "Diminta Pada",
          hotel_inhouse_in_room_dining_process_detail_button_stop: "Berhenti",
          hotel_inhouse_in_room_dining_process_detail_button_preparing: "Bersedia",
          hotel_inhouse_in_room_dining_process_detail_button_deliver: "Hantarkan",
          hotel_inhouse_in_room_dining_process_detail_label_payment: "Bayaran",
          hotel_inhouse_in_room_dining_process_detail_label_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_process_detail_label_discount_rate: "Kadar diskaun",
          hotel_inhouse_in_room_dining_process_detail_label_price: "harga",
          hotel_inhouse_in_room_dining_process_detail_label_to_go: "Untuk pergi",
          hotel_inhouse_in_room_dining_process_detail_label_order: "Pesanan",
          hotel_inhouse_in_room_dining_process_detail_label_no: "Tidak.",
          hotel_inhouse_in_room_dining_process_detail_label_category: "kategori",
          hotel_inhouse_in_room_dining_process_detail_label_menu: "Menu",
          hotel_inhouse_in_room_dining_process_detail_label_option: "Pilihan",
          hotel_inhouse_in_room_dining_process_detail_label_items: "barang",
          hotel_inhouse_in_room_dining_process_detail_label_quantity: "Kuantiti",
          hotel_inhouse_in_room_dining_process_detail_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_process_detail_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_modal_stop_customer_reason: "Alasan pelanggan",
          hotel_inhouse_in_room_dining_modal_stop_product_issue: "Isu produk",
          hotel_inhouse_in_room_dining_modal_stop_delivery_problem: "Masalah penghantaran",
          hotel_inhouse_in_room_dining_modal_stop_other: "Lain-lain",
          hotel_inhouse_in_room_dining_receipt_label_room_no: "Nombor bilik.",
          hotel_inhouse_in_room_dining_receipt_label_order_created_date: "Tarikh Dibuat Pesanan",
          hotel_inhouse_in_room_dining_receipt_label_guest_requested_date: "Tarikh Diminta Tetamu",
          hotel_inhouse_in_room_dining_receipt_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_in_room_dining_receipt_label_guest_name: "Nama tetamu",
          hotel_inhouse_in_room_dining_receipt_label_check_in: "Daftar masuk",
          hotel_inhouse_in_room_dining_receipt_label_check_out: "Daftar Keluar",
          hotel_inhouse_in_room_dining_receipt_label_order_no: "Pesanan No.",
          hotel_inhouse_in_room_dining_receipt_label_to_go: "Untuk pergi",
          hotel_inhouse_in_room_dining_receipt_label_description: "Penerangan",
          hotel_inhouse_in_room_dining_receipt_label_unit_price: "Harga seunit",
          hotel_inhouse_in_room_dining_receipt_label_quantity: "Kuantiti",
          hotel_inhouse_in_room_dining_receipt_label_amount: "Jumlah",
          hotel_inhouse_in_room_dining_receipt_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_receipt_label_subtotal: "jumlah kecil",
          hotel_inhouse_in_room_dining_receipt_label_discount: "Diskaun",
          hotel_inhouse_in_room_dining_receipt_label_total: "Jumlah",
          hotel_inhouse_in_room_dining_receipt_label_payment: "Bayaran",
          hotel_inhouse_in_room_dining_request_receipt_label_room_posting: "Pengeposan Bilik",
          hotel_inhouse_in_room_dining_request_receipt_label_self_settlement: "Penyelesaian diri",
          hotel_inhouse_in_room_dining_request_receipt_label_balance: "Seimbang",
          hotel_inhouse_in_room_dining_request_receipt_contents_agreement: "Saya bersetuju bahawa liabiliti saya untuk bil ini tidak diketepikan dan bersetuju untuk memegang liabiliti peribadi sekiranya orang, syarikat atau persatuan yang dinyatakan gagal membayar sebahagian atau keseluruhan jumlah caj.",
          hotel_inhouse_in_room_dining_request_list_title: "SENARAI PERMINTAAN MAKAN DALAM BILIK (SEBELUM PENGESAHAN)",
          hotel_inhouse_in_room_dining_request_list_label_number_of_guest_requests: "Bilangan Permintaan Tetamu",
          hotel_inhouse_in_room_dining_request_list_label_unconfirmed_guest_requests: "Permintaan Tetamu Tidak Disahkan",
          hotel_inhouse_in_room_dining_request_list_label_in_room_dining: "Makan Dalam Bilik",
          hotel_inhouse_in_room_dining_request_list_label_all: "Permintaan ( SEMUA )",
          hotel_inhouse_in_room_dining_request_list_label_room_no: "Nombor bilik.",
          hotel_inhouse_in_room_dining_request_list_label_user_name: "Nama Pengguna",
          hotel_inhouse_in_room_dining_request_list_placeholder_search: "Cari...",
          hotel_inhouse_in_room_dining_request_list_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_in_room_dining_request_list_label_request_date: "Tarikh Permintaan",
          hotel_inhouse_in_room_dining_request_list_label_request_type: "Jenis Permintaan",
          hotel_inhouse_in_room_dining_request_list_label_task: "Tugasan",
          hotel_inhouse_in_room_dining_request_list_label_action_status: "Status Tindakan",
          hotel_inhouse_in_room_dining_request_list_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_request_list_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_request_detail_title: "BUTIRAN PERMINTAAN MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_request_detail_label_update_at: "Dikemaskini Pada",
          hotel_inhouse_in_room_dining_request_detail_label_order_no: "Pesanan No.",
          hotel_inhouse_in_room_dining_request_detail_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_in_room_dining_request_detail_label_room_no: "Nombor bilik.",
          hotel_inhouse_in_room_dining_request_detail_label_user_name: "Nama Pengguna",
          hotel_inhouse_in_room_dining_request_detail_label_status: "Status",
          hotel_inhouse_in_room_dining_request_detail_label_requests: "Permintaan",
          hotel_inhouse_in_room_dining_request_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_request_detail_label_request_at: "Diminta Pada",
          hotel_inhouse_in_room_dining_request_detail_button_pdf: "PDF",
          hotel_inhouse_in_room_dining_request_detail_button_not_available: "Tidak Tersedia",
          hotel_inhouse_in_room_dining_request_detail_button_confirm: "sahkan",
          hotel_inhouse_in_room_dining_request_detail_label_payment: "Bayaran",
          hotel_inhouse_in_room_dining_request_detail_label_supply_price: "Harga Bekalan",
          hotel_inhouse_in_room_dining_request_detail_label_discount_rate: "Kadar diskaun",
          hotel_inhouse_in_room_dining_request_detail_label_price: "harga",
          hotel_inhouse_in_room_dining_request_detail_label_to_go: "Untuk pergi",
          hotel_inhouse_in_room_dining_request_detail_label_order: "Pesanan",
          hotel_inhouse_in_room_dining_request_detail_label_no: "Tidak.",
          hotel_inhouse_in_room_dining_request_detail_label_category: "kategori",
          hotel_inhouse_in_room_dining_request_detail_label_menu: "Menu",
          hotel_inhouse_in_room_dining_request_detail_label_option: "Pilihan",
          hotel_inhouse_in_room_dining_request_detail_label_items: "barang",
          hotel_inhouse_in_room_dining_request_detail_label_quantity: "Kuantiti",
          hotel_inhouse_in_room_dining_request_detail_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_request_detail_label_in_room_dining: "Makan Dalam Bilik",
          hotel_inhouse_in_room_dining_request_detail_button_go_to_list: "Pergi ke Senarai",
          hotel_inhouse_in_room_dining_myconect_list_title: "SENARAI KEMAJUAN JADUAL MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_myconect_list_label_user_count: "Dibatalkan Mengikut Kiraan Pengguna",
          hotel_inhouse_in_room_dining_myconect_list_label_hotel_count: "Dibatalkan Oleh Kiraan Hotel",
          hotel_inhouse_in_room_dining_myconect_list_label_in_room_dining: "Makan Dalam Bilik",
          hotel_inhouse_in_room_dining_myconect_list_label_room_no: "Nombor bilik.",
          hotel_inhouse_in_room_dining_myconect_list_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_in_room_dining_myconect_list_label_user_name: "Nama Pengguna",
          hotel_inhouse_in_room_dining_myconect_list_label_request_date: "Tarikh Permintaan",
          hotel_inhouse_in_room_dining_myconect_list_label_request_type: "Jenis Permintaan",
          hotel_inhouse_in_room_dining_myconect_list_label_express: "Tugasan",
          hotel_inhouse_in_room_dining_myconect_list_label_action_status: "Status Tindakan",
          hotel_inhouse_in_room_dining_myconect_list_label_myconect_at: "MyCONECT Di",
          hotel_inhouse_in_room_dining_myconect_list_label_completed_at: "Selesai Pada",
          hotel_inhouse_in_room_dining_myconect_list_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_myconect_list_guide_empty: "Senarai kosong",
          hotel_inhouse_in_room_dining_myconect_detail_title: "BUTIRAN PROSES JADUAL MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_myconect_detail_label_roon_no: "Nombor bilik.",
          hotel_inhouse_in_room_dining_myconect_detail_label_confirmation_no: "No Pengesahan",
          hotel_inhouse_in_room_dining_myconect_detail_label_user_name: "Nama Pengguna",
          hotel_inhouse_in_room_dining_myconect_detail_label_email: "E-mel",
          hotel_inhouse_in_room_dining_myconect_detail_label_stay: "Kekal",
          hotel_inhouse_in_room_dining_myconect_detail_label_request_type: "Jenis Permintaan",
          hotel_inhouse_in_room_dining_myconect_detail_label_status: "Status",
          hotel_inhouse_in_room_dining_myconect_detail_label_request_at: "Diminta Pada",
          hotel_inhouse_in_room_dining_myconect_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_myconect_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_in_room_dining_multi_myconect_list_title: "SENARAI JADUAL MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_multi_myconect_list_button_alive: "Aktif",
          hotel_inhouse_in_room_dining_multi_myconect_list_label_staff_number: "Nombor Kakitangan",
          hotel_inhouse_in_room_dining_multi_myconect_list_label_request: "Permintaan",
          hotel_inhouse_in_room_dining_multi_myconect_list_label_department: "Jabatan",
          hotel_inhouse_in_room_dining_multi_myconect_list_label_email: "E-mel",
          hotel_inhouse_in_room_dining_multi_myconect_list_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_in_room_dining_multi_myconect_list_label_from: "daripada",
          hotel_inhouse_in_room_dining_multi_myconect_list_label_to: "Kepada",
          hotel_inhouse_in_room_dining_multi_myconect_list_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_multi_myconect_list_label_in_room_dining: "Makan Dalam Bilik",
          hotel_inhouse_in_room_dining_multi_myconect_list_button_add: "Tambah",
          hotel_inhouse_in_room_dining_multi_myconect_add_title: "TAMBAHKAN JADUAL MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_multi_myconect_add_label_request_type: "Jenis Permintaan",
          hotel_inhouse_in_room_dining_multi_myconect_add_placeholder_select_box_choose: "Pilih",
          hotel_inhouse_in_room_dining_multi_myconect_add_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_in_room_dining_multi_myconect_add_select_not_availble: "Tidak Tersedia",
          hotel_inhouse_in_room_dining_multi_myconect_add_select_confirm: "sahkan",
          hotel_inhouse_in_room_dining_multi_myconect_add_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_multi_myconect_add_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_in_room_dining_multi_myconect_add_button_add: "Tambah",
          hotel_inhouse_in_room_dining_multi_myconect_add_button_set: "Tetapkan",
          hotel_inhouse_in_room_dining_multi_myconect_add_validation_type_request: "* Sila pilih jenis permintaan.",
          hotel_inhouse_in_room_dining_multi_myconect_add_validation_period_start: "*Sila pilih tarikh.",
          hotel_inhouse_in_room_dining_multi_myconect_add_validation_period_end: "*Sila pilih tarikh.",
          hotel_inhouse_in_room_dining_multi_myconect_add_validation_respond: "* Sila pilih respons MyCONECT.",
          hotel_inhouse_in_room_dining_multi_myconect_detail_title: "MAKLUMAT JADUAL MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_multi_myconect_detail_label_update: "Tarikh Dikemaskini",
          hotel_inhouse_in_room_dining_multi_myconect_detail_label_date: "Tarikh",
          hotel_inhouse_in_room_dining_multi_myconect_detail_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_in_room_dining_multi_myconect_detail_label_staff_number: "Nombor Kakitangan",
          hotel_inhouse_in_room_dining_multi_myconect_detail_label_request: "Permintaan",
          hotel_inhouse_in_room_dining_multi_myconect_detail_label_department: "Jabatan",
          hotel_inhouse_in_room_dining_multi_myconect_detail_label_email: "E-mel",
          hotel_inhouse_in_room_dining_multi_myconect_detail_label_released_at: "Dikeluarkan Pada",
          hotel_inhouse_in_room_dining_multi_myconect_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_in_room_dining_multi_myconect_detail_label_in_room_dining: "Makan Dalam Bilik",
          hotel_inhouse_in_room_dining_multi_myconect_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_in_room_dining_multi_myconect_detail_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_multi_myconect_detail_button_release: "Lepaskan",
          hotel_inhouse_in_room_dining_multi_myconect_detail_button_edit: "Sunting",
          hotel_inhouse_in_room_dining_multi_myconect_detail_button_ok: "okey",
          hotel_inhouse_in_room_dining_setting_detail_title: "TETAPAN MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_setting_detail_label_update_at: "Dikemaskini Pada",
          hotel_inhouse_in_room_dining_setting_detail_label_in_room_dining: "Makan Dalam Bilik",
          hotel_inhouse_in_room_dining_setting_detail_label_room_posting: "Pengeposan Bilik",
          hotel_inhouse_in_room_dining_setting_detail_button_yes: "ya",
          hotel_inhouse_in_room_dining_setting_detail_button_no: "Tidak",
          hotel_inhouse_in_room_dining_setting_detail_label_payment: "Bayaran",
          hotel_inhouse_in_room_dining_setting_detail_button_edit: "Sunting",
          hotel_inhouse_in_room_dining_setting_edit_title: "TETAPAN MAKAN DALAM BILIK",
          hotel_inhouse_in_room_dining_setting_edit_label_update_at: "Dikemaskini Pada",
          hotel_inhouse_in_room_dining_setting_edit_label_in_room_dining: "Makan Dalam Bilik",
          hotel_inhouse_in_room_dining_setting_edit_label_room_posting: "Pengeposan Bilik",
          hotel_inhouse_in_room_dining_setting_edit_button_yes: "ya",
          hotel_inhouse_in_room_dining_setting_edit_button_no: "Tidak",
          hotel_inhouse_in_room_dining_setting_edit_label_payment: "Bayaran",
          hotel_inhouse_in_room_dining_setting_edit_button_cancel: "Batal",
          hotel_inhouse_in_room_dining_setting_edit_button_ok: "okey",
          hotel_inhouse_setting_business_list_title: "TETAPAN PERATURAN PERNIAGAAN",
          hotel_inhouse_setting_business_list_label_no: "TIDAK.",
          hotel_inhouse_setting_business_list_label_type: "taip",
          hotel_inhouse_setting_business_list_label_task: "Tugasan",
          hotel_inhouse_setting_business_list_label_time_from: "Masa Tersedia Dari",
          hotel_inhouse_setting_business_list_label_time_to: "Masa Tersedia Untuk",
          hotel_inhouse_setting_business_list_label_charge_amount: "Jumlah Caj",
          hotel_inhouse_setting_business_list_label_in_room_dining: "Makan Dalam Bilik",
          hotel_inhouse_setting_business_list_label_update_at: "Dikemaskini Pada",
          hotel_inhouse_setting_business_list_guide_empty: "Senarai kosong",
          hotel_inhouse_setting_business_detail_title: "MAKLUMAT PENETAPAN PERATURAN PERNIAGAAN",
          hotel_inhouse_setting_business_detail_label_date: "Tarikh Dikemaskini",
          hotel_inhouse_setting_business_detail_label_type: "taip",
          hotel_inhouse_setting_business_detail_label_task: "Tugasan",
          hotel_inhouse_setting_business_detail_label_in_room_dining: "Makan Dalam Bilik",
          hotel_inhouse_setting_business_detail_label_time_from: "Masa Tersedia Dari",
          hotel_inhouse_setting_business_detail_label_time_to: "Masa Tersedia Untuk",
          hotel_inhouse_setting_business_detail_label_min_requirement_time: "Masa Keperluan Min (Minit)",
          hotel_inhouse_setting_business_detail_label_waiting_time: "Masa Menunggu MyCONECT (Minit)",
          hotel_inhouse_setting_business_detail_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_setting_business_detail_label_amount: "Jumlah Caj",
          hotel_inhouse_setting_business_detail_label_transaction_code: "Kod Transaksi Caj",
          hotel_inhouse_setting_business_detail_label_description: "Penerangan Caj",
          hotel_inhouse_setting_business_detail_label_supplement: "Butiran Caj",
          hotel_inhouse_setting_business_detail_label_myconect_from: "MyCONECT Daripada",
          hotel_inhouse_setting_business_detail_label_myconect_to: "MyCONECT Kepada",
          hotel_inhouse_setting_business_detail_label_additional_charge_count: "Kiraan Keadaan Caj Tambahan",
          hotel_inhouse_setting_business_detail_label_additional_charge_repeat_flag: "Bendera Ulangan Keadaan Caj Tambahan",
          hotel_inhouse_setting_business_detail_button_yes: "ya",
          hotel_inhouse_setting_business_detail_button_no: "Tidak",
          hotel_inhouse_setting_business_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_setting_business_detail_button_edit: "Sunting",
          hotel_inhouse_setting_business_edit_title: "MAKLUMAT PENETAPAN PERATURAN PERNIAGAAN",
          hotel_inhouse_setting_business_edit_label_date: "Tarikh Dikemaskini",
          hotel_inhouse_setting_business_edit_label_type: "taip",
          hotel_inhouse_setting_business_edit_label_task: "Tugasan",
          hotel_inhouse_setting_business_edit_label_time_from: "Masa Tersedia Dari",
          hotel_inhouse_setting_business_edit_label_time_to: "Masa Tersedia Untuk",
          hotel_inhouse_setting_business_edit_label_min_requirement_time: "Masa Keperluan Min",
          hotel_inhouse_setting_business_edit_label_waiting_time: "Masa Menunggu MyCONECT",
          hotel_inhouse_setting_business_edit_label_respond: "Jawapan MyCONECT",
          hotel_inhouse_setting_business_edit_select_not_availble: "Tidak Tersedia",
          hotel_inhouse_setting_business_edit_select_confirm: "sahkan",
          hotel_inhouse_setting_business_edit_select_delay15: "Kelewatan 15 Minit",
          hotel_inhouse_setting_business_edit_select_delay30: "Kelewatan 30 Minit",
          hotel_inhouse_setting_business_edit_select_delay45: "Kelewatan 45 Minit",
          hotel_inhouse_setting_business_edit_select_delay60: "Kelewatan 60 Minit",
          hotel_inhouse_setting_business_edit_select_asaa: "Sebaik sahaja tersedia",
          hotel_inhouse_setting_business_edit_label_amount: "Jumlah Caj",
          hotel_inhouse_setting_business_edit_label_transaction_code: "Kod Transaksi Caj",
          hotel_inhouse_setting_business_edit_label_description: "Penerangan Caj",
          hotel_inhouse_setting_business_edit_label_supplement: "Butiran Caj",
          hotel_inhouse_setting_business_edit_label_myconect_from: "MyCONECT Daripada",
          hotel_inhouse_setting_business_edit_label_myconect_to: "MyCONECT Kepada",
          hotel_inhouse_setting_business_edit_validation_available_time_from: "* Sila masukkan masa yang tersedia dari",
          hotel_inhouse_setting_business_edit_validation_available_time_to: "* Sila masukkan masa yang tersedia untuk",
          hotel_inhouse_setting_business_edit_validation_min_requirement_time: "* Sila masukkan min. masa keperluan.",
          hotel_inhouse_setting_business_edit_validation_myconect_waiting_time: "* Sila masukkan masa menunggu MyCONECT.",
          hotel_inhouse_setting_business_edit_validation_myconect_respond: "* Sila pilih respons MyCONECT.",
          hotel_inhouse_setting_business_edit_validation_charge_amount: "* Sila masukkan jumlah caj.",
          hotel_inhouse_setting_business_edit_validation_charge_transaction_code: "* Sila masukkan kod transaksi caj",
          hotel_inhouse_setting_business_edit_validation_charge_description: "* Sila masukkan keterangan caj",
          hotel_inhouse_setting_business_edit_validation_charge_supplement: "* Sila masukkan butiran caj",
          hotel_inhouse_setting_business_edit_label_in_room_dining: "Makan Dalam Bilik",
          hotel_inhouse_setting_business_edit_button_cancel: "Batal",
          hotel_inhouse_setting_business_edit_button_ok: "okey",
          hotel_inhouse_setting_boundary_list_title: "PENETAPAN GARIS SEMPADAN",
          hotel_inhouse_setting_boundary_list_label_no: "TIDAK.",
          hotel_inhouse_setting_boundary_list_label_type: "taip",
          hotel_inhouse_setting_boundary_list_label_task: "Tugasan",
          hotel_inhouse_setting_boundary_list_label_ten_mins: "Sepuluh Min",
          hotel_inhouse_setting_boundary_list_label_hour: "Jam",
          hotel_inhouse_setting_boundary_list_label_three_hours: "Tiga jam",
          hotel_inhouse_setting_boundary_list_label_update_at: "Dikemaskini Pada",
          hotel_inhouse_setting_boundary_list_guide_empty: "Senarai kosong",
          hotel_inhouse_setting_boundary_detail_title: "MAKLUMAT PENETAPAN GARIS SEMPADAN",
          hotel_inhouse_setting_boundary_detail_label_date: "Tarikh Dikemaskini",
          hotel_inhouse_setting_boundary_detail_label_type: "taip",
          hotel_inhouse_setting_boundary_detail_label_task: "Tugasan",
          hotel_inhouse_setting_boundary_detail_label_ten_mins: "Sepuluh Min",
          hotel_inhouse_setting_boundary_detail_label_hour: "Jam",
          hotel_inhouse_setting_boundary_detail_label_three_hours: "Tiga jam",
          hotel_inhouse_setting_boundary_detail_button_none: "tiada",
          hotel_inhouse_setting_boundary_detail_button_unlimited: "Tiada had masa",
          hotel_inhouse_setting_boundary_detail_button_value: "Nilai",
          hotel_inhouse_setting_boundary_detail_label_option_one: "Pilihan Satu",
          hotel_inhouse_setting_boundary_detail_label_option_two: "Pilihan Kedua",
          hotel_inhouse_setting_boundary_detail_button_go_to_list: "Pergi ke senarai",
          hotel_inhouse_setting_boundary_detail_button_edit: "Sunting",
          hotel_inhouse_setting_boundary_edit_title: "PENETAPAN GARIS SEMPADAN",
          hotel_inhouse_setting_boundary_edit_label_date: "Tarikh Dikemaskini",
          hotel_inhouse_setting_boundary_edit_label_type: "taip",
          hotel_inhouse_setting_boundary_edit_label_task: "Tugasan",
          hotel_inhouse_setting_boundary_edit_label_ten_mins: "Sepuluh Min",
          hotel_inhouse_setting_boundary_edit_label_hour: "Jam",
          hotel_inhouse_setting_boundary_edit_label_three_hours: "Tiga jam",
          hotel_inhouse_setting_boundary_edit_button_none: "tiada",
          hotel_inhouse_setting_boundary_edit_button_unlimited: "Tiada had masa",
          hotel_inhouse_setting_boundary_edit_button_value: "Nilai",
          hotel_inhouse_setting_boundary_edit_label_option_one: "Pilihan Satu",
          hotel_inhouse_setting_boundary_edit_label_option_two: "Pilihan Kedua",
          hotel_inhouse_setting_boundary_edit_validation_boundary_line_hour: "* Sila masukkan jam.",
          hotel_inhouse_setting_boundary_edit_button_cancel: "Batal",
          hotel_inhouse_setting_boundary_edit_button_ok: "okey",
          hotel_inhouse_setting_amenity_list_title: "TETAPAN KEMUDAHAN",
          hotel_inhouse_setting_amenity_list_label_no: "TIDAK.",
          hotel_inhouse_setting_amenity_list_label_item: "item",
          hotel_inhouse_setting_amenity_list_label_order_by: "Perintah oleh",
          hotel_inhouse_setting_amenity_list_label_update_at: "Dikemaskini Pada",
          hotel_inhouse_setting_amenity_list_guide_empty: "Senarai kosong",
          hotel_inhouse_room_controller_detail_title: "PENGAWAL BILIK",
          hotel_inhouse_room_controller_detail_button_choose_file: "Pilih fail",
          hotel_inhouse_room_controller_detail_button_insert: "Input",
          hotel_inhouse_room_controller_detail_button_export: "Eksport",
          hotel_inhouse_help_list_title: "BANTUAN UNTUK MENU KUNCI MOBILE",
          hotel_inhouse_help_list_button_add: "Tambah",
          hotel_inhouse_help_list_button_delete: "Padam",
          hotel_inhouse_help_list_button_edit: "Sunting",
          hotel_inhouse_help_list_guide_add: "Sila tambah maklumat hotel.",
          hotel_inhouse_help_add_title: "TAMBAH BANTUAN UNTUK KUNCI MOBILE",
          hotel_inhouse_help_add_placeholder_title: "Sila masukkan tajuk.",
          hotel_inhouse_help_add_placeholder_index: "Sila masukkan indeks.",
          hotel_inhouse_help_add_select_choose: "Sila pilih jenis",
          hotel_inhouse_help_add_select_use: "guna",
          hotel_inhouse_help_add_select_access: "Akses",
          hotel_inhouse_help_add_select_range: "Julat",
          hotel_inhouse_help_add_select_not_working: "Tidak bekerja",
          hotel_inhouse_help_add_select_in_room_tech: "In Room Tech",
          hotel_inhouse_help_add_select_trouble: "masalah",
          hotel_inhouse_help_add_toolbar_bold: "berani",
          hotel_inhouse_help_add_toolbar_italic: "Italic",
          hotel_inhouse_help_add_toolbar_underline: "Garis bawah",
          hotel_inhouse_help_add_toolbar_remove_font_style: "Alih keluar gaya fon",
          hotel_inhouse_help_add_toolbar_font_family: "Keluarga fon",
          hotel_inhouse_help_add_toolbar_font_size: "Saiz huruf",
          hotel_inhouse_help_add_toolbar_recent_color: "Warna terkini",
          hotel_inhouse_help_add_toolbar_recent_more_color: "Lebih banyak warna",
          hotel_inhouse_help_add_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_inhouse_help_add_toolbar_ordered_list: "Senarai pesanan",
          hotel_inhouse_help_add_toolbar_paragraph: "Perenggan",
          hotel_inhouse_help_add_toolbar_line_height: "Ketinggian Garisan",
          hotel_inhouse_help_add_placeholder_content: "Sila masukkan kandungan.",
          hotel_inhouse_help_add_button_cancel: "Batal",
          hotel_inhouse_help_add_button_ok: "okey",
          hotel_inhouse_help_edit_title: "EDIT MAKLUMAT HOTEL",
          hotel_inhouse_help_edit_placeholder_title: "Tajuk",
          hotel_inhouse_help_edit_select_kr: "KR",
          hotel_inhouse_help_edit_select_en: "EN",
          hotel_inhouse_help_edit_select_cn: "CN",
          hotel_inhouse_help_edit_select_jp: "JP",
          hotel_inhouse_help_edit_select_ms: "MS",
          hotel_inhouse_help_edit_placeholder_index: "Indeks",
          hotel_inhouse_help_edit_toolbar_bold: "berani",
          hotel_inhouse_help_edit_toolbar_italic: "Italic",
          hotel_inhouse_help_edit_toolbar_underline: "Garis bawah",
          hotel_inhouse_help_edit_toolbar_remove_font_style: "Alih keluar gaya fon",
          hotel_inhouse_help_edit_toolbar_font_family: "Keluarga fon",
          hotel_inhouse_help_edit_toolbar_font_size: "Saiz huruf",
          hotel_inhouse_help_edit_toolbar_recent_color: "Warna terkini",
          hotel_inhouse_help_edit_toolbar_recent_more_color: "Lebih banyak warna",
          hotel_inhouse_help_edit_toolbar_unordered_list: "Senarai tidak teratur",
          hotel_inhouse_help_edit_toolbar_ordered_list: "Senarai pesanan",
          hotel_inhouse_help_edit_toolbar_paragraph: "Perenggan",
          hotel_inhouse_help_edit_toolbar_code_view: "Pandangan kod",
          hotel_inhouse_help_edit_toolbar_line_height: "Ketinggian garisan",
          hotel_inhouse_help_edit_button_append: "Lampirkan",
          hotel_inhouse_help_edit_button_remove: "Alih keluar",
          hotel_inhouse_help_edit_button_cancel: "Batal",
          hotel_inhouse_help_edit_button_ok: "okey",
          hotel_key_guest_list_title: "SENARAI KUNCI TETAMU",
          hotel_key_guest_list_button_alive: "Aktif",
          hotel_key_guest_list_placeholder_select_box_key: "kunci",
          hotel_key_guest_list_select_room_no: "Nombor bilik.",
          hotel_key_guest_list_placeholder_search: "Cari...",
          hotel_key_guest_list_label_id: "ID",
          hotel_key_guest_list_label_availble_at: "Tersedia Di",
          hotel_key_guest_list_label_expired_at: "Tamat tempoh Pada",
          hotel_key_guest_list_label_is_main: "Utama",
          hotel_key_guest_list_label_room_no: "Nombor bilik.",
          hotel_key_guest_list_label_user: "pengguna",
          hotel_key_guest_list_button_cancel: "Batal",
          hotel_key_guest_list_guide_empty: "Senarai kosong",
          hotel_key_guest_detail_title: "MAKLUMAT UTAMA",
          hotel_key_guest_detail_label_id: "ID",
          hotel_key_guest_detail_label_availble_at: "Tersedia Di",
          hotel_key_guest_detail_label_expired_at: "Tamat tempoh Pada",
          hotel_key_guest_detail_label_is_main: "Utama",
          hotel_key_guest_detail_label_room_no: "Nombor bilik.",
          hotel_key_guest_detail_label_confirmation_no: "No Pengesahan",
          hotel_key_guest_detail_label_user: "pengguna",
          hotel_key_guest_detail_label_user_email: "E-mel pengguna",
          hotel_key_guest_detail_button_go_to_list: "Pergi ke senarai",
          hotel_key_guest_detail_button_cancel: "Batal",
          hotel_key_staff_list_title: "SENARAI KUNCI STAF",
          hotel_key_staff_list_button_alive: "Aktif",
          hotel_key_staff_list_placeholder_select_box_key: "kunci",
          hotel_key_staff_list_select_task: "Tugasan",
          hotel_key_staff_list_select_emp_id: "EMP. ID",
          hotel_key_staff_list_placeholder_search: "Cari...",
          hotel_key_staff_list_label_type: "taip",
          hotel_key_staff_list_label_link_type: "Jenis Pautan",
          hotel_key_staff_list_label_link_detail: "Butiran Pautan",
          hotel_key_staff_list_label_link_code: "Kod Pautan",
          hotel_key_staff_list_label_availble_at: "Tersedia Di",
          hotel_key_staff_list_label_expired_at: "Tamat tempoh Pada",
          hotel_key_staff_list_label_staff_number: "Nombor Kakitangan",
          hotel_key_staff_list_button_cancel: "Batal",
          hotel_key_staff_list_button_add: "Tambah",
          hotel_key_staff_list_guide_empty: "Senarai kosong",
          hotel_key_staff_add_title: "TAMBAH KUNCI KAKITANGAN",
          hotel_key_staff_add_button_append: "Lampirkan",
          hotel_key_staff_add_label_staff_number: "Nombor Kakitangan",
          hotel_key_staff_add_label_type: "taip",
          hotel_key_staff_add_label_detail: "Perincian",
          hotel_key_staff_add_label_from_date: "Dari tarikh",
          hotel_key_staff_add_label_end_date: "Tarikh tamat",
          hotel_key_staff_add_placeholder_select_box_choose: "Pilih",
          hotel_key_staff_add_button_remove: "Alih keluar",
          hotel_key_staff_add_button_go_to_list: "Pergi ke senarai",
          hotel_key_staff_add_button_add: "Tambah",
          hotel_key_staff_add_validation_number: "* Sila masukkan nombor kakitangan.",
          hotel_key_staff_add_validation_type: "* Sila pilih jenis.",
          hotel_key_staff_add_validation_detail: "* Sila pilih butiran.",
          hotel_key_staff_add_validation_from_date: "* Sila pilih tarikh mula.",
          hotel_key_staff_add_validation_end_date: "* Sila pilih tarikh tamat.",
          hotel_key_staff_detail_title: "MAKLUMAT UTAMA STAF",
          hotel_key_staff_detail_label_type: "taip",
          hotel_key_staff_detail_label_link_type: "Jenis Pautan",
          hotel_key_staff_detail_label_link_detail: "Butiran Pautan",
          hotel_key_staff_detail_label_link_code: "Kod Pautan",
          hotel_key_staff_detail_label_availble_at: "Tersedia Di",
          hotel_key_staff_detail_label_expired_at: "Tamat tempoh Pada",
          hotel_key_staff_detail_label_employee_id: "ID pekerja",
          hotel_key_staff_detail_label_canceled_at: "Dibatalkan Pada",
          hotel_key_staff_detail_button_go_to_list: "Pergi ke senarai",
          hotel_key_staff_detail_button_cancel: "Batal",
          hotel_inhouse_share_key_list_title: "KONGSI SENARAI UTAMA",
          hotel_inhouse_share_key_list_button_alive: "Aktif",
          hotel_inhouse_share_key_list_placeholder_select_box_share_key: "Kongsi Kunci",
          hotel_inhouse_share_key_list_select_room_no: "Nombor bilik.",
          hotel_inhouse_share_key_list_select_email: "E-mel",
          hotel_inhouse_share_key_list_select_user_name: "Nama Pengguna",
          hotel_inhouse_share_key_list_placeholder_search: "Cari...",
          hotel_inhouse_share_key_list_label_no: "Tidak.",
          hotel_inhouse_share_key_list_label_sender_room_no: "Bilik Pengirim No.",
          hotel_inhouse_share_key_list_label_sender_name: "Nama pengirim",
          hotel_inhouse_share_key_list_label_sender_email: "E-mel Pengirim",
          hotel_inhouse_share_key_list_label_key_type: "Jenis Kekunci",
          hotel_inhouse_share_key_list_label_expired_at: "Tamat tempoh Pada",
          hotel_inhouse_share_key_list_label_receiver_room_no: "Bilik Penerima No.",
          hotel_inhouse_share_key_list_label_receiver_email: "E-mel Penerima",
          hotel_inhouse_share_key_list_label_receiver_status: "Status Penerima",
          hotel_inhouse_share_key_list_label_date: "Tarikh",
          hotel_inhouse_share_key_list_guide_empty: "Senarai kosong",
          hotel_inhouse_share_key_detail_title: "KONGSI BUTIRAN UTAMA",
          hotel_inhouse_share_key_detail_label_sender_room_no: "Bilik Pengirim No.",
          hotel_inhouse_share_key_detail_label_sender_name: "Nama pengirim",
          hotel_inhouse_share_key_detail_label_sender_email: "E-mel Pengirim",
          hotel_inhouse_share_key_detail_label_key_type: "Kongsi Jenis Kunci",
          hotel_inhouse_share_key_detail_label_expired_at: "Kongsi Tamat Tempoh Kunci",
          hotel_inhouse_share_key_detail_label_receiver_room_no: "Bilik Penerima No.",
          hotel_inhouse_share_key_detail_label_receiver_email: "E-mel Penerima",
          hotel_inhouse_share_key_detail_label_receiver_status: "Status Penerima",
          hotel_inhouse_share_key_detail_label_created_at: "Dicipta Pada",
          hotel_inhouse_share_key_detail_button_go_to_list: "Pergi ke senarai",
          help_for_mobile_key_add_validation_title: "* Sila masukkan subjek.",
          help_for_mobile_key_add_validation_index: "*Sila masukkan nombor.",
          help_for_mobile_key_add_validation_type: "* Sila pilih jenis.",
          help_for_mobile_key_add_validation_content: "* Sila masukkan butiran anda.",
          help_for_mobile_key_edit_validation_title: "* Sila masukkan subjek.",
          help_for_mobile_key_edit_validation_index: "*Sila masukkan nombor.",
          help_for_mobile_key_edit_validation_content: "* Sila masukkan butiran anda.",
          help_for_mobile_key_edit_validation_language_code: "* Sila pilih kod bahasa yang tidak bertindih.",
          hotel_key_link_list_title: "SENARAI PAUTAN KUNCI STAF",
          hotel_key_link_list_placeholder_search: "Cari...",
          hotel_key_link_list_label_type_code: "Taip Kod",
          hotel_key_link_list_label_type_description: "Jenis Penerangan",
          hotel_key_link_list_label_detail_code: "Kod Perincian",
          hotel_key_link_list_label_detail_description: "Penerangan terperinci",
          hotel_key_link_list_label_staff_type: "Jenis Kakitangan",
          hotel_key_link_list_label_code: "Kod",
          hotel_key_link_list_label_date: "Tarikh",
          hotel_key_link_list_button_delete: "Padam",
          hotel_key_link_list_button_add: "Tambah",
          hotel_key_link_list_guide_empty: "Senarai kosong",
          hotel_key_link_add_title: "TAMBAHKAN PAUTAN KUNCI KAKITANGAN",
          hotel_key_link_add_label_type_code: "Taip Kod",
          hotel_key_link_add_placeholder_select_box_choose: "Pilih",
          hotel_key_link_add_select_floor: "Lantai",
          hotel_key_link_add_select_task: "Tugasan",
          hotel_key_link_add_select_zone: "Kawasan",
          hotel_key_link_add_label_type_description: "Jenis Penerangan",
          hotel_key_link_add_label_detail_code: "Kod Perincian",
          hotel_key_link_add_label_detail_description: "Penerangan terperinci",
          hotel_key_link_add_label_staff_type: "Jenis Kakitangan",
          hotel_key_link_add_select_concoerge: "Concierge",
          hotel_key_link_add_select_housekeeping: "Pengemasan",
          hotel_key_link_add_select_front_desk: "Meja hadapan",
          hotel_key_link_add_label_code: "Kod",
          hotel_key_link_add_button_go_to_list: "Pergi ke senarai",
          hotel_key_link_add_button_add: "Tambah",
          hotel_key_link_add_validation_type_code: "* Sila pilih kod jenis.",
          hotel_key_link_add_validation_type_description: "* Sila masukkan perihalan jenis.",
          hotel_key_link_add_validation_detail_code: "* Sila pilih kod terperinci.",
          hotel_key_link_add_validation_detail_description: "* Sila masukkan penerangan terperinci.",
          hotel_key_link_add_validation_staff_type: "* Sila pilih jenis kakitangan.",
          hotel_key_link_add_validation_code: "* Sila pilih kod.",
          hotel_key_link_detail_title: "MAKLUMAT PAUTAN KUNCI STAF",
          hotel_key_link_detail_label_type_code: "Taip Kod",
          hotel_key_link_detail_label_type_description: "Jenis Penerangan",
          hotel_key_link_detail_label_detail_code: "Kod Perincian",
          hotel_key_link_detail_label_detail_description: "Penerangan terperinci",
          hotel_key_link_detail_label_staff_type: "Jenis Kakitangan",
          hotel_key_link_detail_label_code: "Kod",
          hotel_key_link_detail_label_date: "Tarikh",
          hotel_key_link_detail_button_go_to_list: "Pergi ke senarai",
          hotel_key_link_detail_button_delete: "Padam",
          hotel_key_link_detail_button_edit: "Sunting",
          hotel_key_link_edit_title: "MAKLUMAT PAUTAN KUNCI STAF",
          hotel_key_link_edit_label_type_code: "Taip Kod",
          hotel_key_link_edit_placeholder_select_box_choose: "Pilih",
          hotel_key_link_edit_select_floor: "Lantai",
          hotel_key_link_edit_select_task: "Tugasan",
          hotel_key_link_edit_select_zone: "Kawasan",
          hotel_key_link_edit_select_room: "Bilik",
          hotel_key_link_edit_label_type_description: "Jenis Penerangan",
          hotel_key_link_edit_label_detail_code: "Kod Perincian",
          hotel_key_link_edit_label_detail_description: "Penerangan terperinci",
          hotel_key_link_edit_label_staff_type: "Jenis Kakitangan",
          hotel_key_link_edit_select_concoerge: "Concierge",
          hotel_key_link_edit_select_housekeeping: "Pengemasan",
          hotel_key_link_edit_select_front_desk: "Meja hadapan",
          hotel_key_link_edit_label_code: "Kod",
          hotel_key_link_edit_label_date: "Tarikh",
          hotel_key_link_edit_button_cancel: "Batal",
          hotel_key_link_edit_button_delete: "Padam",
          hotel_key_link_edit_button_ok: "okey",
          hotel_key_link_edit_validation_type_code: "* Sila pilih kod jenis.",
          hotel_key_link_edit_validation_type_description: "* Sila masukkan perihalan jenis.",
          hotel_key_link_edit_validation_detail_code: "* Sila pilih kod terperinci.",
          hotel_key_link_edit_validation_detail_description: "* Sila masukkan penerangan terperinci.",
          hotel_key_link_edit_validation_staff_type: "* Sila pilih jenis kakitangan.",
          hotel_key_link_edit_validation_code: "* Sila pilih kod.",
          hotel_key_access_list_title: "SENARAI AKSES",
          hotel_key_access_list_label_title: "Tajuk",
          hotel_key_access_list_label_type: "taip",
          hotel_key_access_list_label_content: "Penerangan",
          hotel_key_access_list_label_position_code: "Kod Jawatan",
          hotel_key_access_list_label_position_name: "Nama Jawatan",
          hotel_key_access_list_label_remark: "Teguran",
          hotel_key_access_list_label_lcon: "ikon",
          hotel_key_access_list_label_update_at: "Dikemaskini Pada",
          hotel_key_access_list_button_delete: "Padam",
          hotel_key_access_list_button_add: "Tambah",
          hotel_key_access_list_guide_empty: "Senarai kosong",
          hotel_key_access_add_title: "TAMBAH AKSES",
          hotel_key_access_add_label_title: "Tajuk",
          hotel_key_access_add_label_type: "taip",
          hotel_key_access_add_label_content: "Kandungan",
          hotel_key_access_add_label_position_code: "Kod Jawatan",
          hotel_key_access_add_label_position_name: "Nama Jawatan",
          hotel_key_access_add_label_remark: "Teguran",
          hotel_key_access_add_placeholder_select_box_choose: "Pilih",
          hotel_key_access_add_label_icon: "ikon",
          hotel_key_access_add_button_upload: "Muat naik",
          hotel_key_access_add_placeholder_upload: "cth) MyCONECT.png",
          hotel_key_access_add_button_go_to_list: "Pergi ke senarai",
          hotel_key_access_add_button_add: "Tambah",
          hotel_key_access_add_validation_title: "* Sila masukkan tajuk anda.",
          hotel_key_access_add_validation_type: "* Sila masukkan jenis.",
          hotel_key_access_add_validation_content: "* Sila masukkan butiran anda.",
          hotel_key_access_add_validation_position_name: "* Sila pilih nama jawatan.",
          hotel_key_access_add_validation_file: "* Sila muat naik ikon.",
          hotel_key_access_detail_title: "AKSES MAKLUMAT",
          hotel_key_access_detail_label_title: "Tajuk",
          hotel_key_access_detail_label_type: "taip",
          hotel_key_access_detail_label_content: "Kandungan",
          hotel_key_access_detail_label_position_code: "Kod Jawatan",
          hotel_key_access_detail_label_position_name: "Nama Jawatan",
          hotel_key_access_detail_label_remark: "Teguran",
          hotel_key_access_detail_label_lcon: "ikon",
          hotel_key_access_detail_label_update_date: "Tarikh Dikemaskini",
          hotel_key_access_detail_button_upload: "Muat naik",
          hotel_key_access_detail_button_go_to_list: "Pergi ke senarai",
          hotel_key_access_detail_button_cancel: "Batal",
          hotel_key_access_detail_button_delete: "Padam",
          hotel_key_access_detail_button_edit: "Sunting",
          hotel_key_access_detail_button_ok: "okey",
          hotel_key_access_edit_validation_title: "* Sila masukkan tajuk anda.",
          hotel_key_access_edit_validation_type: "* Sila masukkan jenis.",
          hotel_key_access_edit_validation_content: "* Sila masukkan butiran anda.",
          hotel_key_access_edit_validation_position_name: "* Sila pilih nama jawatan.",
          hotel_key_access_edit_validation_file: "* Sila muat naik ikon.",
          hotel_key_access_detail_list_title: "SENARAI BUTIRAN AKSES",
          hotel_key_access_detail_list_label_access_title: "Tajuk Akses",
          hotel_key_access_detail_list_label_language_code: "Kod Bahasa",
          hotel_key_access_detail_list_label_access_detail_title: "Akses Tajuk Butiran",
          hotel_key_access_detail_list_label_date: "Tarikh",
          hotel_key_access_detail_list_button_delete: "Padam",
          hotel_key_access_detail_list_button_add: "Tambah",
          hotel_key_access_detail_list_guide_empty: "Senarai kosong",
          hotel_key_access_detail_add_title: "TAMBAHKAN BUTIRAN AKSES",
          hotel_key_access_detail_add_label_access_title: "Tajuk Akses",
          hotel_key_access_detail_add_placeholder_select_box_choose: "Pilih",
          hotel_key_access_detail_add_label_language_code: "Kod Bahasa",
          hotel_key_access_detail_add_select_kr: "KR",
          hotel_key_access_detail_add_select_en: "EN",
          hotel_key_access_detail_add_select_cn: "CN",
          hotel_key_access_detail_add_select_jp: "JP",
          hotel_key_access_detail_add_label_access_detail_title: "Akses Tajuk Butiran",
          hotel_key_access_detail_add_label_content: "Kandungan",
          hotel_key_access_detail_add_button_go_to_list: "Pergi ke senarai",
          hotel_key_access_detail_add_button_add: "Tambah",
          hotel_key_access_detail_add_validation_hotel_access_area_id: "* Sila pilih tajuk akses.",
          hotel_key_access_detail_add_validation_language_code: "* Sila pilih kod bahasa.",
          hotel_key_access_detail_add_validation_title: "* Sila masukkan tajuk butiran akses.",
          hotel_key_access_detail_add_validation_content: "* Sila masukkan butiran.",
          hotel_key_access_detail_detail_title: "BUTIRAN AKSES",
          hotel_key_access_detail_detail_label_date: "Tarikh Dikemaskini",
          hotel_key_access_detail_detail_label_access_title: "Tajuk Akses",
          hotel_key_access_detail_detail_placeholder_select_box_choose: "Pilih",
          hotel_key_access_detail_detail_label_language_code: "Kod Bahasa",
          hotel_key_access_detail_detail_select_kr: "KR",
          hotel_key_access_detail_detail_select_en: "EN",
          hotel_key_access_detail_detail_select_cn: "CN",
          hotel_key_access_detail_detail_select_jp: "JP",
          hotel_key_access_detail_detail_label_access_detail_title: "Akses Tajuk Butiran",
          hotel_key_access_detail_detail_label_content: "Kandungan",
          hotel_key_access_detail_detail_button_go_to_list: "Pergi ke senarai",
          hotel_key_access_detail_detail_button_cancel: "Batal",
          hotel_key_access_detail_detail_button_delete: "Padam",
          hotel_key_access_detail_detail_button_edit: "Sunting",
          hotel_key_access_detail_detail_button_ok: "okey",
          hotel_key_access_detail_edit_validation_language_code: "* Sila pilih kod bahasa.",
          hotel_key_access_detail_edit_validation_title: "* Sila masukkan tajuk butiran akses.",
          hotel_key_access_detail_edit_validation_content: "* Sila masukkan butiran.",
          hotel_key_reservation_access_add_validation_type: "* Sila pilih jenis.",
          hotel_key_reservation_access_add_validation_code: "* Sila pilih kod.",
          hotel_key_reservation_access_add_validation_name: "* Sila masukkan nama bilik.",
          hotel_key_reservation_access_add_validation_description: "* Sila masukkan butiran.",
          hotel_key_reservation_access_add_validation_checked_list: "* Sila semak senarai kebenaran akses tempahan.",
          hotel_key_reservation_access_edit_validation_checked_list: "* Sila semak senarai kebenaran akses tempahan.",
          hotel_key_setting_detail_title: "TETAPAN UTAMA",
          hotel_key_setting_detail_label_update_date: "Tarikh Dikemaskini",
          hotel_key_setting_detail_label_card_key: "Kunci Kad",
          hotel_key_setting_detail_label_card_key_type: "Jenis Kunci Kad",
          hotel_key_setting_detail_label_card_key_ip: "IP Kunci Kad",
          hotel_key_setting_detail_label_card_key_port: "Port Kunci Kad",
          hotel_key_setting_detail_label_card_key_path: "Laluan Kunci Kad",
          hotel_key_setting_detail_label_card_key_id: "ID Kunci Kad",
          hotel_key_setting_detail_label_card_key_password: "Kata Laluan Kunci Kad",
          hotel_key_setting_detail_label_special_guest: "Kawasan Khas untuk Tetamu",
          hotel_key_setting_detail_label_special_staff: "Kawasan Khas untuk Kakitangan",
          hotel_key_setting_detail_label_rssi_guest: "RSSI untuk Tetamu",
          hotel_key_setting_detail_label_rrssi_staff: "RSSI untuk Kakitangan",
          hotel_key_setting_detail_label_room_show_update_flag: "Bendera Kemas Kini Paparan Bilik",
          hotel_key_setting_detail_label_room_open_update_flag: "Bendera Kemas Kini Bilik Terbuka",
          hotel_key_setting_detail_label_room_show_key: "Kunci Tunjuk Bilik Dikeluarkan di Meja Penyambut Tamu",
          hotel_key_setting_detail_button_yes: "ya",
          hotel_key_setting_detail_button_no: "Tidak",
          hotel_key_setting_detail_label_key_expired_time: "Masa Tamat Tempoh Kunci (Jam:Minit)",
          hotel_key_setting_detail_label_accompany_room_to_room_issue: "Temani Isu Bilik Ke Bilik",
          hotel_key_setting_detail_label_room_to_room_not_available_time: "Masa Menunggu Penerimaan Bilik ke Bilik (minit)",
          hotel_key_setting_detail_label_share_key_issue: "Kongsi Isu Utama",
          hotel_key_setting_detail_label_accompany_share_key_issue: "Mengiringi Isu Utama Kongsi",
          hotel_key_setting_detail_label_share_key_not_available_time: "Kongsi Masa Tunggu Penerimaan Utama (minit)",
          hotel_key_setting_detail_label_share_key_room_key_issued_max_count: "Kiraan Maks Terbitan Kunci Bilik",
          hotel_key_setting_detail_label_test_room_number: "Nombor Bilik Ujian",
          hotel_key_setting_detail_button_cancel: "Batal",
          hotel_key_setting_detail_button_edit: "Sunting",
          hotel_key_setting_edit_title: "TETAPAN UTAMA",
          hotel_key_setting_edit_label_update_date: "Tarikh Dikemaskini",
          hotel_key_setting_edit_label_card_key: "Kunci Kad",
          hotel_key_setting_edit_label_card_key_type: "Jenis Kunci Kad",
          hotel_key_setting_edit_placeholder_select_box_choose: "Pilih",
          hotel_key_setting_edit_select_messerschmitt: "Messerschmitt",
          hotel_key_setting_edit_select_racos: "RACOS",
          hotel_key_setting_edit_select_racos_cms: "RACOS CMS",
          hotel_key_setting_edit_select_assa_abloy: "ASSA ABLOY",
          hotel_key_setting_edit_select_none: "tiada",
          hotel_key_setting_edit_label_card_key_ip: "IP Kunci Kad",
          hotel_key_setting_edit_placeholder_card_key_ip: "cth) 127.0.0.1",
          hotel_key_setting_edit_label_card_key_port: "Port Kunci Kad",
          hotel_key_setting_edit_placeholder_card_key_port: "cth) 8080",
          hotel_key_setting_edit_label_card_key_path: "Laluan Kunci Kad",
          hotel_key_setting_edit_label_card_key_id: "ID Kunci Kad",
          hotel_key_setting_edit_label_card_key_password: "Kata Laluan Kunci Kad",
          hotel_key_setting_edit_label_special_guest: "Kawasan Khas untuk Tetamu",
          hotel_key_setting_edit_label_special_staff: "Kawasan Khas untuk Kakitangan",
          hotel_key_setting_edit_label_rssi_guest: "RSSI untuk Tetamu",
          hotel_key_setting_edit_label_rrssi_staff: "RSSI untuk Kakitangan",
          hotel_key_setting_edit_label_room_show_update_flag: "Bendera Kemas Kini Paparan Bilik",
          hotel_key_setting_edit_label_room_show_key: "Isu Kunci Tunjuk Bilik di Meja Penyambut Tamu",
          hotel_key_setting_edit_button_yes: "ya",
          hotel_key_setting_edit_button_no: "Tidak",
          hotel_key_setting_edit_label_test_room_number: "Nombor Bilik Ujian",
          hotel_key_setting_edit_button_cancel: "Batal",
          hotel_key_setting_edit_button_ok: "okey",
          hotel_key_setting_edit_validation_card_key_type: "* Sila pilih jenis kunci kad.",
          hotel_key_setting_edit_validation_card_key_IP: "* Sila masukkan IP kunci kad.",
          hotel_key_setting_edit_validation_card_key_port: "* Sila masukkan port kunci kad.",
          hotel_key_setting_edit_validation_card_key_path: "* Sila masukkan laluan kunci kad.",
          hotel_key_setting_edit_validation_card_key_client_id: "* Sila masukkan ID kunci kad anda.",
          hotel_key_setting_edit_validation_card_key_client_password: "* Sila masukkan kata laluan kunci kad anda.",
          hotel_key_setting_edit_validation_special_area_for_guest: "* Sila masukkan kawasan khas tetamu.",
          hotel_key_setting_edit_validation_special_area_for_staff: "* Sila masukkan kawasan khas kakitangan.",
          hotel_key_setting_edit_validation_RSSI_for_guest: "* Sila masukkan RSSI pelanggan.",
          hotel_key_setting_edit_validation_RSSI_for_staff: "* Sila masukkan RSSI kakitangan.",
          hotel_key_setting_edit_validation_room_show_flag: "* Sila pilih untuk memaparkan kemas kini rancangan bilik.",
          hotel_key_setting_edit_validation_room_show_issued_front_desk: "* Sila pilih Bilik Meja Hadapan Tunjukkan Isu Utama.",
          hotel_key_setting_edit_validation_key_expired_time: "* Sila masukkan masa tamat tempoh kunci.",
          hotel_key_setting_edit_validation_room_to_room_not_available_time: "* Sila masukkan masa menunggu untuk penerimaan bilik ke bilik.",
          hotel_key_setting_edit_validation_is_share_key_issue: "* Pilih Isu kongsi kunci.",
          hotel_key_setting_edit_validation_share_key_not_available_time: "* Sila masukkan masa menunggu untuk berkongsi penerimaan kunci.",
          hotel_key_setting_edit_validation_room_key_issued_max_count: "* Sila masukkan bilangan maksimum kunci bilik yang dikeluarkan.",
          hotel_key_setting_edit_validation_test_room_no: "* Sila masukkan nombor bilik ujian.",
          hotel_key_reservation_access_list_title: "PAUTAN AKSES",
          hotel_key_reservation_access_list_label_type: "taip",
          hotel_key_reservation_access_list_label_name: "Nama",
          hotel_key_reservation_access_list_label_code: "Kod",
          hotel_key_reservation_access_list_label_access_area: "Kawasan Akses",
          hotel_key_reservation_access_list_label_date: "Tarikh",
          hotel_key_reservation_access_list_label_description: "Penerangan",
          hotel_key_reservation_access_list_button_cancel: "Padam",
          hotel_key_reservation_access_list_button_add: "Tambah",
          hotel_key_reservation_access_list_guide_empty: "Senarai kosong",
          hotel_key_reservation_access_add_title: "TAMBAH PAUTAN AKSES",
          hotel_key_reservation_access_add_label_type: "taip",
          hotel_key_reservation_access_add_placeholder_select_box_choose: "Pilih",
          hotel_key_reservation_access_add_select_transaction: "Transaksi",
          hotel_key_reservation_access_add_select_package: "Pakej",
          hotel_key_reservation_access_add_label_code: "Kod",
          hotel_key_reservation_access_add_label_name: "Nama",
          hotel_key_reservation_access_add_label_description: "Penerangan",
          hotel_key_reservation_access_add_label_list: "Senarai Akses Tempahan",
          hotel_key_reservation_access_add_label_list_title: "Tajuk",
          hotel_key_reservation_access_add_label_list_type: "taip",
          hotel_key_reservation_access_add_label_list_content: "Kandungan",
          hotel_key_reservation_access_add_label_list_position_code: "Kod Jawatan",
          hotel_key_reservation_access_add_label_list_position_name: "Nama Jawatan",
          hotel_key_reservation_access_add_label_list_remark: "Teguran",
          hotel_key_reservation_access_add_button_go_to_list: "Pergi ke senarai",
          hotel_key_reservation_access_add_button_add: "Tambah",
          hotel_key_reservation_access_detail_title: "AKSES MAKLUMAT PAUTAN",
          hotel_key_reservation_access_detail_label_type: "taip",
          hotel_key_reservation_access_detail_label_code: "Kod",
          hotel_key_reservation_access_detail_label_name: "Nama",
          hotel_key_reservation_access_detail_label_description: "Penerangan",
          hotel_key_reservation_access_detail_label_list: "Senarai Akses Tempahan",
          hotel_key_reservation_access_detail_label_list_title: "Tajuk",
          hotel_key_reservation_access_detail_label_list_type: "taip",
          hotel_key_reservation_access_detail_label_list_content: "Kandungan",
          hotel_key_reservation_access_detail_label_list_position_code: "Kod Jawatan",
          hotel_key_reservation_access_detail_label_list_position_name: "Nama Jawatan",
          hotel_key_reservation_access_detail_label_list_remark: "Teguran",
          hotel_key_reservation_access_detail_button_go_to_list: "Pergi ke senarai",
          hotel_key_reservation_access_detail_button_delete: "Padam",
          hotel_key_reservation_access_detail_button_edit: "Sunting",
          hotel_key_reservation_access_edit_title: "AKSES MAKLUMAT PAUTAN",
          hotel_key_reservation_access_edit_label_type: "taip",
          hotel_key_reservation_access_edit_placeholder_select_box_choose: "Pilih",
          hotel_key_reservation_access_edit_select_transaction: "Transaksi",
          hotel_key_reservation_access_edit_select_package: "Pakej",
          hotel_key_reservation_access_edit_label_code: "Kod",
          hotel_key_reservation_access_edit_label_description: "Penerangan",
          hotel_key_reservation_access_edit_label_list: "Senarai Akses Tempahan",
          hotel_key_reservation_access_edit_label_list_title: "Tajuk",
          hotel_key_reservation_access_edit_label_list_type: "taip",
          hotel_key_reservation_access_edit_label_list_content: "Kandungan",
          hotel_key_reservation_access_edit_label_list_position_code: "Kod Jawatan",
          hotel_key_reservation_access_edit_label_list_position_name: "Nama Jawatan",
          hotel_key_reservation_access_edit_label_list_remark: "Teguran",
          hotel_key_reservation_access_edit_button_cancel: "Batal",
          hotel_key_reservation_access_edit_button_delete: "Padam",
          hotel_key_reservation_access_edit_button_ok: "okey",
          hotel_terms_list_title: "SENARAI TERMA",
          hotel_terms_list_select_type: "Jenis ( SEMUA )",
          hotel_terms_list_select_hotel_terms: "Syarat Hotel",
          hotel_terms_list_select_hotel_privacy_policies: "Dasar Privasi Hotel",
          hotel_terms_list_select_reservation_terms: "Syarat Tempahan",
          hotel_terms_list_select_reservation_privacy_policies: "Dasar Privasi Tempahan",
          hotel_terms_list_select_language_code: "Kod Bahasa ( SEMUA )",
          hotel_terms_list_select_ko: "KO",
          hotel_terms_list_select_en: "EN",
          hotel_terms_list_select_cn: "CN",
          hotel_terms_list_select_jp: "JP",
          hotel_terms_list_label_id: "ID",
          hotel_terms_list_label_language_code: "Kod Bahasa",
          hotel_terms_list_label_type: "taip",
          hotel_terms_list_label_title: "Tajuk",
          hotel_terms_list_label_version: "Versi",
          hotel_terms_list_label_link_text: "Teks Pautan",
          hotel_terms_list_label_created_at: "Dicipta Pada",
          hotel_terms_list_select_posted: "Disiarkan ( SEMUA )",
          hotel_terms_list_select_yes: "ya",
          hotel_terms_list_select_no: "Tidak",
          hotel_terms_list_placeholder_search: "Cari...",
          hotel_terms_list_guide_empty: "Senarai kosong",
          hotel_terms_list_button_delete: "Padam",
          hotel_terms_list_button_add: "Tambah",
          hotel_terms_add_title: "TAMBAH SYARAT",
          hotel_terms_add_label_language_code: "Kod Bahasa",
          hotel_terms_add_placeholder_select_box_select: "Pilih",
          hotel_terms_add_select_ko: "KO",
          hotel_terms_add_select_en: "EN",
          hotel_terms_add_select_cn: "CN",
          hotel_terms_add_select_jp: "JP",
          hotel_terms_add_label_type: "taip",
          hotel_terms_add_select_hotel_terms: "Syarat Hotel",
          hotel_terms_add_select_hotel_privacy_policies: "Dasar Privasi Hotel",
          hotel_terms_add_select_reservation_terms: "Syarat Tempahan",
          hotel_terms_add_select_reservation_privacy_policies: "Dasar Privasi Tempahan",
          hotel_terms_add_label_title: "Tajuk",
          hotel_terms_add_label_required: "Diperlukan",
          hotel_terms_add_button_yes: "ya",
          hotel_terms_add_button_no: "Tidak",
          hotel_terms_add_label_description: "Penerangan",
          hotel_terms_add_label_link_text: "Teks Pautan",
          hotel_terms_add_label_posted: "Disiarkan",
          hotel_terms_add_label_version: "Versi",
          hotel_terms_add_label_content_type: "Jenis kandungan",
          hotel_terms_add_select_file: "Fail",
          hotel_terms_add_select_text: "Teks",
          hotel_terms_add_label_content: "Kandungan",
          hotel_terms_add_label_file_url: "URL fail",
          hotel_terms_add_button_upload: "Muat naik",
          hotel_terms_add_button_go_to_list: "Pergi ke senarai",
          hotel_terms_add_button_add: "Tambah",
          hotel_terms_add_validation_language_code: "* Sila pilih kod bahasa.",
          hotel_terms_add_validation_type: "* Sila pilih jenis.",
          hotel_terms_add_validation_title: "* Sila masukkan tajuk anda.",
          hotel_terms_add_validation_required: "* Sila pilih sama ada ia diperlukan atau tidak.",
          hotel_terms_add_validation_description: "* Sila masukkan penerangan.",
          hotel_terms_add_validation_link_text: "* Sila masukkan teks pautan.",
          hotel_terms_add_validation_posted: "*Sila pilih sama ada nak post atau tidak.",
          hotel_terms_add_validation_version: "* Sila masukkan versi.",
          hotel_terms_add_validation_content_type: "* Sila pilih jenis kandungan.",
          hotel_terms_add_validation_content: "* Sila masukkan butiran.",
          hotel_terms_add_validation_hotel_terms_file_url: "* Sila muat naik fail.",
          hotel_terms_detail_title: "MAKLUMAT TERMA",
          hotel_terms_detail_label_terms_id: "ID Syarat",
          hotel_terms_detail_label_language_code: "Kod Bahasa",
          hotel_terms_detail_select_ko: "KO",
          hotel_terms_detail_select_en: "EN",
          hotel_terms_detail_select_cn: "CN",
          hotel_terms_detail_select_jp: "JP",
          hotel_terms_detail_label_type: "taip",
          hotel_terms_detail_select_hotel_terms: "Syarat Hotel",
          hotel_terms_detail_select_hotel_privacy_policies: "Dasar Privasi Hotel",
          hotel_terms_detail_select_reservation_terms: "Syarat Tempahan",
          hotel_terms_detail_select_reservation_privacy_policies: "Dasar Privasi Tempahan",
          hotel_terms_detail_label_version: "Versi",
          hotel_terms_detail_label_title: "Tajuk",
          hotel_terms_detail_label_posted: "Disiarkan",
          hotel_terms_detail_button_yes: "ya",
          hotel_terms_detail_button_no: "Tidak",
          hotel_terms_detail_label_required: "Diperlukan",
          hotel_terms_detail_label_description: "Penerangan",
          hotel_terms_detail_label_link_text: "Teks Pautan",
          hotel_terms_detail_label_content_type: "Jenis kandungan",
          hotel_terms_detail_select_file: "Fail",
          hotel_terms_detail_select_text: "Teks",
          hotel_terms_detail_label_file_url: "URL fail",
          hotel_terms_detail_button_upload: "Muat naik",
          hotel_terms_detail_label_content: "Kandungan",
          hotel_terms_detail_label_created_at: "Dicipta Pada",
          hotel_terms_detail_label_updated_at: "Dikemaskini Pada",
          hotel_terms_detail_button_go_to_list: "Pergi ke senarai",
          hotel_terms_edit_button_cancel: "Batal",
          hotel_terms_detail_button_delete: "Padam",
          hotel_terms_edit_button_download: "Muat turun",
          hotel_terms_detail_button_edit: "Sunting",
          hotel_terms_edit_button_ok: "okey",
          hotel_terms_edit_validation_language_code: "* Sila pilih kod bahasa.",
          hotel_terms_edit_validation_type: "* Sila pilih jenis.",
          hotel_terms_edit_validation_title: "* Sila masukkan tajuk anda.",
          hotel_terms_edit_validation_required: "* Sila pilih sama ada ia diperlukan atau tidak.",
          hotel_terms_edit_validation_description: "* Sila masukkan penerangan.",
          hotel_terms_edit_validation_link_text: "* Sila masukkan teks pautan.",
          hotel_terms_edit_validation_posted: "*Sila pilih sama ada nak post atau tidak.",
          hotel_terms_edit_validation_version: "* Sila masukkan versi.",
          hotel_terms_edit_validation_content_type: "* Sila pilih jenis kandungan.",
          hotel_terms_edit_validation_content: "* Sila masukkan butiran.",
          hotel_terms_edit_validation_hotel_terms_file_url: "* Sila muat naik fail.",
          hotel_agreement_list_title: "SENARAI SEJARAH PERJANJIAN",
          hotel_agreement_list_placeholder_select_box_choose: "Pilih",
          hotel_agreement_list_select_email: "E-mel",
          hotel_agreement_list_select_content: "Kandungan",
          hotel_agreement_list_select_confirmation_no: "No Pengesahan",
          hotel_agreement_list_placeholder_search: "Cari...",
          hotel_agreement_list_label_no: "TIDAK.",
          hotel_agreement_list_label_terms_type: "Jenis Terma",
          hotel_agreement_list_label_terms_id: "ID Syarat",
          hotel_agreement_list_label_terms_title: "Tajuk Syarat",
          hotel_agreement_list_label_confirmation_no: "No Pengesahan",
          hotel_agreement_list_label_email: "E-mel",
          hotel_agreement_list_label_name: "Nama",
          hotel_agreement_list_label_created_at: "Dicipta Pada",
          hotel_agreement_detail_title: "MAKLUMAT SEJARAH PERJANJIAN",
          hotel_agreement_detail_label_agreement: "Perjanjian",
          hotel_agreement_detail_label_agreement_at: "Perjanjian Disahkan Pada",
          hotel_agreement_detail_label_user: "pengguna",
          hotel_agreement_detail_label_email: "E-mel",
          hotel_agreement_detail_label_name: "Nama",
          hotel_agreement_detail_label_language_code: "Kod Bahasa",
          hotel_agreement_detail_label_reservation: "Tempahan",
          hotel_agreement_detail_label_confirmation_no: "No Pengesahan",
          hotel_agreement_detail_label_terms: "Syarat",
          hotel_agreement_detail_label_terms_id: "ID Syarat",
          hotel_agreement_detail_label_type: "taip",
          hotel_agreement_detail_label_version: "Versi",
          hotel_agreement_detail_label_title: "Tajuk",
          hotel_agreement_detail_label_required: "Diperlukan",
          hotel_agreement_detail_button_go_to_list: "Pergi ke senarai",
          hotel_event_list_title: "EVENT HISTORY",
          hotel_event_list_label_category_all: "Kategori ( SEMUA )",
          hotel_event_list_label_search_type_all: "Jenis Carian ( SEMUA )",
          hotel_event_list_label_title: "Tajuk",
          hotel_event_list_label_description: "Penerangan",
          hotel_event_list_placeholder_search: "Cari...",
          hotel_event_list_label_no: "Tidak.",
          hotel_event_list_label_category: "kategori",
          hotel_event_list_label_date: "Tarikh",
          hotel_event_list_guide_empty: "Senarai kosong",
          hotel_event_list_button_delete: "Padam",
          hotel_event_list_button_add: "Tambah",
          hotel_event_add_validation_category: "* Sila pilih kategori.",
          hotel_event_add_validation_templates: "* Sila pilih templat.",
          hotel_event_add_validation_users: "* Sila pilih pengguna.",
          hotel_event_add_title: "ACARA TAMBAH",
          hotel_input_detail_label_details: "Butiran",
          hotel_event_add_label_category: "kategori",
          hotel_event_add_label_templates: "templat",
          hotel_event_add_select_all: "SEMUA",
          hotel_event_add_label_notification_time: "Masa Pemberitahuan",
          hotel_event_add_label_title: "Tajuk",
          hotel_event_add_label_content: "Kandungan",
          hotel_event_add_label_description: "Penerangan",
          hotel_event_add_label_template_language: "Bahasa Templat",
          hotel_event_add_template_add_empty_guide: "Sila Tambah Kandungan",
          hotel_event_add_label_user: "pengguna",
          hotel_event_add_label_search_time: "Search Time",
          hotel_event_add_label_myconect_id: "ID MyCONECT",
          hotel_event_add_label_check_in: "Check In",
          hotel_event_add_placeholder_search: "Cari...",
          hotel_event_add_label_no: "Tidak.",
          hotel_event_add_label_first_name: "Nama pertama",
          hotel_event_add_label_last_name: "Nama terakhir",
          hotel_event_add_label_email: "E-mel",
          hotel_event_add_label_tile: "Tajuk",
          hotel_event_add_label_check_out: "Check Out",
          hotel_event_add_guide_empty: "Senarai kosong",
          hotel_event_add_button_go_to_list: "Pergi ke Senarai",
          hotel_event_add_button_delete: "Padam",
          hotel_event_add_button_ok: "okey",
          hotel_event_add_user_validation_users: "* Sila pilih pengguna.",
          hotel_event_add_user_title: "ACARA TAMBAH",
          hotel_event_add_user_label_user: "pengguna",
          hotel_event_add_user_label_current_time: "Current Time",
          hotel_event_add_user_label_search_time: "Search Time",
          hotel_event_add_user_label_all: "SEMUA",
          hotel_event_add_user_label_myconect_id: "ID MyCONECT",
          hotel_event_add_user_label_profile_id: "ID Profil",
          hotel_event_add_user_label_check_in: "Check In",
          hotel_event_add_user_placeholder_search: "Cari...",
          hotel_event_add_user_label_floor: "Lantai",
          hotel_event_add_user_label_reservation_status: "Status Tempahan",
          hotel_event_add_user_label_room_no: "Nombor bilik.",
          hotel_event_add_user_label_reserved: "Terpelihara",
          hotel_event_add_user_label_due_in: "Due in",
          hotel_event_add_user_label_due_out: "Due out",
          hotel_event_add_user_label_in_house: "Dalam Rumah",
          hotel_event_add_user_label_checked_out: "Checked out",
          hotel_event_add_user_label_title: "Tajuk",
          hotel_event_add_user_label_no: "Tidak.",
          hotel_event_add_user_label_first_name: "Nama pertama",
          hotel_event_add_user_label_last_name: "Nama terakhir",
          hotel_event_add_user_label_email: "E-mel",
          hotel_event_add_user_label_check_out: "Check Out",
          hotel_event_add_user_guide_empty: "Senarai kosong",
          hotel_event_add_user_button_cancel: "Batal",
          hotel_event_add_user_button_ok: "okey",
          hotel_event_detail_title: "BUTIRAN ACARA",
          hotel_event_detail_label_details: "Butiran",
          hotel_event_detail_label_category: "kategori",
          hotel_event_detail_label_templates: "templat",
          hotel_event_detail_label_notification_time: "Masa Pemberitahuan",
          hotel_event_detail_label_updated_at: "Dikemaskini Pada",
          hotel_event_detail_label_title: "Tajuk",
          hotel_event_detail_label_content: "Kandungan",
          hotel_event_detail_label_description: "Penerangan",
          hotel_event_detail_label_templates_language: "Bahasa Templat",
          hotel_event_detail_template_add_empty_guide: "Sila Tambah Kandungan",
          hotel_event_detail_label_all: "SEMUA",
          hotel_event_detail_label_myconect_id: "ID MyCONECT",
          hotel_event_detail_label_profile_id: "ID Profil",
          hotel_event_detail_placeholder_search: "Cari...",
          hotel_event_detail_label_no: "Tidak.",
          hotel_event_detail_label_first_name: "Nama pertama",
          hotel_event_detail_label_last_name: "Nama terakhir",
          hotel_event_detail_label_email: "E-mel",
          hotel_event_detail_label_gender: "Tajuk",
          hotel_event_detail_label_send: "Hantar",
          hotel_event_detail_label_check_up: "Pemeriksaan",
          hotel_event_detail_label_check_in: "Check In",
          hotel_event_detail_label_check_out: "Check Out",
          hotel_event_detail_label_success: "Kejayaan",
          hotel_event_detail_label_failed: "gagal",
          hotel_event_detail_guide_empty: "Senarai kosong",
          hotel_event_detail_button_go_to_list: "Pergi ke senarai",
          hotel_event_detail_button_delete: "Padam",
          hotel_event_detail_button_edit: "Sunting",
          hotel_event_edit_validation_category: "* Sila pilih kategori.",
          hotel_event_edit_validation_templates: "* Sila pilih templat.",
          hotel_event_edit_validation_users: "* Sila pilih pengguna.",
          hotel_event_edit_title: "EDIT ACARA",
          hotel_event_edit_label_details: "Butiran",
          hotel_event_edit_label_category: "kategori",
          hotel_event_edit_label_templates: "templat",
          hotel_event_edit_label_all: "SEMUA",
          hotel_event_edit_label_notification_time: "Masa Pemberitahuan",
          hotel_event_edit_label_title: "Tajuk",
          hotel_event_edit_label_content: "Kandungan",
          hotel_event_edit_label_description: "Penerangan",
          hotel_input_edit_label_templates_language: "Bahasa Templat",
          hotel_event_edit_template_add_empty_guide: "Sila Tambah Kandungan",
          hotel_event_edit_label_user: "pengguna",
          hotel_event_edit_label_myconect_id: "ID MyCONECT",
          hotel_event_edit_label_check_in: "Check In",
          hotel_event_edit_label_check_out: "",
          hotel_event_edit_placeholder_search: "Cari...",
          hotel_event_edit_button_delete: "Padam",
          hotel_event_edit_label_no: "Tidak.",
          hotel_event_edit_label_first_name: "Nama pertama",
          hotel_event_edit_label_last_name: "Nama terakhir",
          hotel_event_edit_label_email: "E-mel",
          hotel_event_edit_label_gender: "Tajuk",
          hotel_event_edit_guide_empty: "Senarai kosong",
          hotel_event_edit_button_cancel: "Batal",
          hotel_event_edit_button_ok: "okey",
          hotel_event_category_list_title: "KATEGORI ACARA",
          hotel_event_category_list_label_all: "SEMUA",
          hotel_event_category_list_label_type: "taip",
          hotel_event_category_list_label_show: "Tunjukkan",
          hotel_event_category_list_placeholder_search: "Cari...",
          hotel_event_category_list_label_no: "Tidak.",
          hotel_event_category_list_label_date: "Tarikh",
          hotel_event_category_list_guide_empty: "Senarai kosong",
          hotel_event_category_list_button_delete: "Padam",
          hotel_event_category_list_button_add: "Tambah",
          hotel_event_category_add_title: "TAMBAH KATEGORI ACARA",
          hotel_event_category_add_label_type: "taip",
          hotel_event_category_add_label_show: "Tunjukkan",
          hotel_event_category_add_label_yes: "ya",
          hotel_event_category_add_label_no: "Tidak",
          hotel_event_category_add_button_go_to_list: "Pergi ke Senarai",
          hotel_event_category_add_button_add: "Tambah",
          hotel_event_category_add_validation_type: "* Sila masukkan jenis.",
          hotel_event_category_add_validation_show: "* Sila pilih persembahan.",
          hotel_event_category_detail_title: "BUTIRAN KATEGORI ACARA",
          hotel_event_category_detail_label_detail: "Perincian",
          hotel_event_category_detail_button_edit: "Sunting",
          hotel_event_category_detail_label_type: "taip",
          hotel_event_category_detail_label_show: "Tunjukkan",
          hotel_event_category_detail_label_date: "Tarikh",
          hotel_event_category_detail_button_go_to_list: "Pergi ke Senarai",
          hotel_event_category_detail_button_delete: "Padam",
          hotel_event_category_detail_label_yes: "ya",
          hotel_event_category_detail_label_no: "Tidak",
          hotel_event_category_detail_label_template: "templat",
          hotel_event_category_detail_button_add: "Tambah",
          hotel_event_category_detail_label_number: "Tidak.",
          hotel_event_category_detail_label_title: "Tajuk",
          hotel_event_category_detail_guide_empty: "Senarai kosong",
          hotel_event_category_edit_title: "EDIT KATEGORI ACARA",
          hotel_event_category_edit_label_type: "taip",
          hotel_event_category_edit_label_show: "Tunjukkan",
          hotel_event_category_edit_label_yes: "ya",
          hotel_event_category_edit_label_no: "Tidak",
          hotel_event_category_edit_label_date: "Tarikh",
          hotel_event_category_edit_button_cancel: "Batal",
          hotel_event_category_edit_button_delete: "Padam",
          hotel_event_category_edit_button_ok: "okey",
          hotel_event_category_edit_validation_type: "* Sila masukkan jenis.",
          hotel_event_reservation_list_title: "EVENT RESERVATION LIST",
          hotel_event_reservation_list_label_number: "Tidak.",
          hotel_event_reservation_list_label_category: "kategori",
          hotel_event_reservation_list_label_title: "Tajuk",
          hotel_event_reservation_list_label_from: "daripada",
          hotel_event_reservation_list_label_to: "Kepada",
          hotel_event_reservation_list_label_reservation_status: "Status Tempahan",
          hotel_event_reservation_list_label_minutes_after: "After (min)",
          hotel_event_reservation_list_label_day_after: "After (day)",
          hotel_event_reservation_list_label_days_ago: " days ago",
          hotel_event_reservation_list_label_days_later: " days later",
          hotel_event_reservation_list_label_notification_time: "Masa Pemberitahuan",
          hotel_event_reservation_list_label_immediately: "Immediately",
          hotel_event_reservation_list_guide_empty: "Senarai kosong",
          hotel_event_reservation_list_button_add: "Tambah",
          hotel_event_reservation_add_title: "ADD EVENT RESERVATION",
          hotel_event_reservation_add_label_date: "Tarikh",
          hotel_event_reservation_add_label_reservation_status: "Status Tempahan",
          hotel_event_reservation_add_label_reserved: "Terpelihara",
          hotel_event_reservation_add_label_due_in: "Due in",
          hotel_event_reservation_add_label_walk_in: "Walk-In",
          hotel_event_reservation_add_label_check_in: "Daftar masuk",
          hotel_event_reservation_add_label_due_out: "Due out",
          hotel_event_reservation_add_label_check_out: "Checked out",
          hotel_event_reservation_add_label_cancel: "Batal",
          hotel_event_reservation_add_label_notification_time: "Masa Pemberitahuan",
          hotel_event_reservation_add_label_minutes_after: "After (min)",
          hotel_event_reservation_add_label_day_after: "After (day)",
          hotel_event_reservation_add_label_delay_passable: "Delay Passable",
          hotel_event_reservation_add_label_yes: "ya",
          hotel_event_reservation_add_label_no: "Tidak",
          hotel_event_reservation_add_label_category: "kategori",
          hotel_event_reservation_add_select_all: "Semua",
          hotel_event_reservation_add_label_templates: "templat",
          hotel_event_reservation_add_label_title: "Tajuk",
          hotel_event_reservation_add_label_content: "Kandungan",
          hotel_event_reservation_add_label_description: "Penerangan",
          hotel_event_reservation_add_label_template_language: "Bahasa Templat",
          hotel_event_reservation_add_template_add_empty_guide: "Sila Tambah Kandungan",
          hotel_event_reservation_add_button_go_to_list: "Pergi ke Senarai",
          hotel_event_reservation_add_button_ok: "okey",
          hotel_event_reservation_add_validation_translation_language_code: "* Please enter the translation language code.",
          hotel_event_reservation_add_validation_translation_title: "* Please enter the translation title.",
          hotel_event_reservation_add_validation_translation_content: "* Please enter the translation content.",
          hotel_event_reservation_add_validation_translation_description: "* Please enter the translation description.",
          hotel_event_reservation_add_validation_hotel_event_template_id: "* Please select a templates.",
          hotel_event_reservation_add_validation_title: "* Please enter the title.",
          hotel_event_reservation_add_validation_content: "* Please enter the content.",
          hotel_event_reservation_add_validation_description: "* Please enter the description.",
          hotel_event_reservation_add_validation_translations: "* Please enter the translations.",
          hotel_event_reservation_add_validation_hotel_event_category_id: "* Please select a category.",
          hotel_event_reservation_add_validation_minute_after: "* Please enter the minutes after.",
          hotel_event_reservation_detail_title: "EVENT RESERVATION DETAIL",
          hotel_event_reservation_detail_label_update: "Tarikh Dikemaskini",
          hotel_event_reservation_detail_label_date: "Tarikh",
          hotel_event_reservation_detail_label_reservation_status: "Status Tempahan",
          hotel_event_reservation_detail_label_minutes_after: "After (min)",
          hotel_event_reservation_detail_label_day_after: "After (day)",
          hotel_event_reservation_detail_label_delay_passable: "Delay Passable",
          hotel_event_reservation_detail_label_notification_time: "Masa Pemberitahuan",
          hotel_event_reservation_detail_label_category: "kategori",
          hotel_event_reservation_detail_label_templates: "templat",
          hotel_event_reservation_detail_label_created_at: "Dicipta Pada",
          hotel_event_reservation_detail_label_title: "Tajuk",
          hotel_event_reservation_detail_label_content: "Kandungan",
          hotel_event_reservation_detail_label_description: "Penerangan",
          hotel_event_reservation_detail_label_updated_at: "Bahasa Templat",
          hotel_event_reservation_detail_label_templates_language: "Bahasa Templat",
          hotel_event_reservation_detail_template_add_empty_guide: "Sila Tambah Kandungan",
          hotel_event_reservation_detail_button_go_to_list: "Pergi ke Senarai",
          hotel_event_reservation_detail_button_delete: "Padam",
          hotel_event_reservation_detail_button_edit: "Sunting",
          hotel_event_reservation_edit_title: "EVENT RESERVATION EDIT",
          hotel_event_reservation_edit_label_date: "Tarikh",
          hotel_event_reservation_edit_label_reservation_status: "Status Tempahan",
          hotel_event_reservation_edit_label_reserved: "Terpelihara",
          hotel_event_reservation_edit_label_due_in: "Due in",
          hotel_event_reservation_edit_label_walk_in: "Walk-In",
          hotel_event_reservation_edit_label_check_in: "Daftar masuk",
          hotel_event_reservation_edit_label_due_out: "Due out",
          hotel_event_reservation_edit_label_check_out: "Checked out",
          hotel_event_reservation_edit_label_cancel: "Batal",
          hotel_event_reservation_edit_label_notification_time: "Masa Pemberitahuan",
          hotel_event_reservation_edit_label_minutes_after: "After (min)",
          hotel_event_reservation_edit_label_day_after: "After (day)",
          hotel_event_reservation_edit_label_delay_passable: "Delay Passable",
          hotel_event_reservation_edit_label_yes: "ya",
          hotel_event_reservation_edit_label_no: "Tidak",
          hotel_event_reservation_edit_label_category: "kategori",
          hotel_event_reservation_edit_select_all: "Semua",
          hotel_event_reservation_edit_label_templates: "templat",
          hotel_event_reservation_edit_label_title: "Tajuk",
          hotel_event_reservation_edit_label_content: "Kandungan",
          hotel_event_reservation_edit_label_description: "Penerangan",
          hotel_event_reservation_edit_label_template_language: "Bahasa Templat",
          hotel_event_reservation_edit_template_add_empty_guide: "Sila Tambah Kandungan",
          hotel_event_reservation_edit_button_cancel: "Batal",
          hotel_event_reservation_edit_button_delete: "Padam",
          hotel_event_reservation_edit_button_ok: "okey",
          hotel_event_reservation_edit_validation_translation_language_code: "* Please enter the translation language code.",
          hotel_event_reservation_edit_validation_translation_title: "* Please enter the translation title.",
          hotel_event_reservation_edit_validation_translation_content: "* Please enter the translation content.",
          hotel_event_reservation_edit_validation_translation_description: "* Please enter the translation description.",
          hotel_event_reservation_edit_validation_hotel_event_template_id: "* Please select a templates.",
          hotel_event_reservation_edit_validation_title: "* Please enter the title.",
          hotel_event_reservation_edit_validation_content: "* Please enter the content.",
          hotel_event_reservation_edit_validation_description: "* Please enter the description.",
          hotel_event_reservation_edit_validation_translations: "* Please enter the translations.",
          hotel_event_reservation_edit_validation_hotel_event_category_id: "* Please select a category.",
          hotel_event_reservation_edit_validation_minute_after: "* Please enter the minutes after.",
          hotel_event_category_template_character_title: "EVENT CATEGORY TEMPLATE CONTENTS",
          hotel_event_category_template_character_label_confirmation_number: "Confirmation Number",
          hotel_event_category_template_character_example_confirmation_number: "{{confirmationNumber}}",
          hotel_event_category_template_character_label_accom_first_name: "Accompany First Name",
          hotel_event_category_template_character_example_accom_first_name: "{{accompanyFirstName}}",
          hotel_event_category_template_character_label_accom_last_name: "Accompany Last Name",
          hotel_event_category_template_character_example_accom_last_name: "{{accompanyLastName}}",
          hotel_event_category_template_character_label_first_name: "First Name",
          hotel_event_category_template_character_example_first_name: "{{firstName}}",
          hotel_event_category_template_character_label_last_name: "Last Name",
          hotel_event_category_template_character_example_last_name: "{{lastName}}",
          hotel_event_category_template_character_label_room_type: "Room Type",
          hotel_event_category_template_character_example_room_type: "{{roomType}}",
          hotel_event_category_template_character_label_arrival_date: "Arrival Date",
          hotel_event_category_template_character_example_arrival_date: "{{arrivalDate}}",
          hotel_event_category_template_character_label_departure_date: "Departure Date",
          hotel_event_category_template_character_example_departure_date: "{{departureDate}}",
          hotel_event_category_template_character_label_accom_title: "Accompany Title",
          hotel_event_category_template_character_example_accom_title: "{{accompanyTitle}}",
          hotel_event_category_template_character_label_adults: "Adults",
          hotel_event_category_template_character_example_adults: "{{adults}}",
          hotel_event_category_template_character_label_night: "Night",
          hotel_event_category_template_character_example_night: "{{night}}",
          hotel_event_category_template_character_label_room_rate: "Room Rate",
          hotel_event_category_template_character_example_room_rate: "{{roomRate}}",
          hotel_event_category_template_character_label_room_rate_currency: "Room Rate Currency",
          hotel_event_category_template_character_example_room_rate_currency: "{{roomRateCurrency}}",
          hotel_event_category_template_character_label_total_rate: "Total Rate",
          hotel_event_category_template_character_example_total_rate: "{{totalRate}}",
          hotel_event_category_template_character_label_cancellation: "Cancellation Policy",
          hotel_event_category_template_character_example_cancellationPolicy: "{{cancellationPolicy}}",
          hotel_event_category_template_add_title: "TAMBAH TEMPLAT KATEGORI ACARA",
          hotel_event_category_template_add_label_title: "Tajuk",
          hotel_event_category_template_add_label_content: "Kandungan",
          hotel_event_category_template_add_label_description: "Penerangan",
          hotel_event_category_template_add_label_language: "Bahasa",
          hotel_event_category_template_add_button_add: "Tambah",
          hotel_event_category_template_add_button_delete: "Padam",
          hotel_event_category_template_add_empty_guide: "Sila Tambah Kandungan",
          hotel_event_category_template_add_button_go_to_list: "Pergi ke Senarai",
          hotel_event_category_template_add_button_ok: "okey",
          hotel_event_category_template_add_validation_title: "* Sila masukkan tajuk.",
          hotel_event_category_template_add_validation_content: "* Sila masukkan kandungan.",
          hotel_event_category_template_add_validation_description: "* Sila masukkan keterangan.",
          hotel_event_category_template_detail_title: "BUTIRAN TEMPLAT KATEGORI ACARA",
          hotel_event_category_template_detail_label_templates: "templat",
          hotel_event_category_template_detail_label_category: "kategori",
          hotel_event_category_template_detail_label_title: "Tajuk",
          hotel_event_category_template_detail_label_content: "Kandungan",
          hotel_event_category_template_detail_label_description: "Penerangan",
          hotel_event_category_template_detail_label_date: "Tarikh",
          hotel_event_category_template_detail_label_language: "Bahasa",
          hotel_event_category_template_detail_empty_guide: "Sila Tambah Kandungan",
          hotel_event_category_template_detail_button_go_to_list: "Pergi ke Senarai",
          hotel_event_category_template_detail_button_delete: "Padam",
          hotel_event_category_template_detail_button_edit: "Sunting",
          hotel_event_category_template_edit_title: "EDIT TEMPLAT KATEGORI ACARA",
          hotel_event_category_template_edit_label_title: "Tajuk",
          hotel_event_category_template_edit_label_content: "Kandungan",
          hotel_event_category_template_edit_label_description: "Penerangan",
          hotel_event_category_template_edit_label_date: "Tarikh",
          hotel_event_category_template_edit_label_language: "Bahasa",
          hotel_event_category_template_edit_button_add: "Tambah",
          hotel_event_category_template_edit_button_delete: "Padam",
          hotel_event_category_template_edit_empty_guide: "Sila Tambah Kandungan",
          hotel_event_category_template_edit_button_go_to_list: "Pergi ke Senarai",
          hotel_event_category_template_edit_button_ok: "okey",
          hotel_event_category_template_edit_validation_title: "* Sila masukkan tajuk.",
          hotel_event_category_template_edit_validation_content: "* Sila masukkan kandungan.",
          hotel_event_category_template_edit_validation_description: "* Sila masukkan keterangan.",
          hotel_staff_user_list_title: "SENARAI KAKITANGAN",
          hotel_staff_user_list_select_staff: "Kakitangan ( SEMUA )",
          hotel_staff_user_list_select_all: "Semua",
          hotel_staff_user_list_select_department: "Jabatan",
          hotel_staff_user_list_select_team: "Pasukan",
          hotel_staff_user_list_select_level: "Tahap",
          hotel_staff_user_list_select_task: "Tugasan",
          hotel_staff_user_list_select_staff_number: "Nombor Kakitangan",
          hotel_staff_user_list_select_email: "E-mel",
          hotel_staff_user_list_placeholder_search: "Cari...",
          hotel_staff_user_list_label_department: "Jabatan",
          hotel_staff_user_list_label_team: "Pasukan",
          hotel_staff_user_list_label_level: "Tahap",
          hotel_staff_user_list_label_task: "Tugasan",
          hotel_staff_user_list_label_number: "Nombor",
          hotel_staff_user_list_label_employee_id: "ID pekerja",
          hotel_staff_user_list_label_available_at: "Tersedia Di",
          hotel_staff_user_list_label_expired_at: "Tamat tempoh Pada",
          hotel_staff_user_list_label_login_at: "Log masuk di",
          hotel_staff_user_list_label_date: "Tarikh",
          hotel_staff_user_list_button_delete: "Padam",
          hotel_staff_user_list_button_add: "Tambah",
          hotel_staff_user_add_title: "STAF BARU",
          hotel_staff_user_add_guide_click_add: "Untuk menambah kakitangan baharu, masukkan maklumat yang diperlukan dan klik \"Tambah\".",
          hotel_staff_user_add_validation_department: "* Sila pilih jabatan kakitangan.",
          hotel_staff_user_add_validation_team: "* Sila pilih pasukan kakitangan.",
          hotel_staff_user_add_validation_level: "* Sila pilih jawatan kakitangan.",
          hotel_staff_user_add_validation_guest_inquiry_level: "* Sila pilih tahap pertanyaan tetamu kakitangan.",
          hotel_staff_user_add_validation_number: "* Sila masukkan nombor kakitangan.",
          hotel_staff_user_add_validation_task: "* Sila masukkan tugas kakitangan.",
          hotel_staff_user_add_validation_number_only: "* Sila masukkan menggunakan nombor sahaja.",
          hotel_staff_user_add_validation_email: "* Sila masukkan e-mel kakitangan.",
          hotel_staff_user_add_validation_email_format: "* Sila masukkan mengikut format e-mel.",
          hotel_staff_user_add_validation_password: "* Sila masukkan kata laluan kakitangan.",
          hotel_staff_user_add_validation_character_number_combining: "* Sila masukkan sekurang-kurangnya 8 aksara menggabungkan huruf dan nombor.",
          hotel_staff_user_add_validation_not_mach: "* Kata laluan tidak sepadan.",
          hotel_staff_user_add_label_type: "taip",
          hotel_staff_user_add_select_full_time: "Sepenuh masa",
          hotel_staff_user_add_select_temporary: "Sementara",
          hotel_staff_user_add_placeholder_select_box_choose: "Pilih",
          hotel_staff_user_add_label_department: "Jabatan",
          hotel_staff_user_add_select_manage: "Pengurusan",
          hotel_staff_user_add_select_concierge: "Concierge",
          hotel_staff_user_add_select_housekeeping: "Pengemasan",
          hotel_staff_user_add_select_front_desk: "Meja hadapan",
          hotel_staff_user_add_select_valet: "Valet",
          hotel_staff_user_add_label_team: "Pasukan",
          hotel_staff_user_add_select_none: "tiada",
          hotel_staff_user_add_select_guest_service: "Perkhidmatan Tetamu",
          hotel_staff_user_add_label_level: "Tahap",
          hotel_staff_user_add_select_manager: "Pengurus",
          hotel_staff_user_add_select_supervisor: "Penyelia",
          hotel_staff_user_add_select_staff: "Kakitangan",
          hotel_staff_user_add_select_duty_manager: "Pengurus Bertugas",
          hotel_staff_user_add_label_guest_inquiry_level: "Tahap Pertanyaan Tetamu",
          hotel_staff_user_add_label_number: "Nombor",
          hotel_staff_user_add_placeholder_staff_number: "Nombor Kakitangan",
          hotel_staff_user_add_label_location: "Lokasi",
          hotel_staff_user_add_placeholder_staff_location: "Lokasi Kakitangan",
          hotel_staff_user_add_label_task: "Tugasan",
          hotel_staff_user_add_placeholder_staff_task: "Tugas Kakitangan",
          hotel_staff_user_add_label_email: "E-mel",
          hotel_staff_user_add_placeholder_staff_email: "E-mel Kakitangan",
          hotel_staff_user_add_label_password: "Kata laluan",
          hotel_staff_user_add_placeholder_staff_password: "Kata Laluan Kakitangan",
          hotel_staff_user_add_label_confirm_password: "Sahkan Kata Laluan",
          hotel_staff_user_add_placeholder_staff_confirm_password: "Sahkan Kata Laluan",
          hotel_staff_user_add_label_available_at: "Tersedia Di",
          hotel_staff_user_add_label_expired_at: "Tamat tempoh Pada",
          hotel_staff_user_add_button_add: "Tambah",
          hotel_staff_user_add_button_go_to_list: "Pergi ke senarai",
          hotel_staff_user_detail_title: "MAKLUMAT STAF",
          hotel_staff_user_detail_label_update_date: "Tarikh Dikemaskini",
          hotel_staff_user_detail_label_department: "Jabatan",
          hotel_staff_user_detail_label_team: "Pasukan",
          hotel_staff_user_detail_label_level: "Tahap",
          hotel_staff_user_detail_label_guest_inquiry_level: "Tahap pertanyaan tetamu",
          hotel_staff_user_detail_label_task: "Tugasan",
          hotel_staff_user_detail_label_staff_number: "Nombor Kakitangan",
          hotel_staff_user_detail_label_email: "E-mel",
          hotel_staff_user_detail_label_location: "Lokasi",
          hotel_staff_user_detail_label_room: "bilik",
          hotel_staff_user_detail_label_login_at: "Log masuk di",
          hotel_staff_user_detail_label_date: "Tarikh",
          hotel_staff_user_detail_label_available_at: "Tersedia Di",
          hotel_staff_user_detail_label_expired_at: "Tamat tempoh Pada",
          hotel_staff_user_detail_label_locked_information: "Maklumat Terkunci",
          hotel_staff_user_detail_label_locked_reason: "Sebab Terkunci",
          hotel_staff_user_detail_label_locked_at: "Dikunci Di",
          hotel_staff_user_detail_label_password: "Kata laluan",
          hotel_staff_user_detail_label_password_change: "Tukar Kata Laluan",
          hotel_staff_user_detail_button_go_to_list: "Pergi ke senarai",
          hotel_staff_user_detail_button_delete: "Padam",
          hotel_staff_user_detail_button_edit: "Sunting",
          hotel_staff_user_edit_title: "MAKLUMAT STAF",
          hotel_staff_user_edit_label_update_date: "Tarikh Dikemaskini",
          hotel_staff_user_edit_label_department: "Jabatan",
          hotel_staff_user_edit_placeholder_select_box_choose: "Pilih",
          hotel_staff_user_edit_validation_department: "* Sila pilih jabatan kakitangan.",
          hotel_staff_user_edit_select_manage: "Pengurusan",
          hotel_staff_user_edit_select_concierge: "Concierge",
          hotel_staff_user_edit_select_housekeeping: "Pengemasan",
          hotel_staff_user_edit_select_front_desk: "Meja hadapan",
          hotel_staff_user_edit_select_valet: "Valet",
          hotel_staff_user_edit_label_team: "Pasukan",
          hotel_staff_user_edit_select_none: "tiada",
          hotel_staff_user_edit_select_guest_service: "Perkhidmatan Tetamu",
          hotel_staff_user_edit_label_level: "Tahap",
          hotel_staff_user_edit_select_manager: "Pengurus",
          hotel_staff_user_edit_select_supervisor: "Penyelia",
          hotel_staff_user_edit_select_staff: "Kakitangan",
          hotel_staff_user_edit_select_duty_manager: "Pengurus Bertugas",
          hotel_staff_user_edit_label_task: "Tugasan",
          hotel_staff_user_edit_placeholder_task: "Tugasan",
          hotel_staff_user_edit_label_staff_number: "Nombor Kakitangan",
          hotel_staff_user_edit_placeholder_staff_number: "Nombor Kakitangan",
          hotel_staff_user_edit_label_email: "E-mel",
          hotel_staff_user_edit_placeholder_email: "E-mel",
          hotel_staff_user_edit_label_location: "Lokasi",
          hotel_staff_user_edit_placeholder_location: "Lokasi",
          hotel_staff_user_edit_label_room: "bilik",
          hotel_staff_user_edit_label_login_at: "Log masuk di",
          hotel_staff_user_edit_label_date: "Tarikh",
          hotel_staff_user_edit_label_available_at: "Tersedia Di",
          hotel_staff_user_edit_label_expired_at: "Tamat tempoh Pada",
          hotel_staff_user_edit_label_locked_information: "Maklumat Terkunci",
          hotel_staff_user_edit_label_locked_reason: "Sebab Terkunci",
          hotel_staff_user_edit_label_locked_at: "Dikunci Di",
          hotel_staff_user_edit_button_cancel: "Batal",
          hotel_staff_user_edit_button_delete: "Padam",
          hotel_staff_user_edit_button_ok: "okey",
          contents_failed_unable_connect_server: "Jaringan tidak dapat dicapai. \nSila semak sambungan Internet anda.",
          button_ok: "okey",
          button_cancel: "Batal",
          button_failed_login_message: "E-mel dan kata laluan tidak sepadan.",
          button_delete_message: "Adakah anda pasti mahu memadamkan maklumat yang dipilih?",
          button_no_select_delete_message: "Tiada yang dipilih. \nSila pilih senarai untuk dipadamkan.",
          button_no_select_hotel_copy_message: "Tiada yang dipilih.\nSila pilih hotel untuk disalin.",
          button_only_one_hotel_copy_message: "Sila pilih hanya satu hotel.",
          button_cancel_message: "Adakah anda pasti mahu membatalkan maklumat yang dipilih?",
          button_no_select_cancel_message: "Tiada yang dipilih. \nSila pilih senarai untuk dibatalkan.",
          button_admin_cant_delete_message: "Anda tidak boleh memadam ADMIN. Sila nyahpilih.",
          button_manager_cant_delete_message: "Anda tidak boleh memadam Pengurus. \nSila nyahpilih.",
          button_cant_release_message: "Anda tidak boleh melepaskan ahli ini. \nSila nyahpilih.",
          button_withdraw_message: "Adakah anda ingin menarik diri?",
          button_release_message: "Adakah anda pasti mahu mengeluarkan maklumat yang dipilih?",
          button_no_select_release_message: "Tiada yang dipilih. \nSila pilih senarai untuk dikeluarkan.",
          button_delete_qna_message: "Adakah anda pasti mahu memadamkan QnA?",
          button_update_information_message: "Maklumat yang sepadan wujud. \nAdakah anda ingin mengemas kini maklumat ini?",
          button_delete_information_message: "Padam maklumat sedia ada dan kemas kini \ndengan maklumat baru.",
          button_insert_information_message: "Masukkan maklumat baharu.",
          button_qna_loading_message: "Memuatkan…",
          button_cancel_not_save_message: "Jika anda membatalkan, kandungan tidak akan disimpan.\nAdakah anda pasti mahu membatalkannya?",
          button_cancel_booking_message: "Adakah anda pasti mahu membatalkan tempahan yang dipilih?",
          button_delete_owner_message: "Adakah anda pasti mahu memadamkan tetamu utama yang dipilih?",
          button_success_message: "Pengubahsuaian telah dibuat.",
          button_stop_message: "Adakah anda mahu menghentikan permintaan yang dipilih? \n(Keadaan tidak diketahui)",
          button_no_select_stop_message: "Tiada yang dipilih. Sila pilih senarai untuk berhenti.",
          button_upload_success_message: "Kejayaan Muat Naik. \n[[gambar]]",
          button_reservation_message: "Ada permintaan yang ada \nbelum selesai. Adakah anda ingin mengesahkan permintaan?",
          button_amenity_message_title: "barang No.",
          button_amenity_message: "Adakah anda ingin mengubah suai No. Item Kemudahan yang sepadan seperti berikut?",
          button_transfer_user_id_message: "Masukkan ID pengguna MyCONECT tetamu untuk disegerakkan semula.",
          contents_failed_session_password: "Sila masukkan kata laluan baharu\ndengan sekurang-kurangnya 8 aksara.",
          contents_session_out_message: "Tamat masa sesi. \nSila pergi ke halaman log masuk.",
          contents_failed_session_message: "Gagal untuk mendapatkan semula sesi.",
          contents_failed_password_incorrect_message: "Kata laluan semasa adalah tidak betul.",
          contents_failed_get_hotel_detail: "Gagal mendapatkan butiran hotel.",
          contents_failed_get_region_detail: "Gagal mendapatkan butiran wilayah.",
          contents_failed_get_region_list: "Gagal mendapatkan semula senarai wilayah",
          contents_failed_get_country_detail: "Gagal mendapatkan semula butiran negara.",
          contents_failed_get_country_list: "Gagal mendapatkan semula senarai negara",
          contents_failed_get_city_detail: "Gagal mendapatkan semula butiran bandar.",
          contents_failed_get_city_list: "Gagal mendapatkan semula senarai bandar",
          contents_failed_edit_hotel: "Gagal mengedit hotel.",
          contents_failed_edit_region: "Gagal mengedit wilayah.",
          contents_failed_edit_country: "Gagal mengedit negara.",
          contents_failed_edit_city: "Gagal mengedit bandar.",
          contents_failed_edit_amenity: "Gagal mengedit kemudahan.",
          contents_failed_add_amenity: "Tidak dapat menambah Kemudahan.",
          contents_failed_edit_recommend_app: "Gagal mengedit Apl Syor.",
          contents_failed_add_recommend_app: "Tidak dapat menambah Apl Syor.",
          contents_failed_edit_id_recognition_information: "Gagal mengedit Maklumat Pengecaman ID.",
          contents_failed_add_id_recognition_information: "Tidak dapat menambah Maklumat Pengecaman ID.",
          contents_failed_get_list: "Gagal mendapatkan senarai halaman yang sepadan.",
          contents_failed_logout: "Log keluar gagal.",
          contents_failed_get_informarion: "Gagal mendapatkan maklumat pengguna.",
          contents_failed_edit_information: "Gagal mengedit maklumat.",
          contents_failed_modify: "Penukaran Kata Laluan Gagal.",
          contents_failed_add_user: "Tidak dapat menambah pengguna. Sila semak sama ada e-mel itu sudah wujud.",
          contents_failed_transfer: "Pemindahan pautan gagal.",
          contents_failed_change_duty: "Pertukaran tugas gagal.",
          contents_failed_edit_user: "Gagal mengedit pengguna.",
          contents_failed_get_withdraw: "Gagal menarik diri.",
          contents_failed_add_version: "Tidak dapat menambah Versi.",
          contents_failed_edit_version: "Gagal mengedit versi.",
          contents_failed_delete: "Gagal memadam.",
          contents_failed_image: "Gagal mendapatkan maklumat imej.",
          contents_failed_get_area_list: "Gagal mendapatkan senarai kawasan khas.",
          contents_failed_get_hotel_detail_list: "Gagal mendapatkan semula senarai butiran hotel.",
          contents_failed_get_hotel_detail_detail: "Gagal mendapatkan butiran Hotel.",
          contents_failed_get_facility_icon_list: "Gagal mendapatkan semula senarai ikon kemudahan.",
          contents_failed_get_hotel_amenity_list: "Gagal mendapatkan semula senarai kemudahan hotel.",
          contents_failed_add_hotel_detail_content: "Tidak dapat menambah Kandungan Butiran Hotel.",
          contents_failed_add_hotel_detail_amenity: "Tidak dapat menambah Kemudahan Perincian Hotel.",
          contents_failed_get_hotel_recommend_application_list: "Gagal mendapatkan semula senarai permohonan yang disyorkan hotel.",
          contents_failed_add_hotel_detail_recommend_application: "Tidak dapat menambah aplikasi yang disyorkan hotel.",
          contents_failed_get_hotel_img_detail: "Gagal mendapatkan butiran Imej Hotel.",
          contents_failed_get_facility_img_detail: "Gagal mendapatkan butiran Imej Kemudahan Hotel.",
          contents_failed_add_hotel_img: "Tidak dapat menambah Imej Hotel.",
          contents_failed_add_facility_img: "Tidak dapat menambah Imej Kemudahan Hotel.",
          contents_failed_add_room_img: "Tidak dapat menambah Imej Bilik.",
          contents_failed_get_room_amenity_list: "Gagal mendapatkan semula senarai kemudahan bilik.",
          contents_failed_add_room_detail_amenity: "Tidak dapat menambah Kemudahan Perincian Bilik.",
          contents_failed_get_room_img_detail: "Gagal mendapatkan butiran Imej Bilik.",
          contents_failed_edit_hotel_detail_content: "Gagal mengedit Kandungan Butiran Hotel.",
          contents_failed_edit_room: "Gagal mengedit Bilik.",
          contents_failed_edit_room_detail_content: "Gagal mengedit Kandungan Butiran Bilik.",
          contents_failed_add_package: "Tidak dapat menambah Pakej.",
          contents_failed_add_package_detail: "Tidak dapat menambah Butiran Pakej.",
          contents_failed_get_package_detail: "Gagal mendapatkan semula pakej.",
          contents_failed_get_package_detail_detail: "Gagal mendapatkan butiran pakej.",
          contents_failed_get_access_detail_detail: "Gagal mendapatkan semula butiran akses.",
          contents_failed_add_access: "Tidak dapat menambah Akses.",
          contents_failed_edit_access: "Gagal mengedit Access.",
          contents_failed_add_access_detail: "Tidak dapat menambah Butiran Akses.",
          contents_failed_add_beacon: "Tidak dapat menambah Beacon.",
          contents_failed_add_region: "Tidak dapat menambah Wilayah.",
          contents_failed_add_country: "Tidak dapat menambah Negara.",
          contents_failed_add_city: "Tidak dapat menambah City.",
          contents_failed_edit_beacon: "Gagal mengedit Beacon.",
          contents_failed_add_currency: "Tidak dapat menambah Mata Wang.",
          contents_failed_get_currency_detail: "Gagal mendapatkan butiran mata wang hotel.",
          contents_not_supported_exchange_rate: "Kadar pertukaran tidak disokong.",
          contents_failed_get_currency_pms: "Gagal mendapatkan Mata Wang Hotel PMS.",
          contents_failed_get_currency_update: "Gagal mengemas kini Mata Wang Hotel.",
          contents_failed_request_stop: "Gagal Meminta Berhenti.",
          contents_failed_upload_file: "Gagal memuat naik fail.",
          contents_failed_get_count_canceled: "Gagal mendapatkan Papan Kiraan yang Dibatalkan.",
          contents_failed_get_business_rule_list: "Gagal mendapatkan senarai peraturan perniagaan.",
          contents_failed_uptade_myconect: "Gagal mengemas kini tetapan masa operasi MyCONECT.",
          contents_failed_get_concierge_myconect: "Gagal mendapatkan Senarai Concierge MyCONECT.",
          contents_request_type_not_exist: "Jenis Permintaan tidak wujud.",
          contents_failed_get_request_detail: "Gagal mendapatkan butiran permintaan.",
          contents_failed_request_stand_by: "Gagal meminta Standby.",
          contents_failed_request_pickup: "Gagal meminta Pickup.",
          contents_failed_request_start: "Gagal meminta Mula.",
          contents_failed_request_deliver: "Gagal meminta Hantar.",
          contents_failed_request_valet_no: "Gagal meminta Valet No.",
          contents_failed_get_hotel_setting: "Gagal mendapatkan Tetapan Hotel.",
          contents_failed_get_count_request_board: "Gagal mendapatkan Papan Kiraan Permintaan.",
          contents_failed_get_concierge_process_list: "Gagal mendapatkan Senarai Proses Concierge.",
          contents_failed_get_valet_parking_list: "Gagal mendapatkan Senarai Tempat Letak Kereta Berjoki.",
          contents_failed_add_parking_area_no: "Tidak dapat menambah Kawasan Letak Kereta No.",
          contents_failed_change_veicle_no: "Perubahan No. Kenderaan gagal.",
          contents_failed_request_location: "Gagal menyediakan kenderaan.",
          contents_failed_request_return: "Gagal memulangkan kenderaan.",
          contents_failed_request_handover: "Gagal menyerahkan kenderaan kepada tetamu.",
          contents_failed_get_business_rule: "Gagal mendapatkan semula peraturan perniagaan.",
          contents_failed_get_boundary_line_setting: "Gagal mendapatkan semula Tetapan Garisan Sempadan.",
          contents_failed_request_confirm: "Gagal meminta 'Pengesahan'.",
          contents_failed_request_not_available: "Gagal meminta 'Tidak Tersedia'.",
          contents_failed_request_not_available_myconect: "Gagal meminta 'Tidak Tersedia oleh MyCONECT'.",
          contents_failed_get_concierge_request_list: "Gagal mendapatkan semula Senarai Permintaan Concierge.",
          contents_already_exists: "Jenis ini sudah wujud.",
          contents_failed_add_help_for_mobile_key: "Gagal menambah 'Bantuan untuk Kunci Mudah Alih'.",
          contents_failed_get_help_for_mobile_key: "Gagal mendapatkan 'Bantuan untuk Kunci Mudah Alih'.",
          contents_failed_edit_help_for_mobile_key: "Gagal mengedit 'Bantuan untuk Kunci Mudah Alih'.",
          contents_failed_delete_help_for_mobile_key: "Gagal memadamkan maklumat 'Bantuan untuk Kunci Mudah Alih'.",
          contents_failed_add_service_description: "Gagal menambah 'Perihalan Perkhidmatan'.",
          contents_failed_get_service_description: "Gagal mendapatkan 'Perihalan Perkhidmatan'.",
          contents_failed_edit_service_description: "Gagal mengedit 'Perihalan Perkhidmatan'.",
          contents_failed_delete_service_description: "Gagal memadamkan 'Perihalan Perkhidmatan'.",
          contents_failed_add_hotel_information: "Gagal menambah Maklumat Hotel.",
          contents_failed_get_hotel_information: "Gagal mendapatkan Maklumat Hotel.",
          contents_failed_edit_hotel_information: "Gagal mengedit Maklumat Hotel.",
          contents_failed_edit_language_code_translation: "Bahasa telah dipilih.",
          contents_failed_all_language_code_exist: "All translations have been added.",
          contents_failed_delete_hotel_information: "Gagal memadamkan maklumat hotel.",
          contents_failed_get_canceled_user_count: "Gagal dibatalkan Mengikut Kiraan Pengguna.",
          contents_failed_get_canceled_staff_count: "Gagal Dibatalkan Oleh MyCONECT atau Kiraan Kakitangan.",
          contents_failed_get_housekeeping_myconect: "Gagal mendapatkan Senarai MyCONECT Housekeeping.",
          contents_failed_add_multi_myconect: "Tarikh yang anda pilih tidak betul",
          contents_failed_request_complete: "Gagal meminta 'Lengkap'.",
          contents_failed_request_put_off: "Gagal meminta 'Tunda'.",
          contents_failed_get_amenity_count: "Gagal mendapatkan Kiraan Kemudahan.",
          contents_failed_get_valet_count: "Gagal mendapatkan Valet Count.",
          contents_failed_get_housekeeping_process_list: "Gagal mendapatkan semula Senarai Kemajuan Pengemasan.",
          contents_failed_get_boundary_line_request_count: "Gagal mendapatkan Kiraan Permintaan Garis Sempadan.",
          contents_failed_update_auto: "Gagal mengemas kini masa mod auto Kempen Hijau",
          contents_failed_get_housekeeping_request_list: "Gagal mendapatkan Senarai Permintaan Pengemasan.",
          contents_failed_get_room_controller_detail: "Gagal mendapatkan butiran Kawalan Bilik.",
          contents_failed_insert_files: "Gagal memasukkan fail.",
          contents_failed_get_room_to_room_detail: "Gagal mendapatkan butiran Bilik ke Bilik.",
          contents_failed_get_share_key_detail: "Gagal mendapatkan semula butiran utama Kongsi.",
          contents_failed_modify_amenity: "Gagal mengubah suai Item Kemudahan No.",
          contents_failed_get_boundary_line_detail: "Gagal mendapatkan butiran garis sempadan.",
          contents_failed_modify_boundary_line_detail: "Gagal mengubah suai butiran garis sempadan.",
          contents_failed_modify_boundary_line_unasigned_count: "Gagal mendapatkan semula kiraan garis sempadan yang tidak ditetapkan.",
          contents_failed_get_business_rule_detail: "Gagal mendapatkan semula butiran peraturan perniagaan.",
          contents_failed_modify_business_rule_detail: "Gagal mengubah suai butiran peraturan perniagaan.",
          contents_failed_modify_waiting_time: "Gagal mengubah suai masa Tunggu Bilik.",
          contents_failed_cancel: "Gagal Membatalkan.",
          contents_failed_add_member_type: "Tidak dapat menambah Jenis Ahli.",
          contents_failed_edit_member_type: "Gagal mengedit Jenis Ahli.",
          contents_failed_edit_pms: "Gagal mengedit PMS.",
          contents_failed_sync: "Gagal Menyegerakkan.",
          contents_failed_send_reservation_email: "Gagal menghantar E-mel Tempahan.",
          contents_failed_get_staff_key_link_list: "Gagal mendapatkan semula senarai pautan kunci kakitangan.",
          contents_failed_get_staff_key_link_type_list: "Gagal mendapatkan senarai jenis pautan kunci kakitangan.",
          contents_failed_get_staff_key_link_floor_list: "Gagal mendapatkan senarai lantai pautan kunci kakitangan.",
          contents_failed_get_staff_key_link_zone_list: "Gagal mendapatkan senarai zon pautan kunci kakitangan.",
          contents_failed_get_staff_key_link_code_list: "Gagal mendapatkan senarai kod pautan kunci kakitangan.",
          contents_failed_get_staff_detail: "Gagal mendapatkan semula butiran kakitangan.",
          contents_failed_edit_staff_user: "Gagal mengedit pengguna kakitangan.",
          contents_failed_add_title: "Tidak dapat menambah tajuk.",
          contents_failed_edit_title: "Gagal mengedit tajuk.",
          contents_failed_get_list_search_page: "Gagal mendapatkan semula senarai halaman carian.",
          contents_failed_edit_setting: "Gagal mengedit Tetapan.",
          contents_failed_add_staff_key_link: "Tidak dapat menambah Pautan Kunci Kakitangan.",
          contents_failed_edit_staff_key_link: "Gagal mengedit Pautan Kunci Kakitangan.",
          contents_failed_edit_hotel_img: "Gagal mengedit Imej Hotel.",
          contents_failed_edit_facility_img: "Gagal mengedit Imej Kemudahan Hotel.",
          contents_failed_edit_room_img: "Gagal mengedit Imej Bilik.",
          contents_failed_edit_package_detail: "Gagal mengedit Butiran Pakej.",
          contents_failed_edit_access_detail: "Gagal mengedit Butiran Akses.",
          contents_failed_add_guest_app_version: "Gagal menambah versi apl tetamu.",
          contents_failed_add_staff_app_version: "Gagal menambah versi apl kakitangan.",
          contents_failed_get_rule_information: "Gagal mendapatkan semula Maklumat Peraturan.",
          contents_failed_edit_rule_information: "Gagal mengedit Maklumat Peraturan.",
          contents_failed_edit_sequence_information: "Gagal mengedit Jujukan.",
          contents_failed_get_rule_schedule_information: "Gagal mendapatkan semula Maklumat Jadual Peraturan.",
          contents_failed_edit_rule_schedule_information: "Gagal mengedit Maklumat Jadual Peraturan.",
          "": "Gagal mendapatkan Bandingkan Maklumat Wajah.",
          contents_modal_stop_message_unknown: "Mesej henti disimpan seperti berikut: \n'Situasi Tidak Diketahui' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_no_taxi: "Mesej henti disimpan seperti berikut: \n'Tidak Hadir Teksi' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_no_guest: "Mesej henti disimpan seperti berikut: \n'Tetamu Tidak Hadir' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_time_out_30: "Mesej henti disimpan seperti berikut: \n'Masa menunggu melebihi 30 minit' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_time_out_60: "Mesej henti disimpan seperti berikut: \n'Masa menunggu melebihi 60 minit' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_no_motorcycle: "Mesej henti disimpan seperti berikut: \n'Penghantaran Motosikal Tidak Hadir' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_no_luggage: "Mesej henti disimpan seperti berikut: \n'Bagasi belum siap' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_delay_30: "Mesej Put Off disimpan seperti berikut: \n'Lengah 30 minit' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_delay_60: "Mesej Put Off disimpan seperti berikut: \n'Lengah 60 minit' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_as_soon_as: "Mesej Put Off disimpan seperti berikut: \n'Sebaik sahaja tersedia' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_out_of_order: "Mesej henti disimpan seperti berikut: \n'Telah habis' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_cancel_service: "Mesej henti disimpan seperti berikut: \n'Batalkan perkhidmatan' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_not_found_it: "Mesej henti disimpan seperti berikut: \n'Tidak dapat ditemui' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_no_guest_room: "Mesej henti disimpan seperti berikut: \n'Tetamu tiada dalam bilik' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_change_items: "Mesej henti disimpan seperti berikut: \n'Tukar item' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_unparked: "Mesej henti disimpan seperti berikut: \n'Dinyahparkir dan batalkan' \nAdakah anda mahu meneruskan?",
          contents_modal_stop_message_parked: "Mesej henti disimpan seperti berikut: \n'Diparkir dan batalkan' \nAdakah anda mahu meneruskan?",
          contents_modal_myconect_release_proceed: "MyCONECT dikembangkan seperti berikut: \n'Keluaran Tidak Tersedia oleh MyCONECT' \nAdakah anda mahu meneruskan?",
          contents_modal_pickup_request: "Adakah anda mahu mengambil permintaan?",
          contents_modal_deliver_request: "Adakah anda ingin menyampaikan permintaan itu?",
          contents_modal_start_request: "Adakah anda mahu Memulakan permintaan?",
          contents_modal_confirm_request: "Adakah anda ingin Sahkan permintaan?",
          contents_modal_not_available_request: "Adakah anda mahu menetapkan permintaan kepada 'Tidak Tersedia'?",
          contents_modal_complete_request: "Adakah anda ingin Melengkapkan permintaan?",
          contents_modal_greencampaign_auto_process: "Adakah anda ingin memajukan Kempen Hijau Auto?",
          contents_modal_select_staff_payment: "Adakah anda mahu meneruskan dengan maklumat yang dipilih?",
          contents_modal_select_business_rule: "Adakah anda mahu meneruskan dengan maklumat yang dipilih?",
          contents_modal_delay_request: "Adakah anda mahu menangguhkan permintaan?",
          contents_modal_put_off_request: "Adakah anda mahu menangguhkan permintaan itu?",
          contents_modal_change_request: "Adakah anda mahu mengubahnya?",
          contents_modal_save_these_modifications: "Adakah anda ingin menyimpan pengubahsuaian ini?",
          contents_modal_select_myconect_not_available: "Pilih masa tetapan 'Tidak Tersedia oleh MyCONECT'",
          contents_modal_select_delay: "Pilih masa tetapan 'Lengah'",
          contents_modal_select_status: "Sila pilih status.",
          contents_modal_make_sure_reason: "Sila pilih sebab.",
          contents_modal_myconect_not_available_proceed: "MyCONECT akan dimajukan seperti berikut: \n'Permintaan ini akan ditetapkan sebagai 'Tidak tersedia' \nAdakah anda mahu meneruskan?",
          contents_modal_myconect_time_proceed: "MyCONECT akan dimajukan seperti berikut: \nDaripada: {{fromTime}} / Kepada: {{endTime}} \nAdakah anda mahu meneruskan?",
          contents_modal_greencampaign_time_proceed: "Kempen Hijau akan diteruskan seperti berikut: \nDaripada: {{fromTime}} / Kepada: {{endTime}} \nAdakah anda mahu meneruskan?",
          contents_modal_cannot_empty_value: "Nilai tidak boleh kosong. \nSila masukkan nilai.",
          contents_modal_information_already_exists: "Maklumat sudah wujud. \nSila cuba lagi.",
          contents_modal_placeholder_user_email: "Sila masukkan e-mel pengguna.",
          contents_modal_check_reset: "Previously selected users will be reset. Would you like to continue?"
      }
  },
  date_format: {
      date_format_slash_time_date: {
          date_format: "hh:mm a MM/dd/yy",
          language_code: "ms"
      },
      date_format_slash_date: {
          date_format: "MM/dd/yy",
          language_code: "ms"
      },
      date_format_hyphen_date: {
          date_format: "yyyy-MM-dd",
          language_code: "ms"
      },
      date_format_dot_month_year: {
          date_format: "MMM.yyyy",
          language_code: "ms"
      },
      date_format_dot_year_month: {
          date_format: "yyyy.MM",
          language_code: "ms"
      },
      date_format_slash_year_month: {
          date_format: "MM/yy",
          language_code: "ms"
      },
      date_format_ampm_time: {
          date_format: "a hh:mm",
          language_code: "ms"
      },
      date_format_time_ampm: {
          date_format: "hh:mm a",
          language_code: "ms"
      },
      date_format_24_hours_time: {
          date_format: "HH:mm",
          language_code: "ms"
      },
      date_format_hours_ampm: {
          date_format: "ha",
          language_code: "ms"
      },
      date_format_full_year: {
          date_format: "yyyy",
          language_code: "ms"
      },
      date_format_word_month: {
          date_format: "MMM",
          language_code: "ms"
      },
      date_format_full_day: {
          date_format: "dd",
          language_code: "ms"
      },
      date_format_short_day: {
          date_format: "d",
          language_code: "ms"
      },
      date_format_day_of_week: {
          date_format: "E",
          language_code: "ms"
      },
      date_format_ampm: {
          date_format: "a",
          language_code: "ms"
      },
      date_format_day_of_week_full: {
          date_format: "EEEE",
          language_code: "ms"
      },
      date_format_dot_month_day: {
          date_format: "MM.dd",
          language_code: "ms"
      },
      date_format_day_of_week_date: {
          date_format: "E. MMM. dd, yyyy",
          language_code: "ms"
      },
      date_format_slash_date_time: {
          date_format: "MM/dd/yy hh:mm a",
          language_code: "ms"
      },
      date_format_slash_month_day_time: {
          date_format: "MM/dd hh:mm a",
          language_code: "ms"
      },
      date_format_dot_date_time_full: {
          date_format: "yyyy.MM.dd hh:mm a",
          language_code: "ms"
      },
      date_format_dot_year_month_day: {
          date_format: "MM.dd.yy",
          language_code: "ms"
      },
      date_format_slash_full_year_date: {
          date_format: "yyyy/MM/dd",
          language_code: "ms"
      },
      date_format_space_date: {
          date_format: "dd MMM yyyy",
          language_code: "ms"
      },
      date_format_create_at_time_full: {
          date_format: "yyyy.MM.dd\nhh:mm a",
          language_code: "ms"
      }
  }
};

export default MalayLanguage;
