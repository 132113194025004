// StatisticsListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../components/Paths";
import StatisticsListData from "../List/StatisticsListData";

//datePicker
import DatePicker from "react-datepicker";

//chart.js
import  'chart.js/auto';
import { Line } from "react-chartjs-2";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/statistics/statisticsList.css";

// icon
import CalendarIcon from "../../img/data_icon.svg";
import { eachDayOfInterval } from "date-fns";

import { formatDash } from "../../common/Utils";


class StatisticsListView extends StatisticsListData {
  constructor(props) {
    super(props);

    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      hotelId: "",
      startDate: "",
      endDate: "",

      // 임시값
      tempHotelId: "",
      tempStartDate: "",
      tempEndDate: "",
    };
  }

  /* ---------------------------------------------------- lifecycle --------------------------------------------------- */
  componentDidMount() {
    const { history, location } = this.props;
    this.requestGetHotelList();
    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      // Date 설정
      const startDate = new Date();
      const yesterday = startDate.getDate() - 7;
      startDate.setHours(0, 0, 0, 0);
      startDate.setDate(yesterday);

      const endDate = new Date();
      endDate.setHours(23, 59, 59, 0);
      this.setState(
        {
          startDate: startDate, // startDate를 일주일 전으로 설정
          endDate: endDate,
          tempStartDate: startDate,
          tempEndDate: endDate,
        },
        () => {
          this.requestGetStatisticsList(this.state.pageNo, this.formatDateISO(startDate), this.formatDateISO(endDate), this.state.hotelId);
        }
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* -------------------------------------------------- event handler ------------------------------------------------- */
  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    const { history, location } = this.props;
    let value = "";

    if (stateName == "tempStartDate" || stateName == "tempEndDate") {
      value = e;
      history.replace(location.pathname, this.state);
    } else {
      value = e.target.value;
    }

    this.setState({ [stateName]: value }, () => {
      if (stateName == "tempHotelId" || stateName == "tempStartDate" || stateName == "tempEndDate") {
        this.changeHotelId();
      }
    });
  };

  changeHotelId = () => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        hotelId: this.state.tempHotelId,
        startDate: this.state.tempStartDate,
        endDate: this.state.tempEndDate,
      },
      () => {
        this.requestGetStatisticsList(this.state.pageNo, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate), this.state.hotelId);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          hotelId: this.state.tempHotelId,
          startDate: this.state.tempStartDate,
          endDate: this.state.tempEndDate,
        },
        () => {
          this.requestGetStatisticsList(this.state.pageNo, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate), this.state.hotelId);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState(
      {
        isShowAllLoading: true,
        tempHotelId: this.state.hotelId,
        tempStartDate: this.state.startDate,
        tempEndDate: this.state.endDate,
      },
      () => {
        this.requestGetStatisticsList(pageNo, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate), this.state.hotelId);
      }
    );
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if (isMoved) return;

    this.setState({
      isMoved: true,
    });
  };

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false,
    });
  };

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (date, type) => {
    const { isMoved } = this.state;
    if (isMoved) {
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkStatisticsDetail(date, type);
    }
  };

  // 해당 날짜의 예약 목록 페이지로 이동
  linkStatisticsDetail = (date, type) => {
    const { tempHotelId } = this.state;
    this.props.history.push(`/reservationListPage?hotelId=${tempHotelId}&date=${date}&type=${type}`);
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState(
      {
        pageNo: newPageNo,
        checkedList: [],
      },
      () => {
        history.replace(location.pathname, this.state);
      }
    );
  };

  // 다운로드 버튼을 클릭했을 때 csv 파일을 다운로드
  onDownloadButton = () => {
    const csvContent = this.convertToCSV();
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const hotelName = this.getHotelName();
    const startDate = this.formatDateTime(this.state.tempStartDate);
    const endDate = this.formatDateTime(this.state.tempEndDate);
    const fileName = `reservation_${hotelName}_${startDate}_${endDate}.csv`;
    
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // File name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  };

  /* ------------------------------------------------------- etc ------------------------------------------------------ */
  // YYYY-MM-DDTHH:mm:ss.sssZ로 반환
  formatDateISO = (date) => {
    if (!date || date == "") return;
    return date.toISOString();
  };

  // hotel list option을 생성
  makeHotelListOption = (hotelList) => {
    if (!hotelList) return [];
    return (
      hotelList.length > 0 &&
      hotelList.map((hotel) => {
        return (
          <option value={hotel.id} key={hotel.id}>
            {hotel.name}
          </option>
        );
      })
    );
  };

  // 같은 날짜의 데이터를 한 개의 객체로 합치기
  groupByDate = (list) => {
    if (!list || list.length == 0) return [];
    const countData = [];

    // 객체의 각 키를 순회
    for (const key in list) {
      if (list.hasOwnProperty(key)) {
        // 객체의 자기 속성만을 체크
        countData.push({
          date: key,
          count: list[key],
        });
      }
    }
    return countData;
    // return list.reduce((acc, cur) => {
    //   const dateIndex = acc.findIndex((data) => data.date == cur.date);
    //   if (dateIndex == -1) {
    //     // 날짜가 존재하지 않으면 새로운 날짜 객체를 생성
    //     const newDate = {
    //       date: cur.date,
    //       count: {
    //         [cur.type]: cur.count,
    //       },
    //     };
    //     acc.push(newDate);
    //   } else {
    //     // 날짜가 이미 존재하면 해당 날짜 객체에 새로운 데이터를 추가
    //     acc[dateIndex].count = {
    //       ...acc[dateIndex].count,
    //       [cur.type]: cur.count,
    //     };
    //   }
    //   return acc;
    // }, []);
  };

  initializeChartDate = (countData) => {
    const datasets_label = ["RESERVATION", "WALKIN", "CHECKIN", "CHECKOUT"];
    const { startDate, endDate } = this.state;
    let eachDayFromStartToEnd = [];

    if (startDate == "" || endDate == "") {
    } else {
      eachDayFromStartToEnd = eachDayOfInterval({
        start: startDate,
        end: endDate,
      }).map((date) => formatDash(date));
    }

    const labels = eachDayFromStartToEnd;
    const datasets = datasets_label.map((label) => {
      const dataColor = this.matchColor(label);
      return {
        type: "line",
        label: label,
        // backgroundColor: dataColor,
        // borderColor: dataColor,
        borderWidth: 2,
        data: new Array(labels.length).fill(0),
      };
    });
    if (countData) {
      datasets.map((dataset) => {
        // datasets: [{..., label: "CHECKIN", data: [0,0,0] }, {..., label: "WALKIN", data: [0,0,0] }, ...]
        // labels: [2024-01-02, 2024-01-03, 2024-01-04]
        // countData : [ {date: '2024-01-02', count: { CHECKIN: 2}}];

        // countData를 순회하며 data의 값들을 dataset data에 할당(날짜순서에 맞게)
        countData.map((data) => {
          const dateIndex = labels.findIndex((date) => date == data.date);
          if (Object.keys(data.count).includes(dataset.label)) {
            dataset.data[dateIndex] = data.count[dataset.label];
          }
        });
      });
    }
    return {
      labels: labels,
      datasets: datasets,
    };
  };

  matchColor = (label) => {
    switch (label) {
      case "RESERVATION":
        return "red";
      case "WALKIN":
        return "blue";
      case "CHECKIN":
        return "yellow";
      case "CHECKOUT":
        return "violet";
      default:
        return "white";
    }
  };

  initializeChartOption = (maxNumber) => {
    const options = {
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: true,
            text: "Date",
            color: "white",
            font: {
              size: 20,
              weight: "bold",
              lineHeight: 1.2,
            },
            align: "end",
          },
          grid: {
            display: false,
          },
          ticks: {
            color: "white",
            font: {
              size: 13,
            },
          },
          border: {
            color: "white",
          },
        },
        y: {
          title: {
            display: true,
            text: "Count",
            color: "white",
            font: {
              size: 20,
              weight: "bold",
              lineHeight: 1.2,
            },
            align: "end",
          },
          grid: {
            display: true,
            color: "#777", // 가로 표시선
          },
          ticks: {
            stepSize: 1,
            color: "white",
            font: {
              size: 13,
            },
          },
          beginAtZero: true,
          // border: {
          //   color: "white",
          // },
          // min: 0,
          suggestedMax: maxNumber,
        },
      },
      plugins: {
        customCanvasBackgroundColor: {
          color: "lightGreen",
        },
        legend: {
          labels: {
            font: {
              size: 12,
            },
            color: "white",
          },
          maxHeight: 40,
        },
      },
    };
    return options;
  };

  // data중 가장 큰 값의 1.2배한값을 반올림한 값을 반환
  calculateMax = (statisticsList) => {
    const numberArray = statisticsList.reduce((acc, cur) => {
      const numberArr = Object.values(cur.count);
      return [...acc, ...numberArr];
    }, []);
    let max = 0;
    const maxData = Math.max(...numberArray);
    max = Math.round(maxData * 1.2);
    return max;
  };

  // table 데이터를 CSV 포맷으로 변환
  convertToCSV = () => {
    const tableData = [];

    // hotel name
    const hotelName = this.getHotelName();
    tableData.push(["Hotel", hotelName]);

    // period
    const startDate = formatDash(this.state.tempStartDate);
    const endDate = formatDash(this.state.tempEndDate);
    tableData.push(["Start Date", startDate]);
    tableData.push(["End Date", endDate]);

    // table header
    const tableHeader = ["Date", "Reservation", "Walk-In", "Check In", "Checked Out"];
    tableData.push(tableHeader);

    // table body
    if (this.props.statisticsList && this.props.statisticsList?.success == true) {
      // 일별
      const statisticsList = this.groupByDate(this.props.statisticsList.results.dailyCount);
      const tableBody = statisticsList.map((statistics) => {
        const date = statistics?.date;
        const reservation = statistics?.count.RESERVATION ?? "0";
        const workIn = statistics?.count.WALKIN ?? "0";
        const checkIn = statistics?.count.CHECKIN ?? "0";
        const checkOut = statistics?.count.CHECKOUT ?? "0";
        return [date, reservation, workIn, checkIn, checkOut];
      });
      // 합계
      const total = this.props.statisticsList.results.total;
      const totalReservation = total?.RESERVATION ?? "0";
      const totalWorkIn = total?.WALKIN ?? "0";
      const totalCheckIn = total?.CHECKIN ?? "0";
      const totalCheckOut = total?.CHECKOUT ?? "0";
      tableData.push(...tableBody);
      tableData.push(["Total", totalReservation, totalWorkIn, totalCheckIn, totalCheckOut]);
    } else {
      tableData.push(["Total", "0", "0", "0", "0"]);
    }

    return tableData.map((row) => row.join(",")).join("\n");
  };

  getHotelName = () => {
    const hotelList = this.props.hotelList.results.rows;
    const hotelId = this.state.tempHotelId;
    const selectedHotel = hotelList?.find((hotel) => hotel.id == hotelId);
    let hotelName = "";
    if (selectedHotel) {
      hotelName = selectedHotel.name;
    } else {
      hotelName = "ALL";
    }

    return hotelName;
  };

  //날자 포맷팅 함수 (20240101)
  formatDateTime = (isoString) => {
    if (isoString == null || isoString == undefined || isoString == "") {
      return "-";
    }
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  };

  render() {
    let count;
    let hotelList = [];
    let statisticsList = [];
    let total = null;
    let chartData = this.initializeChartDate();
    let chartOption = this.initializeChartOption(1);

    if (this.props.hotelList != null && this.props.hotelList?.success == true) {
      hotelList = this.props.hotelList.results.rows;
    }
    if (this.props.statisticsList && this.props.statisticsList?.success == true) {
      statisticsList = this.groupByDate(this.props.statisticsList.results.dailyCount);
      total = this.props.statisticsList.results.total;
      chartData = this.initializeChartDate(statisticsList);
      chartOption = this.initializeChartOption(this.calculateMax(statisticsList));
    }
    return (
      <div className="statisticsList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="statistics_list_container">
          <div className="statistics_list_container_inner">
            <div className="title" data-detect="admin_statistics_list_title">
              STATISTICS LIST
            </div>
            <div className="period_container">
              <div className="period_start">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  shouldCloseOnSelect
                  selected={this.state.tempStartDate}
                  onChange={(e) => this.onChangeValue(e, "tempStartDate")}
                  id="termStartPicker"
                  name="startDate"
                />
                <img
                  src={CalendarIcon}
                  alt="calendar icon"
                  onClick={() => {
                    document.getElementById("termStartPicker").click();
                  }}
                />
              </div>
              <div className="period_line"></div>
              <div className="period_end">
                <DatePicker dateFormat="yyyy-MM-dd" shouldCloseOnSelect selected={this.state.tempEndDate} onChange={(e) => this.onChangeValue(e, "tempEndDate")} id="termEndPicker" />
                <img
                  src={CalendarIcon}
                  alt="calendar icon"
                  onClick={() => {
                    document.getElementById("termEndPicker").click();
                  }}
                />
              </div>
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="find">
                    <select id="hotel_select" className="search_select_hotel" name="" value={this.state.tempHotelId} onChange={(e) => this.onChangeValue(e, "tempHotelId")}>
                      <option value="" data-detect="admin_statistics_list_placeholder_select_box_choose">
                        Hotel (All)
                      </option>
                      {this.makeHotelListOption(hotelList)}
                    </select>
                  </div>
                  {/* <div className="search">
                    <input
                      type="search"
                      id="search_input"
                      data-detect-placeholder="admin_statistics_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                    />
                    <button type="button" className="search_btn" onClick={this.changeHotelId}></button>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="graph_container">
              <Line data={chartData} options={chartOption} />
            </div>
            <div className="statistics_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell" data-detect="admin_statistics_list_label_date">
                      Date
                    </div>
                    <div className="cell" data-detect="admin_statistics_list_label_reservation">
                      Reservation
                    </div>
                    <div className="cell" data-detect="admin_statistics_list_label_walk_in">
                      Walk-In
                    </div>
                    <div className="cell" data-detect="admin_statistics_list_label_check_in">
                      Check In
                    </div>
                    <div className="cell" data-detect="admin_statistics_list_label_checked_out">
                      Checked Out
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {statisticsList.length > 0 &&
                    statisticsList.map((statistics, index) => (
                      <div className="row" key={index}>
                        <div className="cell">{statistics?.date}</div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(statistics.date, "reservation")} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {statistics?.count?.RESERVATION || "0"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(statistics.date, "walkin")} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {statistics?.count?.WALKIN || "0"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(statistics.date, "checkin")} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {statistics?.count?.CHECKIN || "0"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(statistics.date, "checkout")} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {statistics?.count?.CHECKOUT || "0"}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="tfoot">
                  <div className="row">
                    <div className="cell">Total</div>
                    <div className="cell">{total?.RESERVATION || "0"}</div>
                    <div className="cell">{total?.WALKIN || "0"}</div>
                    <div className="cell">{total?.CHECKIN || "0"}</div>
                    <div className="cell">{total?.CHECKOUT || "0"}</div>
                  </div>
                </div>
              </div>
              {/* <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} /> */}
            </div>
            <div className="button_form_container">
              <button type="button" className="download_btn" data-detect="admin_statistics_list_button_download" onClick={this.onDownloadButton}>
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = StatisticsListData.mapStateToProps;
let mapDispatchToProps = StatisticsListData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatisticsListView));
